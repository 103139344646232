import { gql } from "@apollo/client";

export const create_many_clinic_member = gql`mutation CreateManyClinicMember(
    $data: [ClinicMemberCreateManyInput!]!
    $skipDuplicates: Boolean
  ) {
    createManyClinicMember(data: $data, skipDuplicates: $skipDuplicates) {
      count
    }
  }
  `;
export const getCreateManyClinicMemberVar = (clinicId: string, members: Array<{ name: string, phonenumber: string }>) => ({
  "data": members.map((m) => ({ ...m, clinic_id: clinicId })),
  "skipDuplicates": true
});
export const get_slim_members = gql`
query Members(
  $where: MemberWhereInput
  $orderBy: [MemberOrderByWithRelationInput!]
  $take: Int
  $skip: Int
) {
  members(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
    id
    name
    phonenumber
  }
}
`;
export const getSlimMemberVar = (data: {
  clinicId: string,
  skip: number,
  take: number,
}) => {
  const { skip, take } = data;
  let variables: any = {}, where: any = {}
  where.clinics = { some: { id: { equals: data.clinicId } } }
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }]
  variables.skip = skip
  variables.take = take
  return variables;
}

export const delete_many_clinic_member = gql`
mutation DeleteManyClinicMember($where: ClinicMemberWhereInput) {
  deleteManyClinicMember(where: $where) {
    count
  }
}
`;

export const getDeleteManyClinicMemberVar = (clinicId: string, phoneNumbers: string[]) => ({
  "where": {
    "phonenumber": {
      "in": phoneNumbers
    },
    clinic_id: {
      equals: clinicId
    }
  }
});

export const updateOneClinicMember = gql`
  mutation UpdateOneClinicMember(
    $data: ClinicMemberUpdateInput!
    $where: ClinicMemberWhereUniqueInput!
  ) {
    updateOneClinicMember(data: $data, where: $where) {
      id
    }
  }
`;

export const updateOneClinicMemberVar = (
  clinicMemberId: string,
  practitionerId: string
) => {
  return {
    'where': {
      'id': clinicMemberId,
    },
    'data': {
      'practitioner': {
        'connect': {
          'id': practitionerId,
        },
      },
    },
  };
};

export const getOneClinicMember = gql`
  query ClinicMember($where: ClinicMemberWhereUniqueInput!) {
    clinicMember(where: $where) {
      name
      id
      practitioner {
        name
        id
      }
    }
  }
`;

export const getOneClinicMemberVar = (clinicMemberId: string) => {
  return {
    where: {
      id: clinicMemberId,
    },
  };
};