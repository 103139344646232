import React, { useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Booking,
  CheckIn,
  Clinic,
  Member,
  MemberServiceBag,
  Order,
  Service,
} from "../../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useQuery, useLazyQuery, useApolloClient } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import MultiSelect from "../../components/multi_select";
import { formatMoney } from "../../helpers/utils";
import { Avatar } from "antd";
import {
  getMemberBookingVar,
  getMemberCheckInVar,
  getMemberSvcBagsVar,
  getMembersVar2,
  get_member_bookings,
  get_member_checkins,
  get_member_svc_bags,
  get_members_2,
} from "../../graphql/member";
import {
  getClinicMembersBookingCountVar,
  get_clinic_members_booking_count,
} from "../../graphql/booking";
import {
  getMembersCheckInCountVar,
  get_members_checkin_count,
} from "../../graphql/checkin";
import {
  getMembersOrderCountVar,
  get_members_order_count,
} from "../../graphql/order";
import { Card } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import paths from "../../routes/paths";
import ImportButton from "../../components/import_button";
import { Button } from "antd";
import {
  CopyOutlined,
  DoubleLeftOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import {
  create_many_clinic_member,
  getCreateManyClinicMemberVar,
  getSlimMemberVar,
  get_slim_members,
} from "../../graphql/clinic_member";
import { Tag } from "antd";
interface P {
  currentClinic?: Clinic;
}

const MemberPage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const navigate = useNavigate();
  const client = useApolloClient();
  const [searchParams] = useSearchParams();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [memberIds, setMemberIds] = useState<string[]>([]);
  const [Ids, setIds] = useState<string[]>([]);
  const [duplicating, setDuplicate] = useState(false);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const [cursor, setCursor] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [next, setNext] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: addDays(new Date(), 15),
  });
  // console.log(Ids);

  const [form] = Form.useForm();
  const variables = getMembersVar2({
    clinicId,
    search: searchText,
    version: "v1",
    cursor,
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"getMembers", Member[]>
  >(get_members_2, { variables });
  const loadNext = () => {
    if (Ids.length > 0) {
      setCursor(Ids[Ids.length - 1]);
      setIds(Ids);

      setCurrentPage((prev) => prev + 1);
    }
  };
  const loadPrevious = () => {
    if (Ids.length > 2) {
      const id = Ids.pop();
      // console.log(id);

      const lastMemberId = Ids[Ids.length - 2];
      // console.log(lastMemberId);

      setIds(Ids);
      setCursor(lastMemberId);
      setCurrentPage((prev) => prev - 1);
      Ids.pop();
    } else {
      setIds([]);
      setCurrentPage(1);
      setCursor(null);
    }
  };
  const { data: mbrSrvBags, loading: mbrSrvBagLoading } = useQuery<
    ApolloResult<"memberServiceBags", MemberServiceBag[]>
  >(get_member_svc_bags, {
    variables: getMemberSvcBagsVar({ clinicId, memberIds }),
  });

  const bookingHistory = useQuery<ApolloResult<"bookings", Booking[]>>(
    get_member_bookings,
    {
      variables: getMemberBookingVar({
        clinicId,
        memberIds,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      }),
    }
  );
  const checkInHistory = useQuery<ApolloResult<"checkIns", CheckIn[]>>(
    get_member_checkins,
    {
      variables: getMemberCheckInVar({
        clinicId,
        memberIds,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      }),
    }
  );
  const [getBookingCount, { data: bookingData, loading: bload }] = useLazyQuery<
    ApolloResult<
      "groupByBooking",
      Array<{ _count: { _all: number }; member_id: string }>
    >
  >(get_clinic_members_booking_count);
  const [getCheckInCount, { data: checkInData, loading: cload }] = useLazyQuery<
    ApolloResult<
      "groupByCheckIn",
      Array<{ _count: { _all: number }; member_id: string }>
    >
  >(get_members_checkin_count);
  const [getOrderCount, { data: orderData, loading: oload }] = useLazyQuery<
    ApolloResult<
      "groupByOrder",
      Array<{ _count: { _all: number }; member_id: string }>
    >
  >(get_members_order_count);
  const checking = bload || cload || oload;
  const bookingCount = Object.assign(
    {},
    ...(bookingData?.groupByBooking || []).map((a) => ({
      [a.member_id]: a._count._all,
    }))
  );
  const checkInCount = Object.assign(
    {},
    ...(checkInData?.groupByCheckIn || []).map((a) => ({
      [a.member_id]: a._count._all,
    }))
  );
  const orderCount = Object.assign(
    {},
    ...(orderData?.groupByOrder || []).map((a) => ({
      [a.member_id]: a._count._all,
    }))
  );
  const loadCount = (memberIds: string[], startDate: Date, endDate: Date) => {
    getBookingCount({
      variables: getClinicMembersBookingCountVar({
        clinicId,
        memberIds,
        startDate,
        endDate,
      }),
    });
    getCheckInCount({
      variables: getMembersCheckInCountVar({
        clinicId,
        memberIds,
        startDate,
        endDate,
      }),
    });
    getOrderCount({
      variables: getMembersOrderCountVar({
        clinicId,
        memberIds,
        startDate,
        endDate,
      }),
    });
  };
  useEffect(() => {
    if (data?.getMembers) {
      const memberIds = data.getMembers.map((m) => m.id);
      loadCount(memberIds, ranges.startDate, ranges.endDate);
      const lastMember = data?.getMembers[data.getMembers.length - 1];
      if (data.getMembers.length === 20) {
        setIds((prev) => [...prev, lastMember?.id]);
        setNext(true);
      } else {
        setIds((prev) => [...prev, "last"]);
        setNext(false);
      }
    }
  }, [data]);
  useEffect(() => {
    if (clinicId) {
      setCursor(null);
      setCurrentPage(1);
    }
  }, [clinicId]);
  useEffect(() => {
    if (searchParams.get("mode")) {
      const mode = searchParams.get("mode");
      if (mode === "support") {
        setShowDuplicate(true);
      }
    }
  }, [searchParams.get("mode")]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onExpandedRowsChange = async (Ids: string[]) => {
    setMemberIds(Ids);
  };
  const expandedRowRender = (row: Member) => {
    const columnA: any[] = [
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: MemberServiceBag, b: MemberServiceBag) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        render: (v: number, r: MemberServiceBag) => {
          return r.service.name;
        },
      },
      {
        title: "Usage",
        dataIndex: "usage",
        key: "usage",
        editable: false,
        sorter: (a: MemberServiceBag, b: MemberServiceBag) =>
          a.remaining_count - b.remaining_count,
        render: (v: number, r: MemberServiceBag) => {
          return `${r.remaining_count}/${r.original_count}`;
        },
      },
      {
        title: "Package",
        dataIndex: "package",
        key: "package",
        editable: false,
        sorter: (a: MemberServiceBag, b: MemberServiceBag) =>
          (a.service_package?.name || "").localeCompare(
            b.service_package?.name || ""
          ),
        render: (v: number, r: MemberServiceBag) => {
          return r.service_package?.name || "";
        },
      },
    ];
    let columnB: any[] = [
      {
        title: "Date",
        dataIndex: "from_time",
        key: "from_time",
        editable: false,
        sorter: (a: Booking, b: Booking) =>
          new Date(a.from_time).getTime() - new Date(b.from_time).getTime(),
        render: (v: number, r: Booking) => {
          return format(new Date(r.from_time), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: Booking, b: Booking) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        render: (v: number, r: Booking) => {
          return r.service.name;
        },
      },
      {
        title: "Staus",
        dataIndex: "status",
        key: "status",
        sorter: (a: Booking, b: Booking) => a.status.localeCompare(b.status),
        editable: false,
      },
    ];
    let colummC: any[] = [
      {
        title: "CheckIn Time",
        dataIndex: "in_time",
        key: "in_time",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          new Date(a.in_time).getTime() - new Date(b.in_time).getTime(),
        render: (v: number, r: CheckIn) => {
          return format(new Date(r.in_time), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        render: (v: number, r: CheckIn) => {
          return r.service.name;
        },
      },
      {
        title: "Staus",
        dataIndex: "status",
        key: "status",
        sorter: (a: CheckIn, b: CheckIn) => a.status.localeCompare(b.status),
        editable: false,
      },
    ];
    return (
      <Row>
        <Col span={8}>
          <Card
            loading={mbrSrvBagLoading}
            size="small"
            type="inner"
            title={`${row.name}(Purchased Services)`}
          >
            <Table
              loading={mbrSrvBagLoading}
              showHeader={(mbrSrvBags?.memberServiceBags || []).length > 0}
              size="small"
              columns={columnA}
              dataSource={(mbrSrvBags?.memberServiceBags || []).filter(
                (b) => b.member_id === row.id && b.remaining_count > 0
              )}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card type="inner" title={`${row.name}(Bookings)`} size="small">
            <Table
              loading={bookingHistory.loading}
              showHeader={(bookingHistory.data?.bookings || []).length > 0}
              size="small"
              columns={columnB}
              dataSource={(bookingHistory.data?.bookings || []).filter(
                (b) => b.member_id === row.id
              )}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card title={`${row.name}(CheckIns)`} type="inner" size="small">
            <Table
              loading={checkInHistory.loading}
              showHeader={(checkInHistory.data?.checkIns || []).length > 0}
              size="small"
              columns={colummC}
              dataSource={(checkInHistory.data?.checkIns || []).filter(
                (c) => c.member_id === row.id
              )}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
      </Row>
    );
  };
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    if (data.getMembers && data.getMembers.length > 0) {
      const memberIds = data.getMembers.map((m) => m.id);
      loadCount(memberIds, startDate, endDate);
    }
  };
  const handleDuplicateClinicMember = async () => {
    setDuplicate(true);
    let rolling = true;
    const take = 200;
    let skip = 0;
    while (rolling) {
      const variables = getSlimMemberVar({
        clinicId,
        take,
        skip,
      });
      const result = await client.query<ApolloResult<"members", Member[]>>({
        query: get_slim_members,
        variables,
      });
      const members = result.data?.members || [];
      if (members.length > 0) {
        const var2 = getCreateManyClinicMemberVar(
          clinicId,
          members.map((m) => ({ name: m.name, phonenumber: m.phonenumber }))
        );
        await client.mutate<
          ApolloResult<"createManyClinicMember", { count: number }>
        >({ mutation: create_many_clinic_member, variables: var2 });
      }
      skip += members.length;
      rolling = members.length === take;
    }
    setDuplicate(false);
  };

  const columns: any[] = [
    {
      title: "Photo",
      dataIndex: "image",
      key: "image",
      editable: false,
      render: (v: number, r: Member) => {
        return (
          <Link to={paths.getMemberDetailRoute(r.id)}>
            {" "}
            <Avatar src={r.image} size={50} />
          </Link>
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: false,
      sorter: (a: Member, b: Member) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      defaultSortOrder: "ascend",
      render: (v: number, r: Member) => {
        return <Link to={paths.getMemberDetailRoute(r.id)}>{r.name}</Link>;
      },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: Member, b: Member) => a.status.localeCompare(b.status),
      editable: false,
    },
    {
      title: "Member Id",
      dataIndex: "member_id",
      key: "member_id",
      sorter: (a: Member, b: Member) => a.member_id.localeCompare(b.member_id),
      editable: false,
    },
    {
      title: "PhoneNo",
      dataIndex: "phonenumber",
      key: "phonenumber",
      sorter: (a: Member, b: Member) =>
        a.phonenumber.localeCompare(b.phonenumber),
      editable: true,
    },
    {
      title: "Booking",
      dataIndex: "booking",
      key: "booking",
      sorter: (a: Member, b: Member) =>
        (bookingCount[a.id] || 0) - (bookingCount[b.id] || 0),
      editable: true,
      render: (v: number, r: Member) => {
        return bookingCount[r.id] || 0;
      },
    },
    {
      title: "CheckIn",
      dataIndex: "check_in",
      key: "check_in",
      sorter: (a: Member, b: Member) =>
        (checkInCount[a.id] || 0) - (checkInCount[b.id] || 0),
      editable: true,
      render: (v: number, r: Member) => {
        return checkInCount[r.id] || 0;
      },
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      sorter: (a: Member, b: Member) =>
        (orderCount[a.id] || 0) - (orderCount[b.id] || 0),
      editable: true,
      render: (v: number, r: Member) => {
        return orderCount[r.id] || 0;
      },
    },
    {
      title: "JoinedAt",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: Member, b: Member) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Member, r: Member) => {
        return format(new Date(r.created_at), "dd MMM,yyyy");
      },
    },
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Members</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${
                props.currentClinic?._count?.members || ""
              } member...`}
              allowClear
              size="middle"
              onSearch={(val: any) => {
                setSearchText(val);
                setCurrentPage(1);
                setIds([]);
                setCursor(null);
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
        <ImportButton
          title="Member Import"
          label="excel import"
          loading={false}
          onClick={() => navigate(paths.memberImport)}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={checking}
            showHeader={data.getMembers.length > 0}
            // components={{ body: { cell: EditableCell, } }}
            expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={data.getMembers.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4 }}>
      <Col span={6}>
        <Row>
          <Button
            size="small"
            icon={<DoubleLeftOutlined />}
            onClick={loadPrevious}
            disabled={!cursor}
          >
            Previous
          </Button>
          <Tag style={{ marginRight: 0 }}>{currentPage}</Tag>
          <Button
            size="small"
            icon={<DoubleRightOutlined />}
            onClick={loadNext}
            disabled={!next}
          >
            Next
          </Button>
        </Row>
      </Col>
      <Col span={16}>
        <MultiSelect
          selected={filter}
          placeholder="Select member status"
          options={["ACTIVE", "INACTIVE"]}
          onChange={setFilter}
        />
      </Col>

      {showDuplicate && (
        <Col span={2}>
          <Button
            size="small"
            icon={<CopyOutlined />}
            loading={duplicating}
            onClick={handleDuplicateClinicMember}
          >
            Duplicate
          </Button>
        </Col>
      )}
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(MemberPage);
