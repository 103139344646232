import { gql } from "@apollo/client";

export const get_psi = gql`
query ProductStockItems($where: ProductStockItemWhereInput, $orderBy: [ProductStockItemOrderByWithRelationInput!], $take: Int) {
    productStockItems(where: $where, orderBy: $orderBy, take: $take) {
      id
      name
      price
      sku
      sort_order
      status
      stock
      stock_control_unit
      supply_price
      tax
      service_stock
      clinic_id
      created_at
      original_price
      images {
        image
      }
      product_id
      product {
        name
        id
      }
    }
  }
`;
export const getPSIVar = (data: {
    clinicId: string,
    startDate: Date,
    endDate: Date,
    status?: string[];
    searchText?: string | null,
    take: number,
}) => {
    const { status = [], searchText } = data;
    let variables: any = {}, where: any = {}, OR: any[] = [];
    if (searchText && searchText !== "") {
        OR.push({
            product: {
                is: {
                    OR: [
                        { name: { contains: searchText } },
                    ]
                }
            }
        });
        OR.push({ name: { contains: searchText } })
    }
    where.clinic_id = { equals: data.clinicId }
    // where.created_at = { gte: data.startDate, lte: data.endDate };
    if (status.length > 0) where.status = { in: status };
    if (OR.length > 0) where.OR = OR;
    variables.where = where;
    variables.orderBy = [{ created_at: "desc" }]
    variables.take = 100
    return variables;
}