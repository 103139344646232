import React from 'react';

const ResponsiveEmbedBI = ({ src, title }: { src: string, title: string }) => {
    return (
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', background: '#ccc' }}>
            <iframe
                src={src}
                title={title}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    border: 'none'
                }}
                frameBorder="0"
                allowFullScreen
            />
        </div>
    );
};

export default ResponsiveEmbedBI;
