import { createHttpLink } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import Cookies from "js-cookie";

const httpLink = createHttpLink({
    uri: "https://api.pitipass.com/graphql"
    // uri: "http://localhost:4000/graphql"
});
const auth = setContext(async (_, { headers, }) => {
    let token: string | null = null;
    if (!token) {
        token = Cookies.get('p_token') || null;
    }
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});
export default auth.concat(httpLink);