import React from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router";
import { AppState, AuthState } from "../store";
import paths from "../routes/paths";
import { useSearchParams } from "react-router-dom";
import { encryptData } from "../helpers/crypto";

const withUser = (WrappedComponent: any) => {
  const HOC = (props: any) => {
    const location = useLocation();
    const [searchParams] = useSearchParams();

    let search: string | null = null;
    if (searchParams.get("mode")) {
      search = `mode=${searchParams.get("mode")}`;
    }
    const { pathname } = location;
    const auth = props.user as AuthState;

    // if (props.clinic) encryptData("clinicInfo", props.clinc);
    const state = { ...location.state, from: location.pathname, search };

    //<Navigate to={`/login?redirect=${pathname ? pathname : '/home'}`} replace />
    return auth.status === "loggedIn" ? (
      <WrappedComponent {...props} />
    ) : (
      <Navigate to={paths.login} state={state} replace />
    );
  };
  const mapStateToProps = (state: AppState) => {
    return {
      user: state.authState,
      clinic: state.clinicState,
    };
  };
  const mapStateToDispatch = (dispatch: any) => {
    return {
      dispatch,
    };
  };
  return connect(mapStateToProps, mapStateToDispatch)(HOC);
};

export default withUser;
