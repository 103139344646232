import { useEffect, useState } from 'react';
import { getBI } from '../bi-dashboard';
import withClinic from '../hooks/with_clinic';
import BIContainer from './container';
import Unauthorized from './unauthorized';

function ServiceBIDashboard(props: any) {
  const [bi, setBI] = useState(getBI(props?.currentClinic?.code || "", 'service'));
  useEffect(() => { 
    if (props.currentClinic){
      setBI(getBI(props?.currentClinic?.code || "", 'service'));
    }
  },[props.currentClinic])

  if (bi) return <BIContainer title='Service Behaviour' src={bi.link} />
  return <Unauthorized />
}

export default withClinic(ServiceBIDashboard);
