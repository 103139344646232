import React, { useCallback, useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import { ApolloResult, Clinic, Practitioner, Service } from "../../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { addDays, endOfMonth, format, startOfMonth, subDays } from "date-fns";
import { useLazyQuery, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import MultiSelect from "../../components/multi_select";
import {
  getPractitionerCheckInCountVar,
  // getservicesVar,
  get_practitioner_checkin_count,
  get_practitioners,
} from "../../graphql/practitioner";
import { ExportExcel } from "../../helpers/excel";
import { FileExcelFilled } from "@ant-design/icons";
import { Button } from "antd";
import {
  excelTopTreatmentCheckInCountVar,
  excelTopTreatmentVar,
  excel_top_treatment,
  excel_top_treatment_checkin_count,
  getTopTreatmentCheckInCountVar,
  getTopTreatmentVar,
  get_top_treatment,
  get_top_treatment_checkin_count,
} from "../../graphql/reports/top_treatment";
import client from "../../graphql/apollo_client";
interface P {
  currentClinic?: Clinic;
}

const TopTreatment = (props: P) => {
  const clinicId = props.currentClinic?.id || "";

  const [searchText, setSearchText] = useState<string | null>(null);
  const [exporting, setExporting] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });
  const [form] = Form.useForm();
  const variables = getTopTreatmentVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    take: 150,
    status: "ACTIVE",
    searchText: searchText,
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"services", Service[]>
  >(get_top_treatment, { variables });

  const [getCheckInCount, { data: checkInData, loading: cload }] = useLazyQuery<
    ApolloResult<
      "groupByCheckIn",
      Array<{ _count: { _all: number }; service_id: string }>
    >
  >(get_top_treatment_checkin_count);
  const checking = cload;

  const checkInCount = Object.assign(
    {},
    ...(checkInData?.groupByCheckIn || []).map((a) => ({
      [a.service_id]: a._count._all,
    }))
  );
  const load = useCallback(
    (v: { serviceIds: string[]; startDate: Date; endDate: Date }) => {
      getCheckInCount({
        variables: getTopTreatmentCheckInCountVar({
          ...v,
          clinicId,
          status: "CHECKOUT",
        }),
      });
    },
    [clinicId, getCheckInCount]
  );

  useEffect(() => {
    if (data?.services && data.services.length > 0) {
      const serviceIds = data.services.map((p) => p.id);
      const { startDate, endDate } = ranges;
      load({ serviceIds, startDate, endDate });
    }
  }, [data, ranges, load]);

  // const handleExport = async () => {
  //   const fileName =
  //     "Top_Treatment_" +
  //     format(ranges.startDate, "yyyy-MMM-dd") +
  //     "_" +
  //     format(ranges.endDate, "yyyy-MMM-dd");
  //   let excelData: any[] = [];

  //   let list: Service[] = [];
  //   let finalData: any[] = [];
  //   let serviceCheckInCount;
  //   let rolling = true;
  //   const take = 100;
  //   let skip = 0;
  //   setExporting(true);
  //   while (rolling) {
  //     const variables = excelTopTreatmentVar({
  //       clinicId,
  //       take,
  //       skip,

  //       startDate: ranges.startDate,
  //       endDate: ranges.endDate,
  //     });
  //     const result = await client.query<ApolloResult<"services", Service[]>>({
  //       query: excel_top_treatment,
  //       variables,
  //     });
  //     const variables2 = excelTopTreatmentCheckInCountVar({
  //       clinicId,
  //       startDate: ranges.startDate,
  //       endDate: ranges.endDate,
  //       serviceIds: result.data.services.map((v) => v.id),
  //       status: "CHECKOUT",
  //     });
  //     const checkInCount = await client.query<
  //       ApolloResult<
  //         "groupByCheckIn",
  //         Array<{ _count: { _all: number }; service_id: string }>
  //       >
  //     >({
  //       query: excel_top_treatment_checkin_count,
  //       variables: variables2,
  //     });

  //     serviceCheckInCount = Object.assign(
  //       {},
  //       ...(checkInCount?.data.groupByCheckIn || []).map((a) => ({
  //         [a.service_id]: a._count._all,
  //       }))
  //     );

  //     console.log(finalData);

  //     const _services = result.data.services || [];
  //     list = list.concat(_services);

  //     skip += _services.length;
  //     rolling = _services.length === take;
  //   }
  //   let totalCheckOutCount = 0;
  //   list.sort((a, b) => {
  //     const countA = checkInCount[a.id] || 0;
  //     const countB = checkInCount[b.id] || 0;
  //     return countB - countA;
  //   });

  //   for (const t of list) {
  //     const Service = t.name;
  //     const CheckOutCount = serviceCheckInCount[t.id] || 0;

  //     excelData.push({
  //       Service,
  //       CheckOutCount,
  //     });
  //     totalCheckOutCount += CheckOutCount;
  //   }
  //   excelData.push({
  //     Service: "total",
  //     CheckOutCount: totalCheckOutCount,
  //   });
  //   ExportExcel(excelData, fileName);
  //   setExporting(false);
  // };
  const handleExport = async () => {
    const fileName =
      "Top_Treatment_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];

    let list: Service[] = [];
    let finalData: any[] = [];
    let serviceCheckInCount;
    let rolling = true;
    const take = 100;
    let skip = 0;
    setExporting(true);
    while (rolling) {
      const variables = excelTopTreatmentVar({
        clinicId,
        take,
        skip,

        startDate: ranges.startDate,
        endDate: ranges.endDate,
      });
      const result = await client.query<ApolloResult<"services", Service[]>>({
        query: excel_top_treatment,
        variables,
      });
      const variables2 = excelTopTreatmentCheckInCountVar({
        clinicId,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
        serviceIds: result.data.services.map((v) => v.id),
        status: "CHECKOUT",
      });
      const checkInCount = await client.query<
        ApolloResult<
          "groupByCheckIn",
          Array<{ _count: { _all: number }; service_id: string }>
        >
      >({
        query: excel_top_treatment_checkin_count,
        variables: variables2,
      });

      serviceCheckInCount = Object.assign(
        {},
        ...(checkInCount?.data.groupByCheckIn || []).map((a) => ({
          [a.service_id]: a._count._all,
        }))
      );

      // console.log(finalData);

      const _services = result.data.services || [];
      list = list.concat(_services);

      skip += _services.length;
      rolling = _services.length === take;
    }
    let totalCheckOutCount = 0;
    list.sort((a, b) => {
      const countA = checkInCount[a.id] || 0;
      const countB = checkInCount[b.id] || 0;
      return countB - countA;
    });

    for (const t of list) {
      const Service = t.name;
      const CheckOutCount = serviceCheckInCount[t.id] || 0;

      excelData.push({
        Service,
        CheckOutCount,
      });
      totalCheckOutCount += CheckOutCount;
    }
    excelData.push({
      Service: "total",
      CheckOutCount: totalCheckOutCount,
    });
    ExportExcel(excelData, fileName);
    setExporting(false);
  };
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    if (data?.services && data.services.length > 0) {
      const serviceIds = data.services.map((p) => p.id);
      load({ serviceIds, startDate, endDate });
    }
  };
  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: false,
      sorter: (a: Service, b: Service) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      render: (text: number, record: Service) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <img
            src={record.image}
            height={50}
            width={50}
            style={{ objectFit: "cover", objectPosition: "center" }}
            alt=""
          />
          <p>{record.name}</p>
        </div>
      ),
    },
    {
      title: "CheckIn",
      dataIndex: "check_in",
      key: "check_in",
      sorter: (a: Practitioner, b: Practitioner) =>
        (checkInCount[b.id] || 0) - (checkInCount[a.id] || 0),
      sortOrder: "ascend",
      render: (text: number, record: Service) => checkInCount[record.id] || 0,
    },
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Top Treatment Report</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search services...`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
      <Col span={2}>
        <Button
          loading={exporting}
          icon={<FileExcelFilled />}
          onClick={handleExport}>
          Export
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={checking}
            showHeader={data.services.length > 0}
            //components={{ body: { cell: EditableCell, } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={data.services.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}

      {context}
    </React.Fragment>
  );
};

export default withClinic(TopTreatment);
