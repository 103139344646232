import React, { useState } from "react";
import withClinic from "../../hooks/with_clinic";
import { ApolloResult, Booking, CheckIn, Clinic, Order } from "../../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import MultiSelect from "../../components/multi_select";
import { getOrdersVar, get_orders } from "../../graphql/order";
import { formatMoney } from "../../helpers/utils";
import { groupBy, orderBy } from "lodash";
interface P {
  currentClinic?: Clinic;
}

const DailySaleReportPage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 15),
    endDate: addDays(new Date(), 15),
  });
  const [form] = Form.useForm();
  const variables = getOrdersVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take: 20000,
    status: ["ACTIVE", "DONE"],
    notUserIds: ["self_checkout"],
  });
  const { loading, data, error } = useQuery<ApolloResult<"orders", Order[]>>(
    get_orders,
    { variables }
  );
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };
  const filterSales = () => {
    let _data: Array<{
      label: string;
      totalAmount: number;
      netAmount: number;
      discount: number;
    }> = [];
    const _list = (data?.orders || []).map((o) => {
      const date = format(new Date(o.created_at), "dd MMM,yyyy");
      const totalAmount = Number(o.total);
      const netAmount = Number(o.net_total);
      const discount = Number(o.discount);
      return { date, totalAmount, netAmount, discount };
    });
    const days = groupBy(_list, "date");
    Object.keys(days).forEach((d) => {
      const totalAmount = days[d].reduce((a, b) => a + b.totalAmount, 0);
      const netAmount = days[d].reduce((a, b) => a + b.netAmount, 0);
      const discount = days[d].reduce((a, b) => a + b.discount, 0);
      _data.push({ label: d, totalAmount, netAmount, discount });
    });
    return orderBy(_data, "label", "desc");
  };

  const columns: any[] = [
    {
      title: "Date",
      dataIndex: "label",
      key: "label",
      editable: false,
      align: "center",
      sorter: (a: { label: string }, b: { label: string }) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    },
    {
      title: "Total Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: (a: { totalAmount: number }, b: { totalAmount: number }) =>
        a.totalAmount - b.totalAmount,
      editable: true,
      render: (v: number, r: { totalAmount: number }) => {
        return `${formatMoney(r.totalAmount)} MMK`;
      },
    },
    {
      title: "Discount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      sorter: (a: { discount: number }, b: { discount: number }) =>
        a.discount - b.discount,
      editable: true,
      render: (v: number, r: { discount: number }) => {
        return `${formatMoney(r.discount)} MMK`;
      },
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      sorter: (a: { netAmount: number }, b: { netAmount: number }) =>
        a.netAmount - b.netAmount,
      editable: true,
      render: (v: number, r: { netAmount: number }) => {
        return `${formatMoney(r.netAmount)} MMK`;
      },
    },
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Daily Sale Report</Typography>
          </Col>
          {/* <Col span={8} offset={8}>
                        <Search
                            defaultValue={searchText || undefined}
                            placeholder="search ...."
                            allowClear
                            size="middle"
                            onSearch={(val: any) => setSearchText(val)}
                        />

                    </Col> */}
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={loading}
            size="middle"
            showHeader={data.orders.length > 0}
            //components={{ body: { cell: EditableCell, } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={filterSales().map((b: any) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4 }}>
      <Col span={24}>
        <MultiSelect
          selected={filter}
          placeholder="Select orders status"
          options={["ACTIVE", "INACTIVE", "CANCEL", "DONE"]}
          onChange={setFilter}
        />
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {/* {filters} */}
      {context}
    </React.Fragment>
  );
};

export default withClinic(DailySaleReportPage);
