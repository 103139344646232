export const LoginActionTypes = {
    LOGGING_IN: 'auth/LOGGING_IN',
    LOGOUT_REQUEST: 'auth/LOGOUT_REQUEST',
    LOGGED_OUT: 'auth/LOGGED_OUT',
    LOGGED_IN: 'auth/LOGGED_IN',
};

export const AntdActionTypes = {
    SIDE_TOOGLE_CHANGE: 'antd/SIDE_TOOGLE_CHANGE',
}
export const ClinicActionTypes = {
    SET_CLINIC: "CLINIC/SET_CLINIC",
    SET_SELECTED_CLINIC:"CLINIC/SET_SELECTED_CLINIC"
}