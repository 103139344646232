import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Card,
  Form,
  Button,
  Row,
  Col,
  Select,
  Spin,
  Divider,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  getPractitionersVar,
  get_practitioners,
} from "../graphql/practitioner";
import withClinic from "../hooks/with_clinic";
import {
  ApolloResult,
  CheckIn,
  Clinic,
  Member,
  Practitioner,
  Service,
  User,
} from "../store";
import { getMembersVar, get_members } from "../graphql/member";
import { create_one_checkin, getCreateOneCheckInVar } from "../graphql/checkin";
import { getServicesVar, get_services } from "../graphql/service";
import type { FormInstance } from "antd/es/form";
import { Navigate, useNavigate } from "react-router-dom";
import paths from "../routes/paths";
import { DatePicker } from "antd";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { useStore } from "../zustand/store";
const { Search } = Input;
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
interface P {
  currentClinic?: Clinic;
  user?: User;
}

function NewCheckout(props: P) {
  const navigate = useNavigate();
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const userId = props.user?.userId || "";
  const startDate = new Date();
  const endDate = new Date();
  const status = ["ACTIVE"];
  const [mbrText, setMbrText] = useState<string | null>(null);
  const [pracText, setPracText] = useState<string | null>(null);
  const [srvText, setSrvText] = useState<string | null>(null);
  const { value, setValue } = useStore();

  const [getPractitioners, { data: pracData, loading: pracLoading }] =
    useLazyQuery<ApolloResult<"practitioners", Practitioner[]>>(
      get_practitioners,
      { fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" }
    );
  const [getMembers, { data: mbrData, loading: mbrLoading }] = useLazyQuery<
    ApolloResult<"members", Member[]>
  >(get_members, { fetchPolicy: "cache-and-network" });
  const [getServices, { data: srvData, loading: srvLoading }] = useLazyQuery<
    ApolloResult<"services", Service[]>
  >(get_services, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const [createNew, { loading, error }] =
    useMutation<ApolloResult<"createOneCheckIn", CheckIn>>(create_one_checkin);
  const formRef = React.useRef<FormInstance>(null);
  useEffect(() => {
    formRef.current?.resetFields();
    getPractitioners({
      variables: getPractitionersVar({
        clinicId,
        take: 50,
        startDate,
        endDate,
        status,
      }),
    });
    getServices({
      variables: getServicesVar({
        clinicId,
        take: 50,
        endDate,
        startDate,
        status,
      }),
    });
    getMembers({
      variables: getMembersVar({
        clinicId,
        startDate,
        endDate,
        take: 50,
        status,
      }),
    });
  }, []);

  const onSubmit = async (val: any) => {
    const {
      member_id: memberId,
      service_id: serviceId,
      practitioner_id: practitionerId,
      in_time,
    } = val as {
      member_id: string;
      practitioner_id: string;
      service_id: string;
      in_time: any;
    };

    const date = new Date(in_time.local().format("YYYY-MM-DD HH:mm"));
    const inTime = date;
    const outTime = new Date(in_time.local().format("YYYY-MM-DD HH:mm"));
    outTime.setTime(outTime.getTime() + 1 * 60 * 60 * 1000);
    const variables = getCreateOneCheckInVar({
      clinicId,
      serviceId,
      practitionerId,
      memberId,
      inTime,
      outTime,
      userId,
    });
    await setValue({
      memberName: memberId,
      serviceName: serviceId,
    });
    const result = await createNew({ variables });
    if (result.data?.createOneCheckIn) {
      navigate(paths.checkIns);
    }
  };

  return (
    <Card title="New Checkout">
      <Row>
        <Col span={24}>
          <Form
            {...layout}
            ref={formRef}
            name="control-ref"
            onFinish={onSubmit}
            style={{ maxWidth: 600 }}
          >
            <Form.Item
              name="member_id"
              label="Member"
              rules={[{ required: true, message: "Member is required" }]}
            >
              <Select
                key={"member_select"}
                placeholder="select member"
                loading={mbrLoading}
                allowClear
                dropdownRender={(menu: any) => (
                  <Spin spinning={mbrLoading} tip="searching...">
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Search
                      defaultValue={mbrText}
                      loading={mbrLoading}
                      placeholder="search member...."
                      allowClear
                      size="default"
                      onSearch={async (val: string) => {
                        setMbrText(val);
                        if (val && val != "")
                          await getMembers({
                            variables: getMembersVar({
                              clinicId,
                              startDate,
                              endDate,
                              take: 50,
                              searchText: val,
                              status: ["ACTIVE"],
                            }),
                          });
                        else if (val === "")
                          await getMembers({
                            variables: getMembersVar({
                              clinicId,
                              startDate,
                              endDate,
                              take: 50,
                              searchText: val,
                              status: ["ACTIVE"],
                            }),
                          });
                      }}
                      enterButton={false}
                    />
                  </Spin>
                )}
                options={(mbrData?.members || []).map((mbr) => ({
                  value: mbr.id,
                  label: `${mbr.name} (${mbr.phonenumber})`,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="service_id"
              label="Service"
              rules={[{ required: true, message: "Service is required" }]}
            >
              <Select
                key={"service_select"}
                placeholder="select service"
                loading={srvLoading}
                allowClear
                options={(srvData?.services || []).map((srv) => ({
                  value: srv.id,
                  label: srv.name,
                }))}
                dropdownRender={(menu: any) => (
                  <Spin spinning={srvLoading} tip="searching...">
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Search
                      defaultValue={srvText}
                      loading={srvLoading}
                      placeholder="search service.."
                      allowClear
                      size="default"
                      onSearch={async (val: string) => {
                        setSrvText(val);
                        if (val && val != "")
                          await getServices({
                            variables: getServicesVar({
                              clinicId,
                              startDate,
                              endDate,
                              take: 50,
                              searchText: val,
                              status: ["ACTIVE"],
                            }),
                          });
                        else if (val === "")
                          await getMembers({
                            variables: getMembersVar({
                              clinicId,
                              startDate,
                              endDate,
                              take: 50,
                              searchText: val,
                              status: ["ACTIVE"],
                            }),
                          });
                      }}
                      enterButton={false}
                    />
                  </Spin>
                )}
              />
            </Form.Item>

            <Form.Item
              name="practitioner_id"
              label="Therapist"
              rules={[{ required: true, message: "Therapist is required" }]}
            >
              <Select
                key={"practitioner_select"}
                placeholder="select therapist"
                loading={pracLoading}
                allowClear
                dropdownRender={(menu: any) => (
                  <Spin spinning={pracLoading} tip="searching...">
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Search
                      defaultValue={pracText}
                      loading={pracLoading}
                      placeholder="search therapist...."
                      allowClear
                      size="default"
                      onSearch={async (val: string) => {
                        setPracText(val);
                        await getPractitioners({
                          variables: getPractitionersVar({
                            clinicId,
                            startDate,
                            endDate,
                            status: ["ACTIVE"],
                            searchText: val,
                            take: 50,
                          }),
                        });
                      }}
                      enterButton={false}
                    />
                  </Spin>
                )}
                options={(pracData?.practitioners || []).map((prc) => ({
                  value: prc.id,
                  label: prc.name,
                }))}
              />
            </Form.Item>
            <Form.Item
              name="in_time"
              label="Date"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <DatePicker showTime key={`date_chk`} />
            </Form.Item>
            <Form.Item {...tailLayout}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                create
              </Button>
            </Form.Item>
            {error && (
              <div style={{ color: "red", textAlign: "center" }}>
                {error.name}
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Card>
  );
}

export default withClinic(NewCheckout);
