import React, { useState, useEffect } from 'react';
import AppRoutes from './routes/routes';
import 'antd/dist/antd.css';
import './app.css';
import Login from './pages/login/login'
import { Provider } from "react-redux";
import { ApolloProvider, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import paths from './routes/paths';
import { store } from "./store";
import client from './graphql/apollo_client';
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
const B = () => {
  return (
    <Router >
      <Routes>
        <Route index element={<Login />} />
        <Route path={paths.login} element={<Login />} />
        <Route path={"/*"} element={<AppRoutes />} />
      </Routes>
    </Router>
  );
}


const A = () => {
  return (
    <ApolloProvider client={client}>
      <Provider store={store} >
        <B />
      </Provider>
    </ApolloProvider>
  )
}

export default A;

