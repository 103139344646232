import { gql } from "@apollo/client";

export const tl_count_and_sum = gql`
query _count($where: MemberWhereInput, $aggregatePractitionerWhere2: PractitionerWhereInput, $aggregateBookingWhere2: BookingWhereInput, $aggregateOrderWhere2: OrderWhereInput) {
  aggregateMember(where: $where) {
    _count {
      id
    }
  }
  aggregatePractitioner(where: $aggregatePractitionerWhere2) {
    _count {
      id
    }
  }
  aggregateBooking(where: $aggregateBookingWhere2) {
    _count {
      id
    }
  }
  aggregateOrder(where: $aggregateOrderWhere2) {
    _sum {
      net_total
    }
  }
}
`;

export const getTlCountAndSumVar = (clinicId: string) => ({
  "where": {
    "clinics": {
      "some": {
        "id": {
          "equals": clinicId
        }
      }
    }
  },
  "aggregatePractitionerWhere2": {
    "clinic_id": {
      "equals": clinicId
    },
    "status": {
      "equals": "ACTIVE"
    }
  },
  "aggregateBookingWhere2": {
    "clinic_id": {
      "equals": clinicId
    },
    "status": {
      "notIn": ["MEMBER_CANCEL", "MERCHANT_CANCEL"]
    }
  },
  "aggregateOrderWhere2": {
    "clinic_id": {
      "equals": clinicId
    },
    "status": {
      "notIn": ["CANCEL", "INACTIVE"]
    },
    "user_id": {
      "notIn": ["self_checkout"]
    }
  }
});

export const getTlCountAndSumByRangeVar = (clinicId: string, start: Date, end: Date) => (
  {
    "where": {
      "bookings": {
        "some": {
          "clinic_id": {
            "equals": clinicId
          },
          "from_time": {
            "gte": start,
            "lte": end
          }
        }
      }
    },
    "aggregatePractitionerWhere2": {
      "bookings": {
        "some": {
          "clinic_id": {
            "equals": clinicId
          },
          "from_time": {
            "gte": start,
            "lte": end,
          }
        }
      }
    },
    "aggregateBookingWhere2": {
      "from_time": {
        "gte": start,
        "lte": end,
      },
      "clinic_id": {
        "equals": clinicId
      },
      "status": {
        "notIn": ["MEMBER_CANCEL", "MERCHANT_CANCEL"]
      }
    },
    "aggregateOrderWhere2": {
      "clinic_id": {
        "equals": clinicId
      },
      "status": {
        "notIn": [
          "INACTIVE",
          "CANCEL"
        ]
      },
      "created_at": {
        "gte": start,
        "lte": end,
      }
    }
  }
);

export const tl_practitioner_appoinment_count = gql`
query _count($by: [BookingScalarFieldEnum!]!, $where: BookingWhereInput) {
  groupByBooking(by: $by, where: $where) {
    practitioner_id
    _count {
      id
    }
  }
}
`;

export const getTlPracAptCountVar = (clinicId: String, start: Date, end: Date) => ({
  "by": [
    "practitioner_id"
  ],
  "where": {
    "clinic_id": {
      "equals": clinicId
    },
    "from_time": {
      "gte": start,
      "lte": end
    },
    "status": {
      "notIn": ["MEMBER_CANCEL", "MEMBER_CANCEL"]
    }
  }
})