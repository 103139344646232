import React from "react";
import { connect } from "react-redux";
import { AppState, Clinic, actions } from "../store";
import { decryptData } from "../helpers/crypto";
import CryptoJS from "crypto-js";

const withClinic = (WrappedComponent: any) => {
  const HOC = (props: any) => {
    return <WrappedComponent {...props} />;
  };
  const mapStateToProps = (state: AppState) => {
    const localUser = decryptData("userInfo");
    const SECRET = "umatter";
    const localClinic = localStorage.getItem("clinicInfo");
    let localClinicData;
    if (localClinic?.length) {
      const bytes = CryptoJS.AES.decrypt(localClinic, SECRET);
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      localClinicData = data;
    }
    // console.log(localClinicData, "localClinicData wc");
    // console.log(localUser, "local user wc");

    return {
      // NOTE: USE STORE STATE
      // currentClinic: state.clinicState.selected,
      // clinics: state.clinicState.clinics,
      // user:
      //   state.authState.status === "loggedIn"
      //     ? state.authState.userInfo!
      //     : undefined,

      // NOTE: USE LOCAL STORAGE AND STATE
      currentClinic:
        localClinicData?.length > 1
          ? localClinicData.selected
          : state.clinicState.selected,
      clinics:
        localClinicData?.length > 1
          ? localClinicData?.clinics
          : state.clinicState.clinics,
      user: localUser
        ? localUser.status === "loggedIn"
          ? localUser.userInfo!
          : undefined
        : state.authState.status === "loggedIn"
        ? state.authState.userInfo!
        : undefined,
    };
  };
  const mapStateToDispatch = (dispatch: any) => {
    return {
      setClinics: (d: Clinic[]) => dispatch(actions.clinicAction.setClinics(d)),
      setSelectedClinic: (d: Clinic) =>
        dispatch(actions.clinicAction.setSelected(d)),
    };
  };
  return connect(mapStateToProps, mapStateToDispatch)(HOC);
};

export default withClinic;
