import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// Define the state type
interface AppState {
  memberName: string;
  serviceName: string;
}

// Define the store type
interface AppStore {
  value: AppState;
  setValue: (newValue: AppState) => void;
}

export const useStore = create<AppStore>()(
  persist(
    (set) => ({
      value: {
        memberName: "",
        serviceName: "",
      },
      setValue: (newValue) => set({ value: newValue }),
    }),
    {
      name: "stored data",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
interface ModalState {
  image: string;
  name: string;
}
interface ModalStore {
  value: ModalState;
  setValue: (newValue: ModalState) => void;
}

export const modalStore = create<ModalStore>((set) => ({
  value: {
    image: "",
    name: "",
  },
  setValue: (newValue) => set({ value: newValue }),
}));
// export default useStore;
