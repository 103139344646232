import React, { useEffect, useState } from "react";
import withClinic from "../hooks/with_clinic";
import {
  ApolloResult,
  Booking,
  CheckIn,
  Clinic,
  Order,
  Practitioner as PractionerInterface,
  Service,
} from "../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../components/date_range";
import { Form } from "antd";
import { Table, Card } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import MultiSelect from "../components/multi_select";
import { formatMoney } from "../helpers/utils";
import { Avatar } from "antd";
import {
  getPractitionerBookingCountVar,
  getPractitionerBookingVar,
  getPractitionerCheckInCountVar,
  getPractitionerCheckInVar,
  getPractitionersVar,
  getUpdateOnePractionerVar,
  get_practitioner_booking_count,
  get_practitioner_bookings,
  get_practitioner_checkin,
  get_practitioner_checkin_count,
  get_practitioners,
  update_one_practitioner,
} from "../graphql/practitioner";
import { Link } from "react-router-dom";
import paths from "../routes/paths";
import { Button } from "antd";
import { EditOutlined, LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { message } from "antd";
import { Input } from "antd";
import { InputNumber } from "antd";
import { Switch } from "antd";
import { Select } from "antd";
import ImagUpload from "../components/image_upload";

interface P {
  currentClinic?: Clinic;
}

interface Practitioner
  extends Omit<
    PractionerInterface,
    "services" | "leaves" | "metadata" | "phonenumber"
  > {
  username?: string;
  is_app_user: boolean;
}

interface PractitionerMutate extends Practitioner {
  password: string;
}

const genderOption = [
  { label: "Male", value: "MALE" },
  { label: "Female", value: "FEMALE" },
];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "password" | "select" | "img";
  record: PractitionerMutate;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode: any = <Input />;
  if (inputType === "number") {
    inputNode = <InputNumber />;
  }
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.status === "ACTIVE"} />;
  }
  if (inputType === "select") {
    const options = dataIndex === "sex" && genderOption;
    inputNode = (
      <Select
        defaultValue={(record as any)[dataIndex]}
        style={{ width: 120 }}
        options={options || []}
      />
    );
  }
  if (inputType === "password") {
    inputNode = <Input.Password />;
  }
  if (inputType === "img") {
    inputNode = <ImagUpload defaultValue={(record as any)[dataIndex]} />;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            ...(dataIndex === "username" && record.is_app_user
              ? [
                  {
                    pattern: /^[a-z0-9]+$/,
                    message:
                      "User Name must be all small letter and number only.",
                  },
                  {
                    required: true,
                    message: "User Name is required for app user.",
                  },
                ]
              : [
                  {
                    required: dataIndex === "name" ? true : false,
                    message: `Please Input ${title}!`,
                  },
                ]),
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ServicePage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [expandedIds, setExpandedIds] = useState<string[]>([]);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: addDays(new Date(), 15),
  });
  const [form] = Form.useForm();
  const [practitioners, setPractioners] = useState<Practitioner[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: PractitionerMutate) => record.id === editingKey;
  const variables = getPractitionersVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take: 150,
    status: filter,
  });
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"practitioners", Practitioner[]>
  >(get_practitioners, { variables });
  const bookingHistory = useQuery<ApolloResult<"bookings", Booking[]>>(
    get_practitioner_bookings,
    {
      variables: getPractitionerBookingVar({
        clinicId,
        practitionerIds: expandedIds,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      }),
    }
  );
  const checkInHistory = useQuery<ApolloResult<"checkIns", CheckIn[]>>(
    get_practitioner_checkin,
    {
      variables: getPractitionerCheckInVar({
        clinicId,
        practitionerIds: expandedIds,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      }),
    }
  );
  const [getBookingCount, { data: bookingData, loading: bload }] = useLazyQuery<
    ApolloResult<
      "groupByBooking",
      Array<{ _count: { _all: number }; practitioner_id: string }>
    >
  >(get_practitioner_booking_count);
  const [getCheckInCount, { data: checkInData, loading: cload }] = useLazyQuery<
    ApolloResult<
      "groupByCheckIn",
      Array<{ _count: { _all: number }; practitioner_id: string }>
    >
  >(get_practitioner_checkin_count);

  const [update, { loading: saveLoading }] = useMutation<
    ApolloResult<"updateOnePractitioner", Pick<Practitioner, "id">>
  >(update_one_practitioner);

  const checking = bload || cload;
  const bookingCount = Object.assign(
    {},
    ...(bookingData?.groupByBooking || []).map((a) => ({
      [a.practitioner_id]: a._count._all,
    }))
  );
  const checkInCount = Object.assign(
    {},
    ...(checkInData?.groupByCheckIn || []).map((a) => ({
      [a.practitioner_id]: a._count._all,
    }))
  );
  const load = (v: {
    practitionerIds: string[];
    startDate: Date;
    endDate: Date;
  }) => {
    getBookingCount({
      variables: getPractitionerBookingCountVar({ ...v, clinicId }),
    });
    getCheckInCount({
      variables: getPractitionerCheckInCountVar({ ...v, clinicId }),
    });
  };
  useEffect(() => {
    if (data?.practitioners && data.practitioners.length > 0) {
      setPractioners(data.practitioners);
      const practitionerIds = data.practitioners.map((p) => p.id);
      const { startDate, endDate } = ranges;
      load({ practitionerIds, startDate, endDate });
    }
  }, [data]);

  const save = async (r: Practitioner) => {
    // if (r.id.startsWith("new_")) {
    //   await onCreate(r);
    // } else {
    try {
      let formData = (await form.validateFields()) as any;
      const fData = { ...formData };
      const isActive = fData as { status: boolean };

      fData.status = isActive.status ? "ACTIVE" : "INACTIVE";

      if (props.currentClinic?.code == null) {
        message.error("Clinic code is empty");
        return;
      }

      const result = await update({
        variables: getUpdateOnePractionerVar(
          r.id,
          props.currentClinic?.code,
          fData
        ),
      });

      if (result.data?.updateOnePractitioner) {
        message.success("update success");
        await refetch();
      } else {
        message.error("update failed");
      }

      // }
      setEditingKey("");
      form.resetFields();
    } catch (error) {
      message.error("update failed");
      console.log("error", error);
    }
  };

  const onCancel = async (r: PractitionerMutate) => {
    if (r.id.startsWith("new_")) {
      const newList = [...practitioners];
      newList.shift();
      setPractioners(newList);
    }
    form.resetFields();
    setEditingKey("");
  };

  const edit = (r: PractitionerMutate) => {
    r.username = r.username?.split("@")[0];
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    if (data?.practitioners && data.practitioners.length > 0) {
      const practitionerIds = data.practitioners.map((p) => p.id);
      load({ practitionerIds, startDate, endDate });
    }
  };
  const onExpandedRowsChange = async (Ids: string[]) => {
    setExpandedIds(Ids);
  };
  const expandedRowRender = (row: Practitioner) => {
    let columnB: any[] = [
      {
        title: "Date",
        dataIndex: "from_time",
        key: "from_time",
        editable: false,
        sorter: (a: Booking, b: Booking) =>
          new Date(a.from_time).getTime() - new Date(b.from_time).getTime(),
        render: (v: number, r: Booking) => {
          return format(new Date(r.from_time), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "Member",
        dataIndex: "member",
        key: "member",
        editable: false,
        sorter: (a: Booking, b: Booking) =>
          a.member.name
            .toLowerCase()
            .localeCompare(b.member.name.toLowerCase()),
        render: (v: number, r: Booking) => {
          return r.member.name;
        },
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: Booking, b: Booking) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        render: (v: number, r: Booking) => {
          return r.service.name;
        },
      },
      {
        title: "Staus",
        dataIndex: "status",
        key: "status",
        sorter: (a: Booking, b: Booking) => a.status.localeCompare(b.status),
        editable: false,
      },
    ];
    let colummC: any[] = [
      {
        title: "CheckIn Time",
        dataIndex: "in_time",
        key: "in_time",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          new Date(a.in_time).getTime() - new Date(b.in_time).getTime(),
        render: (v: number, r: CheckIn) => {
          return format(new Date(r.in_time), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "Member",
        dataIndex: "member",
        key: "member",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          a.member.name
            .toLowerCase()
            .localeCompare(b.member.name.toLowerCase()),
        render: (v: number, r: CheckIn) => {
          return r.member.name;
        },
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        render: (v: number, r: CheckIn) => {
          return r.service.name;
        },
      },
      {
        title: "Staus",
        dataIndex: "status",
        key: "status",
        sorter: (a: CheckIn, b: CheckIn) => a.status.localeCompare(b.status),
        editable: false,
      },
    ];
    return (
      <Row>
        <Col span={12}>
          <Card type="inner" title={`${row.name}(Bookings)`} size="small">
            <Table
              loading={bookingHistory.loading}
              showHeader={(bookingHistory.data?.bookings || []).length > 0}
              size="small"
              columns={columnB}
              dataSource={(bookingHistory.data?.bookings || []).filter(
                (b) => b.practitioner_id === row.id
              )}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title={`${row.name}(CheckIns)`} type="inner" size="small">
            <Table
              loading={checkInHistory.loading}
              showHeader={(checkInHistory.data?.checkIns || []).length > 0}
              size="small"
              columns={colummC}
              dataSource={(checkInHistory.data?.checkIns || []).filter(
                (c) => c.practitioner_id === row.id
              )}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
      </Row>
    );
  };

  const columns: any[] = [
    {
      title: "Photo",
      dataIndex: "image",
      key: "image",
      editable: true,
      render: (v: number, r: PractitionerMutate) => {
        return (
          <Link to={paths.getTherapistDetailRoute(r.id)}>
            <Avatar src={r.image} size={50} />
          </Link>
        );
      },
    },
    {
      title: "Display Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      sorter: (a: PractitionerMutate, b: PractitionerMutate) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      render: (v: number, r: Service) => {
        return <Link to={paths.getTherapistDetailRoute(r.id)}>{r.name}</Link>;
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      editable: true,
      sorter: (a: PractitionerMutate, b: PractitionerMutate) =>
        a.username
          ?.toLowerCase()
          .localeCompare(b.username?.toLowerCase() ?? ""),
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      editable: true,
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: PractitionerMutate, b: PractitionerMutate) =>
        a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Booking",
      dataIndex: "booking",
      key: "booking",
      sorter: (a: PractitionerMutate, b: PractitionerMutate) =>
        (bookingCount[a.id] || 0) - (bookingCount[b.id] || 0),
      editable: false,
      render: (v: number, r: PractitionerMutate) => {
        return bookingCount[r.id] || 0;
      },
    },
    {
      title: "CheckIn",
      dataIndex: "check_in",
      key: "check_in",
      sorter: (a: PractitionerMutate, b: PractitionerMutate) =>
        (checkInCount[a.id] || 0) - (checkInCount[b.id] || 0),
      editable: false,
      render: (v: number, r: PractitionerMutate) => {
        return checkInCount[r.id] || 0;
      },
    },
    {
      title: "Gender",
      dataIndex: "sex",
      key: "sex",
      editable: true,
      sorter: (a: PractitionerMutate, b: PractitionerMutate) =>
        a.sex.localeCompare(b.sex),
    },
    {
      title: "CreatedAt",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: Order, b: Order) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Order, r: Order) => {
        return format(new Date(r.created_at), "dd MMM,yyyy");
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: PractitionerMutate) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: PractitionerMutate) => ({
        record,
        inputType:
          col.dataIndex === "sex"
            ? "select"
            : col.dataIndex === "password"
            ? "password"
            : col.dataIndex === "status"
            ? "switch"
            : col.dataIndex === "image"
            ? "img"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Therapists</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${
                props.currentClinic?._count.practitioners || 0
              } therapists...`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} autoComplete="off" component={false}>
          <Table
            loading={checking}
            showHeader={practitioners.length > 0}
            components={{ body: { cell: EditableCell } }}
            expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={practitioners.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4 }}>
      <Col span={24}>
        <MultiSelect
          selected={filter}
          placeholder="Select therapist status"
          options={["ACTIVE", "INACTIVE"]}
          onChange={setFilter}
        />
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(ServicePage);
