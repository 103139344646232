import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Typography } from "antd";
import {
  TeamOutlined,
  CalendarOutlined,
  UserOutlined,
  ShoppingOutlined,
} from "@ant-design/icons";
import "chart.js/auto";
import { DatePicker } from "antd";
import LineChart from "../components/home/chart/linechart";
import PieChart from "../components/home/chart/bar_chart";
import ItemList from "../components/home/list/list";
import DateRange from "../components/date_range";
import { addDays, subDays } from "date-fns";
import PanelCard from "../components/panel_card";
import { ApolloResult, Clinic, Order, Practitioner, Service } from "../store";
import withClinic from "../hooks/with_clinic";
import { useLazyQuery, useQuery } from "@apollo/client";
import * as _ from "lodash";
import {
  getTlCountAndSumByRangeVar,
  getTlCountAndSumVar,
  getTlPracAptCountVar,
  tl_count_and_sum,
  tl_practitioner_appoinment_count,
} from "../graphql/report_query";
import { getOrdersVar, get_orders, get_slim_orders } from "../graphql/order";
import {
  getPractitionerCheckInCountVar,
  getPractitionersVar,
  get_practitioner_booking_count,
  get_practitioner_checkin_count,
  get_practitioners,
} from "../graphql/practitioner";
import PractitionersChart from "../components/home/chart/top_practitioners_chart";
import {
  getTopTreatmentVar,
  get_top_treatment,
  get_top_treatment_checkin_count,
} from "../graphql/reports/top_treatment";
import TopTreatmentChart from "../components/home/chart/top_treatment_chart";
const { RangePicker } = DatePicker;

interface P {
  currentClinic?: Clinic;
}
const panels = [
  {
    title: "Member",
    value: "0",
    persent: "",
    icon: <TeamOutlined style={{ fontSize: 30 }} />,
    loading: false,
    dbKey: "aggregateMember",
    dbPath: "_count.id",
  },
  {
    title: "Appointment",
    value: "0",
    persent: "",
    icon: <CalendarOutlined style={{ fontSize: 30 }} />,
    loading: false,
    dbKey: "aggregateBooking",
    dbPath: "_count.id",
  },
  {
    title: "Therapist",
    value: "0",
    persent: "",
    icon: <UserOutlined style={{ fontSize: 30 }} />,
    loading: false,
    dbKey: "aggregatePractitioner",
    dbPath: "_count.id",
  },
  {
    title: "Total Sale",
    unit: "MMK",
    value: "0",
    persent: "",
    icon: <ShoppingOutlined style={{ fontSize: 30 }} />,
    loading: false,
    dbKey: "aggregateOrder",
    dbPath: "_sum.net_total",
  },
];
function Home(props: P) {
  const clinicId = props.currentClinic?.id || "";
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 30),
    endDate: addDays(new Date(), 15),
  });
  const [panelList, setPanelList] = useState(panels);
  const [prcDic, setPracDic] = useState<any>({});
  const { loading: tlLoading, data: tlData } = useQuery(tl_count_and_sum, {
    variables: getTlCountAndSumVar(clinicId),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { loading: cLoading, data: cData } = useQuery(tl_count_and_sum, {
    variables: getTlCountAndSumByRangeVar(
      clinicId,
      ranges.startDate,
      ranges.endDate
    ),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });
  const { loading: oLoading, data: oData } = useQuery<
    ApolloResult<"orders", Order[]>
  >(get_slim_orders, {
    variables: getOrdersVar({
      clinicId,
      startDate: ranges.startDate,
      endDate: ranges.endDate,
      take: 500,
    }),
  });
  const { loading: prcLoading, data: prcData } = useQuery<
    ApolloResult<
      "groupByBooking",
      Array<{ practitioner_id: string; _count: { id: number } }>
    >
  >(tl_practitioner_appoinment_count, {
    variables: getTlPracAptCountVar(clinicId, ranges.startDate, ranges.endDate),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const practitionerList = useQuery<
    ApolloResult<"practitioners", Practitioner[]>
  >(get_practitioners, {
    variables: getPractitionersVar({
      clinicId,
      startDate: new Date(),
      endDate: new Date(),
      take: 100,
    }),
    fetchPolicy: "cache-and-network",
  });

  const variables = getPractitionersVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    take: 150,
    status: "ACTIVE",
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"practitioners", Practitioner[]>
  >(get_practitioners, { variables });

  const [getCheckInCount, { data: checkInData, loading: cload }] = useLazyQuery<
    ApolloResult<
      "groupByCheckIn",
      Array<{ _count: { _all: number }; practitioner_id: string }>
    >
  >(get_practitioner_checkin_count);

  const checking = cload;
  const checkInCount = Object.assign(
    {},
    ...(checkInData?.groupByCheckIn || []).map((a) => ({
      [a.practitioner_id]: a._count._all,
    }))
  );
  const result = data?.practitioners?.map((p) => ({
    ...p,
    checkin: checkInCount[p.id],
  }));
  const load = useCallback(
    (v: { practitionerIds: string[]; startDate: Date; endDate: Date }) => {
      getCheckInCount({
        variables: getPractitionerCheckInCountVar({
          ...v,
          clinicId,
          status: "CHECKOUT",
        }),
      });
    },
    [clinicId, getCheckInCount]
  );
  useEffect(() => {
    if (data?.practitioners && data.practitioners.length > 0) {
      const practitionerIds = data.practitioners.map((p) => p.id);
      const { startDate, endDate } = ranges;
      load({ practitionerIds, startDate, endDate });
    }
  }, [data, ranges, load]);

  // top treatment
  const tt_variables = getTopTreatmentVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    take: 150,
    status: "ACTIVE",
    // searchText: searchText2,
  });
  const {
    loading: topTreatmentLoading,
    data: topTreatmentData,
    error: topTreatmentErr,
  } = useQuery<ApolloResult<"services", Service[]>>(get_top_treatment, {
    variables: tt_variables,
  });

  useEffect(() => {
    if (practitionerList.data?.practitioners) {
      const optDic = Object.assign(
        {},
        ...practitionerList.data.practitioners.map((x) => ({ [x.id]: x.name }))
      );
      setPracDic(optDic);
    }
  }, [practitionerList.data]);
  useEffect(() => {
    if (tlData && cData) {
      let _list = [...panelList];
      for (const [idx, p] of _list.entries()) {
        if (tlData[p.dbKey] && cData[p.dbKey]) {
          const total = _.get(tlData[p.dbKey], p.dbPath, "0") || "0";
          const current = _.get(cData[p.dbKey], p.dbPath, "0") || "0";
          const persent = (Number(current) / Number(total)) * 100;
          _list[idx].value = current;
          _list[idx].persent = persent > 0 ? persent.toFixed(2) + "%" : "";
        }
      }
      setPanelList(_list);
    }
  }, [tlData, cData]);
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    if (data?.practitioners && data.practitioners.length > 0) {
      const practitionerIds = data.practitioners.map((p) => p.id);
      load({ practitionerIds, startDate, endDate });
    }
  };
  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "end", padding: 2 }}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </div>
      <div className="layout-content">
        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {panelList.map((panel, index) => (
            <PanelCard key={`panel_${index}`} {...panel} loading={tlLoading} />
          ))}
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} className="mb-12">
            <Card className="criclebox shadow-md  w-full">
              <LineChart
                title="Sales"
                data={(oData?.orders || [])
                  .filter((o) => Number(o.total) > 0)
                  .map((o) => ({
                    x: new Date(o.created_at),
                    y: Number(o.total),
                  }))}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="mb-12">
            <Card className="criclebox shadow-md h-full w-full">
              <PieChart
                title="Therapist Appoinment"
                labels={(prcData?.groupByBooking || [])
                  .filter((p) => p._count.id > 0)
                  .map((p) => prcDic[p.practitioner_id] || p.practitioner_id)}
                data={(prcData?.groupByBooking || [])
                  .filter((p) => p._count.id > 0)
                  .map((p) => p._count.id)}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} className="mb-12">
            <Card className="criclebox shadow-md  w-full">
              <TopTreatmentChart
                ranges={ranges}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="mb-12">
            <Card className="criclebox shadow-md h-full w-full">
              <PractitionersChart
                title="Top Practitioners"
                labels={(result || [])
                  // .filter((p) => p?._count?.checkins > 0)
                  .map((p) => p?.name)}
                data={(result || [])
                  // .filter((p) => p?._count?.checkins > 0)
                  .map((p) => p?.checkin)}
              />
            </Card>
          </Col>
        </Row>
        {/* <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={12} className="mb-24">
            <Card className="criclebox shadow-md h-full w-full">
              <ItemList />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="mb-24">
            <Card className="criclebox shadow-md h-full w-full">
              <ItemList />
            </Card>
          </Col>
        </Row> */}
      </div>
    </React.Fragment>
  );
}

export default withClinic(Home);
