import { gql } from "@apollo/client";

export const get_products = gql`
query Products($where: ProductWhereInput, $orderBy: [ProductOrderByWithRelationInput!], $take: Int) {
    products(where: $where, orderBy: $orderBy, take: $take) {
      id
      name
      sort_order
      status
      description
      created_at
      clinic_id
      measurement {
        id
        name
        description
      }
      images {
        image
      }
      measurement_amount
      measurement_id
      brand_id
      brand {
        image
        name
        id
      }
    }
  }
`;
export const getProductsVar = (data: {
    clinicId: string,
    startDate: Date,
    endDate: Date,
    status?: string[];
    searchText?: string | null,
    take: number,
}) => {
    const { status = [], searchText } = data;
    let variables: any = {}, where: any = {}, OR: any[] = [];
    if (searchText && searchText !== "") {
        OR.push({ name: { contains: searchText } })
    }
    where.clinic_id = { equals: data.clinicId }
    // where.created_at = { gte: data.startDate, lte: data.endDate };
    if (status.length > 0) where.status = { in: status };
    if (OR.length > 0) where.OR = OR;
    variables.where = where;
    variables.orderBy = [{ created_at: "desc" }]
    variables.take = 150
    return variables;
}
