import React, { useState } from 'react';
import { Select } from 'antd';

const OPTIONS = ['Apples', 'Nails', 'Bananas', 'Helicopters'];
interface P {
    options: string[],
    onChange: (val: string[]) => void,
    selected: string[],
    placeholder?: string,
    size?: string;
}
const C = (props: P) => {
    const [selectedItems, setSelectedItems] = useState<string[]>(props.selected);
    const filteredOptions = props.options.filter((o) => !selectedItems.includes(o));
    const handleChange = (v: string[]) => {
        setSelectedItems(v);
        props.onChange(v);
    }
    return (
        <Select
            mode="multiple"
            size={props.size || "small"}
            placeholder={props.placeholder || ""}
            value={selectedItems}
            onChange={handleChange}
            style={{ width: '100%', minWidth: '50%' }}
            options={filteredOptions.map((item) => ({
                value: item,
                label: item,
            }))}
        />
    );
};

export default C;