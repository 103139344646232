import { Input, Switch, Form, InputNumber } from 'antd';
const { TextArea } = Input
export interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: 'number' | 'text' | "switch" | "textarea";
    record: any;
    index: number;
    children: React.ReactNode;
}

export const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    let inputNode = inputType === 'number' ?
        <InputNumber /> :
        inputType === 'switch' ?
            <Switch defaultChecked={record.status === 'ACTIVE'} />
            : <Input />;
    if (inputType === 'textarea') {
        inputNode = <TextArea key={`${dataIndex}`} rows={3} defaultValue={(record as any)[dataIndex]} />
    }
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};
