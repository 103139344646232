
import { gql, DocumentNode } from '@apollo/client';
import client from './apollo_client';
import { orderBy } from 'lodash';
import { create_one_sys_lg } from './system_log';

interface MemberDelArgs {
    clinicId: string;
    memberId: string;
    phoneNumber: string;
}
interface MemberDelOpt {
    name: string;
    query: DocumentNode,
    getVars: (args: MemberDelArgs) => any,
    resultField: string,
    sequelize: number,
}

const normalVar = (args: MemberDelArgs) => {
    return {
        "where": {
            "member_id": { "equals": args.memberId },
            "clinic_id": { "equals": args.clinicId }
        }
    }
}

//delete bookings
const deleteBookingsOpt: MemberDelOpt = {
    name: "Bookings",
    query: gql`mutation DeleteManyBooking($where: BookingWhereInput) {
        deleteManyBooking(where: $where) {
          count
        }
      }`,
    getVars: (args: MemberDelArgs) => {
        return normalVar(args);
    },
    resultField: "deleteManyBooking",
    sequelize: 5
};
//delete checkIns
const deleteCheckInsOpt: MemberDelOpt = {
    name: "CheckIns",
    query: gql`mutation DeleteManyCheckIn($where: CheckInWhereInput) {
        deleteManyCheckIn(where: $where) {
          count
        }
      }
      `,
    getVars: (args: MemberDelArgs) => {
        return normalVar(args);
    },
    resultField: "deleteManyCheckIn",
    sequelize: 4
}
//delete order items
const deleteOrderItemsOpt: MemberDelOpt = {
    name: "OrderItems",
    query: gql`mutation DeleteManyOrderItem($where: OrderItemWhereInput) {
        deleteManyOrderItem(where: $where) {
          count
        }
      }
      `,
    getVars: (args: MemberDelArgs) => {
        return {
            "where": {
                "order": {
                    "is": { "clinic_id": { "equals": args.clinicId }, "member_id": { "equals": args.memberId } }
                }
            }
        }
    },
    resultField: "deleteManyOrderItem",
    sequelize: 2
}
//delete orders
const deleteOrdersOpt: MemberDelOpt = {
    name: "Orders",
    query: gql`mutation DeleteManyOrder($where: OrderWhereInput) {
        deleteManyOrder(where: $where) {
          count
        }
      }
      `,
    getVars: (args: MemberDelArgs) => {
        return normalVar(args);
    },
    resultField: "deleteManyOrder",
    sequelize: 3
}
//delete member bags
const deleteMemberBagsOpt: MemberDelOpt = {
    name: "MemberServiceBags",
    query: gql`mutation DeleteManyMemberServiceBag($where: MemberServiceBagWhereInput) {
        deleteManyMemberServiceBag(where: $where) {
          count
        }
      }
      `,
    getVars: (args: MemberDelArgs) => {
        return {
            "where": {
                "member_id": { "equals": args.memberId },
                "service": {
                    "is": { "clinic_id": { "equals": args.clinicId } }
                }
            }
        };
    },
    resultField: "deleteManyMemberServiceBag",
    sequelize: 6
}
//delete member packages
const deleteMemberPackagesOpt: MemberDelOpt = {
    name: "MemberServicePackages",
    query: gql`mutation DeleteManyMemberServicePackage($where: MemberServicePackageWhereInput) {
        deleteManyMemberServicePackage(where: $where) {
          count
        }
      }
      `,
    getVars: (args: MemberDelArgs) => {
        return {
            "where": {
                "member_id": { "equals": args.memberId },
                "service_package": {
                    "is": { "clinic_id": { "equals": args.clinicId } }
                }
            }
        };
    },
    resultField: "deleteManyMemberServicePackage",
    sequelize: 7
};
// 
//delete member service records
const deleteMemberServiceRecordsOpt: MemberDelOpt = {
    name: "MemberServiceRecords",
    query: gql`mutation DeleteManyMemberServiceRecord($where: MemberServiceRecordWhereInput) {
        deleteManyMemberServiceRecord(where: $where) {
          count
        }
      }
      `,
    getVars: (args: MemberDelArgs) => {
        return {
            "where": {
                "member_id": { "equals": args.memberId },
                "checkin": {
                    "is": { "clinic_id": { "equals": args.clinicId } }
                }
            }
        };
    },
    resultField: "deleteManyMemberServiceRecord",
    sequelize: 1
};
// delete member
const deleteMemberRecordOpt: MemberDelOpt = {
    name: "MemberRecord",
    query: gql`mutation UpdateOneMember(
        $data: MemberUpdateInput!
        $where: MemberWhereUniqueInput!
      ) {
        updateOneMember(data: $data, where: $where) {
          id
        }
      }      
      `,
    getVars: (args: MemberDelArgs) => {
        const { clinicId, memberId, phoneNumber } = args;
        return {
            "where": { "id": memberId },
            "data": {
                "clinics": {
                    "disconnect": [
                        { "id": clinicId }
                    ]
                },
                "clinic_members": {
                    "delete": [
                        {
                            "clinic_id_phonenumber": {
                                "clinic_id": clinicId,
                                "phonenumber": phoneNumber
                            }
                        }
                    ]
                }
            },
        }
    },
    resultField: "updateOneMember",
    sequelize: 1000
};

const muatations: MemberDelOpt[] = [
    deleteBookingsOpt,
    deleteCheckInsOpt,
    deleteOrderItemsOpt,
    deleteOrdersOpt,

    deleteMemberBagsOpt,
    deleteMemberPackagesOpt,
    deleteMemberServiceRecordsOpt,
    deleteMemberRecordOpt
];

const deleteMember = async (args: MemberDelArgs, userId: string) => {
    let successCount = 0;
    let failCount = 0;
    let errors: string[] = [];
    let results: any[] = [];
    const opts = orderBy(muatations, 'sequelize', 'asc');
    for (const opt of opts) {
        const queryResult = await client.mutate({ mutation: opt.query, variables: opt.getVars(args) });
        results.push(queryResult.data);
        if (queryResult.data && queryResult.data[opt.resultField]) {
            successCount += 1;
        } else {
            failCount += 1;
            errors.push(opt.name)
        }
    }
    //logging
    const log_id = `${args.memberId}_${new Date().getTime()}`;
    const code = `${args.memberId}`;
    const metadata = JSON.stringify({
        type: "member",
        action: "delete",
        context: args,
        results,
        successCount,
        failCount
    });
    const user = { connect: { id: userId } };
    await client.mutate({ mutation: create_one_sys_lg, variables: { data: { code, log_id, metadata, user, log_type: "WARN" } } });
    return {
        success: successCount === opts.length,
        successCount,
        failCount,
        errors
    }

}

export default deleteMember;