import React, { useEffect, useState } from "react";
import withClinic from "../hooks/with_clinic";
import { ApolloResult, Clinic, ServiceHelper } from "../store";
import {
  Row,
  Col,
  Typography,
  Empty,
  Button,
  Popconfirm,
  InputNumber,
  Input,
  Switch,
} from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import { format } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import {
  helpers,
  getHelpersVar,
  create_one_helper,
  update_one_helper,
  getUpdateOneHelperVar,
} from "../graphql/helper";
import paths from "../routes/paths";
import { Link } from "react-router-dom";
import { boolean } from "yup";

const { TextArea } = Input;

interface P {
  currentClinic?: Clinic;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea";
  record: ServiceHelper;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : inputType === "switch" ? (
      <Switch defaultChecked={record.status === "ACTIVE"} />
    ) : (
      <Input />
    );
  if (inputType === "textarea") {
    inputNode = (
      <TextArea
        key={`${dataIndex}`}
        rows={3}
        defaultValue={(record as any)[dataIndex]}
      />
    );
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const HelperPage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [serviceHelper, setServiceHelper] = useState<ServiceHelper[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: ServiceHelper) => record.id === editingKey;
  const variables = getHelpersVar(clinicId);
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"serviceHelpers", ServiceHelper[]>
  >(helpers, { variables });

  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneServiceHelper", ServiceHelper>>(
      update_one_helper
    );
  const [create, { loading: createLoading }] =
    useMutation<ApolloResult<"createOneServiceHelper", ServiceHelper>>(
      create_one_helper
    );

  useEffect(() => {
    if (data?.serviceHelpers) {
      setServiceHelper(data.serviceHelpers);
    }
  }, [data]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;

  const edit = (record: ServiceHelper) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id!);
  };

  const add = () => {
    const newData: ServiceHelper = {
      id: `new_${new Date().getTime()}`,
      name: "New Helper",
      status: "INACTIVE",
      created_at: new Date(),
      services: [],
      sort_order: 1,
    };
    const newList = [newData, ...serviceHelper];
    setServiceHelper(newList);
    setEditingKey(newData.id);
  };

  const save = async (record: ServiceHelper) => {
    if (record.id.startsWith("new_")) {
      await onCreate(record);
    } else {
      let formData = (await form.validateFields()) as {
        status: string | boolean;
      };
      // console.log(formData.status);
      const status =
        typeof formData.status === "boolean"
          ? formData.status
          : formData.status === "ACTIVE";

      const updatedData = {
        ...formData,
        status: status ? "ACTIVE" : "INACTIVE",
      } as ServiceHelper;
      const result = await update({
        variables: getUpdateOneHelperVar(record.id, updatedData),
      });
      // console.log(result);

      if (result.data?.updateOneServiceHelper) {
        message.success("Update successful");

        await refetch();
      } else {
        message.error("Update failed");
      }
    }
    form.resetFields();
    setEditingKey("");
  };

  const onCreate = async (record: ServiceHelper) => {
    let formData = (await form.validateFields()) as { status: boolean };
    const newData = {
      ...formData,
      status: formData.status === true ? "ACTIVE" : "INACTIVE",
    } as any;
    delete newData["id"];
    const result = await create({
      variables: {
        data: {
          ...newData,
          clinic: {
            connect: {
              id: clinicId,
            },
          },
        },
      },
    });

    if (result.data?.createOneServiceHelper) {
      message.success("Create successful");
      await refetch();
    } else {
      message.error("Create failed");
    }
  };

  const onCancel = (record: ServiceHelper) => {
    if (record.id.startsWith("new_")) {
      const newList = serviceHelper.filter((item) => item.id !== record.id);
      setServiceHelper(newList);
    }
    form.resetFields();
    setEditingKey("");
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      sorter: (a: ServiceHelper, b: ServiceHelper) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onFilter: (value: string, record: ServiceHelper) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
      render: (v: ServiceHelper, r: ServiceHelper) => (
        <Link to={paths.getServiceHelperDetailRoute(r.id)}>{r.name}</Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: ServiceHelper, b: ServiceHelper) =>
        a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: (a: ServiceHelper, b: ServiceHelper) =>
        a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Sort Order",
      dataIndex: "sort_order",
      key: "sort_order",
      editable: true,
      sorter: (a: ServiceHelper, b: ServiceHelper) =>
        a.sort_order - b.sort_order,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: ServiceHelper, b: ServiceHelper) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (text: string, record: ServiceHelper) =>
        format(new Date(record.created_at), "dd MMM, yyyy"),
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (text: string, record: ServiceHelper) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading || createLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  Saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    Save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ServiceHelper) => ({
        record,
        inputType:
          col.dataIndex === "sort_order"
            ? "number"
            : col.dataIndex === "status"
            ? "switch"
            : col.dataIndex === "description"
            ? "textarea"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Service Helpers</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="Search ..."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );

  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || createLoading || loading}
            showHeader={serviceHelper.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={serviceHelper.map((item) => ({
              key: item.id,
              ...item,
            }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(HelperPage);
