import React, { useEffect, useMemo, useState } from "react";
import withClinic from "../hooks/with_clinic";
import { useParams, useSearchParams } from "react-router-dom";
import {
  ApolloResult,
  Clinic,
  GTLeave,
  Practitioner,
  PractitionerService as PractitionerServiceBase,
  User,
} from "../store";
import { useMutation, useQuery } from "@apollo/client";
import {
  getConnectServiceWitPractitionerVar,
  getDisconnectServiceWitPractitionerVar,
  getPractitionerVar,
  get_practitioner,
  update_one_practitioner,
} from "../graphql/practitioner";
import { Card } from "antd";
import { Image } from "antd";
import { format } from "date-fns";
import { Row } from "antd";
import { Col } from "antd";
import { Table } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import ButtonGroup from "antd/lib/button/button-group";
import { Popconfirm } from "antd";
import { Form, Button } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { message } from "antd";
import { Select } from "antd";
import {
  getServicesForPractitionerVar,
  get_services_for_Practitioner,
} from "../graphql/service";

interface ServiceColumn {
  title: string;
  dataIndex: string;
  key: string;
  editable: boolean;
  defaultSortOrder?: string;
  align?: string;
  width?: string;
  fixed?: string;
  render?: (text: any, record: any) => any;
  sorter?: (a: PractitionerService, b: PractitionerService) => any;
}

interface PractitionerService extends PractitionerServiceBase {
  serviceId?: string;
}

interface P {
  currentClinic?: Clinic;
  therapit?: User;
}

interface ServiceNameColumn {
  label: string;
  value: string;
  image: string;
  duration: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea" | "select" | "img";
  record: PractitionerService;
  options: PractitionerService[];
  index: number;
  children: React.ReactNode;
  onServiceChange?: (id: string, value: string) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  options,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  onServiceChange,
  ...restProps
}) => {
  let inputNode: any = (
    <Select
      style={{ width: "90%" }}
      options={options || []}
      onChange={(value: string) => {
        if (onServiceChange != null) {
          onServiceChange(record.id, value);
        }
      }}
    />
  );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const baseTabs = [
  {
    key: "info",
    tab: "Therapists Info",
  },
  {
    key: "services",
    tab: "Services ",
  },
  {
    key: "leave",
    tab: " Leave Day",
  },
];

const TherapistsDetail = (props: P) => {
  const { id: PractitionerId = "" } = useParams() as { id?: string };
  const clinicId = props.currentClinic?.id || "";
  const [activeTabKey, setActiveTabKey] = useState<string>("info");
  const [tabs, setTabs] = useState([...baseTabs]);
  const [practitionerSevices, setPractitionerServices] = useState<
    PractitionerService[]
  >([]);
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: PractitionerService) => record.id === editingKey;
  const var2 = getPractitionerVar({
    PractitionerId,
    clinicId,
  });
  const {
    loading: pacLoad,
    data: pacData,
    refetch: refetchPractitioner,
  } = useQuery<ApolloResult<"practitioner", Practitioner>>(get_practitioner, {
    variables: var2,
    fetchPolicy: "cache-and-network",
  });

  const {
    loading: allServiceLoading,
    data: allServiceData,
    refetch: refetchAllService,
  } = useQuery<ApolloResult<"services", PractitionerService[]>>(
    get_services_for_Practitioner,
    {
      ...getServicesForPractitionerVar({
        clinicId: clinicId,
        practitionerId: PractitionerId,
      }),
      fetchPolicy: "cache-and-network",
    }
  );

  const allServices: ServiceNameColumn[] = useMemo(() => {
    if (allServiceData?.services) {
      return allServiceData?.services.map((s) => ({
        label: s.name,
        value: s.id,
        image: s.image,
        duration: s.duration,
      }));
    }
    return [];
  }, [allServiceData]);
  const leaveDayData = pacData?.practitioner.leaves || [];
  const [
    updateOnePractitioner,
    { loading: saveLoading, error: saveServiceError },
  ] = useMutation<
    ApolloResult<"updateOnePractitioner", Pick<Practitioner, "id">>
  >(update_one_practitioner);

  useEffect(() => {
    if (searchParams.get("mode")) {
      const mode = searchParams.get("mode");
      if (mode === "delete") {
        setTabs((prev) =>
          [...prev].concat([{ key: "action", tab: "Actions" }])
        );
      }
    }
  }, [searchParams.get("mode")]);

  useEffect(() => {
    if (pacData?.practitioner?.services) {
      setPractitionerServices(pacData.practitioner.services);
    }
  }, [pacData?.practitioner?.services]);

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const onServiceChange = (id: string, value: string) => {
    const serviceData = allServices.find((s) => s.value === value);
    if (serviceData == null) return;

    const newList = practitionerSevices.map((s) => {
      if (s.id === id) {
        s.image = serviceData.image;
        s.name = serviceData.label;
        s.duration = serviceData.duration;
        s.serviceId = serviceData.value;
      }
      return s;
    });
    setPractitionerServices(newList);
  };

  const add = () => {
    const newData: PractitionerService = {
      id: `new_${new Date().getTime()}`,
      image: "",
      name: "",
      duration: "",
    };

    const newList = [...practitionerSevices];
    newList.unshift(newData);
    setPractitionerServices(newList);
    setEditingKey(newData.id);
  };

  const serviceSave = async (r: PractitionerService) => {
    try {
      if (r.serviceId == null) return;

      const services: Array<{ id: string }> = practitionerSevices.map((s) =>
        s.id.startsWith("new_") && r.serviceId != null
          ? { id: r.serviceId }
          : { id: s.id }
      );

      const updateVar = getConnectServiceWitPractitionerVar(
        PractitionerId,
        services
      );

      const result = await updateOnePractitioner(updateVar);

      if (result.data?.updateOnePractitioner?.id) {
        await refetchPractitioner();
        await refetchAllService();
        setEditingKey("");
        message.success("Add service success");
      }
    } catch (error: unknown) {
      message.error("Add service failed");
      console.error("error", error);
    }
  };

  const serviceRemove = async (sevice: PractitionerService) => {
    try {
      const updateVar = getDisconnectServiceWitPractitionerVar(
        PractitionerId,
        sevice.id
      );

      const result = await updateOnePractitioner(updateVar);

      if (result.data?.updateOnePractitioner?.id) {
        await refetchPractitioner();
        await refetchAllService();
        message.success("Remove service success");
      }
    } catch (error: unknown) {
      message.error("Remove service failed");
      console.error("error", error);
    }
  };

  const onServiceCancel = async (r: PractitionerService) => {
    if (r.id.startsWith("new_")) {
      const newList = [...practitionerSevices];
      newList.shift();
      setPractitionerServices(newList);
    }
    form.resetFields();
    setEditingKey("");
  };

  const TherapistInfo = () => (
    <div className="site-card-border-less-wrapper">
      <Row gutter={24}>
        <Col span={6}>
          <Card title="Practitioner Info" bordered={false}>
            <Image src={pacData?.practitioner?.image} />
            <p>
              <span>name : </span>
              {pacData?.practitioner?.name}
            </p>
            <p>
              <span>phone Number : </span>
              {pacData?.practitioner?.phonenumber}
            </p>
            <p>
              <span>description : </span>
              {pacData?.practitioner?.metadata}
            </p>
          </Card>
        </Col>
        <Col span={8}></Col>
      </Row>
    </div>
  );

  const Services = () => {
    const columns: ServiceColumn[] = [
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        editable: false,
        render: (imageSrc: string, record: PractitionerService) =>
          !imageSrc ? (
            <Image width={150} height={150} preview={false} />
          ) : (
            <Image src={imageSrc} width={150} height={150} />
          ),
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        editable: true,
        render: (text: PractitionerService, record: PractitionerService) =>
          text,
        ...(editingKey === "" && {
          defaultSortOrder: "ascend",
          sorter: (a: PractitionerService, b: PractitionerService) =>
            a.name.localeCompare(b.name),
        }),
      },
      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
        editable: false,
        render: (text: PractitionerService, record: PractitionerService) =>
          text,
      },
      {
        title: "Action",
        key: "action",
        align: "center",
        width: "10%",
        dataIndex: "action",
        editable: false,
        fixed: "right",
        render: (v: number, record: PractitionerService) => {
          const editable = isEditing(record);
          return (
            <ButtonGroup>
              {editable ? (
                saveLoading ? (
                  <Button type="primary" icon={<LoadingOutlined />}>
                    saving...
                  </Button>
                ) : (
                  <Row gutter={[8, 8]} justify="left">
                    <Col>
                      <Popconfirm
                        placement="topRight"
                        title={`Sure to add ${record.name}?`}
                        onConfirm={() => serviceSave(record)}
                        onCancel={() => onServiceCancel(record)}
                      >
                        <Button type="primary" icon={<SaveOutlined />}>
                          save
                        </Button>
                      </Popconfirm>
                    </Col>
                    <Col>
                      <Button onClick={() => onServiceCancel(record)}>
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                )
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to add ${record.name}?`}
                  onConfirm={() => serviceRemove(record)}
                  onCancel={() => {}}
                >
                  <Button type="primary" icon={<DeleteOutlined />}>
                    Remove
                  </Button>
                </Popconfirm>
              )}
            </ButtonGroup>
          );
        },
      },
    ];

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record: PractitionerService) => ({
          record,
          options: allServices,
          inputType: "select",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          onServiceChange: onServiceChange,
        }),
      };
    });

    return (
      <>
        <Row style={{ paddingBottom: 8 }}>
          <Col offset={22}>
            <Button
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={add}
              disabled={editingKey !== ""}
            >
              Add
            </Button>
          </Col>
        </Row>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || pacLoad || saveLoading}
            showHeader={practitionerSevices.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={practitionerSevices.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </>
    );
  };

  const LeaveDay = () => {
    const columns = [
      {
        title: "From",
        dataIndex: "from_time",
        key: "from_time",
        editable: true,
        required: true,
        editOnOld: false,
        sorter: (a: GTLeave, b: GTLeave) =>
          new Date(a.from_time).getTime() - new Date(b.from_time).getTime(),
        render: (v: number, r: GTLeave) => {
          return format(new Date(r.from_time), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "To",
        dataIndex: "to_time",
        key: "to_time",
        editable: true,
        required: true,
        editOnOld: false,
        sorter: (a: GTLeave, b: GTLeave) =>
          new Date(a.to_time).getTime() - new Date(b.to_time).getTime(),
        render: (v: number, r: GTLeave) => {
          return format(new Date(r.to_time), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "Staus",
        dataIndex: "status",
        key: "status",
        sorter: (a: GTLeave, b: GTLeave) => a.status.localeCompare(b.status),
        editable: true,
        required: true,
        editOnOld: true,
      },
      {
        title: "Remark",
        dataIndex: "remark",
        key: "remark",
        editable: true,
        required: false,
        editOnOld: true,
        render: (v: number, r: GTLeave) => {
          return (
            <Paragraph
              style={{ maxWidth: 230 }}
              ellipsis={{ rows: 3, expandable: false }}
            >
              {r.remark}
            </Paragraph>
          );
        },
      },
    ];
    return (
      <div className="site-card-border-less-wrapper">
        <Table dataSource={leaveDayData} columns={columns} />
      </div>
    );
  };
  const tabContext: Record<string, React.ReactNode> = {
    info: <TherapistInfo />,
    services: <Services />,
    leave: <LeaveDay />,
  } as any;
  return (
    <div>
      <React.Fragment>
        <Card
          // title={data.service?.name + " Service"}
          loading={pacLoad}
          tabList={tabs}
          activeTabKey={activeTabKey}
          onTabChange={onTabChange}
        >
          {tabContext[activeTabKey]}
        </Card>
      </React.Fragment>
      {/* <Calendar dateCellRender={dateCellRender} /> */}
    </div>
  );
};

export default withClinic(TherapistsDetail);
const styles = {
  card: {
    height: "100%",
  },
};
