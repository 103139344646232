/* eslint-disable no-restricted-globals */
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Space, Typography } from "antd";
import type { FormInstance } from 'antd/es/form';
import React, { useState } from "react";

interface P {
    onChange: (val: DListItem[]) => void;
    defaultValue?: DListItem[];
    title: string;
    label: string;
    formId: string;
}

interface DListItem {
    id: string,
    term: string
}
interface FormData {
    list: DListItem[]
}

const DyanmicPoupList = ({ defaultValue, onChange, title, label, formId }: P) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [list, setList] = useState<DListItem[]>(defaultValue || []);
    const formRef = React.useRef<FormInstance<FormData>>(null);
    const openModal = () => {
        setTimeout(() => {
            formRef.current?.setFieldsValue({ list });
        }, 500)
        setIsModalVisible(true);
    };
    const handleSave = (val: FormData) => {
        console.log('formdata', val)
        if (val.list) {
            setList(val.list)
            onChange(val.list)
        }
        setIsModalVisible(false);
    };
    return (
        <>
            <span onClick={openModal}>
                {list.length > 0 ? <Typography.Paragraph editable
                    style={{ maxWidth: 230 }}
                    ellipsis={{ rows: 3, expandable: false }}>{list.map(i => i.term).join(',')}</Typography.Paragraph> : <PlusOutlined />}
            </span>
            <Modal
                destroyOnClose={true}
                afterClose={() => {
                    formRef.current?.resetFields();
                }}
                title={title}
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={[
                    <Button type="primary" form={formId} key="submit" htmlType="submit">
                        Save
                    </Button>
                ]}
            >
                <Form
                    id={formId}
                    ref={formRef}
                    autoComplete="off"
                    onFinish={handleSave}
                >
                    <Form.List name="list">
                        {(fields: any, { add, remove }: any) => (
                            <>
                                {fields.map(({ key, name, ...restField }: any) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            label="id"
                                            name={[name, 'id']}
                                            style={{ display: 'none' }}
                                        >
                                            <Input placeholder="id" disabled />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'term']}
                                            rules={[{ required: true, message: `required ${label}` }]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add({ id: `new_${new Date().getTime()}` })} block icon={<PlusOutlined />}>
                                        Add
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Form>
            </Modal>
        </>
    );
};

export default DyanmicPoupList as any;
