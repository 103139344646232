import { gql } from "@apollo/client";

export const get_treatment_report = gql`
  query CheckIns(
    $where: CheckInWhereInput
    $orderBy: [CheckInOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    checkIns(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      member {
        name
      }
      practitioner {
        id
        name
      }
      status
      in_time
      service {
        name
      }
      booking {
        from_time
        to_time
        service_helper {
          name
          id
        }
      }
    }
  }
`;

export const getTreatmentReportVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[] | string;
  searchText?: string | null;
  take: number;
  skip: number;
  practitionerId?: string;
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({
      member: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({
      practitioner: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({ service: { is: { OR: [{ name: { contains: searchText } }] } } });
  }
  where.clinic_id = { equals: data.clinicId };
  where.in_time = { gte: data.startDate, lte: data.endDate };
  if (status.length > 0) where.status = { in: status };
  if (data.practitionerId)
    where.practitioner_id = { equals: data.practitionerId };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = data.take;
  variables.skip = data.skip;
  return variables;
};

export const get_treatment_count = gql`
  query GroupByCheckIn(
    $by: [CheckInScalarFieldEnum!]!
    $where: CheckInWhereInput
  ) {
    groupByCheckIn(by: $by, where: $where) {
      _count {
        _all
      }
    }
  }
`;

export const getTreatmentCountVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string | string[];
  practitionerId?: string;
}) => {
  let variables: any = {};
  variables.by = ["clinic_id", "status"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    status: { in: data.status },
  };

  if (data.practitionerId)
    variables.where.practitioner_id = { equals: data.practitionerId };
  return variables;
};

export const export_treatments = gql`
  query CheckIns(
    $where: CheckInWhereInput
    $orderBy: [CheckInOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    checkIns(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      member {
        name
      }
      practitioner {
        id
        name
      }
      status
      in_time
      service {
        name
      }
      booking {
        from_time
        to_time
        service_helper {
          name
          id
        }
      }
    }
  }
`;

export const getExportTreatmentVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string;
  take: number;
  skip: number;
  practitionerId?: string;
}) => {
  let variables: any = {},
    where: any = {},
    OR: any[] = [];

  where.clinic_id = { equals: data.clinicId };
  where.in_time = { gte: data.startDate, lte: data.endDate };
  where.status = { in: ["CHECKOUT"] };
  if (data.practitionerId)
    where.practitioner_id = { equals: data.practitionerId };
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = data.take;
  variables.skip = data.skip;
  return variables;
};
