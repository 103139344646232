import {  Result } from 'antd';
import React from 'react';

const C: React.FC = () => (
  <Result
    status="403"
    title="403"
    subTitle="Sorry, you are not authorized to access this page."
   // extra={<Button type="primary">Back</Button>}
  />
);

export default C;