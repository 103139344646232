import React, { useState } from "react";
import { PictureOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  getStorage,
} from "firebase/storage";
import { Button } from "antd";
export const uploadImageToGoogleStorageAsPromise = async (
  imageFile: any,
  prefix: string
) => {
  const metadata = {
    contentType: imageFile.type,
  };
  const storage = getStorage();
  return new Promise((resolve, reject) => {
    var storageRef = ref(
      storage,
      `adminUploads/${prefix}/imageGallery/${imageFile.name}`
    );
    var task = uploadBytesResumable(storageRef, imageFile, metadata);
    //Update progress bar
    task.on(
      "state_changed",
      (snapshot) => {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //console.log(percentage);
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      () => {
        getDownloadURL(task.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
interface GImage {
  uid: string;
  name: string;
  url: string;
  status: "done";
}
interface P {
  images: GImage[];
  title?: string;
  loading?: boolean;
  clinicId: string;
  onOk: (
    images: Array<{
      uid: string;
      url: string;
      type: "old" | "new";
      name: string;
    }>,
    removes: string[]
  ) => void;
}
const ImageGallery = (props: P) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [removeList, SetRemoveList] = useState<string[]>([]);
  const [fileList, setFileList] = useState<UploadFile[]>(props.images);
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) =>
    setFileList(newFileList);
  const beforeUpload: UploadProps["beforeUpload"] = (file) => {
    return false;
  };
  const onRemove: UploadProps["onRemove"] = async (file) => {
    // console.log('rm', file)
    const newList = [...removeList];
    newList.unshift(file.uid);
    SetRemoveList(newList);
    //removeList.push(file.uid);
    return true;
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleOk = async () => {
    let images: Array<{
      uid: string;
      name: string;
      url: string;
      type: "old" | "new";
    }> = [];
    let removes: string[] = [];
    setConfirm(true);
    for (const [index, f] of fileList.entries()) {
      if (f.url) {
        images.push({ uid: f.uid, url: f.url, type: "old", name: f.name });
      } else {
        try {
          const imageUrl = (await uploadImageToGoogleStorageAsPromise(
            f.originFileObj,
            props.clinicId
          )) as string;
          // console.log('imageUrl', imageUrl)
          images.push({ uid: f.uid, url: imageUrl, type: "new", name: f.name });
        } catch (error) {
          console.error(error);
        }
      }
    }
    for (const rmi of removeList) {
      const find = props.images.find((img) => img.uid === rmi);
      if (find) {
        removes.push(rmi);
      }
    }
    if (props.onOk) {
      props.onOk(images, removes);
    }
    setShow(false);
    setConfirm(false);
  };
  return (
    <React.Fragment>
      <Button
        loading={props.loading || false}
        icon={<PictureOutlined />}
        onClick={() => setShow(true)}
      />
      <Modal
        title={`${props.title || ""} (Photo Gallery)`}
        open={show}
        onCancel={() => setShow(false)}
        onOk={handleOk}
        confirmLoading={confirm}
      >
        <Upload
          //action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          beforeUpload={beforeUpload}
          onRemove={onRemove}
        >
          {fileList.length >= 4 ? null : uploadButton}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="preview-eg" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Modal>
    </React.Fragment>
  );
};

export default ImageGallery;
