import { gql } from "@apollo/client";


export const get_service_from_type_slims = gql`
query ServiceFormTypes($where: ServiceFormTypeWhereInput, $orderBy: [ServiceFormTypeOrderByWithRelationInput!]) {
    serviceFormTypes(where: $where, orderBy: $orderBy) {
      id
      name
      legal_desc
      form_type
      description
      status
      consent_image
      consent_sign_align
    }
  }
`;
export const get_service_form_types = gql`query ServiceFormTypes(
    $where: ServiceFormTypeWhereInput
    $orderBy: [ServiceFormTypeOrderByWithRelationInput!]
  ) {
    serviceFormTypes(where: $where, orderBy: $orderBy) {
      id
      name
      legal_desc
      form_type
      description
      status
      terms {
        id
        term
        status
        type
      }
    }
  }
  `
export const getServiceFormTypeVar = (params: {
  clinicId: string,
  types: Array<"CONSENT" | "RECORD">,
  status?: Array<"ACTIVE" | "INACTIVE">
}) => ({
  "where": {
    "clinic_id": { "equals": params.clinicId },
    "form_type": { "in": params.types },
    "status": { "in": params.status || ["ACTIVE", "INACTIVE"] }
  },
  "orderBy": [{ "updated_at": "desc" }]
});


export const create_one_service_form_type = gql`
mutation CreateOneServiceFormType($data: ServiceFormTypeCreateInput!) {
    createOneServiceFormType(data: $data) {
      id
    }
  }
`
export const createOneServiceFormTypeVar = (args: {
  clinicId: string,
  payload: any,
}) => ({
  data: {
    ...args.payload,
    clinic: { connect: { id: args.clinicId } },
  }
});
export const update_one_service_form_type = gql`
mutation UpdateOneServiceFormType($data: ServiceFormTypeUpdateInput!, $where: ServiceFormTypeWhereUniqueInput!) {
    updateOneServiceFormType(data: $data, where: $where) {
      id
    }
  }
`;

export const updateOneServiceFormTypeVar = (id: string, data: any, connectors?: Record<string, any> | null) => {
  let variables: any = {},
    update: any = {};
  variables.where = { id };
  if (typeof data === "object") {
    update = Object.assign(
      {},
      ...Object.keys(data).map((k, i) => ({ [k]: { set: data[k] } }))
    );
  }
  variables.data = update;
  if (connectors && typeof connectors === "object") {
    variables.data = { ...variables.data, ...connectors };
  }
  return variables;
}
