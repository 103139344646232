import React, { useState } from 'react';
import { ClockCircleFilled, ClockCircleOutlined, HistoryOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Button, Col, TimePicker, Row, Card, Select } from 'antd';
import { orderBy } from 'lodash';
import { Divider } from 'antd';
import { SystemLog } from '../store';
import { Empty } from 'antd';
import { format } from 'date-fns';
import { Timeline } from 'antd';

interface P {
    title?: string;
    start: number;
    end: number
    logs: SystemLog[];
    clinicId: string,
    saving: boolean;
    onSave: (start: number, end: number) => void
}
const getTimeSlot = (min: number) => {
    let hr: any = Math.trunc(min / 60);
    let mn: any = Math.trunc(min % 60);
    if (hr < 10) {
        hr = `0` + hr;
    }
    if (mn < 10) {
        mn = `0` + mn;
    }
    return `${hr}:${mn}`
}
const getTimeSlots = () => {
    const slots: Array<{ value: string | number, label: string }> = [];
    let min = 60
    while (min <= 24 * 60) {
        slots.push({ value: min, label: getTimeSlot(min) });
        min += 30;
    }
    return orderBy(slots, 'value', 'asc')
}
const timeSlocDic = Object.assign({}, ...getTimeSlots().map((x) => ({ [x.value]: x.label })))
const TimeRangeSelector = (props: P) => {
    const [show, setShow] = useState(false);
    const [showLg, setShowLg] = useState(false)
    const [startTime, setStartTime] = useState<any | null>({ value: props.start, label: getTimeSlot(props.start) });
    const [endTime, setEndTime] = useState<any | null>({ value: props.end, label: getTimeSlot(props.end) });
    const handleOk = () => {
        if (!startTime || !endTime) return;
        props.onSave(Number(startTime.value), Number(endTime.value))
        setShow(false)
    }
    return (
        <React.Fragment>
            <span
                onClick={() => setShow(true)}>
                <Button
                    loading={props.saving || false}
                    icon={<ClockCircleFilled />}
                    onClick={() => setShow(true)}
                />
                {getTimeSlot(props.start)} hr - {getTimeSlot(props.end)} hr
            </span>
            <Modal
                title={`${props.title || ""}`}
                open={show}
                onCancel={() => setShow(false)}
                onOk={handleOk}
                confirmLoading={props.saving}
                okText={"save"}
            >
                <Row span={24}>
                    <Col span={10} style={{ display: 'flex', placeContent: 'center', alignItems: 'center' }}>
                        <span>
                            Start
                            <Select
                                key={`startime_slt`}
                                value={startTime}
                                placeholder="start time"
                                style={{ width: 100 }}
                                onChange={(val: any) => {
                                    setEndTime(null)
                                    setStartTime({ value: val, label: getTimeSlot(val) })
                                }}
                                options={getTimeSlots()}
                                suffixIcon={<ClockCircleOutlined />}
                            />
                        </span>
                        <Divider type="vertical" />
                        <span>
                            End
                            <Select
                                key={`endtime_slt`}
                                placeholder="end time"
                                value={endTime}
                                style={{ width: 100 }}
                                onChange={(val: any) => setEndTime({ value: val, label: getTimeSlot(val) })}
                                options={startTime ? getTimeSlots().filter(sl => Number(sl.value) > Number(startTime.value)) : getTimeSlots()}
                                suffixIcon={<ClockCircleOutlined />}
                            />
                        </span>
                    </Col>
                    <Col span={14}
                        style={{ display: 'flex', placeContent: 'center', alignItems: 'center' }}
                    >
                        <Button onClick={() => setShowLg(!showLg)} icon={<HistoryOutlined />}> ChangeLog</Button>
                    </Col>
                    {showLg === true && (props.logs.length < 0 ? <Empty description="No history" /> :
                        <Timeline style={{ margin: 10 }}>
                            {props.logs.map((lg, idx) => {
                                let previous = "", time = format(new Date(lg.created_at), 'dd MMM,yyyy h:mm a'), by = lg.user.name
                                if (lg.metadata) {
                                    const { opening_in: from = 0, closing_in: to = 0 } = JSON.parse(lg.metadata) as { opening_in?: number, closing_in?: number };
                                    if (from > 0 && to > 0) {
                                        previous = `${getTimeSlot(from)}hr - ${getTimeSlot(to)} hr`
                                    }
                                }
                                return (
                                    <Timeline.Item key={`sys_lg_item-${idx}`}>
                                        <p>{previous}</p>
                                        <p>{time} By {by}</p>
                                    </Timeline.Item>

                                )
                            })}

                        </Timeline>
                    )
                    }
                </Row>

            </Modal >
        </React.Fragment >
    );
};

export default TimeRangeSelector;