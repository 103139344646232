import { gql } from "@apollo/client";

export const get_leaves = gql`
query PractitionerLeaves(
    $where: PractitionerLeaveWhereInput
    $orderBy: [PractitionerLeaveOrderByWithRelationInput!]
    $take: Int
  ) {
    practitionerLeaves(where: $where, orderBy: $orderBy, take: $take) {
      id
      status
      from_time
      to_time
      practitioner_id
      practitioner {
        id
        name
      }
      remark
      created_at
    }
  }
  
`;
export const getLeaveVar = (data: {
    clinicId: string,
    status?: string[];
    searchText?: string | null,
    take?: number,
}) => {
    const { status = [], searchText, take = 100 } = data;
    let variables: any = {}, where: any = {}, OR: any[] = [];
    if (searchText && searchText !== "") {
        OR.push({ practitioner: { is: { name: { contains: searchText } } } });
    }
    where.clinic_id = { equals: data.clinicId }
    if (status.length > 0) where.status = { in: status };
    if (OR.length > 0) where.OR = OR;
    variables.where = where;
    variables.orderBy = [{ created_at: "desc" }]
    variables.take = take
    return variables;
}
export const create_one_leave = gql`
mutation CreateOnePractitionerLeave($data: PractitionerLeaveCreateInput!) {
    createOnePractitionerLeave(data: $data) {
      id
    }
  }
  
`;
export const update_one_leave = gql`
mutation UpdateOnePractitionerLeave(
    $data: PractitionerLeaveUpdateInput!
    $where: PractitionerLeaveWhereUniqueInput!
  ) {
    updateOnePractitionerLeave(data: $data, where: $where) {
      id
    }
  }
  
`;

export const getUpdateOneLeaveVar = (id: string, data: any) => {
    let variables: any = {}, update: any = {};
    variables.where = { id }
    if (typeof data === 'object') {
        update = Object.assign({}, ...Object.keys(data).map((k, i) => ({ [k]: { "set": data[k] } })))
    }
    variables.data = update;
    return variables;
}