import { createHttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import passLink from "./pass_link";
const prod = "https://greattime-api-core-hs6rtohe3q-uc.a.run.app/apicore";
//const stag = "http://localhost:4000/apicore";
const auth = setContext(async (req, previousContext) => {
    const { headers = {} } = previousContext;
    let token: string | null = null;
    // const user = getAuth().currentUser;
    // if (user) {
    //     token = await user.getIdToken(true);
    // }
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const defaultLink = auth.concat(createHttpLink({
    uri: prod
}));

export default split(
    o => o.getContext().className === 'pass',
    passLink,
    defaultLink
);