import React, { useState } from "react";
import withClinic from "../hooks/with_clinic";
import { ApolloResult, Booking, Clinic } from "../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { addDays, format, subDays } from "date-fns";
import { useQuery } from "@apollo/client";
import { getBookingVar, get_bookings } from "../graphql/booking";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import MultiSelect from "../components/multi_select";
interface P {
  currentClinic?: Clinic;
}

const Appoinment = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 15),
    endDate: addDays(new Date(), 15),
  });
  const [form] = Form.useForm();
  const variables = getBookingVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take: 150,
    status: filter,
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"bookings", Booking[]>
  >(get_bookings, { variables });
  // console.log(data);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };
  const columns: any[] = [
    {
      title: "Date",
      dataIndex: "from_time",
      key: "from_time",
      editable: false,
      sorter: (a: Booking, b: Booking) =>
        new Date(a.from_time).getTime() - new Date(b.from_time).getTime(),
      render: (v: number, r: Booking) => {
        return format(new Date(r.from_time), "dd MMM,yyyy h:mm a");
      },
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      editable: false,
      sorter: (a: Booking, b: Booking) =>
        a.member.name.toLowerCase().localeCompare(b.member.name.toLowerCase()),
      render: (v: number, r: Booking) => {
        return r.member.name;
      },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: Booking, b: Booking) => a.status.localeCompare(b.status),
      editable: false,
    },
    {
      title: "Practitioner",
      dataIndex: "practitioner",
      key: "practitioner",
      editable: false,
      sorter: (a: Booking, b: Booking) =>
        a.practitioner.name
          .toLowerCase()
          .localeCompare(b.practitioner.name.toLowerCase()),
      render: (v: number, r: Booking) => {
        return r.practitioner.name;
      },
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      editable: false,
      sorter: (a: Booking, b: Booking) =>
        a.service.name
          .toLowerCase()
          .localeCompare(b.service.name.toLowerCase()),
      render: (v: number, r: Booking) => {
        return r.service.name;
      },
    },
    {
      title: "Helper",
      dataIndex: "Helper",
      key: "Helper",
      editable: false,
      sorter: (a: Booking, b: Booking) =>
        a.service_helper.name
          .toLowerCase()
          .localeCompare(b.service_helper?.name.toLowerCase()),
      render: (v: number, r: Booking) => {
        return r.service_helper?.name;
      },
    },
    {
      title: "Note",
      dataIndex: "member_note",
      key: "member_note",
      editable: true,
    },
    // {
    //     title: 'Action',
    //     key: 'action',
    //     align: 'center',
    //     width: '10%',
    //     dataIndex: 'action',
    //     editable: false,
    //     fixed: 'right',
    //     render: (v: number, record: Account) => {
    //        // const editable = isEditing(record);
    //         return (
    //             <ButtonGroup>
    //                 {editable ?
    //                     (
    //                         saveLoading ? <Button type="primary" icon={<LoadingOutlined />} >saving...</Button> :
    //                             < Popconfirm placement="topRight" title={`Sure to save ${record.account_number}?`} onConfirm={() => handleSave(record)} onCancel={() => setEditingKey('')}>
    //                                 <Button type='primary' icon={<SaveOutlined />} >save</Button>
    //                             </Popconfirm>
    //                     )
    //                     :
    //                     <Button shape="circle" icon={<EditOutlined />}
    //                         onClick={() => edit(record)}
    //                     />
    //                 }
    //             </ButtonGroup >
    //         );

    //     }
    // }
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Appointments</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${
                props.currentClinic?._count.bookings || ""
              } appoinments`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4 }}>
      <Col span={24}>
        <MultiSelect
          selected={filter}
          placeholder="Select appointment status"
          options={[
            "REQUEST",
            "BOOKED",
            "CHECKIN",
            "CHECKOUT",
            "MEMBER_CANCEL",
            "MERCHANT_CANCEL",
            "NO_SHOW",
          ]}
          onChange={setFilter}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            showHeader={data.bookings.length > 0}
            //components={{ body: { cell: EditableCell, } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={data.bookings.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(Appoinment);
