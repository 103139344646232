import React, { Fragment, useEffect } from "react";
import logo from "./logo.png";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  LogoutOutlined,
  CalendarOutlined,
  FileDoneOutlined,
  ContactsOutlined,
  GiftOutlined,
  MedicineBoxOutlined,
  SkinOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  UserOutlined,
  SendOutlined,
  NotificationOutlined,
  WalletOutlined,
  TransactionOutlined,
  UsergroupAddOutlined,
  TeamOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { ApolloResult, AppState, Clinic, User, actions } from "../store";
import paths from "../routes/paths";
import { useLazyQuery } from "@apollo/client";
import { getClinicVariables, get_clinics } from "../graphql/clinic";
import SubMenu from "antd/lib/menu/SubMenu";
import { appVersion } from "../constants";
import { Divider } from "antd";
const { Sider } = Layout;
interface P {
  onMenuClik: (item: { key: string }) => void;
  openSider: boolean;
  currentMenuItem?: string;
  currentClinic?: Clinic;
  clinicIds: string[];
  clinics: Clinic[];
  setClinics: (c: Clinic[]) => void;
  setSelectedClinic: (c: Clinic) => void;
  user?: User;
  logout: () => void;
}
const menuList = [
  {
    name: "Home",
    path: paths.home,
    icon: HomeOutlined,
    isMain: true,
  },
  {
    name: "BI Dashboard",
    path: paths.customerBehaviourDashboard,
    icon: DashboardOutlined,
    isMain: false,
    links: [
      { path: paths.customerBehaviourDashboard, name: "Customer Behaviour" },
      { path: paths.serviceBehaviourDashborad, name: "Service Behaviour" },
    ],
  },

  {
    name: "Appointments",
    path: paths.appointments,
    icon: CalendarOutlined,
    isMain: true,
  },
  {
    name: "Check In/Out",
    path: paths.checkIns,
    icon: ClockCircleOutlined,
    isMain: true,
  },
  {
    name: "Sales",
    path: paths.sales,
    icon: FileDoneOutlined,
    isMain: true,
  },
  {
    name: "Report",
    path: paths.report,
    icon: FileTextOutlined,
    isMain: false,
    links: [
      { path: paths.daily_sales, name: "Daily Sale Report" },
      { path: paths.treatment_report, name: "Treatment Report" },
      { path: paths.top_treatment_report, name: "Top Treatment Report" },
      { path: paths.top_practitioner_report, name: "Top Practitioner Report" },
      { path: paths.seller_report, name: "Seller Report" },
      // { path: paths.top_member_report, name: "Top Member Report" },
    ],
  },
  {
    name: "Service",
    path: paths.services,
    icon: MedicineBoxOutlined,
    isMain: false,
    links: [
      { path: paths.services, name: "Service List" },
      { path: paths.service_packages, name: "Service Packages" },
      { path: paths.service_categories, name: "Service Type Category" },
      { path: paths.service_record_froms, name: "Service Record Form" },
      { path: paths.service_consent_froms, name: "Service Consent Form" },
      // { path: paths.service_stock_items, name: "Service Stock Items" },
      // { path: paths.service_stock_usages, name: "Service Stock Usages" },
    ],
  },
  // {
  //   name: "Service Packages",
  //   path: paths.service_packages,
  //   icon: GiftOutlined,
  //   isMain: true,
  // },
  {
    name: "Product",
    path: paths.products,
    icon: SkinOutlined,
    isMain: false,
    links: [
      { path: paths.products, name: "Product List" },
      { path: paths.product_stock_items, name: "Product Stock Items" },
    ],
  },
  {
    name: "Therapists",
    path: paths.therapists,
    icon: ContactsOutlined,
    isMain: true,
  },
  {
    name: "Helper",
    path: paths.helper,
    icon: UsergroupAddOutlined,
    isMain: true,
  },
  {
    name: "Members",
    path: paths.members,
    icon: UserOutlined,
    isMain: true,
  },
  {
    name: "Wallets",
    path: paths.wallets,
    icon: WalletOutlined,
    isMain: true,
  },
  {
    name: "Transactions",
    path: paths.transactions,
    icon: TransactionOutlined,
    isMain: true,
  },
  {
    name: "Offer",
    path: paths.offers,
    icon: SendOutlined,
    isMain: false,
    links: [
      { path: paths.offer_categories, name: "Offer Category" },
      { path: paths.offers, name: "Offer List" },
    ],
  },
  {
    name: "Notification",
    path: paths.notifications,
    icon: NotificationOutlined,
    isMain: true,
    links: [],
  },
  {
    name: "Leaves",
    path: paths.leaves,
    icon: CalendarOutlined,
    isMain: true,
    links: [],
  },
  {
    name: "User",
    path: paths.user,
    icon: TeamOutlined,
    isMain: true,
    links: [],
  },
];

function SideNav(props: P) {
  const [getClinics, { data: cData, error: cError, loading: cLoading }] =
    useLazyQuery<ApolloResult<"clinics", Clinic[]>>(get_clinics, {
      variables: getClinicVariables(props.clinicIds),
    });
  useEffect(() => {
    if (cData?.clinics) {
      localStorage.setItem("clinics", JSON.stringify(cData.clinics));
      props.setClinics(cData.clinics);
    }
  }, [cData]);

  useEffect(() => {
    const localClinics = localStorage.getItem("clinics");
    if (localClinics != null) {
      const clinics = JSON.parse(localClinics);
      props.setClinics(clinics);
    } else {
      getClinics();
    }
  }, []);

  return (
    <React.Fragment>
      <Sider
        className="side-nav"
        collapsible
        collapsed={props.openSider}
        trigger={
          <Menu
            style={!props.openSider ? styles.logout : styles.logoutIn}
            onClick={props.logout}
          >
            <Menu.Item icon={<LogoutOutlined style={{ fontSize: 14 }} />}>
              <span>Logout</span>
            </Menu.Item>
          </Menu>
        }
        width={220}
        style={styles.sider}
      >
        <div className="logo">
          <img
            alt={"logo"}
            src={props.user?.photo || logo}
            style={{ borderRadius: 30 }}
          />
          <h2>{props.user?.name || "Admin"}</h2>
          <span style={{ padding: 4 }}>{appVersion}</span>
        </div>
        <div style={styles.menuList as any}>
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={
              props.currentMenuItem ? props.currentMenuItem : [paths.home]
            }
            onClick={props.onMenuClik}
          >
            {menuList
              .map((m, i) =>
                m.isMain ? (
                  <Menu.Item key={m.path} icon={<m.icon />}>
                    {" "}
                    <span>{m.name}</span>{" "}
                  </Menu.Item>
                ) : (
                  <SubMenu
                    key={`sub-menu_${i}`}
                    icon={<m.icon />}
                    title={<span>{m.name}</span>}
                  >
                    {m.links?.map((sub) => (
                      <Menu.Item key={sub.path}>
                        {" "}
                        <span>{sub.name}</span>{" "}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                )
              )
              .concat(<Divider />)}
          </Menu>
        </div>
      </Sider>
    </React.Fragment>
  );
}
const mapStateToDispatch = (dispatch: any) => {
  return {
    setClinics: (d: Clinic[]) => dispatch(actions.clinicAction.setClinics(d)),
    setSelectedClinic: (d: Clinic) =>
      dispatch(actions.clinicAction.setSelected(d)),
    logout: () => dispatch(actions.authAction.loggedOut()),
  };
};
const mapStateToProps = (state: AppState) => {
  return {
    openSider: state.antdState.openSideToogle,
    currentClinic: state.clinicState.selected,
    clinicIds:
      state.authState.status === "loggedIn"
        ? state.authState.userInfo?.clinics || []
        : [],
    clinics: state.clinicState.clinics,
    user:
      state.authState.status === "loggedIn"
        ? state.authState.userInfo!
        : undefined,
  };
};
export default connect(mapStateToProps, mapStateToDispatch)(SideNav) as any;
const styles = {
  sider: {
    background: "#F5F5F5",
    borderRight: "1px solid gray",
    padding: "10px",
  },
  logout: {
    color: "blue",
    marginRight: 10,
    borderRight: "1px solid gray",
  },
  logoutIn: {
    color: "blue",
    marginLeft: -10,
    borderRight: "1px solid gray",
  },
  menuList: {
    overflowY: "scroll",
  },
};
