import { gql } from "@apollo/client";

export const service_rooms = gql`
  query ServiceRooms($where: ServiceRoomWhereInput) {
    serviceRooms(where: $where) {
      id
      name
      sort_order
      status
      description
      created_at
    }
  }
`;

export const getServiceRoomsVar = (clinicId: string) => ({
  where: {
    clinic_id: { equals: clinicId },
  },
});

export const create_one_srv_room = gql`
  mutation CreateOneServiceRoom($data: ServiceRoomCreateInput!) {
    createOneServiceRoom(data: $data) {
      id
    }
  }
`;

export const update_one_srv_room = gql`
  mutation UpdateOneServiceRoom(
    $data: ServiceRoomUpdateInput!
    $where: ServiceRoomWhereUniqueInput!
  ) {
    updateOneServiceRoom(data: $data, where: $where) {
      id
    }
  }
`;

export const getUpdateOneSrvRoomVar = (
  id: string,
  data: any,
  connectors?: any
) => {
  let variables: any = {},
    update: any = {};
  variables.where = { id };
  if (typeof data === "object") {
    update = Object.assign(
      {},
      ...Object.keys(data).map((k, i) => ({ [k]: { set: data[k] } }))
    );
  }
  variables.data = update;
  if (connectors && typeof connectors === "object") {
    variables.data = { ...variables.data, ...connectors };
  }
  return variables;
};


export const one_service_room = gql`
  query ServiceRoom($where: ServiceRoomWhereUniqueInput!) {
    serviceRoom(where: $where) {
      id
      name
      sort_order
      status
      clinic_id
      description
      services {
        id
        name
      }
    }
  }
`;

export const getOneSrvRoomVar = (id: string) => ({ where: { id } });
