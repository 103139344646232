import React, { useState } from 'react';
import { PictureOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { ref, getDownloadURL, uploadBytesResumable, getStorage } from "firebase/storage";
import { Button } from 'antd';
import { Input } from 'antd';
import { Typography } from 'antd';
export const uploadImageToGoogleStorageAsPromise = async (imageFile: any, prefix: string) => {
    const metadata = {
        contentType: imageFile.type,
    };
    const storage = getStorage();
    return new Promise((resolve, reject) => {
        var storageRef = ref(storage, `adminUploads/${prefix}/imageGallery/${imageFile.name}`);
        var task = uploadBytesResumable(storageRef, imageFile, metadata);
        //Update progress bar
        task.on(
            "state_changed",
            (snapshot) => {
                const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //console.log(percentage);
            },
            (err) => {
                console.log(err);
                reject(err);
            },
            () => {
                getDownloadURL(task.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });

            }
        );
    });
};
const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
interface GImage {
    uid: string, name: string, url: string, status: "done", note?: string
}
interface P {
    images: GImage[],
    clinicId: string,
    loading: boolean,
    onOk: (images: Array<{ uid: string, url: string, type: "old" | "new", name: string, note?: string }>, removes: string[]) => void
}
const ImageGalleryWithNote = (props: P) => {
    const [loading, setLoading] = useState(false)
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [previewId, setPreviewId] = useState('');
    const [removeList, SetRemoveList] = useState<string[]>([]);
    const [fileList, setFileList] = useState<UploadFile[]>(props.images);
    const [noteDic, setNoteDic] = useState(Object.assign({}, ...props.images.map((x) => ({ [x.uid]: x.note }))))
    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as RcFile);
        }
        setPreviewId(file.uid);
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));

    };

    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        let nfile: UploadFile | null = null;
        if (newFileList.length > 0) {
            const n = newFileList[newFileList.length - 1];
            const findIndex = fileList.findIndex(f => f.uid === n.uid);
            if (findIndex === -1) {
                nfile = n;
            }
        }
        if (nfile) {
            handlePreview(nfile);
        }
        setFileList(newFileList)

    };
    const beforeUpload: UploadProps["beforeUpload"] = async (file: UploadFile) => {
        return false;
    };
    const onRemove: UploadProps["onRemove"] = async (file) => {
        const newList = [...removeList];
        newList.unshift(file.uid);
        SetRemoveList(newList)
        //removeList.push(file.uid);
        return true;
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );
    const handleOk = async () => {
        let images: Array<{ uid: string, name: string, url: string, type: "old" | "new", note?: string }> = [];
        let removes: string[] = [];
        setLoading(true)
        for (const [index, f] of fileList.entries()) {
            if (f.url) {
                images.push({ uid: f.uid, url: f.url, type: 'old', name: f.name, note: noteDic[f.uid] || '' });
            } else {
                try {
                    const imageUrl = await uploadImageToGoogleStorageAsPromise(f.originFileObj, props.clinicId) as string;
                    images.push({ uid: f.uid, url: imageUrl, type: "new", name: f.name, note: noteDic[f.uid] || '' });
                } catch (error) {
                    console.error(error)
                }
            }
        }
        setLoading(false)
        for (const rmi of removeList) {
            const find = props.images.find(img => img.uid === rmi)
            if (find) {
                removes.push(rmi)
            }
        }
        if (props.onOk) {
            props.onOk(images, removes)
        }
    }
    return (
        <React.Fragment>
            <Upload
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                beforeUpload={beforeUpload}
                onRemove={onRemove}
            >
                {uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="preview-eg" style={{ width: '100%' }} src={previewImage} />
                <div style={{ padding: 10 }}> <Typography.Title level={4} >Caption</Typography.Title>
                    <Input
                        value={noteDic[previewId]}
                        placeholder="Enter a caption for the photo"
                        onChange={(e: any) => {
                            setNoteDic({ ...noteDic, [previewId]: e.target.value })
                        }}
                    />
                </div>
            </Modal>
            <Button type="primary" onClick={handleOk} loading={props.loading || loading} icon={<SaveOutlined />}> save</Button>
        </React.Fragment>
    );
};

export default ImageGalleryWithNote;