import React, { useEffect, useState } from 'react';
import { EditOutlined, HistoryOutlined } from '@ant-design/icons';
import { Modal, Input, Form } from 'antd';
import { Button, Row, Space, Select, InputNumber } from 'antd';
import { ApolloResult, Clinic, MemberServiceBag, SystemLog } from '../store';
import type { FormInstance } from 'antd/es/form';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { getUpdateMemberBagVar, update_member_bag } from '../graphql/member';
import { Col, Timeline, Empty } from 'antd';
import { getSysLgVar, get_system_logs } from '../graphql/system_log';
import { format } from 'date-fns';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
interface P {
    userId: string,
    title?: string
    bag: MemberServiceBag
    onClose: () => void;
    onFinish: () => void;
}
interface FormData {
    remaining_count: number;
    original_count: number;
}

const MemberBagEdit = (props: P) => {
    const formRef = React.useRef<FormInstance<FormData>>(null);
    const [show, setShow] = useState(true);
    const [updateBag, { loading, data }] = useMutation<ApolloResult<"updateOneMemberServiceBag", MemberServiceBag>>(update_member_bag);
    const [getLogs, { data: logs, loading: lgLoading }] = useLazyQuery<ApolloResult<"systemLogs", SystemLog[]>>(get_system_logs, { variables: getSysLgVar(props.bag.id, 3), fetchPolicy: 'cache-and-network' })
    useEffect(() => {
        formRef.current?.resetFields();
        formRef.current?.setFieldsValue({
            remaining_count: props.bag.remaining_count,
            original_count: props.bag.original_count
        });
    }, [props])
    const handleOk = async (val: FormData) => {
        const bagId = props.bag.id;
        const variables = getUpdateMemberBagVar({
            bagId: bagId,
            remainCount: val.remaining_count,
            originCount: val.original_count,
            log: {
                code: bagId,
                log_id: `${bagId}_${new Date().getTime()}`,
                metadata: JSON.stringify(props.bag),
                user: { connect: { id: props.userId } }
            }
        });
        const result = await updateBag({ variables });
        setShow(false)
        if (result.data?.updateOneMemberServiceBag) {
            props.onFinish();
        }

    }
    return (
        <React.Fragment>
            <Modal
                title={`${props.title || ""}`}
                open={show}
                onCancel={() => setShow(false)}
                footer={[
                    <Button loading={loading} type="primary" form="bagForm" key="submit" htmlType="submit">
                        Save
                    </Button>
                ]}
                afterClose={() => {
                    props.onClose();
                }}
                destroyOnClose={true}
                closable={!loading}
                maskClosable={!loading}
            >
                <Row >
                    <Col span={14}>
                        <Form
                            {...layout}
                            id="bagForm"
                            ref={formRef}
                            name="control-ref"
                            onFinish={handleOk}
                            style={{ maxWidth: 800 }}
                        >
                            <Form.Item
                                label="Remaining"
                                name="remaining_count"
                                rules={[{ required: true, message: 'Remaining count is required' }]}
                            >
                                <InputNumber min={0} max={100} />
                            </Form.Item >
                            <Form.Item
                                label="Total"
                                name="original_count"
                                rules={[{ required: true, message: 'Total count is required' }]}
                            >
                                <InputNumber min={0} max={100} />
                            </Form.Item>
                            <Form.Item label="Logs">
                                <Button loading={lgLoading}
                                    icon={<HistoryOutlined />}
                                    onClick={getLogs}
                                > click to see</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    {logs && <Col span={10}>
                        <Timeline >
                            {logs.systemLogs.length === 0 && <Empty description="No logs" />}
                            {logs.systemLogs.map((lg, idx) => {
                                let previous = "", time = format(new Date(lg.created_at), 'dd MMM,yyyy h:mm a'), by = lg.user.name
                                if (lg.metadata) {
                                    const bag = JSON.parse(lg.metadata) as MemberServiceBag
                                    previous += `(${bag.remaining_count}/ ${bag.original_count})`
                                }
                                return (
                                    <Timeline.Item key={`sys_lg_item-${idx}`}>
                                        <p>{previous}</p>
                                        <p>{time} By {by}</p>
                                    </Timeline.Item>
                                )
                            })}
                        </Timeline>
                    </Col>
                    }
                </Row>

            </Modal >
        </React.Fragment >
    );
};

export default MemberBagEdit;