import { createStore, combineReducers, compose } from 'redux';

import * as reducers from './reducers';
// redux
const reduxCombineReducers: any = combineReducers; //tslint:disable-line
const reducer = reduxCombineReducers({
    authState: reducers.authReducer,
    antdState: reducers.antdReducer,
    clinicState: reducers.clinicReducer
});

class RWindow extends Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose | undefined;
}

const rwindow = window as unknown as RWindow;
//const composeEnhancers = rwindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    reducer,
);
export * from './actions'
export * from './state';
export {
    store,
};





