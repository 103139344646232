import { gql } from "@apollo/client";

export const get_orders = gql`
  query Orders(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationInput!]
    $take: Int
  ) {
    orders(where: $where, orderBy: $orderBy, take: $take) {
      id
      discount
      net_total
      order_id
      status
      total
      clinic_id
      created_at
      payment_method
      payment_status
      credit_balance
      balance
      tax
      member_id
      member {
        name
        id
      }
      user_id
      user {
        id
        name
      }
    }
  }
`;
export const get_orders2 = gql`
  query Orders(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationInput!]
    $take: Int
    $skip: Int
    $clinicMembersWhere2: ClinicMemberWhereInput
  ) {
    orders(where: $where, orderBy: $orderBy, take: $take,skip: $skip) {
      id
      discount
      net_total
      order_id
      status
      total
      clinic_id
      created_at
      payment_method
      payment_status
      credit_balance
      balance
      tax
      member_id
      member {
        name
        id
        clinic_members(where: $clinicMembersWhere2) {
          name
          clinic_id
        }
      }
      user_id
      user {
        id
        name
      }
    }
  }
`;
export const get_slim_orders = gql`
  query Orders(
    $where: OrderWhereInput
    $orderBy: [OrderOrderByWithRelationInput!]
    $take: Int
  ) {
    orders(where: $where, orderBy: $orderBy, take: $take) {
      id
      total
      created_at
    }
  }
`;

export const getOrdersVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[];
  searchText?: string | null;
  take: number;
  notUserIds?: string[];
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({
      member: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({ user: { is: { OR: [{ name: { contains: searchText } }] } } });
    OR.push({ order_id: { contains: searchText } });
  }
  where.clinic_id = { equals: data.clinicId };
  where.created_at = { gte: data.startDate, lte: data.endDate };
  if (data.notUserIds && data.notUserIds.length > 0) {
    where.user_id = { notIn: data.notUserIds };
  }
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = data.take;
  return variables;
};
export const getOrdersVar2 = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[];
  payment_status: string[];
  searchText?: string | null;
  take: number;
  notUserIds?: string[];
  skip?: number
}) => {
  const { status = [], searchText, skip = 0 } = data;
  const { payment_status = [] } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({
      member: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({ user: { is: { OR: [{ name: { contains: searchText } }] } } });
    OR.push({ order_id: { contains: searchText } });
  }
  where.clinic_id = { equals: data.clinicId };
  where.created_at = { gte: data.startDate, lte: data.endDate };
  if (data.notUserIds && data.notUserIds.length > 0) {
    where.user_id = { notIn: data.notUserIds };
  }
  if (status.length > 0) where.status = { in: status };
  if (payment_status.length > 0) where.payment_status = { in: payment_status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.clinicMembersWhere2 = { clinic_id: { equals: data.clinicId } }
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = data.take;
  variables.skip = skip;
  return variables;
};

export const get_total_order_count = gql`
query _count($where: OrderWhereInput) {
  aggregateOrder(where: $where) {
    _count {
      id
    }
  }
}
`;

export const getTotalOrderCountVar = (args: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[];
  searchText?: string | null;
  notUserIds?: string[];
}) => {
  const { status = [], searchText } = args;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({
      member: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({ user: { is: { OR: [{ name: { contains: searchText } }] } } });
    OR.push({ order_id: { contains: searchText } });
  }
  where.clinic_id = { equals: args.clinicId };
  where.created_at = { gte: args.startDate, lte: args.endDate };
  if (args.notUserIds && args.notUserIds.length > 0) {
    where.user_id = { notIn: args.notUserIds };
  }
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  return variables;
}
export const get_members_order_count = gql`
  query _count($by: [OrderScalarFieldEnum!]!, $where: OrderWhereInput) {
    groupByOrder(by: $by, where: $where) {
      _count {
        _all
      }
      member_id
      clinic_id
    }
  }
`;
export const getMembersOrderCountVar = (data: {
  clinicId: string;
  memberIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.by = ["clinic_id", "member_id"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    created_at: { gte: data.startDate, lte: data.endDate },
    member_id: { in: data.memberIds },
  };
  return variables;
};

export const get_one_order = gql`
query GetOrder(
  $where: OrderWhereUniqueInput!
  $clinicMembersWhere2: ClinicMemberWhereInput
) {
  getOrder(where: $where) {
    id
    order_id
    total
    tax
    member_id
    status
    payment_method
    payment_detail
    net_total
    discount
    metadata
    created_at
    user_id
    user {
      name
      id
    }
    member {
      id
      name
      clinic_members(where: $clinicMembersWhere2) {
        name
        clinic_id
      }
    }
    clinic {
      id
      name
    }
    order_items {
      id
      quantity
      total
      tax
      price
      original_price
      service_id
      service {
        id
        name
        image
      }
      service_package_id
      service_package {
        id
        name
        image
      }
      product_stock_item_id
      product_stock_item {
        name
        id
      }
    }
  }
}
`;

export const getOneOrderVar = (id: string, clinicId: string) => ({ where: { id }, clinicMembersWhere2: { clinic_id: { equals: clinicId } } });

export const cancel_order = gql`
  mutation FallbackSaleOrder($orderId: String!, $userId: String!) {
    fallbackSaleOrder(order_id: $orderId, user_id: $userId) {
      id
    }
  }
`;

export const getCancelOrderVar = (data: {
  orderId: string;
  userId: string;
}) => ({ ...data });

export const order_summary = gql`
  query OrderSummary(
    $sellers: [String!]!
    $fromDate: DateTime!
    $toDate: DateTime!
    $status: [String!]!
    $paymentStatus: [String!]!
  ) {
    orderSummary(
      sellers: $sellers
      from_date: $fromDate
      to_date: $toDate
      status: $status
      payment_status: $paymentStatus
    ) {
      total_records
      total_net_amount
      total_balance
      total_credit_balance
      payment_status
      seller_id
    }
  }
`;

export const getOrderSummaryVar = (data: {
  sellers: string[];
  fromDate: Date;
  toDate: Date;
  status: Array<"ACTIVE" | "INACTIVE" | "CANCEL" | "DONE">;
  paymentStatus: Array<"PAID" | "UNPAID" | "PARTIAL_PAID">;
}) => {
  const variable = {
    sellers: data.sellers,
    fromDate: data.fromDate,
    toDate: data.toDate,
    status: data.status,
    paymentStatus: data.paymentStatus,
  };
  return variable;
};
