import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApolloResult, Clinic, Service, ServiceRoom } from "../store";
import {
  getOneSrvRoomVar,
  one_service_room,
  update_one_srv_room,
} from "../graphql/service_room";
import { Empty, Card } from "antd";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import MultiSelect2 from "../components/mulit_select2";
import { getServicesVar, get_services } from "../graphql/service";
import withClinic from "../hooks/with_clinic";
import { Button, message } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import paths from "../routes/paths";
function ServiceRoomDetail() {
  const navigate = useNavigate();
  const { id: roomId = "" } = useParams() as { id?: string };
  const [clinicId, setClinicId] = useState<string>("");
  const [srvChanged, setSrvChanged] = useState(false);
  const [currentSrvIds, setCurrentSrvIds] = useState<string[]>([]);
  const { data, loading, error, refetch } = useQuery<
    ApolloResult<"serviceRoom", ServiceRoom>
  >(one_service_room, { variables: getOneSrvRoomVar(roomId) });
  const [update, { loading: saving }] =
    useMutation<ApolloResult<"updateOneServiceRoom", ServiceRoom>>(
      update_one_srv_room
    );
  const { data: srVData, loading: srvLoading } = useQuery<
    ApolloResult<"services", Service[]>
  >(get_services, {
    variables: getServicesVar({
      clinicId,
      startDate: new Date(),
      endDate: new Date(),
      status: ["ACTIVE"],
      take: 50,
    }),
  });
  useEffect(() => {
    if (data?.serviceRoom) {
      setCurrentSrvIds(data.serviceRoom.services.map((srv) => srv.id));
      setClinicId(data.serviceRoom.clinic_id || "");
    }
  }, [data]);
  if (loading || srvLoading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data || !data.serviceRoom)
    return <Empty description={"No Service Room"} />;

  const handleServiceChange = (ids: string[]) => {
    setCurrentSrvIds(ids);
    setSrvChanged(true);
  };
  const handleServiceSave = async () => {
    let variables: any = {};
    variables.where = { id: roomId };
    variables.data = {
      services: {
        disconnect: data.serviceRoom.services.map((p) => ({ id: p.id })),
        connect: currentSrvIds.map((id) => ({ id })),
      },
    };
    const res = await update({ variables });
    if (res.data?.updateOneServiceRoom) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
    setSrvChanged(false);
  };
  return (
    <Card
      title={`Service Room (${data.serviceRoom.name})`}
      extra={
        <>
          <Link to={paths.serviceRooms}>more services</Link>
        </>
      }
    >
      <Card
        size="small"
        title="Services"
        extra={
          <>
            {srvChanged && (
              <Button
                type="primary"
                loading={saving}
                icon={<SaveOutlined />}
                onClick={handleServiceSave}
              />
            )}
          </>
        }
      >
        <MultiSelect2
          options={(srVData?.services || []).map((pc) => ({
            value: pc.id,
            label: pc.name,
          }))}
          selected={currentSrvIds}
          placeholder="select services"
          onChange={handleServiceChange}
        />
      </Card>
    </Card>
  );
}
export default ServiceRoomDetail;
