import React, { useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Clinic,
  Offer,
  OfferCategory,
  Order,
  Service,
} from "../../store";
import {
  Row,
  Col,
  Typography,
  Empty,
  Button,
  Popconfirm,
  InputNumber,
  Input,
  Switch,
  Avatar,
} from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import ImagUpload from "../../components/image_upload";
import {
  create_one_offer,
  create_one_offerCategory,
  delete_one_offer,
  getDeleteOneOfferVar,
  getOfferCategoriesVar,
  getOffersVar,
  getUpdateOneOfferCategoryVar,
  getUpdateOneOfferVar,
  get_offer_categories,
  get_offers,
  update_one_offer,
  update_one_offer_category,
} from "../../graphql/offer";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ImageGallery from "../../components/image_gallery";

import { Select } from "antd";
import TextareaUpload from "../../components/textarea_upload";
const { TextArea } = Input;
const { Paragraph } = Typography;

interface P {
  currentClinic?: Clinic;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea" | "select" | "img";
  record: Offer;
  options?: OfferCategory[];
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  options,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode: any = <Input />;
  if (inputType === "number") {
    inputNode = <InputNumber />;
  }
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.status === "ACTIVE"} />;
  }
  if (inputType === "select") {
    inputNode = (
      <Select
        defaultValue={(record as any)[dataIndex]}
        style={{ width: 120 }}
        options={(options || []).map((op) => ({
          value: op.id,
          label: op.name,
        }))}
      />
    );
  }
  if (inputType === "textarea") {
    inputNode = (
      <TextareaUpload
        defaultValue={(record as any)[dataIndex]}
        title={` ${record.name}(${title})`}
      />
    );
  }
  if (inputType === "img") {
    inputNode = <ImagUpload defaultValue={(record as any)[dataIndex]} />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required:
                inputType === "select" || inputType === "textarea"
                  ? false
                  : true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
      {/* Modal for editing */}
    </td>
  );
};

const OfferPage = (props: P) => {
  // const [messageApi, contextHolder] = message.useMessage();
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [deleteOfferLoading, setDeleteOfferLoading] = useState(false);

  const isEditing = (record: Offer) => record.id === editingKey;
  const isNew = (r: Offer) => r.id.startsWith("new_");
  const variables = getOffersVar(clinicId);
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"offers", Offer[]>
  >(get_offers, { variables });
  // console.log(data, "get offers data");
  const { data: catData } = useQuery<
    ApolloResult<"offerCategories", OfferCategory[]>
  >(get_offer_categories, { variables });
  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneOffer", Offer>>(update_one_offer);
  const [create, { loading: createLoading }] =
    useMutation<ApolloResult<"createOneOffer", Offer>>(create_one_offer);

  const [deleteOffer, { loading: offerDeleteLoading }] =
    useMutation(delete_one_offer);

  const deleteOfferHandler = async (id: string) => {
    try {
      setDeleteOfferLoading(true);
      const data = await deleteOffer({ variables: getDeleteOneOfferVar(id) });
      await refetch();
      setDeleteOfferLoading(false);
      // console.log(data);
    } catch (err) {
      setDeleteOfferLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (data?.offers) {
      setOffers(data.offers);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (deleteOfferLoading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const edit = (r: OfferCategory) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const add = () => {
    const newData: Offer = {
      id: `new_${new Date().getTime()}`,
      name: "New Offer",
      status: "INACTIVE",
      created_at: new Date(),
      sort_order: 1,
      images: [],
    };
    const newList = [...offers];
    newList.unshift(newData);
    setOffers(newList);
    setEditingKey(newData.id);
    form.resetFields();
  };
  const save = async (r: Offer) => {
    if (r.id.startsWith("new_")) {
      await onCreate(r);
    } else {
      let formData = (await form.validateFields()) as any;
      const fData = { ...formData };
      // console.log('forData', fData)
      let connectors: any | null = null;
      if (fData && fData["category_id"]) {
        const catId = fData["category_id"];
        connectors = { category: { connect: { id: catId } } };
      }
      if (fData.hasOwnProperty("category_id")) {
        delete fData["category_id"];
      }
      const isActive = fData as { status: boolean };
      fData.status = isActive.status ? "ACTIVE" : "INACTIVE";
      const result = await update({
        variables: getUpdateOneOfferVar(r.id, fData, connectors),
      });
      if (result.data?.updateOneOffer) {
        message.success("update success");
        await refetch();
      } else {
        message.error("update failed");
      }
    }
    setEditingKey("");
    form.resetFields();
  };
  const onCreate = async (r: Offer) => {
    let formData = (await form.validateFields()) as any;
    let fData = { ...formData };
    delete fData["id"];
    let connectors: any | null = null;
    if (fData && fData["category_id"]) {
      const catId = fData["category_id"];
      connectors = { category: { connect: { id: catId } } };
    }
    if (fData.hasOwnProperty("category_id")) {
      delete fData["category_id"];
    }
    fData = { ...fData, clinic: { connect: { id: clinicId } } };
    if (connectors) {
      fData = { ...fData, ...connectors };
    }
    const isActive = fData as { status: boolean };
    fData.status = isActive.status ? "ACTIVE" : "INACTIVE";
    const result = await create({
      variables: {
        data: fData,
      },
    });
    if (result.data?.createOneOffer) {
      message.success("create success");
      await refetch();
    } else {
      message.error(`create failed`);
    }
    form.resetFields();
  };
  const onCancel = async (r: Offer) => {
    if (r.id.startsWith("new_")) {
      const newList = [...offers];
      newList.shift();
      setOffers(newList);
    }
    setEditingKey("");
    form.resetFields();
  };
  const onImageUpdate = async (
    offerId: string,
    images: Array<{
      uid: string;
      name: string;
      url: string;
      type: "old" | "new";
    }>,
    removes: string[]
  ) => {
    // console.log("images", images, "\n removes", removes);
    let variables: any = {};
    let connectOrCreate: any[] = images.map((img) => ({
      where: { id: img.uid },
      create: { image: img.url, thumbnail_image: img.url, name: img.name },
    }));
    let disconnect: any[] = removes.map((r) => ({ id: r }));
    variables.where = { id: offerId };
    variables.data = { images: { disconnect, connectOrCreate } };
    // console.log("update var", variables);
    const result = await update({ variables });
    if (result.data?.updateOneOffer) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
  };
  const columns: any[] = [
    {
      title: "Photo",
      dataIndex: "image",
      key: "image",
      editable: true,
      render: (v: number, r: Offer) => {
        return <Avatar src={r.image || null} size={50} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      sorter: (a: OfferCategory, b: OfferCategory) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onFilter: (value: string, record: OfferCategory) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Category",
      dataIndex: "category_id",
      key: "category_id",
      sorter: (a: Offer, b: Offer) =>
        (a.category?.name || "").localeCompare(b.category?.name || ""),
      editable: true,
      render: (v: number, r: Offer) => {
        return r.category?.name || "";
      },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: Service, b: Service) => a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Sort Order",
      dataIndex: "sort_order",
      key: "sort_order",
      editable: true,
      sorter: (a: Service, b: Offer) => a.sort_order - b.sort_order,
    },
    {
      title: "Hight Light",
      dataIndex: "hight_light",
      key: "hight_light",
      editable: true,
      render: (v: number, r: Offer) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}>
            {r.hight_light}
          </Paragraph>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
      render: (v: number, r: Offer) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}>
            {r.description}
          </Paragraph>
        );
      },
    },
    {
      title: "T&C",
      dataIndex: "term_and_condition",
      key: "description",
      editable: true,
      render: (v: number, r: Offer) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}>
            {r.term_and_condition}
          </Paragraph>
        );
      },
    },
    {
      title: "CreatedAt",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: Order, b: Order) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Order, r: Order) => {
        return format(new Date(r.created_at), "dd MMM,yyyy");
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: Offer) => {
        const editable = isEditing(record);
        const n = isNew(record);
        return (
          <ButtonGroup>
            {!n && (
              <ImageGallery
                images={(record.images || []).map((img) => ({
                  uid: img.id,
                  name: img.name,
                  url: img.image,
                  status: "done",
                }))}
                onOk={(images, removes) =>
                  onImageUpdate(record.id, images, removes)
                }
                title={`${record.name}`}
                loading={saveLoading}
                clinicId={clinicId}
              />
            )}
            {editable ? (
              saveLoading || createLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}>
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}

            <Popconfirm
              placement="topRight"
              title={`Sure to delete ${record.name}?`}
              onConfirm={() => deleteOfferHandler(record.id)}
              onCancel={() => onCancel(record)}>
              <Button
                shape="circle"
                icon={<DeleteOutlined />}
                // onClick={() => deleteOfferHandler(record.id)}
              />
            </Popconfirm>
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    const isTextArea = () => {
      let is_t = false;
      if (col.dataIndex === "hight_light") is_t = true;
      if (col.dataIndex === "term_and_condition") is_t = true;
      if (col.dataIndex === "description") is_t = true;
      return is_t;
    };
    return {
      ...col,
      onCell: (record: Offer) => ({
        record,
        options: catData?.offerCategories || [],
        inputType:
          col.dataIndex === "sort_order"
            ? "number"
            : col.dataIndex === "status"
            ? "switch"
            : col.dataIndex === "category_id"
            ? "select"
            : isTextArea()
            ? "textarea"
            : col.dataIndex === "image"
            ? "img"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Offers</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || createLoading || loading}
            showHeader={offers.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={offers.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(OfferPage);
