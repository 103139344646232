import { useEffect, useState } from 'react';
import { getBI } from '../bi-dashboard';
import withClinic from '../hooks/with_clinic';
import BIContainer from './container';
import Unauthorized from './unauthorized';

function CustomerBIDashboard(props: any) {
    const [bi, setBI] = useState(getBI(props?.currentClinic?.code || "", 'customer'));
    useEffect(() => { 
        if (props.currentClinic){
          setBI(getBI(props?.currentClinic?.code || "", 'customer'));
        }
      },[props.currentClinic])
    if (bi) return <BIContainer title='Customer Behaviour' src={bi.link} />
    return <Unauthorized/>
}


export default withClinic(CustomerBIDashboard) as any;