import React, { useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Clinic,
  Exact,
  Role,
  SystemUser,
  SystemUserCreate,
  SystemUserMutate,
} from "../../store";
import { Row, Col, Typography, Empty, Button, Popconfirm, Input } from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  create_one_user,
  getUpdateOneUserVar,
  getUsersVar,
  get_users,
  update_one_user,
} from "../../graphql/user";
import { Select } from "antd";
import { Switch } from "antd";

interface P {
  currentClinic?: Clinic;
}

interface SystemUserForm extends SystemUserMutate {
  key: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "text" | "select" | "password" | "switch";
  record: SystemUserMutate;
  regularExpression?: RegExp;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  regularExpression,
  ...restProps
}) => {
  const inputNode =
    inputType === "select" ? (
      <Select
        defaultValue={Role.ACCOUNT}
        style={{ width: 120 }}
        options={[
          {
            value: Role.ACCOUNT,
            label: Role.ACCOUNT,
          },
          {
            value: Role.ADMIN,
            label: Role.ADMIN,
          },
          {
            value: Role.DOCTOR,
            label: Role.DOCTOR,
          },
          {
            value: Role.EMPLOYEE,
            label: Role.EMPLOYEE,
          },
          {
            value: Role.MANAGER,
            label: Role.MANAGER,
          },
          {
            value: Role.SUPERVISOR,
            label: Role.SUPERVISOR,
          },
        ]}
      />
    ) : inputType === "password" ? (
      <Input.Password />
    ) : inputType === "switch" ? (
      <Switch defaultChecked={record.active} />
    ) : dataIndex === "name" && !record.id.startsWith("new_") ? (
      <Input disabled />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            ...(dataIndex === "name" && record.id.startsWith("new_")
              ? [
                  {
                    pattern: /^[a-z0-9]+$/,
                    message: "Name must be all small letter and number only.",
                  },
                ]
              : []),
            {
              required:
                inputType === "select" || inputType === "switch"
                  ? false
                  : inputType === "password" && !record.id.startsWith("new_")
                  ? false
                  : true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const UserPage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  const clinicCode = props.currentClinic?.code;
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [users, setUsers] = useState<SystemUser[]>([]);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: SystemUserMutate) => record.id === editingKey;
  const variables = getUsersVar(clinicId);
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"users", SystemUser[]>
  >(get_users, { variables });

  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneUser", Pick<SystemUserMutate, "id">>>(
      update_one_user
    );

  const [create, { loading: createLoading }] =
    useMutation<ApolloResult<"createOneUser", Pick<SystemUserMutate, "id">>>(
      create_one_user
    );

  const defaultRole = Role.ACCOUNT;
  const defaultActive = true;

  useEffect(() => {
    if (data?.users) {
      setUsers(data.users);
    }
  }, [data]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;

  const edit = (r: SystemUserMutate) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };

  const add = () => {
    const newData: SystemUserMutate = {
      id: `new_${new Date().getTime()}`,
      name: "",
      display_name: "",
      role: defaultRole,
      password: "",
      active: defaultActive,
    };
    const newList = [...users];
    newList.unshift(newData);
    setUsers(newList);
    setEditingKey(newData.id);
    form.resetFields();
  };

  const save = async (user: SystemUserForm) => {
    if (user.id.startsWith("new_")) {
      try {
        await form.validateFields();
        const formData = form.getFieldsValue() as SystemUserForm;
        formData.role = formData.role ?? defaultRole;
        formData.active = formData.active ?? defaultActive;

        const { key, id, ...toCreateUser } = formData;
        await onCreate(toCreateUser);
      } catch (error) {
        return;
      }
    } else {
      try {
        const formData = (await form.validateFields()) as SystemUserMutate;
        const result = await update({
          variables: getUpdateOneUserVar(user.id, formData),
        });
        if (result.data?.updateOneUser) {
          message.success("update success");
          await refetch();
        } else {
          message.error("update failed");
        }
      } catch (error) {
        console.log("error", error);
        return;
      }
    }
    form.resetFields();
    setEditingKey("");
  };

  const deleteUserHandler = (id: string) => {
    deleteNewUser(id);
  };

  const deleteNewUser = (id: string) => {
    if (id.startsWith("new_")) {
      const newUsers = users.filter((user) => user.id !== id);
      setUsers(newUsers);
    }
  };

  const onCreate = async <T,>(user: Exact<T, SystemUserCreate>) => {
    if (!clinicCode || !clinicId) {
      message.error("Can't create user with no Clinic data.");
      return;
    }
    try {
      user.name = `${user.name}@${clinicCode.toLowerCase()}`;
      const result = await create({
        variables: {
          data: {
            ...user,
            clinic: {
              connect: {
                id: clinicId,
              },
            },
          },
        },
      });
      if (result.data?.createOneUser) {
        message.success("create success");
        await refetch();
      } else {
        message.error("create failed");
      }
    } catch (error: unknown) {
      message.error("create failed");
      throw error;
    }
  };

  const onCancel = async (user: SystemUserMutate) => {
    if (user.id.startsWith("new_")) {
      const newList = [...users];
      newList.shift();
      setUsers(newList);
    }
    setEditingKey("");
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      regularExpression: /^[a-z0-9]+$/,
      sorter: (a: SystemUserMutate, b: SystemUserMutate) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onFilter: (value: string, record: SystemUserMutate) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Password",
      dataIndex: "password",
      key: "password",
      editable: true,
    },
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      sorter: (a: SystemUserMutate, b: SystemUserMutate) =>
        a.display_name
          .toLowerCase()
          .localeCompare(b.display_name.toLowerCase()),
      onFilter: (value: string, record: SystemUserMutate) =>
        record.display_name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      sorter: (a: SystemUserMutate, b: SystemUserMutate) =>
        (a.role || "").localeCompare(b.role || ""),
      editable: true,
      render: (v: number, r: SystemUserMutate) => {
        return r.role || "";
      },
    },
    {
      title: "Acitve",
      dataIndex: "active",
      key: "active",
      sorter: (a: SystemUserMutate, b: SystemUserMutate) =>
        a.active === b.active ? 0 : a.active ? -1 : 1,
      editable: true,
      render: (v: number, r: SystemUserMutate) => {
        return r.active ? "ACTIVE" : "INACTIVE";
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: SystemUserForm) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading || createLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
            {record.id.startsWith("new_") && (
              <Popconfirm
                placement="topRight"
                title={`Sure to delete ${record.name}?`}
                onConfirm={() => deleteUserHandler(record.id)}
              >
                <Button shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>
            )}
          </ButtonGroup>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: SystemUserMutate) => ({
        record,
        inputType:
          col.dataIndex === "role"
            ? "select"
            : col.dataIndex === "password"
            ? "password"
            : col.dataIndex === "active"
            ? "switch"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Users</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || createLoading || loading}
            showHeader={users.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={users.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(UserPage);
