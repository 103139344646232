import React from 'react';
import Icon from '@ant-design/icons';
const HeartSvg = () => (
    <svg width="2em" height="2em" fill="currentColor" viewBox="0 0 1024 1024">
        <path d="M923 283.6c-13.4-31.1-32.6-58.9-56.9-82.8-24.3-23.8-52.5-42.4-84-55.5-32.5-13.5-66.9-20.3-102.4-20.3-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5-24.4 23.9-43.5 51.7-56.9 82.8-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3 0.1-35.3-7-69.6-20.9-101.9z" />
    </svg>
);
const InventorySvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M5 5h2v3h10V5h2v5h2V5c0-1.1-.9-2-2-2h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h6v-2H5V5zm7-2c.55 0 1 .45 1 1s-.45 1-1 1s-1-.45-1-1s.45-1 1-1z" fill="currentColor" /><path d="M21 11.5L15.51 17l-3.01-3l-1.5 1.5l4.51 4.5l6.99-7z" fill="currentColor" /></svg>)
const ReportSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none"><path d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5.586a1 1 0 0 1 .707.293l5.414 5.414a1 1 0 0 1 .293.707V19a2 2 0 0 1-2 2z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" /></g></svg>)
const OrderSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48"><g fill="none" stroke="currentColor" stroke-width="4" stroke-linejoin="round"><rect x="9" y="8" width="30" height="36" rx="2" /><path d="M18 4v6" stroke-linecap="round" /><path d="M30 4v6" stroke-linecap="round" /><path d="M16 19h16" stroke-linecap="round" /><path d="M16 27h12" stroke-linecap="round" /><path d="M16 35h8" stroke-linecap="round" /></g></svg>)
const DashboardSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path d="M24 21h2v5h-2z" fill="currentColor" /><path d="M20 16h2v10h-2z" fill="currentColor" /><path d="M11 26a5.006 5.006 0 0 1-5-5h2a3 3 0 1 0 3-3v-2a5 5 0 0 1 0 10z" fill="currentColor" /><path d="M28 2H4a2.002 2.002 0 0 0-2 2v24a2.002 2.002 0 0 0 2 2h24a2.003 2.003 0 0 0 2-2V4a2.002 2.002 0 0 0-2-2zm0 9H14V4h14zM12 4v7H4V4zM4 28V13h24l.002 15z" fill="currentColor" /></svg>)
const LogoutSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2.5em" height="2.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8c-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 0 1-112.7 75.9A352.8 352.8 0 0 1 512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 0 1-112.7-75.9a353.28 353.28 0 0 1-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8c47.9 0 94.3 9.3 137.9 27.8c42.2 17.8 80.1 43.4 112.7 75.9c7.9 7.9 15.3 16.1 22.4 24.5c3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82C271.7 82.6 79.6 277.1 82 516.4C84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7c3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 0 0 0-12.6z" fill="currentColor" /></svg>)
const ProductSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10zm0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16zm1.334-8a1.5 1.5 0 0 0 0-3H10.5v3h2.834zm0-5a3.5 3.5 0 0 1 0 7H10.5v3h-2V7h4.834z" fill="currentColor" /></svg>)
const ExcelSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><defs><linearGradient id="ssvg-id-file-type-excela" x1="4.494" y1="-2092.086" x2="13.832" y2="-2075.914" gradientTransform="translate(0 2100)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#18884f" /><stop offset=".5" stop-color="#117e43" /><stop offset="1" stop-color="#0b6631" /></linearGradient></defs><path d="M19.581 15.35L8.512 13.4v14.409A1.192 1.192 0 0 0 9.705 29h19.1A1.192 1.192 0 0 0 30 27.809V22.5z" fill="#185c37" /><path d="M19.581 3H9.705a1.192 1.192 0 0 0-1.193 1.191V9.5L19.581 16l5.861 1.95L30 16V9.5z" fill="#21a366" /><path d="M8.512 9.5h11.069V16H8.512z" fill="#107c41" /><path d="M16.434 8.2H8.512v16.25h7.922a1.2 1.2 0 0 0 1.194-1.191V9.391A1.2 1.2 0 0 0 16.434 8.2z" opacity=".1" fill="#000" /><path d="M15.783 8.85H8.512V25.1h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191z" opacity=".2" fill="#000" /><path d="M15.783 8.85H8.512V23.8h7.271a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191z" opacity=".2" fill="#000" /><path d="M15.132 8.85h-6.62V23.8h6.62a1.2 1.2 0 0 0 1.194-1.191V10.041a1.2 1.2 0 0 0-1.194-1.191z" opacity=".2" fill="#000" /><path d="M3.194 8.85h11.938a1.193 1.193 0 0 1 1.194 1.191v11.918a1.193 1.193 0 0 1-1.194 1.191H3.194A1.192 1.192 0 0 1 2 21.959V10.041A1.192 1.192 0 0 1 3.194 8.85z" fill="url(#ssvg-id-file-type-excela)" /><path d="M5.7 19.873l2.511-3.884l-2.3-3.862h1.847L9.013 14.6c.116.234.2.408.238.524h.017c.082-.188.169-.369.26-.546l1.342-2.447h1.7l-2.359 3.84l2.419 3.905h-1.809l-1.45-2.711A2.355 2.355 0 0 1 9.2 16.8h-.024a1.688 1.688 0 0 1-.168.351l-1.493 2.722z" fill="#fff" /><path d="M28.806 3h-9.225v6.5H30V4.191A1.192 1.192 0 0 0 28.806 3z" fill="#33c481" /><path d="M19.581 16H30v6.5H19.581z" fill="#107c41" /></svg>)
const ExportSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6m4 18H6V4h7v5h5v11m-2-9v7.1L13.9 16l-2.8 2.8L8.3 16l2.8-2.8L8.9 11H16z" fill="currentColor" /></svg>);
const MoneySvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path d="M10.6 9c-.4-.1-.8-.3-1.1-.6c-.3-.1-.4-.4-.4-.6c0-.2.1-.5.3-.6c.3-.2.6-.4.9-.3c.6 0 1.1.3 1.4.7l.9-1.2c-.3-.3-.6-.5-.9-.7c-.3-.2-.7-.3-1.1-.3V4H9.4v1.4c-.5.1-1 .4-1.4.8c-.4.5-.7 1.1-.6 1.7c0 .6.2 1.2.6 1.6c.5.5 1.2.8 1.8 1.1c.3.1.7.3 1 .5c.2.2.3.5.3.8c0 .3-.1.6-.3.9c-.3.3-.7.4-1 .4c-.4 0-.9-.1-1.2-.4c-.3-.2-.6-.5-.8-.8l-1 1.1c.3.4.6.7 1 1c.5.3 1.1.6 1.7.6V16h1.1v-1.5c.6-.1 1.1-.4 1.5-.8c.5-.5.8-1.3.8-2c0-.6-.2-1.3-.7-1.7c-.5-.5-1-.8-1.6-1zM10 2c-4.4 0-8 3.6-8 8s3.6 8 8 8s8-3.6 8-8s-3.6-8-8-8zm0 14.9c-3.8 0-6.9-3.1-6.9-6.9S6.2 3.1 10 3.1s6.9 3.1 6.9 6.9s-3.1 6.9-6.9 6.9z" fill="currentColor" /></svg>)
const UpcommingSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path opacity=".3" d="M12 17c-2.04 0-3.81-1.24-4.58-3H4v5h16v-5h-3.42c-.77 1.76-2.54 3-4.58 3z" fill="currentColor" /><path d="M21.16 7.26l-1.41-1.41l-3.56 3.55l1.41 1.41s3.45-3.52 3.56-3.55zM11 3h2v5h-2zm9 9h-5c0 1.66-1.34 3-3 3s-3-1.34-3-3H4c-1.1 0-2 .9-2 2v5c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-5c0-1.1-.9-2-2-2zm0 7H4v-5h3.42c.77 1.76 2.54 3 4.58 3s3.81-1.24 4.58-3H20v5zM6.4 10.81L7.81 9.4L4.26 5.84L2.84 7.26c.11.03 3.56 3.55 3.56 3.55z" fill="currentColor" /></svg>)
const UserSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M12 2a5 5 0 1 0 5 5a5 5 0 0 0-5-5zm0 8a3 3 0 1 1 3-3a3 3 0 0 1-3 3zm9 11v-1a7 7 0 0 0-7-7h-4a7 7 0 0 0-7 7v1h2v-1a5 5 0 0 1 5-5h4a5 5 0 0 1 5 5v1z" fill="currentColor" /></svg>);
const UserGpSVg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><g fill="none"><path d="M13 6a3 3 0 1 1-6 0a3 3 0 0 1 6 0z" fill="currentColor" /><path d="M18 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0z" fill="currentColor" /><path d="M14 15a4 4 0 0 0-8 0v3h8v-3z" fill="currentColor" /><path d="M6 8a2 2 0 1 1-4 0a2 2 0 0 1 4 0z" fill="currentColor" /><path d="M16 18v-3a5.972 5.972 0 0 0-.75-2.906A3.005 3.005 0 0 1 19 15v3h-3z" fill="currentColor" /><path d="M4.75 12.094A5.973 5.973 0 0 0 4 15v3H1v-3a3 3 0 0 1 3.75-2.906z" fill="currentColor" /></g></svg>)
const ExportPdfSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2.5em" height="2.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 100"><path d="M94.284 65.553L75.825 52.411a1.255 1.255 0 0 0-1.312-.093c-.424.218-.684.694-.685 1.173l.009 6.221H57.231c-.706 0-1.391.497-1.391 1.204v11.442c0 .707.685 1.194 1.391 1.194h16.774v6.27c0 .478.184.917.609 1.136s.853.182 1.242-.097l18.432-13.228c.335-.239.477-.626.477-1.038v-.002c0-.414-.144-.8-.481-1.04z" fill="currentColor" /><path d="M64.06 78.553h-6.49a1.73 1.73 0 0 0-1.73 1.73h-.007v3.01H15.191V36.16h17.723a1.73 1.73 0 0 0 1.73-1.73V16.707h21.188v36.356h.011a1.728 1.728 0 0 0 1.726 1.691h6.49c.943 0 1.705-.754 1.726-1.691h.004v-.038v-.001V12.5h-.005V8.48a1.73 1.73 0 0 0-1.73-1.73h-32.87L5.235 32.7v58.819c0 .956.774 1.73 1.73 1.73h57.089a1.73 1.73 0 0 0 1.73-1.73v-2.448h.005v-8.79a1.729 1.729 0 0 0-1.729-1.728z" fill="currentColor" /><path d="M21.525 61.862v9.231h2.795v-2.906h2.131c2.159 0 3.321-1.439 3.321-3.156c0-1.73-1.162-3.169-3.321-3.169h-4.926zm5.411 3.169c0 .484-.374.72-.844.72H24.32v-1.453h1.771c.471 0 .845.235.845.733z" fill="currentColor" /><path d="M31.228 61.862v9.231h4.138c2.893 0 5.052-1.675 5.052-4.623s-2.159-4.608-5.065-4.608h-4.125zm6.352 4.609c0 1.163-.83 2.187-2.228 2.187h-1.329v-4.36h1.342c1.495 0 2.215.927 2.215 2.173z" fill="currentColor" /><path d="M49.116 64.298v-2.436h-7.003v9.231h2.795v-3.446h4.11v-2.436h-4.11v-.913z" fill="currentColor" /></svg>)
const DiscountSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g className="icon-tabler" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M9 15l6-6" /><circle cx="9.5" cy="9.5" r=".5" fill="currentColor" /><circle cx="14.5" cy="14.5" r=".5" fill="currentColor" /><path d="M5 7.2A2.2 2.2 0 0 1 7.2 5h1a2.2 2.2 0 0 0 1.55-.64l.7-.7a2.2 2.2 0 0 1 3.12 0l.7.7a2.2 2.2 0 0 0 1.55.64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7.7a2.2 2.2 0 0 1 0 3.12l-.7.7a2.2 2.2 0 0 0-.64 1.55v1a2.2 2.2 0 0 1-2.2 2.2h-1a2.2 2.2 0 0 0-1.55.64l-.7.7a2.2 2.2 0 0 1-3.12 0l-.7-.7a2.2 2.2 0 0 0-1.55-.64h-1a2.2 2.2 0 0 1-2.2-2.2v-1a2.2 2.2 0 0 0-.64-1.55l-.7-.7a2.2 2.2 0 0 1 0-3.12l.7-.7A2.2 2.2 0 0 0 5 8.2v-1" /></g></svg>);
const RewardSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="12" cy="9" r="6" /><path d="m12.002 15.003l3.4 5.89L17 17.66l3.598.232l-3.4-5.889m-10.396 0l-3.4 5.89L7 17.66l1.598 3.232l3.4-5.889" /></g></svg>);
const SocailSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M6 7q-.825 0-1.412-.588Q4 5.825 4 5t.588-1.413Q5.175 3 6 3t1.412.587Q8 4.175 8 5q0 .825-.588 1.412Q6.825 7 6 7Zm-4 4v-.575q0-.6.325-1.1q.325-.5.9-.75q.65-.275 1.337-.425Q5.25 8 6 8t1.438.15q.687.15 1.337.425q.575.25.9.75q.325.5.325 1.1V11Zm16-4q-.825 0-1.413-.588Q16 5.825 16 5t.587-1.413Q17.175 3 18 3q.825 0 1.413.587Q20 4.175 20 5q0 .825-.587 1.412Q18.825 7 18 7Zm-4 4v-.575q0-.6.325-1.1q.325-.5.9-.75q.65-.275 1.337-.425Q17.25 8 18 8t1.438.15q.687.15 1.337.425q.575.25.9.75q.325.5.325 1.1V11ZM6 21l-4-4l4-4l1.4 1.4L5.8 16h12.4l-1.6-1.6L18 13l4 4l-4 4l-1.4-1.4l1.6-1.6H5.8l1.6 1.6Z" /></svg>);
const DedliverySvg = () => (<svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><circle cx="7" cy="17" r="2" /><circle cx="17" cy="17" r="2" /><path d="M5 17H3v-4M2 5h11v12m-4 0h6m4 0h2v-6h-8m0-5h5l3 5M3 9h4" /></g></svg>)
const EyeSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" width="2.5em" height="2.5em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12 6.5a9.77 9.77 0 0 1 8.82 5.5c-1.65 3.37-5.02 5.5-8.82 5.5S4.83 15.37 3.18 12A9.77 9.77 0 0 1 12 6.5m0-2C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 5a2.5 2.5 0 0 1 0 5a2.5 2.5 0 0 1 0-5m0-2c-2.48 0-4.5 2.02-4.5 4.5s2.02 4.5 4.5 4.5s4.5-2.02 4.5-4.5s-2.02-4.5-4.5-4.5z" /></svg>)
const LocationSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M12 12q.825 0 1.413-.588Q14 10.825 14 10t-.587-1.413Q12.825 8 12 8q-.825 0-1.412.587Q10 9.175 10 10q0 .825.588 1.412Q11.175 12 12 12Zm0 9.625q-.2 0-.4-.075t-.35-.2Q7.6 18.125 5.8 15.363Q4 12.6 4 10.2q0-3.75 2.413-5.975Q8.825 2 12 2t5.587 2.225Q20 6.45 20 10.2q0 2.4-1.8 5.163q-1.8 2.762-5.45 5.987q-.15.125-.35.2q-.2.075-.4.075Z" /></svg>)
const DelvierySerachSvg = () => (<svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M3 9q-.425 0-.712-.288Q2 8.425 2 8t.288-.713Q2.575 7 3 7h3q.425 0 .713.287Q7 7.575 7 8t-.287.712Q6.425 9 6 9Zm0 5q-.425 0-.712-.288Q2 13.425 2 13t.288-.713Q2.575 12 3 12h3q.425 0 .713.287Q7 12.575 7 13t-.287.712Q6.425 14 6 14Zm16.9 4.3l-3.15-3.15q-.6.425-1.312.637Q14.725 16 14 16q-2.075 0-3.537-1.463Q9 13.075 9 11t1.463-3.538Q11.925 6 14 6t3.538 1.462Q19 8.925 19 11q0 .725-.212 1.438q-.213.712-.638 1.312l3.15 3.15q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275ZM14 14q1.25 0 2.125-.875T17 11q0-1.25-.875-2.125T14 8q-1.25 0-2.125.875T11 11q0 1.25.875 2.125T14 14ZM3 19q-.425 0-.712-.288Q2 18.425 2 18t.288-.712Q2.575 17 3 17h8q.425 0 .713.288q.287.287.287.712t-.287.712Q11.425 19 11 19Z" /></svg>)
export const HeartIcon = (props: any) => <Icon component={HeartSvg} {...props} />;
export const InventoryIcon = (props: any) => <Icon component={InventorySvg} {...props} />;
export const ReportIcon = (props: any) => <Icon component={ReportSvg} {...props} />;
export const OrderIcon = (props: any) => <Icon component={OrderSvg} {...props} />;
export const DashboradIcon = (props: any) => <Icon component={DashboardSvg} {...props} />;
export const LogoutIcon = (props: any) => <Icon component={LogoutSvg} {...props} />;
export const ProductIcon = (props: any) => <Icon component={ProductSvg} {...props} />;
export const ExcelIcon = (props: any) => <Icon component={ExcelSvg} {...props} />;
export const ExportIcon = (props: any) => <Icon component={ExportSvg} {...props} />;
export const MoneyIcon = (props: any) => <Icon component={MoneySvg} {...props} />;
export const UpcommingIcon = (props: any) => <Icon component={UpcommingSvg} {...props} />;
export const UserIcon = (props: any) => <Icon component={UserSvg} {...props} />;
export const UserGroupIcon = (props: any) => <Icon component={UserGpSVg} {...props} />;
export const PdfExportIcon = (props: any) => <Icon component={ExportPdfSvg} {...props} />;
export const DelvieryIcon = (props: any) => <Icon component={DedliverySvg} {...props} />;
export const DiscountIcon = (props: any) => <Icon component={DiscountSvg} {...props} />;
export const RewardIcon = (props: any) => <Icon component={RewardSvg} {...props} />;
export const SocialIcon = (props: any) => <Icon component={SocailSvg} {...props} />;
export const EyeIcon = (props: any) => <Icon component={EyeSvg} {...props} />;
export const LocationIcon = (props: any) => <Icon component={LocationSvg} {...props} />;
export const DeliverySearchIcon = (props: any) => <Icon component={DelvierySerachSvg} {...props} />;
