import React from "react";
import { Card } from "antd";
import { Order } from "../store";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { format } from "date-fns";
import * as _ from "lodash";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.defaults.scale.grid.display = false;
ChartJS.defaults.scale.ticks.display = true;
const chartData = (data: Order[]) => {
  let _labels: string[] = [],
    _data: string[] = [],
    days: any[] = [];
  data = data.map((or) => {
    const ym = format(new Date(or.created_at), "yyyy-MMM");
    const sec = new Date(or.created_at).getTime();
    return {
      ...or,
      filter: ym,
      filterValue: sec,
    } as any;
  });
  // console.log(data,"data brrr");

  const ymGroups = _.groupBy(data, "filter");
  Object.keys(ymGroups).forEach((day) => {
    const date = day;
    let totalSales = 0;
    let sec: any | null = null;
    ymGroups[day].forEach((or) => {
      totalSales += Number((or as Order).total);

      if (!sec) {
        const { filterValue } = or as any as { filterValue: number };
        sec = filterValue;
      }
    });
    days.push({ date, totalSales, sec });
  });
  _.orderBy(days, "sec", "asc").forEach((f) => {
    _labels.push(f.date);
    _data.push(f.totalSales);
  });
  return { _labels, _data };
};
export default function MemberBarChart(props: { data: Order[] }) {
  const cData = chartData(props.data);
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
        display: false,
      },
      title: {
        display: true,
        text: `Monthly sales`,
      },
    },
  };
  const data = {
    labels: cData._labels,
    datasets: [
      {
        label: "MMK",
        data: cData._data,
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return (
    <Card size="small">
      <Bar options={options} data={data} />
    </Card>
  );
}
