import React, { useEffect, useState } from "react";
import withClinic from "../hooks/with_clinic";
import { ApolloResult, Clinic, GTNotification, Order } from "../store";
import {
  Row,
  Col,
  Typography,
  Empty,
  Button,
  Popconfirm,
  InputNumber,
  Input,
  Switch,
  Avatar,
} from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import ImagUpload from "../components/image_upload";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import {
  create_one_notification,
  getNotificationVar,
  getUpdateOneNotificationVar,
  get_notifications,
  update_one_notification,
} from "../graphql/notification";
import { sendPassNotice, PSNoitification } from "../api/pass_api";
import Cookies from "js-cookie";
import TextareaUpload from "../components/textarea_upload";
const { TextArea } = Input;
const { Paragraph } = Typography;

interface P {
  currentClinic?: Clinic;
}
const notificationTypes = [
  { label: "Promotion", value: "PROMO" },
  { label: "Information", value: "INFO" },
];
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea" | "select" | "img";
  record: GTNotification;
  options?: any[];
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  options,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode: any = <Input />;
  if (inputType === "number") {
    inputNode = <InputNumber />;
  }
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.status === "ACTIVE"} />;
  }
  if (inputType === "select") {
    inputNode = (
      <Select
        defaultValue={(record as any)[dataIndex]}
        style={{ width: 120 }}
        options={options || []}
      />
    );
  }
  if (inputType === "textarea") {
    inputNode = (
      <TextareaUpload
        defaultValue={(record as any)[dataIndex]}
        title={`${record.title}`}
      />
    );
  }
  if (inputType === "img") {
    inputNode = <ImagUpload defaultValue={(record as any)[dataIndex]} />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required:
                inputType === "select" || inputType === "textarea"
                  ? false
                  : true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const NotifiactionPage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [notifications, setNotifications] = useState<GTNotification[]>([]);
  const [notiLoading, setNotiLoading] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [sendKey, setSendKey] = useState("");
  const isEditing = (record: GTNotification) => record.id === editingKey;
  const isSending = (r: GTNotification) => r.id === sendKey;
  const isNew = (r: GTNotification) => r.id.startsWith("new_");
  const variables = getNotificationVar({
    clinicId,
    searchText: searchText || null,
  });
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"notifications", GTNotification[]>
  >(get_notifications, { variables });
  const [update, { loading: saveLoading }] = useMutation<
    ApolloResult<"updateOneNotification", GTNotification>
  >(update_one_notification);
  const [create, { loading: createLoading }] = useMutation<
    ApolloResult<"createOneNotification", GTNotification>
  >(create_one_notification);
  useEffect(() => {
    if (data?.notifications) {
      setNotifications(data.notifications);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const edit = (r: GTNotification) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const passConfig = () => {
    let config: {
      refresh_token_url?: string;
      refresh_token?: string;
      id?: string;
    } | null = null;
    if (props.currentClinic && props.currentClinic.pass) {
      const obj = JSON.parse(props.currentClinic.pass) as {
        refresh_token_url?: string;
        refresh_token?: string;
        id?: string;
      };
      if (obj.refresh_token && obj.refresh_token_url) {
        config = obj;
      }
    }
    return config;
  };
  const sendNotice = async (notice: PSNoitification) => {
    let r: boolean = false;
    const pass = passConfig();
    if (pass && pass.refresh_token_url && pass.refresh_token) {
      setNotiLoading(true);
      const result = await sendPassNotice(
        [notice],
        pass.refresh_token,
        pass.refresh_token_url
      );
      if (result.status === "success") {
        r = true;
      }
      setNotiLoading(false);
    }
    return r;
  };
  const add = () => {
    const newData: GTNotification = {
      id: `new_${new Date().getTime()}`,
      title: "New Notifiaction",
      status: "INACTIVE",
      created_at: new Date(),
      type: "INFO",
    };
    const newList = [...notifications];
    newList.unshift(newData);
    setNotifications(newList);
    setEditingKey(newData.id);
  };
  const save = async (r: GTNotification) => {
    if (r.id.startsWith("new_")) {
      await onCreate(r);
    } else {
      let formData = (await form.validateFields()) as any;
      const fData = { ...formData };
      const isActive = fData as { status: boolean };
      fData.status = isActive.status ? "ACTIVE" : "INACTIVE";
      const result = await update({
        variables: getUpdateOneNotificationVar(r.id, fData),
      });
      if (result.data?.updateOneNotification) {
        message.success("update success");
        // if (result.data.updateOneNotification.status === 'ACTIVE') {
        //     const n: PSNoitification = {
        //         topic: `noti_${passConfig()?.id || props.currentClinic?.code}`.toLocaleLowerCase(),
        //         title: result.data.updateOneNotification.title,
        //         body: result.data.updateOneNotification.hight_light || ""
        //     };
        //     await sendNotice(n);
        // }
        await refetch();
      } else {
        message.error("update failed");
      }
    }
    setEditingKey("");
    form.resetFields();
  };
  const onCreate = async (r: GTNotification) => {
    let formData = (await form.validateFields()) as any;
    let fData = { ...formData };
    delete fData["id"];
    const isActive = fData as { status: boolean };
    fData = { ...fData, clinic: { connect: { id: clinicId } } };
    fData.status = isActive.status ? "ACTIVE" : "INACTIVE";
    const result = await create({
      variables: {
        data: fData,
      },
    });
    if (result.data?.createOneNotification) {
      message.success("create success");
      // if (result.data.createOneNotification.status === 'ACTIVE') {
      //     const n: PSNoitification = {
      //         topic: `noti_${passConfig()?.id || props.currentClinic?.code}`.toLocaleLowerCase(),
      //         title: result.data.createOneNotification.title,
      //         body: result.data.createOneNotification.hight_light || ""
      //     };
      //     await sendNotice(n);
      // }
      await refetch();
    } else {
      message.error(`create failed`);
    }
  };
  const handleNotiSend = async (r: GTNotification) => {
    const n: PSNoitification = {
      topic: `noti_${
        passConfig()?.id || props.currentClinic?.code
      }`.toLocaleLowerCase(),
      title: r.title,
      body: r.hight_light,
    };
    setSendKey(r.id);
    const success = await sendNotice(n);
    if (success) {
      message.success("send success");
    } else {
      message.error(`send failed`);
    }
    setSendKey("");
  };
  const onCancel = async (r: GTNotification) => {
    if (r.id.startsWith("new_")) {
      const newList = [...notifications];
      newList.shift();
      setNotifications(newList);
    }
    form.resetFields();
    setEditingKey("");
  };
  const columns: any[] = [
    {
      title: "Photo",
      dataIndex: "image",
      key: "image",
      editable: true,
      render: (v: number, r: GTNotification) => {
        return <Avatar src={r.image || null} size={50} />;
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      editable: true,
      // filteredValue: searchText ? [searchText] : null,
      sorter: (a: GTNotification, b: GTNotification) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase()),
      // onFilter: (value: string, record: OfferCategory) => (record.name).toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: GTNotification, b: GTNotification) =>
        a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a: GTNotification, b: GTNotification) =>
        a.type.localeCompare(b.type),
      editable: true,
      render: (v: number, r: GTNotification) => {
        return notificationTypes.find((nt) => nt.value === r.type)?.label;
      },
    },
    {
      title: "Hight Light",
      dataIndex: "hight_light",
      key: "hight_light",
      editable: true,
      render: (v: number, r: GTNotification) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}
          >
            {r.hight_light}
          </Paragraph>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
      render: (v: number, r: GTNotification) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}
          >
            {r.description}
          </Paragraph>
        );
      },
    },
    {
      title: "CreatedAt",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: GTNotification, b: GTNotification) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: GTNotification, r: GTNotification) => {
        return format(new Date(r.created_at), "dd MMM,yyyy");
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: GTNotification) => {
        const editable = isEditing(record);
        const n = isNew(record);
        const active = record.status === "ACTIVE";
        const sending = isSending(record);
        return (
          <ButtonGroup>
            {!n && active && !editable && (
              <Popconfirm
                placement="topRight"
                title={`Sure to send ${record.title}?`}
                onConfirm={() => handleNotiSend(record)}
                // onCancel={() => onCancel(record)}
              >
                <Button
                  icon={<SendOutlined />}
                  loading={sending && notiLoading}
                />
              </Popconfirm>
            )}
            {editable ? (
              saveLoading || createLoading || notiLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.title}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    const isTextArea = () => {
      let is_t = false;
      if (col.dataIndex === "hight_light") is_t = true;
      if (col.dataIndex === "description") is_t = true;
      return is_t;
    };
    return {
      ...col,
      onCell: (record: GTNotification) => ({
        record,
        options: notificationTypes,
        inputType:
          col.dataIndex === "status"
            ? "switch"
            : col.dataIndex === "type"
            ? "select"
            : isTextArea()
            ? "textarea"
            : col.dataIndex === "image"
            ? "img"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Notifications</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || createLoading || loading}
            showHeader={notifications.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={notifications.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  // console.log('accessToken', Cookies.get('p_token'))
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(NotifiactionPage);
