import { gql } from "@apollo/client";

export const get_practitioners = gql`
  query Practitioners(
    $where: PractitionerWhereInput
    $take: Int
    $orderBy: [PractitionerOrderByWithRelationInput!]
  ) {
    practitioners(where: $where, take: $take, orderBy: $orderBy) {
      name
      id
      status
      sex
      image
      created_at
      clinic_id
      username
      is_app_user
      _count {
        bookings
        checkins
      }
    }
  }
`;
export const getPractitionersVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[] | string;
  searchText?: string | null;
  take: number;
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({ name: { contains: searchText } });
    OR.push({ phonenumber: { contains: searchText } });
  }
  where.clinic_id = { equals: data.clinicId };
  // where.created_at = { gte: data.startDate, lte: data.endDate };
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = 100;
  return variables;
};

export const get_practitioner_checkin_count = gql`
  query GroupByCheckIn(
    $by: [CheckInScalarFieldEnum!]!
    $where: CheckInWhereInput
  ) {
    groupByCheckIn(by: $by, where: $where) {
      _count {
        _all
      }
      practitioner_id
      clinic_id
      status
    }
  }
`;
export const getPractitionerCheckInCountVar = (data: {
  clinicId: string;
  practitionerIds: string[];
  startDate: Date;
  endDate: Date;
  status?: string | string[];
}) => {
  let variables: any = {};
  variables.by = ["practitioner_id", "clinic_id", "status"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    practitioner_id: { in: data.practitionerIds },
    status: { in: data.status },
  };
  return variables;
};
export const get_practitioner_checkin = gql`
  query CheckIns(
    $where: CheckInWhereInput
    $orderBy: [CheckInOrderByWithRelationInput!]
  ) {
    checkIns(where: $where, orderBy: $orderBy) {
      id
      in_time
      out_time
      clinic_id
      practitioner_id
      status
      service_id
      service {
        name
        id
      }
      member_id
      member {
        name
        id
      }
    }
  }
`;
export const getPractitionerCheckInVar = (data: {
  clinicId: string;
  practitionerIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    practitioner_id: { in: data.practitionerIds },
  };
  variables.orderBy = [{ in_time: "desc" }];
  return variables;
};
export const get_practitioner_booking_count = gql`
  query _count($by: [BookingScalarFieldEnum!]!, $where: BookingWhereInput) {
    groupByBooking(by: $by, where: $where) {
      _count {
        _all
      }
      practitioner_id
      clinic_id
    }
  }
`;
export const getPractitionerBookingCountVar = (data: {
  clinicId: string;
  practitionerIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.by = ["practitioner_id", "clinic_id"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    from_time: { gte: data.startDate, lte: data.endDate },
    practitioner_id: { in: data.practitionerIds },
  };
  return variables;
};
export const get_practitioner_bookings = gql`
  query Bookings(
    $where: BookingWhereInput
    $orderBy: [BookingOrderByWithRelationInput!]
  ) {
    bookings(where: $where, orderBy: $orderBy) {
      id
      member_id
      member {
        id
        name
      }
      member_note
      practitioner_id
      service_id
      service {
        id
        name
      }
      from_time
      status
    }
  }
`;
export const getPractitionerBookingVar = (data: {
  clinicId: string;
  practitionerIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.where = {
    clinic_id: { equals: data.clinicId },
    from_time: { gte: data.startDate, lte: data.endDate },
    practitioner_id: { in: data.practitionerIds },
  };
  variables.orderBy = [{ from_time: "desc" }];
  return variables;
};

export const get_practitioner = gql`
  query Practitioner(
    $where: PractitionerWhereUniqueInput!
    $servicesWhere2: ServiceWhereInput
  ) {
    practitioner(where: $where) {
      image
      name
      id
      status
      sex
      phonenumber
      _count {
        bookings
        checkins
      }
      leaves {
        from_time
        to_time
        practitioner_id
        status
        remark
      }
      metadata
      services(where: $servicesWhere2) {
        id
        name
        image
        duration
      }
    }
  }
`;
export const getPractitionerVar = (data: {
  PractitionerId: string;
  clinicId: string;
}) => ({
  where: {
    id: data.PractitionerId,
  },
  servicesWhere2: {
    clinic_id: {
      equals: data.clinicId,
    },
    status: {
      equals: "ACTIVE",
    },
    practitioners: {
      some: {
        id: {
          equals: data.PractitionerId,
        },
      },
    },
  },
});

export const update_one_practitioner = gql`
  mutation UpdateOnePractitioner(
    $data: PractitionerUpdateInput!
    $where: PractitionerWhereUniqueInput!
  ) {
    updateOnePractitioner(data: $data, where: $where) {
      id
    }
  }
`;

export const getConnectServiceWitPractitionerVar = (
  practitionerId: string,
  services: Array<{ id: string }>
) => ({
  variables: {
    where: {
      id: practitionerId,
    },
    data: {
      services: {
        connect: services,
      },
    },
  },
});

export const getDisconnectServiceWitPractitionerVar = (
  practitionerId: string,
  serviceId: string
) => ({
  variables: {
    where: {
      id: practitionerId,
    },
    data: {
      services: {
        disconnect: [{ id: serviceId }],
      },
    },
  },
});

export const getUpdateOnePractionerVar = (
  id: string,
  clinicCode: string,
  data: any
) => {
  let variables: any = {},
    update: any = {};
  variables.where = { id };

  const { password: _, ...modData } = data;
  if (data.password == null) {
    data = modData;
  }

  if (data.username != null && data.username.trim() !== "") {
    data.username = `${data.username.trim()}@${clinicCode.toLowerCase()}`;
  }

  if (typeof data === "object") {
    update = Object.assign(
      {},
      ...Object.keys(data).map((k, i) => ({ [k]: { set: data[k] } }))
    );
  }
  variables.data = update;
  return variables;
};
