// import React from "react";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from "chart.js";
// import { Bar } from "react-chartjs-2";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend
// );
// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false,
//       position: "top" as const,
//     },
//     title: {
//       display: true,
//       text: "Top Treatment",
//     },
//   },
//   scales: {
//     y: {
//       display: false
//     }
//   }
// };
// const getDataSource = (labels: any[], data: any[]) => ({
//   labels,
//   datasets: [
//     {

//       label: "checkins",
//       data,
//       backgroundColor: "rgba(53, 162, 235, 0.5)",
//     }
//   ]
// })
// interface P {
//   title: string;
//   labels: any[],
//   data: any[];
// }
// const TopTreatmentChart = (props: P) => {
//   return <Bar options={options} data={getDataSource(props.labels, props.data)} />;
// };

// export default TopTreatmentChart;

import React, { useCallback, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import withClinic from "../../../hooks/with_clinic";
import { ApolloResult, Clinic, Service } from "../../../store";
import {
  getTopTreatmentCheckInCountVar,
  getTopTreatmentVar,
  get_top_treatment,
  get_top_treatment_checkin_count,
} from "../../../graphql/reports/top_treatment";
import { useLazyQuery, useQuery } from "@apollo/client";
interface P {
  currentClinic?: Clinic;
  ranges: any;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Top Treatment",
    },
  },
  scales: {
    y: {
      display: false,
    },
  },
};

const getDataSource = (labels: any[], data: any[]) => ({
  labels,
  datasets: [
    {
      label: "checkins",
      data,
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
});
const TopTreatmentChart = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  const { ranges } = props;
  const variables = getTopTreatmentVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    take: 150,
    status: "ACTIVE",
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"services", Service[]>
  >(get_top_treatment, { variables });

  const [getCheckInCount, { data: checkInData, loading: cload }] = useLazyQuery<
    ApolloResult<
      "groupByCheckIn",
      Array<{ _count: { _all: number }; service_id: string }>
    >
  >(get_top_treatment_checkin_count);
  const checking = cload;

  const checkInCount = Object.assign(
    {},
    ...(checkInData?.groupByCheckIn || []).map((a) => ({
      [a.service_id]: a._count._all,
    }))
  );
  // const result = data?.services?.map((p) => ({
  //   ...p,
  //   checkin: checkInCount[p.id],
  // }));

  const load = useCallback(
    (v: { serviceIds: string[]; startDate: Date; endDate: Date }) => {
      getCheckInCount({
        variables: getTopTreatmentCheckInCountVar({
          ...v,
          clinicId,
          status: "CHECKOUT",
        }),
      });
    },
    [clinicId, getCheckInCount]
  );

  useEffect(() => {
    if (data?.services && data.services.length > 0) {
      const serviceIds = data.services.map((p) => p.id);
      const { startDate, endDate } = ranges;
      load({ serviceIds, startDate, endDate });
    }
  }, [data, ranges, load]);
  // const result = data?.services?.map((item) => ({
  //   ...item,
  //   blar: checkInCount[item?.id],
  // }));
  const result = data?.services?.map((item) => ({
    ...item,
    checkin: checkInCount[item?.id],
  }));
  const filteredData = result?.filter((item) => item.checkin !== undefined);
  const filteredName: any = filteredData?.map((d) => d?.name);
  const filterCount: any = filteredData?.map((d) => d?.checkin);

  return (
    <Bar options={options} data={getDataSource(filteredName, filterCount)} />
  );
};

export default withClinic(TopTreatmentChart);
