import React, { useEffect, useState } from "react";
import Icon from "@ant-design/icons";
import {
  Layout,
  Col,
  Row,
  Menu,
  Dropdown,
  Avatar,
  Divider,
  Typography,
} from "antd";
import {
  MenuOutlined,
  LogoutOutlined,
  LeftCircleFilled,
  RightCircleFilled,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { actions } from "../store/actions";
import { AppState, AuthState, Clinic, User } from "../store";
import { Link } from "react-router-dom";
import paths from "../routes/paths";
const { antdAction } = actions;
interface P {
  authState: AuthState;
  siderControl: () => void;
  logout: () => void;
  opened: boolean;
  currentClinic?: Clinic;
  clinics: Clinic[];
  setSelectedClinic: (v: Clinic) => void;
}
type S = { status: "loading" } | { status: "loaded"; data: User };
function TopBar(props: P) {
  const handleClinicChange = (cId: string) => {
    const s = props.clinics.find((c) => c.id === cId);
    if (s) props.setSelectedClinic(s);
  };



  const menu =
    props.clinics?.length > 1 ? (
      <Menu
        className="menu"
        selectedKeys={props.currentClinic ? [props.currentClinic.id] : []}
        onClick={({ key: cId }: { key: string }) => handleClinicChange(cId)}>
        {props.clinics.map((c) => (
          <Menu.Item key={c.id} icon={<Avatar src={c.logo} />}>
            {c.name}({c.code})
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <div />
    );
  const profile = props.currentClinic?.logo || "/logo.png";
  return (
    <Layout className="top-bar">
      <Row justify="end">
        <Col flex={1}>
          {props.opened ? (
            <RightCircleFilled
              style={styles.toogle}
              onClick={() => {
                props.siderControl();
              }}
            />
          ) : (
            <LeftCircleFilled
              style={styles.toogle}
              onClick={() => {
                props.siderControl();
              }}
            />
          )}
        </Col>
        <Col offset={3} flex={2}>
          <Typography.Title level={5} italic>
            {props.currentClinic?.company.name}
          </Typography.Title>
        </Col>
        <Col>
          {props.currentClinic?.name && (
            <Link to={paths.clinic}> {props.currentClinic?.name || ""}</Link>
          )}
          <Dropdown overlay={menu}>
            <span className="action account" style={{ color: "black" }}>
              <Divider type="vertical" />
              <Avatar
                className="avator"
                size="small"
                icon="user"
                src={profile}
              />
            </span>
          </Dropdown>
        </Col>
      </Row>
    </Layout>
  );
}
const mapStateToProps = ({ authState, antdState, clinicState }: AppState) => {
  return {
    authState,
    opened: antdState.openSideToogle,
    currentClinic: clinicState.selected,
    clinics: clinicState.clinics,
  };
};
const mapStateToDispatch = (dispatch: any) => {
  return {
    siderControl: () => {
      dispatch(antdAction.sideOnOff());
    },
    setSelectedClinic: (d: Clinic) =>
      dispatch(actions.clinicAction.setSelected(d)),
    logout: () => dispatch(actions.authAction.loggedOut()),
  };
};
export default connect(mapStateToProps, mapStateToDispatch)(TopBar) as any;
const styles = {
  toogle: {
    color: "blue",
    fontSize: 23,
    marginLeft: -32,
    marginTop: 20,
    boxShadow: "0px 0px 100px 100px rgba(0,0,0,0.1);",
  },
};
