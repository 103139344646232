import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  ApolloResult,
  Booking,
  CheckIn as InitialCheckIn,
  Clinic,
  Member,
  Practitioner,
  Service,
  ServiceHelper,
} from "../../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { useLazyQuery, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import withClinic from "../../hooks/with_clinic";
import { ExportExcel } from "../../helpers/excel";
import client from "../../graphql/apollo_client";
import { Button } from "antd";
import { FileExcelFilled } from "@ant-design/icons";
import {
  export_treatments,
  getExportTreatmentVar,
  getTreatmentCountVar,
  getTreatmentReportVar,
  get_treatment_count,
  get_treatment_report,
} from "../../graphql/reports/treatment_report";
import { useLocation, useParams } from "react-router-dom";
import paths from "../../routes/paths";

interface P {
  currentClinic?: Clinic;
}

interface CheckIn extends Pick<InitialCheckIn, "id" | "status" | "in_time"> {
  member: Pick<Member, "name">;
  practitioner: Pick<Practitioner, "id" | "name">;
  service: Pick<Service, "name">;
  booking?: Pick<Booking, "from_time" | "to_time"> & {
    service_helper?: Pick<ServiceHelper, "id" | "name">;
  };
}

const TreatmentReport = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [exporting, setExporting] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });
  const [page, setPage] = useState({ current: 1, size: 50 });
  const [practitionerName, setPractitionerName] = useState<
    string | undefined
  >();
  const [form] = Form.useForm();
  const location = useLocation();
  const { id: practitionerId } = useParams();

  const isAllTreatmentReport = useMemo(
    () => location.pathname === paths.treatment_report,
    [location]
  );

  const variables = getTreatmentReportVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take: page.size,
    status: "CHECKOUT",
    skip: (page.current - 1) * page.size,
    practitionerId: !isAllTreatmentReport ? practitionerId : undefined,
  });

  const { loading, data, error } = useQuery<
    ApolloResult<"checkIns", CheckIn[]>
  >(get_treatment_report, { variables });

  const [getCheckInCount, { data: checkInData, loading: cload }] =
    useLazyQuery<
      ApolloResult<"groupByCheckIn", Array<{ _count: { _all: number } }>>
    >(get_treatment_count);
  const checking = cload;
  const load = useCallback(
    (v: { startDate: Date; endDate: Date }) => {
      getCheckInCount({
        variables: getTreatmentCountVar({
          ...v,
          clinicId,
          status: "CHECKOUT",
          practitionerId: !isAllTreatmentReport ? practitionerId : undefined,
        }),
      });
    },
    [getCheckInCount, clinicId]
  );

  useEffect(() => {
    if (
      !isAllTreatmentReport &&
      location.state?.ranges?.startDate &&
      location.state?.ranges?.endDate
    ) {
      setRanges(location.state.ranges);
    }
  }, []);

  useEffect(() => {
    if (data?.checkIns && data.checkIns.length > 0) {
      if (!isAllTreatmentReport)
        setPractitionerName(data.checkIns[0].practitioner.name);
      const { startDate, endDate } = ranges;
      load({ startDate, endDate });
    }
  }, [data, ranges, load]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };
  const onChange = (current: number, size: number) => {
    // console.log(current, "current", size, "size");

    setPage({ current, size });
  };
  const handleExport = async () => {
    const fileName =
      "treatment_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];

    let list: CheckIn[] = [];
    let rolling = true;
    const take = 100;
    let skip = 0;
    setExporting(true);
    while (rolling) {
      const variables = getExportTreatmentVar({
        clinicId,
        take,
        skip,
        status: "CHECKOUT",
        startDate: ranges.startDate,
        endDate: ranges.endDate,
        practitionerId: !isAllTreatmentReport ? practitionerId : undefined,
      });
      const result = await client.query<ApolloResult<"checkIns", CheckIn[]>>({
        query: export_treatments,
        variables,
      });
      const _treatments = result.data.checkIns || [];
      list = list.concat(_treatments);
      skip += _treatments.length;
      rolling = _treatments.length === take;
    }
    for (const t of list) {
      const date = format(new Date(t.in_time), "yyyy-MMM-dd");
      const from_time = t.booking?.from_time
        ? format(new Date(t.booking?.from_time), "h:mm a")
        : "";
      const to_time = t.booking?.to_time
        ? format(new Date(t.booking?.to_time), "h:mm a")
        : "";
      const Treatment = t.service.name;
      const Customer = t.member.name;
      const Practitioner = t.practitioner.name;
      const Helper = t?.booking?.service_helper?.name;
      const Status = t.status;
      excelData.push({
        date,
        from_time: from_time,
        to_time: to_time,
        Treatment,
        Customer,
        Practitioner,
        Helper,
        Status,
      });
    }
    excelData.sort((a, b) => {
      const dateA: any = new Date(a.from_time);
      const dateB: any = new Date(b.from_time);
      return dateA - dateB;
    });
    ExportExcel(excelData, fileName);
    setExporting(false);
  };

  console.log("data", data);

  const columns: any[] = [
    {
      title: "CheckIn Date",
      dataIndex: "in_time",
      key: "in_time",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        new Date(a.in_time).getTime() - new Date(b.in_time).getTime(),
      sortOrder: "descend",
      render: (v: number, r: CheckIn) => {
        return format(new Date(r.in_time), "dd MMM,yyyy");
      },
    },
    {
      title: "From_Time",
      dataIndex: "from_time",
      key: "from_time",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.booking?.from_time && b.booking?.from_time
          ? new Date(a.booking.from_time).getTime() -
            new Date(b.booking.from_time).getTime()
          : 0,
      render: (v: number, r: CheckIn) => {
        return r.booking?.from_time
          ? format(new Date(r?.booking?.from_time), "h:mm a")
          : "";
      },
    },
    {
      title: "To_Time",
      dataIndex: "to_time",
      key: "to_time",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.booking?.to_time && b.booking?.to_time
          ? new Date(a.booking?.to_time).getTime() -
            new Date(b.booking?.to_time).getTime()
          : 0,
      render: (v: number, r: CheckIn) => {
        return r.booking?.to_time
          ? format(new Date(r.booking.to_time), "h:mm a")
          : "";
      },
    },
    {
      title: "Treatment",
      dataIndex: "service",
      key: "service",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.service.name
          .toLowerCase()
          .localeCompare(b.service.name.toLowerCase()),
      render: (v: number, r: CheckIn) => {
        return r.service.name;
      },
    },

    {
      title: "Customer",
      dataIndex: "member",
      key: "member",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.member.name.toLowerCase().localeCompare(b.member.name.toLowerCase()),
      render: (v: number, r: CheckIn) => {
        return r.member.name;
      },
    },
    {
      title: "Practitioner",
      dataIndex: "practitioner",
      key: "practitioner",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.practitioner.name
          .toLowerCase()
          .localeCompare(b.practitioner.name.toLowerCase()),
      render: (v: number, r: CheckIn) => {
        return r.practitioner.name;
      },
    },
    {
      title: "Practitioner",
      dataIndex: "practitioner",
      key: "practitioner",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.booking?.service_helper?.name
          .toLowerCase()
          .localeCompare(b.booking?.service_helper?.name.toLowerCase() ?? ""),
      render: (v: number, r: CheckIn) => {
        return r?.booking?.service_helper?.name;
      },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: Booking, b: Booking) => a.status.localeCompare(b.status),
      editable: false,
    },
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            {!isAllTreatmentReport && practitionerName ? (
              <Typography
                level={2}
              >{`${practitionerName}'s Treatments report`}</Typography>
            ) : (
              <Typography level={2}>{`Treatments report`}</Typography>
            )}
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${
                checkInData?.groupByCheckIn[0]?._count._all || ""
              } treatments`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
      <Col span={2}>
        <Button
          loading={exporting}
          icon={<FileExcelFilled />}
          onClick={handleExport}
        >
          Export
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={checking}
            showHeader={data?.checkIns?.length > 0}
            dataSource={data?.checkIns?.map((t) => ({ key: t.id, ...t }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={{
              position: ["bottomRight"],
              pageSize: page.size,
              current: page.current,
              showSizeChanger: true,
              total: checkInData?.groupByCheckIn[0]?._count._all || page.size,
              pageSizeOptions: [50, 100, 150, 200],
              onChange: onChange,
            }}
          />
        </Form>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(TreatmentReport);
