
import { gql } from "@apollo/client";

export const get_notifications = gql`
query Notifications($where: NotificationWhereInput, $orderBy: [NotificationOrderByWithRelationInput!], $take: Int) {
    notifications(where: $where, orderBy: $orderBy, take: $take) {
      id
      image
      link_url
      metadata
      status
      title
      type
      created_at
      clinic_id
      description
      hight_light
      _count {
        members
      }
    }
  }
  
`;

export const getNotificationVar = (data: { clinicId: string, searchText?: string | null }) => {
  const { clinicId, searchText } = data;
  let variables: any = {}, where: any = {}, includes: any[] = [];
  where = { clinic_id: { equals: clinicId } }
  if (searchText) {
    includes.push({ hight_light: { contains: searchText } });
    includes.push({ title: { contains: searchText } });
    includes.push({ description: { contains: searchText } });
    where.OR = includes;
  }
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }]
  variables.take = 100;
  return variables;
}

export const create_one_notification = gql`
mutation CreateOneNotification($data: NotificationCreateInput!) {
    createOneNotification(data: $data) {
      id
      status
      title
      hight_light
    }
  }
`;

export const update_one_notification = gql`
mutation UpdateOneNotification($data: NotificationUpdateInput!, $where: NotificationWhereUniqueInput!) {
    updateOneNotification(data: $data, where: $where) {
     id 
     status
     title
     hight_light
    }
  }
`;

export const getUpdateOneNotificationVar = (id: string, data: any) => {
  let variables: any = {}, update: any = {};
  variables.where = { id }
  if (typeof data === 'object') {
    update = Object.assign({}, ...Object.keys(data).map((k, i) => ({ [k]: { "set": data[k] } })))
  }
  variables.data = update;
  return variables;
}