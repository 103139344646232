import CryptoJS from "crypto-js";
const SECRET = "umatter";

export const encryptData = (key: string, value: any) => {
  try {
    const data = CryptoJS.AES.encrypt(JSON.stringify(value), SECRET).toString();
    localStorage.setItem(key, data);
  } catch (err) {
    console.log(err);
  }
};

export const decryptData = (key: string) => {
  try {
    const localData: any = localStorage.getItem(key);
    const bytes = CryptoJS.AES.decrypt(localData, SECRET);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return data;
  } catch (err) {
    console.log(err);
  }
};
