import React, { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Row, Col, Typography, message, Empty, Card, Table, Form, List, Avatar, Button, Input, Space, } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import LoadingSpinner from '../components/loading_indicator';
import Error from '../components/apollo_error';
import withClinic from '../hooks/with_clinic';
import type { FormInstance } from 'antd/es/form';
import { Service, ApolloResult, Clinic, Practitioner, GTRating, GTImage, SystemLog, User, Bank, BankAccount } from '../store';
import { getClinicUpdateVar, get_clinic, update_clinic } from '../graphql/clinic';
import ImageUpload from '../components/image_upload';
import ImageGallery from '../components/image_gallery';
import TimeRangeSelector from '../components/time_range_picker';
import { create_one_sys_lg, getSysLgVar, get_system_logs } from '../graphql/system_log';
import { getBanksVar, get_banks } from '../graphql/bank';
import BankAccountList from '../components/bank_account_list';
import PassConfig from '../components/pass_config';
import PitiConfig from '../components/piti_config';
const { Meta } = Card;
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

interface FormData {
    name: string;
    logo: string;
    hostname?: string;
    printer_logo: string;
    phonenumber?: string;
    address?: string;
    description?: string;
    images?: GTImage[];
}

interface P {
    currentClinic?: Clinic
    user?: User,
}

function ClinicDetail(props: P) {
    const clinicId = props.currentClinic?.id || "";
    // const clinicId = "clhobuntc0002s6019ajmqkcj";
    const booking_sys_lg_code = `${clinicId}_booking_hours`;
    const [searchParams] = useSearchParams();
    const [showPass, setShowPass] = useState(false);
    const { data, loading, error, refetch } = useQuery<ApolloResult<"getClinic", Clinic>>(get_clinic, { variables: { where: { id: clinicId } }, fetchPolicy: 'network-only' });
    const { data: syslgData, refetch: lgRefetch } = useQuery<ApolloResult<"systemLogs", SystemLog[]>>(get_system_logs, { variables: getSysLgVar(booking_sys_lg_code), fetchPolicy: 'cache-and-network' })
    const [update, { loading: saveLoading }] = useMutation<ApolloResult<"updateOneClinic", Service>>(update_clinic);
    const [creatLog, { loading: lgSaveLoading }] = useMutation<ApolloResult<"createOneSystemLog", SystemLog>>(create_one_sys_lg);
    const { data: banks } = useQuery<ApolloResult<"banks", Bank[]>>(get_banks, { variables: getBanksVar(), fetchPolicy: 'network-only', nextFetchPolicy: 'cache-first' });

    const formRef = React.useRef<FormInstance<FormData>>(null);
    useEffect(() => {
        if (searchParams.get('mode')) {
            const mode = searchParams.get('mode')
            if (mode === 'support') {
                setShowPass(true)
            }
        }
    }, [searchParams.get('mode')])
    useEffect(() => {
        formRef.current?.resetFields();
    }, []);
    useEffect(() => {
        if (data?.getClinic) {
            const cl = data.getClinic;
            const vl: FormData = {
                name: cl.name,
                logo: cl.logo,
                printer_logo: cl.printer_logo || "",
                address: cl.address,
                description: cl.description,
                phonenumber: cl.phonenumber,
                images: cl.images
            }
            formRef.current?.setFieldsValue({ ...vl });
        }
    }, [data])

    if (loading) return <LoadingSpinner />
    if (error) return <Error error={error} />
    if (!data || !data.getClinic) return <Empty description={"No Data"} />

    const onSubmit = async (val: any) => {
        if (val.hasOwnProperty('images')) {
            delete val["images"];
        }
        if (val.hasOwnProperty('booking_time')) {
            delete val["booking_time"];
        }
        if (val.hasOwnProperty('bank_accounts')) {
            delete val["bank_accounts"];
        }
        if (val.hasOwnProperty('pass')) {
            delete val["pass"];
        }
        if (val.hasOwnProperty('piti')){
            delete val["piti"];
        }
        const variables = getClinicUpdateVar(clinicId, val, null);
        const res = await update({ variables });
        if (res.data?.updateOneClinic) {
            message.success('Update success')
            await refetch();
        }
    }
    const onImageUpdate = async (
        images: Array<{ uid: string, name: string, url: string, type: "old" | "new" }>,
        removes: string[]
    ) => {
        let variables: any = {};
        let connectOrCreate: any[] = images.map(img => ({
            where: { id: img.uid },
            create: { image: img.url, thumbnail_image: img.url, name: img.name }
        }));
        let disconnect: any[] = removes.map(r => ({ id: r }));
        variables.where = { id: clinicId }
        variables.data = { images: { disconnect, connectOrCreate } }
        const result = await update({ variables })
        if (result.data?.updateOneClinic) {
            message.success('Update successs')
            await refetch();
        }
    }
    const onBookTimeUpdate = async (start: number, end: number) => {
        let variables: any = {};
        variables.where = { id: clinicId }
        variables.data = {
            opening_in: { set: start },
            closing_in: { set: end },
        }
        const result = await update({ variables })
        if (result.data?.updateOneClinic) {
            message.success('Update successs')
            const log_id = `${clinicId}_${new Date().getTime()}`;
            const code = booking_sys_lg_code;
            const metadata = JSON.stringify({
                type: "booking_hours",
                action: "update",
                context: data.getClinic,
                opening_in: data.getClinic.opening_in,
                closing_in: data.getClinic.closing_in,
            });
            const user = { connect: { id: props.user?.userId } }
            const var2 = { data: { code, log_id, metadata, user } }
            const lgResult = await creatLog({ variables: var2 })
            if (lgResult.data?.createOneSystemLog) {
                await lgRefetch();
            }
            await refetch();
        }
    }
    const onBankAccountUpdate = async (val: BankAccount[]) => {
        let variables: any = {};
        let connectOrCreate: any[] = val.map(ba => ({
            where: {
                account_type_id_account_number: {
                    account_number: ba.account_number,
                    account_type_id: ba.account_type_id
                }
            },
            create: {
                account_name: ba.account_name,
                account_number: ba.account_number,
                account_type: {
                    connect: { id: ba.account_type_id }
                }
            }
        }));
        const deleteMany = { id: { in: data.getClinic.bank_accounts.map(r => r.id) } }
        await update({
            variables: {
                where: { id: clinicId },
                data: { bank_accounts: { deleteMany } }
            }
        })
        variables.where = { id: clinicId }
        variables.data = { bank_accounts: { connectOrCreate } }
        const result = await update({ variables })
        if (result.data?.updateOneClinic) {
            message.success('Update successs')
            await refetch();
        }
    }
    const onPassConfigUpdate = async (val: any) => {
        let variables: any = {};
        variables.where = { id: clinicId }
        variables.data = { pass: { set: JSON.stringify(val) } }
        const result = await update({ variables })
        if (result.data?.updateOneClinic) {
            message.success('Update Success')
            const log_id = `${clinicId}_${new Date().getTime()}`;
            const code = `${clinicId}_pass_config`;
            const metadata = JSON.stringify({
                type: "pass_config",
                action: "update",
                context: data.getClinic,
            });
            const user = { connect: { id: props.user?.userId } }
            const var2 = { data: { code, log_id, metadata, user } }
            await creatLog({ variables: var2 })
            await refetch();
        }
    }
    return (
        <React.Fragment>
            <Card
                title={`${data.getClinic.name} (${data.getClinic.code})`}
                loading={loading}
            >
                <Form
                    {...layout}
                    ref={formRef}
                    name="control-ref"
                    onFinish={onSubmit}
                    style={{ maxWidth: 600 }}
                >
                    <Form.Item name="name" label="Name" rules={[{ required: true, message: "Name is requried" },]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="logo" label="Logo" rules={[{ required: true, message: "Logo is requried" },]} >
                        <ImageUpload key={'logo_img'} />
                    </Form.Item>
                    <Form.Item name="printer_logo" label="Printer Logo" rules={[{ required: true, message: "Printer logo is requried" },]} >
                        <ImageUpload key={"printer_img"} />
                    </Form.Item>
                    <Form.Item name="phonenumber" label="PhoneNumber" rules={[{ required: true, message: "Phone is requried" },]} >
                        <Input />
                    </Form.Item>
                    <Form.Item name="address" label="Address" rules={[{ required: true, message: "Address is requried" },]} >
                        <Input.TextArea maxLength={100} />
                    </Form.Item>
                    <Form.Item name="description" label="Description">   
                        <Input.TextArea maxLength={1024} autoSize={{ minRows: 2, maxRows: 6 }} />
                    </Form.Item>
                    <Form.Item name="images" label="Image Gallery" >
                        <ImageGallery
                            title={data.getClinic.name}
                            clinicId={clinicId}
                            onOk={onImageUpdate}
                            images={(data.getClinic.images || []).map(img => ({ uid: img.id, name: img.name, url: img.image, status: 'done' }))}
                        />
                    </Form.Item>
                    <Form.Item name="booking_time" label="Booking Time" >
                        <TimeRangeSelector
                            title='Booking Time'
                            saving={lgSaveLoading}
                            clinicId={clinicId}
                            start={data.getClinic.opening_in}
                            end={data.getClinic.closing_in}
                            logs={syslgData?.systemLogs || []}
                            onSave={onBookTimeUpdate}
                        />
                    </Form.Item>
                    <Form.Item name="bank_accounts" label="Bank accounts" >
                        <BankAccountList
                            title={`Bank Accounts (${data.getClinic.name} )`}
                            accounts={data.getClinic.bank_accounts}
                            banks={banks?.banks || []}
                            saving={saveLoading}
                            onSave={onBankAccountUpdate}
                        />
                    </Form.Item>
                    {showPass && <Form.Item name="pass" label="Pass Config" >
                        <PassConfig
                            currentClinic={data.getClinic}
                            saving={saveLoading}
                            title='Pass API Settings'
                            onSave={onPassConfigUpdate}
                        />
                    </Form.Item>
                    }
                     {showPass && <Form.Item name="piti" label="Piti Configuration" >
                        <PitiConfig
                            currentClinic={data.getClinic}
                        />
                    </Form.Item>
                    }
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit" loading={saveLoading} disabled={error}>
                            {"save"}
                        </Button>
                    </Form.Item>
                    {error && <div style={{ color: 'red', textAlign: 'center' }}>{error}</div>}
                </Form>
            </Card>
        </React.Fragment>
    )
}
export default withClinic(ClinicDetail)
const styles = {
    card: {
        height: "100%"
    }
}