import { Action } from "redux";
import { ClinicState, Clinic } from "../state";
import { ClinicActionTypes as actionTypes } from "../actions/action_types";
import { encryptData } from "../../helpers/crypto";
import CryptoJS from "crypto-js";

export const actions = {
  setClinics: (d: Clinic[]) => ({
    type: actionTypes.SET_CLINIC,
    payload: d,
  }),
  setSelected: (c: Clinic) => ({
    type: actionTypes.SET_SELECTED_CLINIC,
    payload: c,
  }),
};

type ClinicAction = Action & { payload: Clinic[] | Clinic };

export const reducers = (
  state: ClinicState = { clinics: [] },
  action: ClinicAction
) => {
  switch (action.type) {
    case actionTypes.SET_CLINIC: {
      const cl = action.payload as Clinic[];
      const SECRET = "umatter";
      const localClinic = localStorage.getItem("clinicInfo");
      let localClinicData;
      if (localClinic?.length) {
        const bytes = CryptoJS.AES.decrypt(localClinic, SECRET);
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        localClinicData = data;
      }
      // console.log(localClinicData, "localClinicData clinic reducer");
      if (!state.selected && cl.length > 0) {
        encryptData("clinicInfo", {
          selected: localClinicData?.selected
            ? localClinicData?.selected
            : cl[0],
          clinics: action.payload,
        });
        return {
          selected: localClinicData?.selected
            ? localClinicData?.selected
            : cl[0],
          clinics: action.payload,
        } as ClinicState;
      } else {
        encryptData("clinicInfo", {
          // ...state,
          selected: cl[0],
          clinics: cl,
        });
        return {
          // ...state,
          selected: cl[0],
          clinics: cl,
        } as ClinicState;
      }
    }
    case actionTypes.SET_SELECTED_CLINIC: {
      encryptData("clinicInfo", { ...state, selected: action.payload });
      return {
        ...state,
        selected: action.payload,
      } as ClinicState;
    }
    default:
      break;
  }

  return state;
};
