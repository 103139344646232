import { EditOutlined, LinkOutlined, LoadingOutlined, PlusCircleOutlined, SaveOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Empty, Form, Input, InputNumber, Popconfirm, Row, Select, Switch, Table, Typography, message } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import Search from 'antd/lib/input/Search';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import Error from '../components/apollo_error';
import LoadingSpinner from '../components/loading_indicator';
import { create_one_service_type, getServiceTypeCategoriesVar, getUpdateOneServiceTypeCategoryVar, service_type_categories, update_one_service_type } from '../graphql/service_type_category';
import withClinic from '../hooks/with_clinic';
import { ApolloResult, Clinic, ServiceTypeCategory } from '../store';
import SaleChannelModal from '../components/salechannel_modal';
const { TextArea } = Input
const { Paragraph } = Typography;
interface P {
    currentClinic?: Clinic
}
type inputElementType = 'number' | 'text' | "switch" | "textarea" | "select";
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
    editing: boolean;
    dataIndex: string;
    title: any;
    inputType: inputElementType;
    record: ServiceTypeCategory,
    options: any;
    index: number;
    children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    options,
    ...restProps
}) => {
    let inputNode = <Input />
    if (inputType === 'number') {
        inputNode = <InputNumber />
    }
    if (inputType === 'switch') {
        inputNode = <Switch defaultChecked={(record as any)[dataIndex]} />;
    }
    if (inputType === 'textarea') {
        inputNode = <TextArea key={`${dataIndex}`} rows={3} defaultValue={(record as any)[dataIndex]} />
    }
    if (inputType === 'select') {
        inputNode = <Select
            defaultValue={(record as any)[dataIndex]}
            style={{ width: 120 }}
            options={options[dataIndex] || []}
        />
    }
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{ margin: 0 }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const ServiceTypeCategoryPage = (props: P) => {
    const clinicId = props.currentClinic?.id || "";
    // const clinicId = "clhobuntc0002s6019ajmqkcj";
    const [searchText, setSearchText] = useState<string | null>(null);
    const [form] = Form.useForm();
    const [serviceCategories, setServiceCategories] = useState<ServiceTypeCategory[]>([]);
    const [editingKey, setEditingKey] = useState('');
    const [current, setCurrent] = useState<string | null>(null);
    const isEditing = (record: ServiceTypeCategory) => record.id === editingKey;
    const variables = getServiceTypeCategoriesVar(clinicId);
    const { loading, data, error, refetch } = useQuery<ApolloResult<"serviceTypeCategories", ServiceTypeCategory[]>>(service_type_categories, { variables, fetchPolicy: 'cache-and-network' })
    const [update, { loading: saveLoading }] = useMutation<ApolloResult<"updateOneServiceTypeCategory", ServiceTypeCategory>>(update_one_service_type);
    const [create, { loading: createLoading }] = useMutation<ApolloResult<"createOneServiceTypeCategory", ServiceTypeCategory>>(create_one_service_type);
    useEffect(() => {
        if (data?.serviceTypeCategories) {
            setServiceCategories(data.serviceTypeCategories);
        }
    }, [data])
    if (loading) return <LoadingSpinner />
    if (error) return <Error error={error} />
    if (!data) return <Empty />
    const edit = (r: ServiceTypeCategory) => {
        form.setFieldsValue({ ...r });
        setEditingKey(r.id!);
    }
    const add = () => {
        const newData: ServiceTypeCategory = {
            id: `new_${(new Date().getTime())}`,
            is_private: true,
            name: "New Service Category",
            status: "INACTIVE",
            created_at: new Date(),
            order: 0,
        }
        const newList = [...serviceCategories];
        newList.unshift(newData);
        setServiceCategories(newList)
        setEditingKey(newData.id)
    }
    const save = async (r: ServiceTypeCategory) => {
        if (r.id.startsWith('new_')) {
            await onCreate(r);
        } else {
            let fData = (await form.validateFields()) as any;
            const result = await update({ variables: getUpdateOneServiceTypeCategoryVar(r.id, fData) })
            if (result.data?.updateOneServiceTypeCategory) {
                message.success('update success')
                await refetch();
            } else {
                message.error('update failed')
            }
        }
        form.resetFields();
        setEditingKey('');
    }
    const handleSaleChannel = async (id: string, channels: string) => {
        setCurrent(id);
        const result = await update({ variables: getUpdateOneServiceTypeCategoryVar(id, { sale_channel: channels }) })
        if (result.data?.updateOneServiceTypeCategory) {
            message.success('update success')
            await refetch({ fetchPolicy: "network-only" });
        } else {
            message.error('update failed')
        }
        setCurrent(null)
    }
    const onCreate = async (r: ServiceTypeCategory) => {
        let fData = (await form.validateFields()) as { status: boolean };
        let uData = { ...fData } as any;
        delete uData["id"];
        const result = await create({
            variables: {
                data: {
                    ...uData,
                    color: "",
                    clinic: {
                        connect: {
                            id: clinicId
                        }
                    }
                }
            }
        });
        if (result.data?.createOneServiceTypeCategory) {
            message.success('create success')
            await refetch();
        } else {
            message.error('create failed')
        }
    }
    const onCancel = async (r: ServiceTypeCategory) => {
        if (r.id.startsWith('new_')) {
            const newList = [...serviceCategories];
            newList.shift();
            setServiceCategories(newList);
        }
        form.resetFields();
        setEditingKey('')
    }

    const columns: any[] = [
        {
            title: 'Name',
            dataIndex: "name",
            key: "name",
            editable: true,
            filteredValue: searchText ? [searchText] : null,
            inputType: 'text' as inputElementType,
            sorter: (a: ServiceTypeCategory, b: ServiceTypeCategory) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            defaultSortOrder: "asc",
            onFilter: (value: string, record: ServiceTypeCategory) => (record.name).toLowerCase().includes(value.toLowerCase()),
            // render: (v: ServiceTypeCategory, r: ServiceTypeCategory) => {
            //     return <Link to={paths.getServiceRoomDetailRoute(r.id)}>{r.name}</Link>;
            // },
        },
        {
            title: 'Staus',
            dataIndex: "status",
            key: 'status',
            inputType: 'select' as inputElementType,
            options: [{ value: "ACTIVE", label: "Active" }, { value: "INACTIVE", label: "Inactive" }],
            sorter: (a: ServiceTypeCategory, b: ServiceTypeCategory) => a.status.localeCompare(b.status),
            editable: true,
        },
        {
            title: 'Order',
            dataIndex: "order",
            key: 'order',
            inputType: 'number' as inputElementType,
            sorter: (a: ServiceTypeCategory, b: ServiceTypeCategory) => a.order - b.order,
            editable: true,
        },
        {
            title: 'Is Private ?',
            dataIndex: "is_private",
            key: 'is_private',
            inputType: 'switch' as inputElementType,
            sorter: (a: ServiceTypeCategory, b: ServiceTypeCategory) => (a.is_private ? 1 : 0) - (b.is_private ? 1 : 0),
            editable: true,
            render: (v: ServiceTypeCategory, r: ServiceTypeCategory) => {
                return r.is_private ? 'private' : "public";
            },
        },
        {
            title: 'Description',
            dataIndex: "description",
            key: 'description',
            inputType: 'textarea' as inputElementType,
            sorter: (a: ServiceTypeCategory, b: ServiceTypeCategory) => a.status.localeCompare(b.status),
            editable: true,
            render: (v: number, r: ServiceTypeCategory) => {
                return <Paragraph style={{ maxWidth: 230 }} ellipsis={{ rows: 3, expandable: false }} >{r.description}</Paragraph>
            },
        },
        {
            title: 'CreatedAt',
            dataIndex: "created_at",
            key: 'created_at',
            editable: false,
            sorter: (a: ServiceTypeCategory, b: ServiceTypeCategory) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (v: ServiceTypeCategory, r: ServiceTypeCategory) => {
                return format(new Date(r.created_at), 'dd MMM,yyyy');
            },
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            width: '10%',
            dataIndex: 'action',
            editable: false,
            fixed: 'right',
            render: (v: number, record: ServiceTypeCategory) => {
                const editable = isEditing(record);
                return (
                    <ButtonGroup>
                        <SaleChannelModal
                            loading={current === record.id && saveLoading}
                            title={`${record.name}'s SaleChannels`}
                            defaultValue={record.sale_channel}
                            onChange={async (channels: string) => {
                                await handleSaleChannel(record.id, channels);
                            }}
                        />
                        {editable ?
                            (
                                (saveLoading || createLoading) ? <Button type="primary" icon={<LoadingOutlined />} >saving...</Button> :
                                    < Popconfirm
                                        placement="topRight"
                                        title={`Sure to save ${record.name}?`}
                                        onConfirm={() => save(record)}
                                        onCancel={() => onCancel(record)}>
                                        <Button type='primary' icon={<SaveOutlined />} >save</Button>
                                    </Popconfirm>
                            )
                            :
                            <Button shape="circle" icon={<EditOutlined />}
                                onClick={() => edit(record)}
                            />
                        }
                    </ButtonGroup >
                );

            }
        }
    ];
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: ServiceTypeCategory) => ({
                record,
                inputType: col.inputType || "text",
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
                options: { [col.dataIndex]: col.options || [] }
            }),
        };
    });

    const control = (
        <Row style={{ marginTop: -18 }}>
            <Col flex={4}>
                <Row >
                    <Col span={8}>
                        <Typography level={2}>Service Categories</Typography>

                    </Col>
                    <Col span={8} offset={8}>
                        <Search
                            defaultValue={searchText || undefined}
                            placeholder="search ...."
                            allowClear
                            size="middle"
                            onSearch={(val: any) => setSearchText(val)}
                        />
                    </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>Add</Button>
            </Col>
        </Row>
    );
    const context = (
        <Row style={{ marginTop: 8 }}>
            <Col span={24}>
                <Form form={form} component={false}>
                    <Table
                        loading={saveLoading || createLoading || loading}
                        showHeader={serviceCategories.length > 0}
                        components={{ body: { cell: EditableCell, } }}
                        dataSource={serviceCategories.map(b => ({ key: b.id, ...b }))}
                        columns={mergedColumns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </Col>
        </Row>
    );
    return (
        <React.Fragment>
            {control}
            {context}
        </React.Fragment>
    )
}

export default withClinic(ServiceTypeCategoryPage);
