import React, { useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Booking,
  CheckIn,
  Clinic,
  OfferCategory,
  Order,
  Service,
} from "../../store";
import {
  Row,
  Col,
  Typography,
  Empty,
  Button,
  Popconfirm,
  InputNumber,
  Input,
  Switch,
} from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import MultiSelect from "../../components/multi_select";
import {
  create_one_offer,
  create_one_offerCategory,
  getOfferCategoriesVar,
  getUpdateOneOfferCategoryVar,
  get_offer_categories,
  update_one_offer,
  update_one_offer_category,
} from "../../graphql/offer";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { bool } from "yup";
import { uuidv4 } from "../../helpers/utils";
import ImagUpload from "../../components/image_upload";

interface P {
  currentClinic?: Clinic;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea" | "img";
  record: OfferCategory;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === "number" ? (
      <InputNumber />
    ) : inputType === "switch" ? (
      <Switch defaultChecked={record.status === "ACTIVE"} />
    ) : inputType === "img" ? (
      <ImagUpload defaultValue={(record as any)[dataIndex]} />
    ) : (
      <Input />
    );

  const isRequired = dataIndex === "image" ? false : true;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: isRequired,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const OfferCategoryPage = (props: P) => {
  // const [messageApi, contextHolder] = message.useMessage();
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [categories, setCategories] = useState<OfferCategory[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: OfferCategory) => record.id === editingKey;
  const variables = getOfferCategoriesVar(clinicId);
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"offerCategories", OfferCategory[]>
  >(get_offer_categories, { variables });
  const [update, { loading: saveLoading }] = useMutation<
    ApolloResult<"updateOneOfferCategory", OfferCategory>
  >(update_one_offer_category);
  const [create, { loading: createLoading }] = useMutation<
    ApolloResult<"createOneOfferCategory", OfferCategory>
  >(create_one_offerCategory);
  useEffect(() => {
    if (data?.offerCategories) {
      setCategories(data.offerCategories);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const edit = (r: OfferCategory) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const add = () => {
    const newData: OfferCategory = {
      id: `new_${new Date().getTime()}`,
      name: "New Offer",
      status: "INACTIVE",
      created_at: new Date(),
      sort_order: 1,
      image: "",
    };
    const newList = [...categories];
    newList.unshift(newData);
    setCategories(newList);
    setEditingKey(newData.id);
  };
  const save = async (r: OfferCategory) => {
    if (r.id.startsWith("new_")) {
      await onCreate(r);
    } else {
      let fData = (await form.validateFields()) as { status: boolean };
      const uData = {
        ...fData,
        status: fData.status === true ? "ACTIVE" : "INACTIVE",
      } as OfferCategory;

      const result = await update({
        variables: getUpdateOneOfferCategoryVar(r.id, uData),
      });
      if (result.data?.updateOneOfferCategory) {
        message.success("update success");
        await refetch();
      } else {
        message.error("update failed");
      }
    }
    setEditingKey("");
  };

  const onCreate = async (r: OfferCategory) => {
    let fData = (await form.validateFields()) as { status: boolean };
    let uData = {
      ...fData,
      status: fData.status === true ? "ACTIVE" : "INACTIVE",
    } as any;
    delete uData["id"];
    const result = await create({
      variables: {
        data: {
          ...uData,
          clinic: {
            connect: {
              id: clinicId,
            },
          },
        },
      },
    });
    if (result.data?.createOneOfferCategory) {
      message.success("create success");
      await refetch();
    } else {
      message.error("create failed");
    }
  };

  const onCancel = async (r: OfferCategory) => {
    if (r.id.startsWith("new_")) {
      const newList = [...categories];
      newList.shift();
      setCategories(newList);
    }
    setEditingKey("");
  };

  const columns: any[] = [
    {
      title: "Photo",
      dataIndex: "image",
      key: "image",
      editable: true,
      render: (v: number, r: OfferCategory) => {
        return (
          <img
            src={r.image}
            height={50}
            width={50}
            style={{ objectFit: "cover", objectPosition: "center" }}
            alt=""
          />
        );
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      sorter: (a: OfferCategory, b: OfferCategory) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onFilter: (value: string, record: OfferCategory) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
      //   render: (value: string, record: OfferCategory) => (
      //     <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      //       <img
      //         src={record.image}
      //         height={50}
      //         width={50}
      //         style={{ objectFit: "cover", objectPosition: "center" }}
      //         alt=""
      //       />
      //       <p>{record.name}</p>
      //     </div>
      //   ),
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: Service, b: Service) => a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Sort Order",
      dataIndex: "sort_order",
      key: "sort_order",
      editable: true,
      sorter: (a: Service, b: Service) => a.sort_order - b.sort_order,
    },
    {
      title: "CreatedAt",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: Order, b: Order) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Order, r: Order) => {
        return format(new Date(r.created_at), "dd MMM,yyyy");
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: OfferCategory) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading || createLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: OfferCategory) => ({
        record,
        inputType:
          col.dataIndex === "sort_order"
            ? "number"
            : col.dataIndex === "status"
            ? "switch"
            : col.dataIndex === "image"
            ? "img"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Offer Category</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || createLoading || loading}
            showHeader={categories.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={categories.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(OfferCategoryPage);
