import React, { useState } from 'react'
import withClinic from '../hooks/with_clinic';
import { ApolloResult, Booking, CheckIn, Clinic, Order, Service, ServicePackage, } from '../store';
import { Row, Col, Typography, Empty } from 'antd';
import Search from 'antd/lib/input/Search';
import DateRange from '../components/date_range';
import { Form } from 'antd';
import { Table } from 'antd';
import { addDays, format, subDays } from 'date-fns';
import ButtonGroup from 'antd/lib/button/button-group';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/loading_indicator';
import Error from '../components/apollo_error';
import MultiSelect from '../components/multi_select';
import { formatMoney } from '../helpers/utils';
import { Avatar } from 'antd';
import { getServicesVar, get_service_packages, get_services } from '../graphql/service';
interface P {
    currentClinic?: Clinic
}

const ServicePackagePage = (props: P) => {
    const clinicId = props.currentClinic?.id || "";
    // const clinicId = "clhobuntc0002s6019ajmqkcj";
    const [searchText, setSearchText] = useState<string | null>(null);
    const [filter, setFilter] = useState<string[]>([]);
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 30), endDate: addDays(new Date(), 15) })
    const [form] = Form.useForm();
    const variables = getServicesVar({ clinicId, startDate: ranges.startDate, endDate: ranges.endDate, searchText, take: 150, status: filter });
    const { loading, data, error } = useQuery<ApolloResult<"servicePackages", ServicePackage[]>>(get_service_packages, { variables })
    if (loading) return <LoadingSpinner />
    if (error) return <Error error={error} />
    if (!data) return <Empty />
    const onDateChange = (startDate: Date, endDate: Date) => {
        setRanges({ startDate, endDate });
    }
    const columns: any[] = [
        {
            title: 'Photo',
            dataIndex: "image",
            key: 'image',
            editable: false,
            render: (v: number, r: ServicePackage) => {
                return <Avatar src={r.image} size={50} />;
            },
        },
        {
            title: 'Name',
            dataIndex: "name",
            key: "name",
            editable: false,
            sorter: (a: ServicePackage, b: ServicePackage) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            defaultSortOrder:"ascend"
        },
        // {
        //     title: 'Id',
        //     dataIndex: "id",
        //     key: "id",
        //     editable: false,
        //     //sorter: (a: ServicePackage, b: ServicePackage) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        // },
        {
            title: 'Staus',
            dataIndex: "status",
            key: 'status',
            sorter: (a: ServicePackage, b: ServicePackage) => a.status.localeCompare(b.status),
            editable: false,
        },
        {
            title: 'Expire Day',
            dataIndex: "expire_day",
            key: 'expire_day',
            sorter: (a: ServicePackage, b: ServicePackage) => a.expire_day - b.expire_day,
            editable: false,
        },
        {
            title: 'Price',
            dataIndex: "price",
            key: 'price',
            sorter: (a: ServicePackage, b: ServicePackage) => Number(a.price) - Number(b.price),
            editable: true,
            render: (v: number, r: ServicePackage) => {
                return `${formatMoney(Number(r.price))} MMK`;
            },
        },
        {
            title: 'Original Price',
            dataIndex: "original_price",
            key: 'original_price',
            sorter: (a: ServicePackage, b: ServicePackage) => Number(a.original_price) - Number(b.original_price),
            editable: true,
            render: (v: number, r: ServicePackage) => {
                return `${formatMoney(Number(r.original_price))} MMK`;
            },
        },
        {
            title: 'Locked',
            dataIndex: "isLock",
            key: "isLock",
            editable: false,
            sorter: (a: ServicePackage, b: ServicePackage) => (a.isLock ? 1 : 0) - (b.isLock ? 1 : 0),
        },
        {
            title: 'Sort Order',
            dataIndex: "sort_order",
            key: "sort_order",
            editable: false,
            sorter: (a: ServicePackage, b: ServicePackage) => a.sort_order - b.sort_order,
        },
        {
            title: 'CreatedAt',
            dataIndex: "created_at",
            key: 'created_at',
            editable: false,
            sorter: (a: ServicePackage, b: ServicePackage) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (v: ServicePackage, r: ServicePackage) => {
                return format(new Date(r.created_at), 'dd MMM,yyyy');
            },
        },
    ];
    const control = (
        <Row style={{ marginTop: -18 }}>
            <Col flex={4}>
                <Row >
                    <Col span={8}>
                        <Typography level={2}>Service Packages</Typography>

                    </Col>
                    <Col span={8} offset={8}>
                        <Search
                            defaultValue={searchText || undefined}
                            placeholder="search ...."
                            allowClear
                            size="middle"
                            onSearch={(val: any) => setSearchText(val)}
                        />

                    </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onDateChange} />
            </Col>
        </Row>
    );
    const context = (
        <Row style={{ marginTop: 8 }}>
            <Col span={24}>
                <Form form={form} component={false}>
                    <Table
                        showHeader={data.servicePackages.length > 0}
                        //components={{ body: { cell: EditableCell, } }}
                        // expandable={{ expandedRowRender, onExpandedRowsChange }}
                        dataSource={data.servicePackages.map(b => ({ key: b.id, ...b }))}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </Col>
        </Row>
    );
    const filters = (
        <Row style={{ marginTop: 4 }}>
            <Col span={24}>
                <MultiSelect
                    selected={filter}
                    placeholder='Select status'
                    options={["ACTIVE", "INACTIVE"]}
                    onChange={setFilter} />
            </Col>
        </Row>
    )

    return (
        <React.Fragment>
            {control}
            {filters}
            {context}
        </React.Fragment>
    )
}

export default withClinic(ServicePackagePage);
