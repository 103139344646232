import React, { useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import { ApolloResult, Bank, Clinic, Order } from "../../store";
import {
  Row,
  Col,
  Typography,
  Empty,
  Button,
  Popconfirm,
  InputNumber,
  Input,
  Switch,
  Avatar,
} from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import { addDays, format, subDays } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import ImagUpload from "../../components/image_upload";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import { Select } from "antd";
import {
  getBanksVar,
  getSetOneBankVar,
  get_banks,
  set_one_bank,
} from "../../graphql/bank";
import { useSearchParams } from "react-router-dom";
const { TextArea } = Input;
const { Paragraph } = Typography;

interface P {
  currentClinic?: Clinic;
}
interface BankCode {
  value: string;
  label: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea" | "select" | "img";
  record: Bank;
  options?: Bank[];
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  options,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode: any = <Input />;
  if (inputType === "number") {
    inputNode = <InputNumber />;
  }
  // if (inputType === 'switch') {
  //     inputNode = <Switch defaultChecked={record.status === 'ACTIVE'} />
  // }
  if (inputType === "select") {
    inputNode = (
      <Select
        defaultValue={(record as any)[dataIndex]}
        style={{ width: 200 }}
        options={options || []}
      />
    );
  }
  if (inputType === "textarea") {
    inputNode = (
      <TextArea
        key={`${dataIndex}`}
        rows={5}
        defaultValue={(record as any)[dataIndex]}
      />
    );
  }
  if (inputType === "img") {
    inputNode = <ImagUpload defaultValue={(record as any)[dataIndex]} />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const bankCodes: BankCode[] = [
  { value: "kbz", label: "KBZ Bank" },
  { value: "kbzmbanking", label: "KBZ mBanking" },
  { value: "kpay", label: "KPay" },
  { value: "kpayspecial", label: "KPay Special" },
  { value: "wavemoney", label: "WaveMoney" },
  { value: "wavepay", label: "WavePay" },
  { value: "yoma", label: "Yoma Bank" },
  { value: "yomaspecial", label: "Yoma Special" },
  { value: "aya", label: "AYA Bank" },
  { value: "ayapay", label: "AYAPay" },
  { value: "ayambanking", label: "AYA mBanking" },
  { value: "cb", label: "CB Bank" },
  { value: "cbpay", label: "CBPay" },
  { value: "apay", label: "A+ wallet" },
  { value: "citizen", label: "Citizen Bank" },
  { value: "citizenpay", label: "Citizen Pay" },
  { value: "uab", label: "UAB Bank" },
  { value: "uabpay", label: "UAB Pay" },
  { value: "paypal", label: "Paypal" },
  { value: "visa", label: "Visa" },
  { value: "master", label: "Master" },
];
const codeDic = Object.assign(
  {},
  ...bankCodes.map((x) => ({ [x.value]: x.label }))
);
const BankPage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [searchParams] = useSearchParams();
  const [banks, setBanks] = useState<Bank[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const [isSupport, setSupport] = useState(false);
  const isEditing = (record: Bank) => record.id === editingKey;
  const isNew = (r: Bank) => r.id.startsWith("new_");
  const variables = getBanksVar();
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"banks", Bank[]>
  >(get_banks, { variables });
  const [upsert, { loading: saveLoading }] =
    useMutation<ApolloResult<"upsertOneBank", Bank>>(set_one_bank);
  useEffect(() => {
    if (searchParams.get("mode")) {
      const mode = searchParams.get("mode");
      if (mode === "support") {
        setSupport(true);
      }
    }
  }, [searchParams.get("mode")]);
  useEffect(() => {
    if (data?.banks) {
      setBanks(data.banks);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  if (!isSupport) return <Empty description="Not Support" />;
  const edit = (r: Bank) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const add = () => {
    const newData: Bank = {
      id: `new_${new Date().getTime()}`,
      name: "New Bank",
      created_at: new Date(),
      code: "new",
      logo: "",
    };
    const newList = [...banks];
    newList.unshift(newData);
    setBanks(newList);
    setEditingKey(newData.id);
  };
  const save = async (r: Bank) => {
    let formData = (await form.validateFields()) as any;
    if (r.id.startsWith("new_")) {
      await onCreate(r);
    } else {
      let formData = (await form.validateFields()) as Bank;
      if (formData.code.startsWith("new")) return;
      const variables = getSetOneBankVar(formData);
      const result = await upsert({ variables });
      if (result.data?.upsertOneBank) {
        message.success("update success");
        await refetch();
      } else {
        message.error(`update failed`);
      }
    }
    form.resetFields();
    setEditingKey("");
  };
  const onCreate = async (r: Bank) => {
    let formData = (await form.validateFields()) as Bank;
    if (formData.code.startsWith("new")) return;
    const variables = getSetOneBankVar(formData);
    const result = await upsert({ variables });
    if (result.data?.upsertOneBank) {
      message.success("create success");
      await refetch();
    } else {
      message.error(`create failed`);
    }
  };
  const onCancel = async (r: Bank) => {
    if (r.id.startsWith("new_")) {
      const newList = [...banks];
      newList.shift();
      setBanks(newList);
    }
    form.resetFields();
    setEditingKey("");
  };
  const columns: any[] = [
    {
      title: "Logo",
      dataIndex: "logo",
      key: "logo",
      editable: true,
      render: (v: number, r: Bank) => {
        return <Avatar src={r.logo || null} size={50} />;
      },
    },
    {
      title: "Type",
      dataIndex: "code",
      key: "code",
      editable: true,
      filteredValue: searchText ? [searchText] : null,
      sorter: (a: Bank, b: Bank) =>
        a.code.toLowerCase().localeCompare(b.code.toLowerCase()),
      onFilter: (value: string, record: Bank) =>
        record.code.toLowerCase().includes(value.toLowerCase()),
      render: (v: number, r: Bank) => {
        return codeDic[r.code] || r.code;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      // filteredValue: searchText ? [searchText] : null,
      sorter: (a: Bank, b: Bank) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      //  onFilter: (value: string, record: Bank) => (record.name).toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "CreatedAt",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: Order, b: Order) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Order, r: Order) => {
        return format(new Date(r.created_at), "dd MMM,yyyy");
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: Bank) => {
        const editable = isEditing(record);
        const n = isNew(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}>
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Bank) => ({
        record,
        options: bankCodes.filter(
          (bc) => !data.banks.map((b) => b.code).includes(bc.value)
        ),
        inputType:
          col.dataIndex === "logo"
            ? "img"
            : col.dataIndex === "code"
            ? "select"
            : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Banks</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || loading}
            showHeader={banks.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={banks.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(BankPage);
