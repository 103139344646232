import { gql } from "@apollo/client";

export const get_offers = gql`
  query Offers(
    $where: OfferWhereInput
    $orderBy: [OfferOrderByWithRelationInput!]
  ) {
    offers(where: $where, orderBy: $orderBy) {
      id
      image
      name
      sort_order
      hight_light
      expired_date
      description
      clinic_id
      category_id
      category {
        id
        name
      }
      term_and_condition
      status
      images {
        id
        name
        image
      }
      metadata
      created_at
    }
  }
`;

export const getOffersVar = (clinicId: string) => ({
  where: {
    clinic_id: { equals: clinicId },
    //status: { equals: "ACTIVE" }
  },
  orderBy: [{ sort_order: "asc" }],
});
export const get_offer_categories = gql`
  query OfferCategories(
    $where: OfferCategoryWhereInput
    $orderBy: [OfferCategoryOrderByWithRelationInput!]
  ) {
    offerCategories(where: $where, orderBy: $orderBy) {
      id
      image
      name
      sort_order
      status
      description
      clinic_id
      created_at
    }
  }
`;

export const getOfferCategoriesVar = (clinicId: string) => ({
  where: {
    clinic_id: { equals: clinicId },
    // status: { equals: "ACTIVE" }
  },
  orderBy: [{ sort_order: "asc" }],
});
export const update_one_offer_category = gql`
  mutation UpdateOneOfferCategory(
    $data: OfferCategoryUpdateInput!
    $where: OfferCategoryWhereUniqueInput!
  ) {
    updateOneOfferCategory(data: $data, where: $where) {
      id
    }
  }
`;

export const getUpdateOneOfferCategoryVar = (id: string, data: any) => {
  let variables: any = {},
    update: any = {};
  variables.where = { id };
  if (typeof data === "object") {
    update = Object.assign(
      {},
      ...Object.keys(data).map((k, i) => ({ [k]: { set: data[k] } }))
    );
  }
  variables.data = update;
  return variables;
};

export const create_one_offerCategory = gql`
  mutation CreateOneOfferCategory($data: OfferCategoryCreateInput!) {
    createOneOfferCategory(data: $data) {
      id
    }
  }
`;

export const create_one_offer = gql`
  mutation CreateOneOffer($data: OfferCreateInput!) {
    createOneOffer(data: $data) {
      id
    }
  }
`;

export const update_one_offer = gql`
  mutation UpdateOneOffer(
    $data: OfferUpdateInput!
    $where: OfferWhereUniqueInput!
  ) {
    updateOneOffer(data: $data, where: $where) {
      id
    }
  }
`;

export const getUpdateOneOfferVar = (
  id: string,
  data: any,
  connectors?: any
) => {
  let variables: any = {},
    update: any = {};
  variables.where = { id };
  if (typeof data === "object") {
    update = Object.assign(
      {},
      ...Object.keys(data).map((k, i) => ({ [k]: { set: data[k] } }))
    );
  }
  variables.data = update;
  if (connectors && typeof connectors === "object") {
    variables.data = { ...variables.data, ...connectors };
  }
  return variables;
};

export const delete_one_offer = gql`
  mutation DeleteOneOffer($where: OfferWhereUniqueInput!) {
    deleteOneOffer(where: $where) {
      id
    }
  }
`;

export const getDeleteOneOfferVar = (offerId: string) => ({
  where: {
    id: offerId,
  },
});
