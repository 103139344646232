import React, { useState } from "react";
import withPass from "../../hooks/with_pass";
import {
  ApolloResult,
  Clinic,
  PSConfig,
  PSTransactionDetial,
} from "../../store";
import { addDays, format, subDays } from "date-fns";
import { useQuery } from "@apollo/client";
import { getTrxDetailVar, get_trx_details } from "../../graphql/pass";
import LoadingSpinner from "../../components/loading_indicator";
import { Empty, Form, Row, Col, Table } from "antd";
import Search from "antd/lib/input/Search";
import Error from "../../components/apollo_error";
import DateRange from "../../components/date_range";
import { formatMoney } from "../../helpers/utils";

interface P {
  currentClinic?: Clinic;
  passConfig?: PSConfig;
}

function Transactions(props: P) {
  const passId = props.passConfig?.id || "";
  //const clinicId = props.currentClinic?.id || "";
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 15),
    endDate: addDays(new Date(), 15),
  });
  const variables = getTrxDetailVar({
    code: passId,
    searchText,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
  });
  const { data, loading, error } = useQuery<
    ApolloResult<"transactionDetails", PSTransactionDetial[]>
  >(get_trx_details, { variables, context: { className: "pass" } });
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty description="no transactions" />;
  const columns: any[] = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: PSTransactionDetial, b: PSTransactionDetial) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: PSTransactionDetial, r: PSTransactionDetial) => {
        return format(new Date(r.created_at), "dd MMM,yyyy h:mm a");
      },
    },
    {
      title: "TransactionNo",
      dataIndex: "transaction_number",
      key: "transaction_number",
      editable: false,
      sorter: (a: PSTransactionDetial, b: PSTransactionDetial) =>
        a.transaction_number
          .toLowerCase()
          .localeCompare(b.transaction_number.toLowerCase()),
      render: (v: number, r: PSTransactionDetial) => {
        return r.transaction_number;
      },
    },
    {
      title: "From",
      dataIndex: "sender",
      key: "sender",
      sorter: (a: PSTransactionDetial, b: PSTransactionDetial) =>
        a.sender.customer.name.localeCompare(b.sender.customer.name),
      editable: true,
      render: (v: number, r: PSTransactionDetial) => {
        return r.sender.customer.name + `(${r.sender.customer.phone_number})`;
      },
    },
    {
      title: "To",
      dataIndex: "recipient",
      key: "recipient",
      sorter: (a: PSTransactionDetial, b: PSTransactionDetial) =>
        a.recipient.customer.name.localeCompare(b.recipient.customer.name),
      editable: true,
      render: (v: number, r: PSTransactionDetial) => {
        return (
          r.recipient.customer.name + `(${r.recipient.customer.phone_number})`
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "balance",
      key: "balance",
      editable: false,
      sorter: (a: PSTransactionDetial, b: PSTransactionDetial) =>
        Number(a.balance) - Number(b.balance),
      render: (v: number, r: PSTransactionDetial) => {
        return formatMoney(Number(r.balance));
      },
    },
    {
      title: "Transfer Note",
      dataIndex: "transactions",
      key: "transactions",
      editable: false,
      sorter: (a: PSTransactionDetial, b: PSTransactionDetial) =>
        a.transactions[0]?.comment?.localeCompare(b.transactions[0]?.comment ?? ""),
      render: (v: number, r: PSTransactionDetial) => {
        return r.transactions[0]?.comment ?? "";
      },
    },
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>Transactions</Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search with transactionNo ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            showHeader={data.transactionDetails.length > 0}
            dataSource={data.transactionDetails.map((b) => ({
              key: b.transaction_number,
              ...b,
            }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
}

export default withPass(Transactions);
