import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import reportWebVitals from "./reportWebVitals";
import { initialServices } from "./services";

//init services
initialServices();

//react root element
const doc = document.getElementById("root");

ReactDOM.render(
  <>
    <App />
  </>,
  doc
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
