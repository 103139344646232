import { gql } from "@apollo/client";

export const get_bookings = gql`
  query Bookings(
    $where: BookingWhereInput
    $orderBy: [BookingOrderByWithRelationInput!]
    $take: Int
  ) {
    bookings(where: $where, orderBy: $orderBy, take: $take) {
      id
      member_id
      member {
        id
        image
        name
        phonenumber
      }
      practitioner_id
      practitioner {
        id
        image
        name
      }
      status
      user_id
      user {
        id
        name
        email
      }
      from_time
      created_at
      to_time
      updated_at
      clinic_id
      service_id
      service {
        id
        image
        name
      }

      member_note
      merchant_note
      service_helper {
        name
      }
    }
  }
`;

export const getBookingVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[] | string;
  searchText?: string | null;
  take: number;
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({
      member: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({
      practitioner: {
        is: {
          OR: [
            { name: { contains: searchText } },
            { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({
      service_helper: {
        is: {
          OR: [
            { name: { contains: searchText } },
            // { phonenumber: { contains: searchText } },
          ],
        },
      },
    });
    OR.push({ service: { is: { OR: [{ name: { contains: searchText } }] } } });
  }
  where.clinic_id = { equals: data.clinicId };
  where.from_time = { gte: data.startDate, lte: data.endDate };
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = 100;
  return variables;
};

export const get_clinic_members_booking_count = gql`
  query _count($by: [BookingScalarFieldEnum!]!, $where: BookingWhereInput) {
    groupByBooking(by: $by, where: $where) {
      _count {
        _all
      }
      clinic_id
      member_id
    }
  }
`;
export const getClinicMembersBookingCountVar = (data: {
  clinicId: string;
  memberIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.by = ["clinic_id", "member_id"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    created_at: { gte: data.startDate, lte: data.endDate },
    member_id: { in: data.memberIds },
  };
  return variables;
};

export const delete_one_booking = gql`
  mutation DeleteOneBooking($where: BookingWhereUniqueInput!) {
    deleteOneBooking(where: $where) {
      id
    }
  }
`;
export const getDeleteOneBookingVar = (bookingId: string) => ({
  where: { id: bookingId },
});
