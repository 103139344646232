import { gql } from "@apollo/client";

export const get_accounts = gql`
  query Accounts(
    $where: AccountWhereInput
    $orderBy: [AccountOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    accounts(where: $where, orderBy: $orderBy, take: $take,skip: $skip) {
      customer {
        id
        name
        phone_number
      }
      id
      lock
      balance
      account_number
      _count {
        transactions
      }
    }
  }
`;

export const getAccountsVariables = (data: {
  accountTypeCode: string;
  searchText?: string | null;
  take?: number;
  skip?: number
}) => {
  const { searchText, take = 50, skip = 0, accountTypeCode } = data;
  let variables: any = {},
    where: any = {};
  let filters: Array<{ key: string; value: any }> = [];
  if (searchText) {
    filters.push({ key: "account_number", value: { contains: searchText } });
    filters.push({
      key: "customer",
      value: {
        is: {
          OR: [
            {
              name: {
                contains: searchText,
              },
            },
            {
              phone_number: {
                contains: searchText,
              },
            },
          ],
        },
      },
    });
  }
  if (filters.length === 1) {
    const filter = filters[0];
    where[filter.key] = filter.value;
  } else if (filters.length > 1) {
    where = {
      OR: filters.map((f) => ({ [f.key]: f.value })),
    };
  } else {
    where = {};
  }
  variables.where = {
    ...where,
    account_type: {
      is: {
        code: {
          equals: accountTypeCode,
        },
      },
    },
  };
  variables.take = take;
  variables.skip = skip;
  variables.orderBy = [
    {
      balance: "desc",
    },
  ];
  return variables;
};

export const get_account_type = gql`
  query GetAccountType($where: AccountTypeWhereUniqueInput!) {
    getAccountType(where: $where) {
      _count {
        accounts
      }
    }
  }
`;

export const getAccountTypeVar = (code: string) => ({
  where: {
    code,
  },
});
export const get_trx_details = gql`
query TransactionDetails(
  $where: TransactionDetailWhereInput
  $take: Int
  $orderBy: [TransactionDetailOrderByWithRelationInput!]
  $skip: Int
) {
  transactionDetails(where: $where, take: $take, orderBy: $orderBy, skip: $skip) {
    transaction_type
    transaction_number
    balance
    created_at
    sender {
      customer {
        name
        phone_number
      }
    }
    recipient {
      customer {
        name
        phone_number
      }
    }
    transactions {
      comment
    }
  }
}
`;



export const getTrxDetailVar = (data: {
  code: string;
  startDate: Date;
  endDate: Date;
  searchText?: string | null;
  take?: number;
  skip?: number;
}) => {
  const { code, searchText, take = 50, startDate, endDate, skip = 0 } = data;
  let variables: any = {},
    where: any = {};
  where.created_at = { gte: startDate, lte: endDate };
  where.recipient = {
    is: { account_type: { is: { code: { equals: code } } } },
  };
  if (searchText) {
    where.transaction_number = { contains: searchText };
  }
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = take;
  variables.skip = skip;
  return variables;
};
export const get_trxs = gql`
query Transactions(
  $where: TransactionWhereInput
  $take: Int
  $skip: Int
  $orderBy: [TransactionOrderByWithRelationInput!]
) {
  transactions(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
    id
    account_id
    transaction_status
    transaction_type
    balance
    comment
    transaction_number
    created_at
    cash
    transaction_detail {
      sender {
        id
        customer {
          name
        }
      }
      recipient {
        id
        customer {
          name
        }
      }
    }
  }
}
`;
export const getTrxVar = (data: {
  accountId: string
  take?: number;
  skip?: number;
}) => {
  const { take = 20, skip = 0, accountId } = data;
  let variables: any = {},
    where: any = {};
  where.account_id = { equals: accountId }
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = take;
  variables.skip = skip;
  return variables;
};

export const get_account_by_type = gql`
  query GetNewAccount($phoneNumber: String!, $accountType: String!) {
    getNewAccount(phone_number: $phoneNumber, account_type: $accountType) {
      id
      balance
      account_number
      lock
      transactions {
        transaction_status
        transaction_number
        comment
        balance
        created_at
      }
      customer {
        name
        phone_number
      }
    }
  }
`;

export const getAccountByTypeVar = (
  phoneNumber: string,
  accountType: string
) => ({
  phoneNumber,
  accountType,
});


export const get_total_account_count = gql`
query _count($where: AccountWhereInput) {
  aggregateAccount(where: $where) {
    _count {
      id
    }
  }
}
`;

export const getTotalCountAccountVar = (args: { code: string, searchText?: string | null }) => {
  const { code, searchText } = args;
  let variables: any = {},
    where: any = {};
  let filters: Array<{ key: string; value: any }> = [];
  if (searchText) {
    filters.push({ key: "account_number", value: { contains: searchText } });
    filters.push({
      key: "customer",
      value: {
        is: {
          OR: [
            {
              name: {
                contains: searchText,
              },
            },
            {
              phone_number: {
                contains: searchText,
              },
            },
          ],
        },
      },
    });
  }
  if (filters.length === 1) {
    const filter = filters[0];
    where[filter.key] = filter.value;
  } else if (filters.length > 1) {
    where = {
      OR: filters.map((f) => ({ [f.key]: f.value })),
    };
  } else {
    where = {};
  }
  variables.where = {
    ...where,
    account_type: {
      is: {
        code: {
          equals: code,
        },
      },
    },
  };

  return variables;
}