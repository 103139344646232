import { isUndefined, omitBy } from "lodash";

export const formatMoney = (
  amount: number,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  if (!amount || amount === 0) {
    return "0";
  }
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    const negativeSign = amount < 0 ? "-" : "";
    amount = Math.abs(Number(amount) || 0);
    let i = parseInt(amount.toFixed(decimalCount)).toString();
    let j = i.length > 3 ? i.length % 3 : 0;
    let formatted =
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : "");
    return formatted.replace(".00", "");
  } catch (e) {
    console.log(e);
  }
  return "0";
};

export const uuidv4 = () => {
  return "xyyxxx-xxxx-4xxx-yyyxx-xxxxx-yyyyxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );
};

export const checkPhoneE164 = (phoneNumber: string) => {
  let phone: string | null = null;
  if (phoneNumber.startsWith("+959")) {
    phone = phoneNumber;
  } else if (phoneNumber.startsWith("09")) {
    phone = "+95" + phoneNumber.substring(1);
  } else if (phoneNumber.startsWith("959")) {
    phone = "+" + phoneNumber;
  } else if (phoneNumber.startsWith("9")) {
    phone = "+95" + phoneNumber;
  } else {
    phone = null;
  }
  // if (phoneNumber.startsWith('9')) {
  //     phone = "+95" + phoneNumber;
  // }
  return phone;
};


export const omitUndefined = <T extends object>(obj: T) => omitBy(obj, isUndefined);