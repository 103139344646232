import { gql } from "@apollo/client";

export const helpers = gql`
  query ServiceHelpers($where: ServiceHelperWhereInput) {
    serviceHelpers(where: $where) {
      name
      id
      created_at
      description
      status
      sort_order
    }
  }
`;
export const getHelpersVar = (clinicId: string) => ({
  where: {
    clinic_id: { equals: clinicId },
  },
});
export const create_one_helper = gql`
  mutation CreateOneServiceHelper($data: ServiceHelperCreateInput!) {
    createOneServiceHelper(data: $data) {
      id
    }
  }
`;

// export const getOneHelperVar = (id: string) => ({ where: { id } });

export const update_one_helper = gql`
  mutation UpdateOneServiceHelper(
    $data: ServiceHelperUpdateInput!
    $where: ServiceHelperWhereUniqueInput!
  ) {
    updateOneServiceHelper(data: $data, where: $where) {
      id
    }
  }
`;

export const getUpdateOneHelperVar = (
  id: string,
  data: any,
  connectors?: any
) => {
  let variables: any = {},
    update: any = {};
  variables.where = { id };
  if (typeof data === "object") {
    update = Object.assign(
      {},
      ...Object.keys(data).map((k, i) => ({ [k]: { set: data[k] } }))
    );
  }
  variables.data = update;
  if (connectors && typeof connectors === "object") {
    variables.data = { ...variables.data, ...connectors };
  }
  return variables;
};
