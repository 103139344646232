import { Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";

interface P {
  onChange: (e: any) => void;
  defaultValue: any;
  title: string;
}

const TextareaUpload = ({ defaultValue, onChange, title }: P) => {
  // console.log(defaultValue);
  // console.log(title);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editedContent, setEditedContent] = useState<string>(
    defaultValue || null
  );

  const openModal = () => {
    setIsModalVisible(true);
  };
  const handleSave = () => {
    if (onChange) {
      onChange(editedContent);
    }
    setIsModalVisible(false);
  };

  return (
    <>
      <span onClick={openModal}>
        <TextArea rows={5} value={editedContent} />
      </span>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleSave}
        onCancel={() => setIsModalVisible(false)}
      >
        <TextArea
          rows={5}
          value={editedContent}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setEditedContent(e.target.value)
          }
        />
      </Modal>
    </>
  );
};

export default TextareaUpload as any;
