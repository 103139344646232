

const bi_embeds: Array<{
    clinic: string
    code: string,
    link: string,
}> = [
        {
            clinic: "gtthequeen",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/72aa4201-2746-40dd-acc0-133a7128b747/page/OoV4D"
        },
        {
            clinic: "gtthequeen",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/601da302-2e46-43a1-ac9b-a59ae0cf7a8a/page/OoV4D"
        },
        {
            clinic: "gtdrmin",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/200a2a7a-85df-4c24-a5c1-53f065ab1757/page/OoV4D"
        },
        {
            clinic: "gtdrmin",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/d4c1a92b-f230-41ab-a511-4f3af53b1542/page/OoV4D"
        },
        {
            clinic: "gtpure",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/f54da130-0503-47f2-890b-f3e8c63fe50c/page/OoV4D"
        },
        {
            clinic: "gtpure",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/1af3b0cf-0a71-4024-bf90-5686233a92c7/page/OoV4D"
        },
        {
            clinic: "gtdrko",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/6af99232-738b-4af9-89f1-53da5c80a0e5/page/OoV4D"
        },
        {
            clinic: "gtdrko",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/103e9f99-5089-464c-a10d-c8dd6f7fe4f1/page/OoV4D"
        },
        {
            clinic: "gtlemon",
            code: "customer",
            link: "https://lookerstudio.google.com/embed/reporting/6cafc83f-3bc8-47c2-9674-a99022b070bc/page/OoV4D"
        },
        {
            clinic: "gtlemon",
            code: "service",
            link: "https://lookerstudio.google.com/embed/reporting/abca5eef-c47c-4ec5-af4e-3a01d5242eda/page/OoV4D"
        }
    ];

export const getBI = (clinic: string, code: string) => {
    const find = bi_embeds.findIndex(be => be.clinic.toLocaleLowerCase() === clinic.toLocaleLowerCase() && be.code === code);
    if (find != -1) {
        return bi_embeds[find];
    }
    return null;
}