import React, { useCallback, useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import { ApolloResult, Clinic, Practitioner, Service } from "../../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { addDays, endOfMonth, format, startOfMonth, subDays } from "date-fns";
import { useLazyQuery, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import MultiSelect from "../../components/multi_select";
import {
  getPractitionerCheckInCountVar,
  getPractitionersVar,
  get_practitioner_checkin_count,
  get_practitioners,
} from "../../graphql/practitioner";
import { ExportExcel } from "../../helpers/excel";
import { FileExcelFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import paths from "../../routes/paths";
interface P {
  currentClinic?: Clinic;
}

const TopPractitionerReport = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [exporting, setExporting] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const variables = getPractitionersVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take: 150,
    status: "ACTIVE",
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"practitioners", Practitioner[]>
  >(get_practitioners, { variables });

  const [getCheckInCount, { data: checkInData, loading: cload }] = useLazyQuery<
    ApolloResult<
      "groupByCheckIn",
      Array<{ _count: { _all: number }; practitioner_id: string }>
    >
  >(get_practitioner_checkin_count);

  const checking = cload;
  const checkInCount = Object.assign(
    {},
    ...(checkInData?.groupByCheckIn || []).map((a) => ({
      [a.practitioner_id]: a._count._all,
    }))
  );
  const load = useCallback(
    (v: { practitionerIds: string[]; startDate: Date; endDate: Date }) => {
      getCheckInCount({
        variables: getPractitionerCheckInCountVar({
          ...v,
          clinicId,
          status: "CHECKOUT",
        }),
      });
    },
    [clinicId, getCheckInCount]
  );
  useEffect(() => {
    if (data?.practitioners && data.practitioners.length > 0) {
      const practitionerIds = data.practitioners.map((p) => p.id);
      const { startDate, endDate } = ranges;
      load({ practitionerIds, startDate, endDate });
    }
  }, [data, ranges, load]);

  const handleExport = async () => {
    const fileName =
      "top_practitioner" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    setExporting(true);
    const exportData: any = data?.practitioners.map((practitioner) => ({
      Name: practitioner.name,
      CheckOut: checkInCount[practitioner.id] || 0,
    }));
    exportData.sort((a: any, b: any) => b.CheckOut - a.CheckOut);

    const totalCheckInCount =
      data?.practitioners.reduce(
        (total, practitioner) => total + (checkInCount[practitioner.id] || 0),
        0
      ) || 0;
    exportData.push({
      Name: "Total",
      CheckOut: totalCheckInCount,
    });

    ExportExcel(exportData, fileName);
    setExporting(false);
  };

  const practitionerOnClick = (practitioner: Practitioner) => {
    if (practitioner.id == null) return;
    navigate(paths.getPractitionerTreatmentReport(practitioner.id), {
      state: { ranges },
    });
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    if (data?.practitioners && data.practitioners.length > 0) {
      const practitionerIds = data.practitioners.map((p) => p.id);
      load({ practitionerIds, startDate, endDate });
    }
  };
  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: false,
      sorter: (a: Practitioner, b: Practitioner) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      render: (text: number, record: Service) => (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <img
            src={
              record.image
                ? record.image
                : "https://i.pinimg.com/474x/e7/c3/f4/e7c3f4a076b8472e0b1bd9c00a847f7f.jpg"
            }
            height={50}
            width={50}
            style={{ objectFit: "cover", objectPosition: "center" }}
            alt=""
          />
          <p>{record.name}</p>
        </div>
      ),
    },
    {
      title: "CheckIn",
      dataIndex: "check_in",
      key: "check_in",
      sorter: (a: Practitioner, b: Practitioner) =>
        (checkInCount[b.id] || 0) - (checkInCount[a.id] || 0),
      sortOrder: "ascend",
      render: (text: number, record: Service) => checkInCount[record.id] || 0,
    },
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Top Practitioner Report</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${
                props.currentClinic?._count.practitioners || 0
              } practitioners...`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
      <Col span={2}>
        <Button
          loading={exporting}
          icon={<FileExcelFilled />}
          onClick={handleExport}
        >
          Export
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={checking}
            showHeader={data.practitioners.length > 0}
            //components={{ body: { cell: EditableCell, } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={data.practitioners.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="clickable-row"
            pagination={false}
            onRow={(record: Practitioner) => {
              return {
                onClick: () => {
                  practitionerOnClick(record);
                },
              };
            }}
          />
        </Form>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(TopPractitionerReport);
