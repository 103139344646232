import { gql } from "@apollo/client";
import { SystemUserMutate } from "../store";
import { Role } from "../store/state";

export const get_users = gql`
  query Users(
    $orderBy: [UserOrderByWithRelationInput!]
    $where: UserWhereInput
  ) {
    users(orderBy: $orderBy, where: $where) {
      id
      name
      role
      active
      display_name
    }
  }
`;

export const getUsersVar = (clinicId: string, role?: Role) => ({
  where: {
    clinic_id: { equals: clinicId },
    ...(role != null && { role: { equals: role } }),
  },
  orderBy: [{ name: "asc" }],
});

export const update_one_user = gql`
  mutation UpdateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      id
    }
  }
`;

type UserMutateInput = Partial<Omit<SystemUserMutate, "name" | "id">>;

export const getUpdateOneUserVar = (id: string, data: UserMutateInput) => {
  return {
    where: {
      id,
    },
    data: {
      ...(data?.display_name != null && {
        display_name: { set: data.display_name },
      }),
      ...(data?.role != null && { role: { set: data.role } }),
      ...(data?.password != null &&
        data?.password != "" && { password: { set: data.password } }),
      ...(data?.active != null && { active: { set: data.active } }),
    },
  };
};

export const create_one_user = gql`
  mutation CreateOneUser($data: UserCreateInput!) {
    createOneUser(data: $data) {
      id
    }
  }
`;

export const delete_one_user = gql`
  mutation DeleteOneUser($where: UserWhereUniqueInput!) {
    deleteOneUser(where: $where) {
      id
    }
  }
`;

export const getDeleteOneUserVar = (id: string) => ({
  where: {
    id,
  },
});
