import { gql } from "@apollo/client";

export const get_members = gql`
  query Members(
    $where: MemberWhereInput
    $orderBy: [MemberOrderByWithRelationInput!]
    $take: Int
  ) {
    members(where: $where, orderBy: $orderBy, take: $take) {
      id
      name
      phonenumber
      member_id
      status
      note
      image
      medical_history
      aesthetic_history
      created_at
      _count {
        bookings
        checkin
        clinics
        orders
      }
    }
  }
`;

export const getMembersVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[];
  searchText?: string | null;
  take: number;
}) => {
  const { status = [], searchText, take } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({ name: { contains: searchText } });
    OR.push({ phonenumber: { contains: searchText } });
    OR.push({ member_id: { contains: searchText } });
  }
  where.clinics = { some: { id: { equals: data.clinicId } } };
  //where.created_at = { gte: data.startDate, lte: data.endDate };
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = take;
  return variables;
};

export const get_members_2 = gql`
  query GetMembers(
    $clinicId: String!
    $version: String
    $search: String
    $cursor: String
  ) {
    getMembers(
      clinicId: $clinicId
      version: $version
      search: $search
      cursor: $cursor
    ) {
      image
      name
      status
      member_id
      phonenumber
      id
      created_at
    }
  }
`;
export const getMembersVar2 = (data: {
  clinicId: string;
  version: string;
  search: string | null;
  cursor: string | null;
}) => ({ ...data });
export const get_member_svc_bags = gql`
  query Service_package(
    $where: MemberServiceBagWhereInput
    $orderBy: [MemberServiceBagOrderByWithRelationInput!]
  ) {
    memberServiceBags(where: $where, orderBy: $orderBy) {
      id
      original_count
      remaining_count
      service_id
      service {
        id
        name
      }
      member_id
      service_package_id

      service_package {
        id
        name
      }
      status
    }
  }
`;

export const getMemberSvcBagsVar = (data: {
  clinicId: string;
  memberIds: string[];
}) => {
  let variables: any = {},
    where: any = {};
  where.service = { is: { clinic_id: { equals: data.clinicId } } };
  where.member_id = { in: data.memberIds };
  variables.where = where;
  variables.orderBy = [{ remaining_count: "desc" }];
  return variables;
};
export const get_member_srv_pkgs = gql`
  query MemberServicePackages(
    $where: MemberServicePackageWhereInput
    $orderBy: [MemberServicePackageOrderByWithRelationInput!]
  ) {
    memberServicePackages(where: $where, orderBy: $orderBy) {
      id
      count
      member_id
      status
      service_package_id
      service_package {
        id
        name
      }
    }
  }
`;

export const getMemberSrvPkgsVar = (data: {
  clinicId: string;
  memberIds: string[];
}) => {
  let variables: any = {},
    where: any = {};
  where.service_package = { is: { clinic_id: { equals: data.clinicId } } };
  where.member_id = { in: data.memberIds };
  variables.where = where;
  variables.orderBy = [{ count: "desc" }];
  return variables;
};

export const get_member_bookings = gql`
  query Bookings(
    $where: BookingWhereInput
    $orderBy: [BookingOrderByWithRelationInput!]
  ) {
    bookings(where: $where, orderBy: $orderBy) {
      id
      member_id
      member_note
      practitioner_id
      service_id
      service {
        id
        name
      }
      from_time
      status
    }
  }
`;
export const getMemberBookingVar = (data: {
  clinicId: string;
  memberIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.where = {
    clinic_id: { equals: data.clinicId },
    from_time: { gte: data.startDate, lte: data.endDate },
    member_id: { in: data.memberIds },
  };
  variables.orderBy = [{ from_time: "desc" }];
  return variables;
};
export const get_member_checkins = gql`
  query CheckIns(
    $where: CheckInWhereInput
    $orderBy: [CheckInOrderByWithRelationInput!]
  ) {
    checkIns(where: $where, orderBy: $orderBy) {
      id
      in_time
      out_time
      clinic_id
      practitioner_id
      status
      service_id
      service {
        name
        id
      }
      member_id
    }
  }
`;
export const getMemberCheckInVar = (data: {
  clinicId: string;
  memberIds: string[];
  startDate: Date;
  endDate: Date;
}) => {
  let variables: any = {};
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    member_id: { in: data.memberIds },
  };
  variables.orderBy = [{ in_time: "desc" }];
  return variables;
};

export const get_member_detail = gql`
  query Member(
    $where: MemberWhereUniqueInput!
    $bookingsWhere2: BookingWhereInput
    $checkinWhere2: CheckInWhereInput
    $ordersWhere2: OrderWhereInput
    $clinicMembersWhere2: ClinicMemberWhereInput
    $memberServiceBagsWhere2: MemberServiceBagWhereInput
  ) {
    member(where: $where) {
      id
      name
      phonenumber
      member_id
      image
      bookings(where: $bookingsWhere2) {
        member_id
        practitioner_id
        practitioner {
          id
          image
          name
        }
        status
        user_id
        user {
          id
          name
          email
        }
        from_time
        created_at
        to_time
        updated_at
        clinic_id
        service_id
        service {
          id
          image
          name
        }
        member_note
        merchant_note
        service_helper {
          id
          name
        }
      }
      checkin(where: $checkinWhere2) {
        id
        clinic_id
        in_time
        created_at
        out_time
        status
        member_id
        images {
          name
          image
        }
        practitioner_id
        practitioner {
          name
          id
        }
        service_id
        service {
          name
          id
        }
        merchant_note
        booking {
          service_helper {
            id
            name
          }
        }
      }
      orders(where: $ordersWhere2) {
        id
        discount
        net_total
        order_id
        status
        total
        clinic_id
        created_at
        payment_method
        tax
        member_id
        member {
          name
          id
        }
        user_id
        user {
          id
          name
        }
        credit_balance
        payment_status
      }
      member_service_bags(where: $memberServiceBagsWhere2) {
        id
        original_count
        remaining_count
        service_id
        service {
          id
          name
        }
        service_package {
          id
          name
        }
      }
      clinic_members(where: $clinicMembersWhere2) {
        image
        id
        member_id
        name
        phonenumber
        note
      }
    }
  }
`;

export const getMemberDetailVar = (data: {
  clinicId: string;
  memberId: string;
}) => {
  let variables: any = {},
    clinicFilter = { clinic_id: { equals: data.clinicId } };
  variables.where = { id: data.memberId };
  variables.bookingsWhere2 = clinicFilter;
  variables.checkinWhere2 = clinicFilter;
  variables.clinicMembersWhere2 = clinicFilter;
  variables.memberServiceBagsWhere2 = { service: { is: clinicFilter } };
  variables.ordersWhere2 = {
    ...clinicFilter,
    user: {
      is: {
        role: {
          equals: "ADMIN",
        },
      },
    },
    status: {
      not: {
        equals: "CANCEL",
      },
    },
  };
  return variables;
};

export const create_many_member = gql`
  mutation CreateManyMember(
    $data: [MemberCreateManyInput!]!
    $skipDuplicates: Boolean
  ) {
    createManyMember(data: $data, skipDuplicates: $skipDuplicates) {
      count
    }
  }
`;

export const getCreateManyMemberVar = (
  data: any[],
  skipDuplicates: boolean
) => {
  return { data, skipDuplicates };
};

export const many_member_join_clinic = gql`
  mutation UpdateOneClinic(
    $data: ClinicUpdateInput!
    $where: ClinicWhereUniqueInput!
  ) {
    updateOneClinic(data: $data, where: $where) {
      id
      _count {
        members
      }
    }
  }
`;

export const getManyMemberJoinClinicVar = (
  members: Array<{ phonenumber: string }>,
  clinicId: string
) => {
  let variables: any = {};
  variables.where = { id: clinicId };
  variables.data = {
    members: { connect: members },
  };
  return variables;
};
export const delete_many_member = gql`
  mutation DeleteManyMember($where: MemberWhereInput) {
    deleteManyMember(where: $where) {
      count
    }
  }
`;

export const getDeleteManyMemberVar = (members: string[]) => ({
  where: {
    phonenumber: {
      in: members,
    },
  },
});

export const update_member_bag = gql`
  mutation UpdateOneMemberServiceBag(
    $data: MemberServiceBagUpdateInput!
    $where: MemberServiceBagWhereUniqueInput!
    $createOneSystemLogData2: SystemLogCreateInput!
  ) {
    updateOneMemberServiceBag(data: $data, where: $where) {
      id
    }
    createOneSystemLog(data: $createOneSystemLogData2) {
      id
    }
  }
`;

export const getUpdateMemberBagVar = (data: {
  bagId: string;
  remainCount: number;
  originCount: number;
  log: {
    code: string;
    log_id: string;
    metadata: any;
    user: any;
  };
}) => ({
  data: {
    remaining_count: {
      set: data.remainCount,
    },
    original_count: {
      set: data.originCount,
    },
  },
  where: {
    id: data.bagId,
  },
  createOneSystemLogData2: { ...data.log },
});
