// Import the functions you need from the SDKs you need
import * as app from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBnJcpWcrehALdz8jQj7_jnoS_pZOwqV5k",
  authDomain: "aesthetics-441d8.firebaseapp.com",
  projectId: "aesthetics-441d8",
  storageBucket: "aesthetics-441d8.appspot.com",
  messagingSenderId: "1033902244249",
  appId: "1:1033902244249:web:b7f598a9d955af6a911308",
  measurementId: "G-B1TDWGHHKB"
};
export const initFirebase = () => {
  const App = app.initializeApp(firebaseConfig);
  getAnalytics(App);
}