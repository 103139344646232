import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AppState, Clinic, PSConfig } from "../store";
import Cookies from "js-cookie";
import { getPassApiToken } from "../api/pass_api";
const withPass = (WrappedComponent: any) => {
    interface P {
        currentClinic?: Clinic
        passConfig?: PSConfig
    }
    const HOC = (props: P) => {
        const _clinic = props.currentClinic;
        const _token = Cookies.get('p_token');
        useEffect(() => {
            if (_clinic?.pass && !_token) {
                const { refresh_token: rToken, refresh_token_url: rUrl } = JSON.parse(_clinic.pass) as { refresh_token_url?: string, refresh_token?: string, id?: string };
                if (rToken && rUrl) {
                    getPassApiToken(rUrl, rToken);
                }
            }
        }, [_clinic, _token]);

        return <WrappedComponent {...props} />;
    };
    const mapStateToProps = (state: AppState) => {
        let passConfig: PSConfig | undefined = undefined;
        if (state.clinicState.selected && state.clinicState.selected.pass) {
            passConfig = JSON.parse(state.clinicState.selected.pass) as { refresh_token_url?: string, refresh_token?: string, id?: string };
        }
        return {
            passConfig,
            currentClinic: state.clinicState.selected,
        };
    };
    return connect(mapStateToProps, {})(HOC);
};

export default withPass;
