import React, { useState } from 'react'
import withClinic from '../hooks/with_clinic';
import { ApolloResult, Clinic, Order, Practitioner, Product, ProductStockItem, Service, ServiceStockItem, ServiceStockItemUsage, } from '../store';
import { Row, Col, Typography, Empty } from 'antd';
import Search from 'antd/lib/input/Search';
import { Form } from 'antd';
import { Table } from 'antd';
import { addDays, format, subDays } from 'date-fns';
import ButtonGroup from 'antd/lib/button/button-group';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/loading_indicator';
import Error from '../components/apollo_error';
import { getServicePsiUsageVar, get_service_psi_usage } from '../graphql/service';
interface P {
    currentClinic?: Clinic
}

const ServiceStockItemUsagePage = (props: P) => {
    const clinicId = props.currentClinic?.id || "";
    // const clinicId = "clhobuntc0002s6019ajmqkcj";
    const [searchText, setSearchText] = useState<string | null>(null);
    const [form] = Form.useForm();
    const variables = getServicePsiUsageVar({ clinicId, searchText, take: 150 });
    const { loading, data, error } = useQuery<ApolloResult<"serviceProductStockItemUsages", ServiceStockItemUsage[]>>(get_service_psi_usage, { variables })
    if (loading) return <LoadingSpinner />
    if (error) return <Error error={error} />
    if (!data) return <Empty />
    const columns: any[] = [
        {
            title: 'Service',
            dataIndex: "service",
            key: 'service',
            editable: false,
            sorter: (a: ServiceStockItemUsage, b: ServiceStockItemUsage) => a.service.name.toLowerCase().localeCompare(b.service.name.toLowerCase()),
            render: (v: ServiceStockItemUsage, r: ServiceStockItemUsage) => {
                return r.service.name
            },
        },
        {
            title: 'Product',
            dataIndex: "product",
            key: "product",
            editable: false,
            sorter: (a: ServiceStockItemUsage, b: ServiceStockItemUsage) => a.service_product_stock_item.name.toLowerCase().localeCompare(b.service_product_stock_item.name.toLowerCase()),
            render: (v: ServiceStockItemUsage, r: ServiceStockItemUsage) => {
                return r.service_product_stock_item.name
            },
        },
        {
            title: 'Usage',
            dataIndex: "usage",
            key: 'usage',
            sorter: (a: ServiceStockItemUsage, b: ServiceStockItemUsage) => Number(a.amount_per_service) - Number(b.amount_per_service),
            editable: false,
            render: (v: ServiceStockItemUsage, r: ServiceStockItemUsage) => {
                return r.amount_per_service;
            },
        },
        {
            title: 'CreatedAt',
            dataIndex: "created_at",
            key: 'created_at',
            editable: false,
            sorter: (a: ServiceStockItemUsage, b: ServiceStockItemUsage) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (v: ServiceStockItemUsage, r: ServiceStockItemUsage) => {
                return format(new Date(r.created_at), 'dd MMM,yyyy');
            },
        },
    ];
    const control = (
        <Row style={{ marginTop: -18 }}>
            <Col flex={4}>
                <Row >
                    <Col span={8}>
                        <Typography level={2}>Product Stock Item Usages</Typography>

                    </Col>
                    <Col span={8} offset={8}>
                        <Search
                            defaultValue={searchText || undefined}
                            placeholder="search ...."
                            allowClear
                            size="middle"
                            onSearch={(val: any) => setSearchText(val)}
                        />

                    </Col>
                </Row>
            </Col>
            {/* <Col flex={0}>
               
            </Col> */}
        </Row>
    );
    const context = (
        <Row style={{ marginTop: 8 }}>
            <Col span={24}>
                <Form form={form} component={false}>
                    <Table
                        showHeader={data.serviceProductStockItemUsages.length > 0}
                        //components={{ body: { cell: EditableCell, } }}
                        // expandable={{ expandedRowRender, onExpandedRowsChange }}
                        dataSource={data.serviceProductStockItemUsages.map(b => ({ key: b.id, ...b }))}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </Col>
        </Row>
    );
    return (
        <React.Fragment>
            {control}
            {context}
        </React.Fragment>
    )
}

export default withClinic(ServiceStockItemUsagePage);
