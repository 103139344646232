import { ApolloError } from "@apollo/client";

export interface AppState {
  authState: AuthState;
  antdState: AntdState;
  clinicState: ClinicState;
}

export type AuthState =
  | {
      status: "none" | "loading";
    }
  | {
      status: "loggedIn" | "error";
      userInfo?: User;
      error?: string;
    };
export interface ClinicState {
  selected?: Clinic;
  clinics: Clinic[];
}
export interface AntdState {
  openSideToogle: boolean;
}
export interface UserInfo {
  userId: string;
  token: string;
}

export interface User {
  email: string;
  userId: string;
  name: string;
  photo?: string;
  roles: string[];
  clinics: string[];
}

export interface ApolloResponse<T> {
  data?: T;
  loading: boolean;
  error: ApolloError;
}

export type ApolloResult<QueryKey extends string, T> = {
  [key in QueryKey]: T;
};
export interface Company {
  id: string;
  name: string;
  description: string;
  address: string;
  phonenumber: string;
  active: boolean;
  clinics: Clinic[];
  created_at: Date;
  updated_at: Date;
}
export interface ClinicCount {
  members: number;
  bookings: number;
  brand: number;
  checkin: number;
  orders: number;
  practitioners: number;
}
export interface Clinic {
  id: string;
  logo: string;
  name: string;
  opening_in: number;
  closing_in: number;
  printer_logo?: string;
  phonenumber?: string;
  hostname?: string;
  code?: string;
  pass?: string;
  address?: string;
  description?: string;
  compnay_id: string;
  company: Company;
  _count: ClinicCount;
  images: GTImage[];
  bank_accounts: BankAccount[];
}
export interface MemberCount {
  bookings: number;
  checkin: number;
  clinics: number;
  orders: number;
}
export interface Member {
  [x: string]: any;
  id: string;
  image?: string;
  name: string;
  phonenumber: string;
  member_id: string;
  status: string;
  metadata?: string;
  medical_history?: string;
  cursor: string;
  aesthetic_history?: string;
  created_at: Date;
  _count: MemberCount;
  bookings: Booking[];
  checkin: CheckIn[];
  orders: Order[];
  member_service_bags: MemberServiceBag[];
  clinic_members: clinicMembers[];
}
export interface clinicMembers {
  id: string;
  image: string;
  member_id: string;
  name: string;
  note: string;
  phonenumber: string;
}

export interface ClinicMember {
  name: string;
  id: string;
  practitioner?: Pick<Practitioner, "name" | "id">;
}

export interface ClinicMemberUpdate {
  id: string;
}

export interface ServiceCount {
  bookings: number;
  checkins: number;
}
export interface Service {
  id: string;
  name: string;
  _count: ServiceCount;
  image: string;
  service_id: string;
  clinic_id: string;
  status: string;
  price: string | number;
  original_price: string | number;
  tax: string | number;
  sort_order: number;
  duration: number;
  max_duration_count: number;
  description?: string;
  created_at: Date;
  ratings: GTRating[];
  commission?: string | number;
  practitioners: Practitioner[];
  service_types: ServiceType[];
  rooms: ServiceRoom[];
  form_type_id?: string;
}
export interface ServiceType {
  id: string;
  name: string;
}
export interface ServicePackage extends Service {
  isLock: boolean;
  expire_day: number;
}
export type BookingStatus =
  | "REQUEST"
  | "BOOKED"
  | "CHECKIN"
  | "CHECKOUT"
  | "MEMBER_CANCEL"
  | "MERCHANT_CANCEL"
  | "NO_SHOW";
export type CheckInStatus = "CHECKIN" | "CHECKOUT";
export type GTStatus = "ACTIVE" | "INACTIVE" | "CANCEL" | "DONE";
export interface Booking {
  _count: any[];
  id: string;
  member_id: string;
  member: Member;
  practitioner_id: string;
  practitioner: Practitioner;
  from_time: Date;
  to_time: Date;
  created_at: Date;
  updated_at: Date;
  clinic_id: string;
  clinic: Clinic;
  user_id: string;
  user: User;
  status: BookingStatus;
  member_note?: string;
  merchant_note?: string;
  service_id: string;
  service: Service;
  service_helper: ServiceHelper;
}
export interface ServiceHelper {
  name: string;
}
export interface GTRating {
  rate: number;
  note?: string;
  member: Member;
  created_at: Date;
}
export interface CheckIn {
  id: string;
  in_time: Date;
  out_time: Date;
  clinic_id: string;
  status: CheckInStatus;
  created_at: Date;
  member_id: string;
  member: Member;
  practitioner_id: string;
  isUsePurchaseService: boolean;
  practitioner: Practitioner;
  merchant_note?: string;
  service_id: string;
  booking_id?: string;
  service: Service;
  images: GTImage[];
  booking: Booking;
}

export interface Order {
  id: string;
  discount: string | number;
  net_total: string | number;
  order_id: string;
  status: GTStatus;
  total: string | number;
  clinic_id: string;
  clinic: Clinic;
  created_at: Date;
  payment_method: string;
  payment_detail?: string;
  payment_status: "PAID" | "UNPAID" | "PARTIAL_PAID";
  credit_balance: string;
  balance: string;
  tax: string | number;
  member_id: string;
  member: Member;
  user_id: string;
  user: User;
  order_items: any[];
}
export interface OrderItem {
  id: string;
  quantity: number;
  total: string | number;
  tax: string | number;
  price: string | number;
  original_price: string | number;
  service_id?: string;
  service?: Service;
  service_package_id?: string;
  service_package?: ServicePackage;
  product_stock_item_id?: string;
  product_stock_item?: ProductStockItem;
}

export interface OrderSummary {
  total_records: number;
  total_net_amount: number;
  total_balance: number;
  total_credit_balance: number;
  payment_status: "PAID" | "UNPAID" | "PARTIAL_PAID";
  seller_id?: string;
}

export interface ProductBrand {
  id: string;
  name: string;
  image: string;
}
export interface GTImage {
  id: string;
  image: string;
  name: string;
  note?: string;
}
export interface Measurement {
  id: string;
  name: string;
  description: string;
}
export interface Product {
  id: string;
  name: string;
  description?: string;
  sort_order: number;
  status: string;
  brand_id: string;
  brand: ProductBrand;
  measurement_id: string;
  measurement: Measurement;
  measurement_amount: string | number;
  created_at: Date;
  images: GTImage[];
}
export interface PractitionerCount {
  id: number;
  bookings: number;
  checkins: number;
}
export interface Practitioner {
  id: string;
  name: string;
  phonenumber: string;
  metadata: string;
  image?: string;
  status: string;
  sex: string;
  created_at: Date;
  clinic_id: string;
  _count: PractitionerCount;
  leaves: GTLeave[];
  services: PractitionerService[];
  // services:Service
}
export interface PractitionerService {
  id: string;
  name: string;
  duration: string;
  image: string;
}
export interface ProductStockItem {
  id: string;
  name: string;
  price: string | number;
  sku?: string;
  sort_order: number;
  status: string;
  stock: string | number;
  stock_control_unit: string;
  supply_price: string | number;
  tax: string | number;
  service_stock: string | number;
  clinic_id: string;
  created_at: Date;
  original_price: string | number;
  images: GTImage[];
  product_id: string;
  product: Product;
}
export interface ServiceStockItem {
  id: string;
  name: string;
  status: string;
  stock: string | number;
  amount_per_product: string | number;
  product_stock_item_id: string;
  product_stock_item: ProductStockItem;
  created_at: Date;
}
export interface ServiceStockItemUsage {
  id: string;
  amount_per_service: string | number;
  service_id: string;
  service: Service;
  created_at: Date;
  service_product_stock_item_id: string;
  service_product_stock_item: ServiceStockItem;
}
export interface MemberServiceBag {
  id: string;
  original_count: number;
  remaining_count: number;
  service_id: string;
  service: Service;
  member_id: string;
  service_package_id?: string;
  service_package?: ServicePackage;
  status: string;
}
export interface MemberServicePackage {
  id: string;
  count: number;
  service_package_id: string;
  service_package: ServicePackage;
}

export interface OfferCategory {
  id: string;
  name: string;
  sort_order: number;
  status: "ACTIVE" | "INACTIVE";
  image?: string;
  description?: string;
  created_at: Date;
}
export interface Offer {
  id: string;
  name: string;
  image?: string;
  sort_order: number;
  description?: string;
  hight_light?: string;
  term_and_condition?: string;
  metadata?: string;
  expired_date?: Date;
  created_at: Date;
  category_id?: string;
  status: "ACTIVE" | "INACTIVE";
  category?: OfferCategory;
  images: GTImage[];
}
export interface ServiceTypeCategory {
  id: string;
  name: string;
  image?: string;
  is_private: boolean;
  description?: string;
  status: "ACTIVE" | "INACTIVE";
  created_at: Date;
  order: number;
  sale_channel?: string;
}
export interface GTNotificationCount {
  members: number;
}
export interface GTNotification {
  id: string;
  image?: string;
  link_url?: string;
  metadata?: string;
  description?: string;
  hight_light?: string;
  status: "ACTIVE" | "INACTIVE";
  title: string;
  type: "INFO" | "PROMO";
  created_at: Date;
  _count?: GTNotificationCount;
}
export interface GTLeave {
  id: string;
  status: "PENDING" | "APPROVE" | "REJECTED";
  from_time: Date;
  to_time: Date;
  practitioner_id: string;
  practitioner?: Practitioner;
  created_at: Date;
  remark?: string;
}
export interface SystemLog {
  id: string;
  code: string;
  log_id: string;
  log_type: "TRACE" | "DEBUG" | "INFO" | "WARN" | "ERROR" | "FATAL";
  metadata?: string;
  user: User;
  created_at: Date;
}

export interface Bank {
  id: string;
  logo?: string;
  name: string;
  code: string;
  created_at: Date;
}

export interface BankAccount {
  id: string;
  clinic_id: string;
  clinic: Clinic;
  account_type_id: string;
  account_type: Bank;
  account_number: string;
  account_name: string;
  created_at: Date;
}

export interface ServiceRoom {
  id: string;
  name: string;
  description?: string;
  clinic_id?: string;
  clinic?: Clinic;
  services: Service[];
  status: "ACTIVE" | "INACTIVE";
  sort_order: number;
  created_at: Date;
}

export interface ServiceHelper {
  id: string;
  name: string;
  description?: string;
  clinic_id?: string;
  clinic?: Clinic;
  services: Service[];
  status: "ACTIVE" | "INACTIVE";
  sort_order: number;
  created_at: Date;
}
export interface PSCustomer {
  id: string;
  name: string;
  phone_number: string;
}
export interface PSAccount {
  id: string;
  lock: boolean;
  is_main: boolean;
  account_number: string;
  balance: string | number;
  max_balance: string | number;
  created_at: Date;
  customer_id: string;
  customer: PSCustomer;
  _count: PSAccountCount;
  transactions: PSTransaction[];
}
export interface PSAccountCount {
  transactions: number;
}
export interface PSAccountType {
  id: string;
  code: String;
  logo: string;
  logo_sm: string;
  name: string;
  _count: PSAccountTypeCount;
}
export interface PSAccountTypeCount {
  accounts: number;
}

export interface PSConfig {
  id?: string;
  refresh_token_url?: string;
  refresh_token?: string;
}

export interface PSTransactionDetial {
  transaction_type: string;
  transaction_number: string;
  balance: string | number;
  sender: PSAccount;
  recipient: PSAccount;
  created_at: Date;
  transactions: Array<Pick<PSTransaction, "comment">>;
}

export interface PSTransaction {
  id: string;
  transaction_status: string;
  account_id: string;
  balance: string;
  comment?: string;
  created_at: Date;
  transaction_number: string;
  transaction_detail: PSTransactionDetial;
}

export interface SystemUser {
  id: string;
  name: string;
  display_name: string;
  role: Role;
  active: boolean;
}

export interface SystemUserMutate extends SystemUser {
  password: string;
}

export type SystemUserCreate = Omit<SystemUserMutate, "id">;

export enum Role {
  ADMIN = "ADMIN",
  MANAGER = "MANAGER",
  SUPERVISOR = "SUPERVISOR",
  EMPLOYEE = "EMPLOYEE",
  ACCOUNT = "ACCOUNT",
  DOCTOR = "DOCTOR",
  SELLER = "SELLER",
}

export type Exact<A, B> = A extends B ? (B extends A ? A : never) : never;

export interface ServiceFormType {
  id: string;
  name: string;
  legal_desc?: string;
  form_type: string;
  description?: string;
  status: "ACTIVE" | "INACTIVE";
  terms: ServiceFormTerm[];
  consent_image?: string;
  consent_sign: string; //right,left,center
}

export interface ServiceFormTerm {
  id: string;
  term: string;
  status: "ACTIVE" | "INACTIVE";
  type: "UNIT" | "LABEL";
}

export interface SystemData {
  id: string;
  code: string;
  metadata?: string;
  description?: string;
}
