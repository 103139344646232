import React, { useEffect, useState } from "react";
import { Select, Tag } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";

interface SOption {
  value: string;
  label: string;
}

interface P {
  options: SOption[];
  onChange: (val: string[]) => void;
  selected: string[];
  placeholder?: string;
}

const C = (props: P) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(props.selected);
  const optDic = Object.assign(
    {},
    ...props.options.map((x) => ({ [x.value]: x.label }))
  );

  useEffect(() => {
    if (props.selected) {
      setSelectedItems(props.selected);
    }
  }, [props.selected]);

  const handleChange = (v: string[]) => {
    setSelectedItems(v);
    props.onChange(v);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3 }}
      >
        {optDic[value]}
      </Tag>
    );
  };

  return (
    <Select
      mode="multiple"
      size="large"
      placeholder={props.placeholder || ""}
      value={selectedItems}
      tagRender={tagRender}
      onChange={handleChange}
      style={{ width: "100%", minWidth: "50%", minHeight: "30%" }}
      showSearch={true} // Enable search
      filterOption={(input:string, option:any) =>
        option?.children.toLowerCase().includes(input.toLowerCase())
      } // Filter options based on search input
      optionFilterProp="children" // Prop to filter by
    >
      {props.options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default C;