import {
  EditOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Switch,
  Table,
  Typography
} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import Search from "antd/lib/input/Search";
import React, { useEffect, useState } from "react";
import Error from "../../components/apollo_error";
import DyanmicPoupList from "../../components/dynamic_list_popup";
import ImagUpload from "../../components/image_upload";
import LoadingSpinner from "../../components/loading_indicator";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Clinic,
  Offer,
  ServiceFormType
} from "../../store";

import { Select, message } from "antd";
import { createOneServiceFormTypeVar, create_one_service_form_type, getServiceFormTypeVar, get_service_form_types, updateOneServiceFormTypeVar, update_one_service_form_type } from "../../graphql/service_form";
import { omitUndefined } from "../../helpers/utils";
const { TextArea } = Input;
const { Paragraph } = Typography;

interface P {
  currentClinic?: Clinic;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea" | "select" | "img" | "popup-list";
  record: ServiceFormType;
  options?: any[];
  getOptions?: (v: ServiceFormType) => any,
  required: boolean;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  required,
  options,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  getOptions,
  ...restProps
}) => {
  let inputNode: any = <Input />;
  if (inputType === "number") {
    inputNode = <InputNumber />;
  }
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.status === "ACTIVE"} />;
  }
  if (inputType === "select") {
    inputNode = (
      <Select
        defaultValue={(record as any)[dataIndex]}
        style={{ width: 120 }}
        options={(options || []).map((op) => ({
          value: op.id,
          label: op.name,
        }))}
      />
    );
  }
  if (inputType === "textarea") {
    inputNode = (
      <TextArea
        defaultValue={(record as any)[dataIndex]}
        title={` ${record.name}(${title})`}
      />
    );
  }
  if (inputType === 'popup-list') {
    inputNode = (<DyanmicPoupList
      defaultValue={getOptions ? getOptions(record) : options}
      title={`${record.name}(${title})`}
      label={title}
      formId={record.id} />)
  }
  if (inputType === "img") {
    inputNode = <ImagUpload defaultValue={(record as any)[dataIndex]} />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
      {/* Modal for editing */}
    </td>
  );
};

const ServiceRecordForm = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [formTypes, setFormTypes] = useState<ServiceFormType[]>([]);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: ServiceFormType) => record.id === editingKey;
  const isNew = (r: ServiceFormType) => r.id.startsWith("new_");
  const variables = getServiceFormTypeVar({ clinicId, types: ["RECORD"] });
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"serviceFormTypes", ServiceFormType[]>
  >(get_service_form_types, { variables });
  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneServiceFormType", Offer>>(update_one_service_form_type);
  const [create, { loading: createLoading }] =
    useMutation<ApolloResult<"createOneServiceFormType", Offer>>(create_one_service_form_type);

  useEffect(() => {
    if (data?.serviceFormTypes) {
      setFormTypes(data.serviceFormTypes);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const edit = (r: ServiceFormType) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const add = () => {
    const newData: ServiceFormType = {
      id: `new_${new Date().getTime()}`,
      name: "New Form Type",
      status: "INACTIVE",
      terms: [],
      form_type: "RECORD",
      consent_sign:"right"
    };
    const newList = [...formTypes];
    newList.unshift(newData);
    setFormTypes(newList);
    form.setFieldsValue({ ...newData });
    setEditingKey(newData.id);
  };
  const save = async (r: ServiceFormType) => {
    if (r.id.startsWith("new_")) {
      await onCreate(r);
    } else {
      await onUpdate(r)
    }
    setEditingKey("");
    form.resetFields();
  };
  const onCreate = async (r: ServiceFormType) => {
    let formData = (await form.validateFields()) as any;
    let fData = { ...formData };
    let terms: any[] = [];
    delete fData["id"];
    if (fData["labels"]) {
      const labels: Array<{ id: string, term: string }> = fData["labels"]
      terms = terms.concat(...labels.map(l => ({ term: l.term, type: "LABEL" })));
      delete fData["labels"];
    }
    if (fData["units"]) {
      const labels: Array<{ id: string, term: string }> = fData["units"]
      terms = terms.concat(...labels.map(l => ({ term: l.term, type: "UNIT" })));
      delete fData["units"];
    }
    let payload = omitUndefined(fData);
    payload = {
      ...payload,
      status: (typeof payload.status === "boolean") ? (payload.status === true ? "ACTIVE" : "INACTIVE") : payload.status,
      form_type: "RECORD",
      terms: { createMany: { data: terms } }
    }

    const variables = createOneServiceFormTypeVar({
      clinicId,
      payload
    })
    console.log('create data', variables)
    const result = await create({ variables });
    if (result.data?.createOneServiceFormType) {
      message.success("create success");
      await refetch();
    } else {
      message.error(`create failed`);
    }
  };
  const onUpdate = async (r: ServiceFormType) => {
    let formData = (await form.validateFields()) as any;
    const fData = { ...formData };
    let connectors = null;
    let changes: Array<{ type: "UNIT" | "LABEL", action: "create" | "update" | "delete", term?: string, id: string }> = []
    if (fData["labels"]) {
      const news: Array<{ id: string, term: string }> = fData["labels"]
      const newIds = news.map(n => n.id);
      const removeIds = r.terms.filter(t => t.type === 'LABEL' && !newIds.includes(t.id)).map(t2 => t2.id);
      for (const rmId of removeIds) {
        changes.push({ id: rmId, type: "LABEL", action: "delete", });
      }
      for (const n of news) {
        const isNew = n.id.startsWith('new_');
        changes.push({ id: n.id, type: "LABEL", action: isNew ? 'create' : "update", term: n.term });
      }
      delete fData["labels"];
    }
    if (fData["units"]) {
      const news: Array<{ id: string, term: string }> = fData["units"]
      const newIds = news.map(n => n.id);
      const removeIds = r.terms.filter(t => t.type === 'UNIT' && !newIds.includes(t.id)).map(t2 => t2.id);
      for (const rmId of removeIds) {
        changes.push({ id: rmId, type: "UNIT", action: "delete", });
      }
      for (const n of news) {
        const isNew = n.id.startsWith('new_');
        changes.push({ id: n.id, type: "UNIT", action: isNew ? 'create' : "update", term: n.term });
      }
      delete fData["units"];
    }
    if (changes.length > 0) {
      const update1: any[] = [];
      const create1: any[] = [];
      for (const ch of changes) {
        if (ch.action === 'create') {
          create1.push({ type: ch.type, term: ch.term })
        }
        if (ch.action === 'update') {
          update1.push({
            where: { id: ch.id },
            data: { term: { set: ch.term } }
          })
        }
        if (ch.action === 'delete') {
          update1.push({
            where: { id: ch.id },
            data: { status: { set: "INACTIVE" } }
          })
        }
      }
      connectors = { terms: { update: update1, create: create1 } }
    }
    let payload = omitUndefined(fData);
    payload = {
      ...payload,
      status: (typeof payload.status === "boolean") ? (payload.status === true ? "ACTIVE" : "INACTIVE") : payload.status
    }
    const variables = updateOneServiceFormTypeVar(r.id, payload, connectors)
    const result = await update({ variables });
    if (result.data?.updateOneServiceFormType) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
  }
  const onCancel = async (r: ServiceFormType) => {
    if (r.id.startsWith("new_")) {
      const newList = [...formTypes];
      newList.shift();
      setFormTypes(newList);
    }
    setEditingKey("");
    form.resetFields();
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      required: true,
      filteredValue: searchText ? [searchText] : null,
      sorter: (a: ServiceFormType, b: ServiceFormType) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onFilter: (value: string, record: ServiceFormType) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      inputType: "switch",
      required: true,
      sorter: (a: ServiceFormType, b: ServiceFormType) => a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      editable: true,
      inputType: "textarea",
      render: (v: number, r: ServiceFormType) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}>
            {r.description}
          </Paragraph>
        );
      },
    },

    {
      title: "Labels",
      dataIndex: "labels",
      key: "labels",
      editable: true,
      inputType: "popup-list",
      getOptions: (r: ServiceFormType) => {
        const isEdit = isEditing(r);
        return isEdit ? r.terms.filter(t => t.status === "ACTIVE" && t.type === 'LABEL') : [];
      },
      render: (v: number, r: ServiceFormType) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}>
            {r.terms.filter(t => t.type === 'LABEL' && t.status === 'ACTIVE').map(t2 => t2.term).join(',')}
          </Paragraph>
        );
      },
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      editable: true,
      inputType: "popup-list",
      getOptions: (r: ServiceFormType) => {
        const isEdit = isEditing(r);
        return isEdit ? r.terms.filter(t => t.status === "ACTIVE" && t.type === 'UNIT') : [];
      },
      render: (v: number, r: ServiceFormType) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}>
            {r.terms.filter(t => t.type === 'UNIT' && t.status === "ACTIVE").map(t2 => t2.term).join(',')}
          </Paragraph>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: ServiceFormType) => {
        const editable = isEditing(record);
        return (
          <ButtonGroup>
            {editable ? (
              createLoading || saveLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}>
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ServiceFormType) => ({
        record,
        options: [],
        getOptions: col.getOptions || undefined,
        inputType: col.inputType || "text",
        required: col.required || false,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Service Record Forms</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={loading}
            showHeader={formTypes.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={formTypes.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(ServiceRecordForm);
