import { FileExcelFilled } from "@ant-design/icons";
import { useApolloClient, useQuery } from "@apollo/client";
import { Button, Col, Empty, Form, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import { addDays, format, subDays } from "date-fns";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PdfExportIcon } from "../assets/svg_icons";
import Error from "../components/apollo_error";
import DateRange from "../components/date_range";
import LoadingSpinner from "../components/loading_indicator";
import MultiSelect from "../components/multi_select";
import { export_orders, getExportOrderVar } from "../graphql/export";
import {
  getOrdersVar2,
  get_orders2,
  get_total_order_count
} from "../graphql/order";
import { ExportExcel } from "../helpers/excel";
import { formatMoney } from "../helpers/utils";
import withClinic from "../hooks/with_clinic";
import paths from "../routes/paths";
import { ApolloResult, Clinic, Order } from "../store";

import { getInvoiceTemplateAndColor } from "../helpers/invoiceHelper";
import { PaginationConfig } from "antd/lib/pagination";
interface P {
  currentClinic?: Clinic;
}

const SalePage = (props: P) => {
  // const navigate = useNavigate()
  const clinicId = props.currentClinic?.id || "";

  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const navigate = useNavigate();
  const client = useApolloClient();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [paymentFilter, SetPaymentFilter] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationConfig | null>(null)
  const [exporting, setExporting] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 15),
    endDate: addDays(new Date(), 15),
  });
  const [form] = Form.useForm();
  const take = pagination?.pageSize || 20;
  const skip = ((pagination?.current || 1) - 1) * take;
  const variables = getOrdersVar2({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take,
    skip,
    status: filter,
    payment_status: paymentFilter,
  });
  const { loading, data, error } = useQuery<ApolloResult<"orders", Order[]>>(get_orders2, { variables });
  const totalCount = useQuery<ApolloResult<"aggregateOrder", { _count: { id: number } }>>(get_total_order_count, { variables });
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };
  const handleExport = async () => {
    const fileName =
      "orders_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];

    let list: Order[] = [];
    let rolling = true;
    const take = 500;
    let skip = 0;
    setExporting(true);
    while (rolling) {
      // console.log('rolling', skip)
      const variables = getExportOrderVar({
        clinicId,
        take,
        skip,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      });
      const result = await client.query<ApolloResult<"orders", Order[]>>({
        query: export_orders,
        variables,
      });
      const _orders = result.data?.orders || [];
      list = list.concat(_orders);
      skip += _orders.length;
      rolling = _orders.length === take;
    }
    for (const o of list) {
      const orderNo = o.order_id;
      const orderDate = format(new Date(o.created_at), "yyyy-MMM-dd");

      const netAmount = Number(o.net_total);
      const discount = Number(o.discount);
      const tax = Number(o.tax);
      const totalAmount = Number(o.total);

      const payment = o.payment_method;
      const note = o.payment_detail;
      const member = o.member.name;
      const phone = o.member.phonenumber;
      const soldBy = o.user.name;
      excelData.push({
        orderNo,
        orderDate,
        totalAmount,
        discount,
        tax,
        netAmount,
        payment,
        member,
        phone,
        soldBy,
        note,
      });
    }
    ExportExcel(excelData, fileName);
    setExporting(false);
  };
  const columns: any[] = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      sorter: (a: Order, b: Order) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Order, r: Order) => {
        return format(new Date(r.created_at), "dd MMM,yyyy h:mm a");
      },
    },
    {
      title: "OrderNo",
      dataIndex: "order_id",
      key: "order_id",
      editable: false,
      sorter: (a: Order, b: Order) =>
        a.order_id.toLowerCase().localeCompare(b.order_id.toLowerCase()),
      render: (v: number, r: Order) => {
        return <Link to={paths.getSaleDetailRoute(r.id)}> {r.order_id}</Link>;
      },
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      editable: false,
      sorter: (a: Order, b: Order) =>
        a.member.name.toLowerCase().localeCompare(b.member.name.toLowerCase()),
      render: (v: number, r: Order) => {
        const val = r.member.clinic_members.length > 0 ? r.member.clinic_members[0].name : r.member.name;
        //console.log('current', clinicId, '\nclinic members', r.member.clinic_members)
        return val;
      },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: Order, b: Order) => a.status.localeCompare(b.status),
      editable: false,
    },

    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
      sorter: (a: Order, b: Order) => Number(a.total) - Number(b.total),
      editable: true,
      render: (v: number, r: Order) => {
        return `${formatMoney(Number(r.net_total))} MMK`;
      },
    },
    {
      title: "Payment Made",
      dataIndex: "balance",
      key: "balance",
      sorter: (a: Order, b: Order) => Number(a.balance) - Number(b.balance),
      editable: true,
      render: (v: number, r: Order) => {
        return `${formatMoney(Number(r.balance))} MMK`;
      },
    },
    {
      title: "Balance Due",
      dataIndex: "credit_balance",
      key: "credit_balance",
      sorter: (a: Order, b: Order) =>
        Number(a.credit_balance) - Number(b.credit_balance),
      editable: true,
      render: (v: number, r: Order) => {
        return `${formatMoney(Number(r.credit_balance))} MMK`;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      sorter: (a: Order, b: Order) =>
        a.payment_method.localeCompare(b.payment_status),
      editable: true,
    },
    {
      title: "Payment",
      dataIndex: "payment_method",
      key: "payment_method",
      sorter: (a: Order, b: Order) =>
        a.payment_method.localeCompare(b.payment_method),
      editable: true,
    },
    {
      title: "SoldBy",
      dataIndex: "user",
      key: "user",
      editable: false,
      sorter: (a: Order, b: Order) =>
        a.user.name.toLowerCase().localeCompare(b.user.name.toLowerCase()),
      render: (v: number, r: Order) => {
        return r.user.name;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      editable: false,
      fixed: "right",
      render: (v: number, r: Order) => {
        const template = getInvoiceTemplateAndColor(
          props?.currentClinic?.code || ""
        );
        return (
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://greattime-api-core-hs6rtohe3q-uc.a.run.app/api/invoice?orderId=${r.id}&template=${template.template}&color=${template.color}`}
          >
            {" "}
            <PdfExportIcon />
          </a>
        );
      },
    },
    // https://greattime-api-core-hs6rtohe3q-uc.a.run.app/api/invoice?orderId=clp0tt75c0003s601qvv52bfr&template=template1
    // {
    //     title: 'Action',
    //     key: 'action',
    //     align: 'center',
    //     width: '10%',
    //     dataIndex: 'action',
    //     editable: false,
    //     fixed: 'right',
    //     render: (v: number, record: Account) => {
    //        // const editable = isEditing(record);
    //         return (
    //             <ButtonGroup>
    //                 {editable ?
    //                     (
    //                         saveLoading ? <Button type="primary" icon={<LoadingOutlined />} >saving...</Button> :
    //                             < Popconfirm placement="topRight" title={`Sure to save ${record.account_number}?`} onConfirm={() => handleSave(record)} onCancel={() => setEditingKey('')}>
    //                                 <Button type='primary' icon={<SaveOutlined />} >save</Button>
    //                             </Popconfirm>
    //                     )
    //                     :
    //                     <Button shape="circle" icon={<EditOutlined />}
    //                         onClick={() => edit(record)}
    //                     />
    //                 }
    //             </ButtonGroup >
    //         );

    //     }
    // }
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>Sales</Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${totalCount.data?.aggregateOrder._count.id}...`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            showHeader={data.orders.length > 0}
            //components={{ body: { cell: EditableCell, } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={data.orders.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            // pagination={false}
            onChange={(p: PaginationConfig) => {
              setPagination(p)
            }}
            pagination={{
              total: totalCount.data?.aggregateOrder._count.id || 0,
              current: pagination?.current,
              pageSize: pagination?.pageSize,
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50']
            }}
          />
        </Form>
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4, marginRight: 2 }}>
      <Col span={16}>
        <MultiSelect
          selected={filter}
          placeholder="Select orders status"
          options={["ACTIVE", "INACTIVE", "CANCEL", "DONE"]}
          onChange={setFilter}
        />
      </Col>
      <Col span={6}>
        <MultiSelect
          selected={paymentFilter}
          placeholder="Select orders Payment Status"
          options={["PAID", "UNPAID", "PARTIAL_PAID"]}
          onChange={SetPaymentFilter}
        />
      </Col>
      <Col span={2}>
        <Button
          size="small"
          loading={exporting}
          icon={<FileExcelFilled />}
          onClick={handleExport}
        >
          Export
        </Button>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(SalePage);
