import { SettingOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Button, Form, Input, Modal, Select, Switch } from 'antd';
import type { FormInstance } from 'antd/es/form';
import React, { useEffect, useState } from 'react';
import { find_one_system_data, getOneSystemDataVar, getUpsertOneSystemData, upsert_one_system_data } from '../graphql/system_data';
import { ApolloResult, Clinic, SystemData } from '../store';
// {"status": "active", 
// "exchangeUrl": "https://us-central1-df-kirin.cloudfunctions.net/apiv1/v1/exchangePoint",
//  "apiKey": "piticafe",
//   "apiMethod": "post”,
//   ”mode”:”whole”
// }

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
interface P {
    currentClinic?: Clinic;
}
interface FormData {
    mode: string;
    apiKey: string;
    status: boolean;

}
interface PitiConfig {
    status: string;
    mode?: string;
    exchangeUrl: string;
    apiMethod: string;
    apiKey: string;
}
const baseConfig = {
    "exchangeUrl": "https://apiv2-wfoxr6wg7q-uc.a.run.app/v1/exchangePoint",
    "apiMethod": "post",
}

const PitiConfigModal = (props: P) => {
    const sys_code = `${props.currentClinic?.id}_piti_config`;
    const formRef = React.useRef<FormInstance<FormData>>(null);
    const [show, setShow] = useState(false);

    const [fetchData, { data: config, loading: fetching, refetch }] = useLazyQuery<ApolloResult<"findUniqueSystemData", SystemData>>(
        find_one_system_data,
        { variables: getOneSystemDataVar(sys_code) }
    );
    const [upsertData, { loading: saving }] = useMutation<ApolloResult<"upsertOneSystemData", SystemData>>(upsert_one_system_data);
    useEffect(() => {
        if (config?.findUniqueSystemData) {
            try {
                const _r = JSON.parse(config.findUniqueSystemData.metadata || "") as PitiConfig;
                const fData: FormData = {
                    status: _r.status === 'active',
                    apiKey: _r.apiKey,
                    mode: _r.mode || "seperate"
                }
                formRef.current?.setFieldsValue({ ...fData });
            } catch (error) {

            }
        }
    }, [config])
    const handleOk = async (val: FormData) => {
        const metadata: PitiConfig = {
            ...baseConfig,
            apiKey: val.apiKey,
            mode: val.mode,
            status: val.status === true ? "active" : "inactive",
        }
        const variables = getUpsertOneSystemData(sys_code, { metadata: JSON.stringify(metadata) })
        const result = await upsertData({ variables })
        if (result.data?.upsertOneSystemData) {
            await refetch();
        }
    }
    return (
        <React.Fragment>
            <Button
                loading={saving}
                icon={<SettingOutlined />}
                onClick={() => {
                    fetchData();
                    setShow(true)
                }}
            />
            <Modal
                title={`Piti Point Config`}
                open={show}
                onCancel={() => setShow(false)}
                footer={[
                    <Button loading={saving} disabled={fetching} type="primary" form="pitiForm" key="submit" htmlType="submit">
                        Save
                    </Button>
                ]}
            // destroyOnClose={true}
            >
                <Form
                    {...layout}
                    initialValues={{ mode: "seperate", status: false }}
                    id="pitiForm"
                    ref={formRef}
                    name="control-ref"
                    onFinish={handleOk}
                    style={{ maxWidth: 800 }}
                >

                    <Form.Item
                        label="Mode"
                        name="mode"
                        rules={[{ required: false }]}
                    >
                        <Select
                            defaultValue={"seperate"}
                            options={[
                                { value: "seperate", label: "seperate" },
                                { value: "whole", label: "whole" },
                                { value: "exclusive", label: "exclusive" }
                            ]}
                            style={{ width: 150 }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="merchantId"
                        name="apiKey"
                        rules={[{ required: true, message: 'MerchantId is required' }]}
                    >
                        <Input />
                    </Form.Item >

                    <Form.Item
                        label="Active"
                        name="status"
                        valuePropName="checked"
                        rules={[{ required: false }]}
                    >
                        <Switch />
                    </Form.Item>

                    <Alert
                        message="Rule"
                        description={
                             (
                                <>
                                <p>Seperate: Points are awarded only for PASS point of sale transactions</p>
                                <p>Whole: Points are awarded on every sale.</p>
                                <p>Exclusive:  Points are awarded on every sale except for PASS transactions. </p>
                                </>
                            )
                        }
                        type="info"
                    />
                </Form>
            </Modal >
        </React.Fragment >
    );
};

export default PitiConfigModal;