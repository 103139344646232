import { gql } from "@apollo/client";

export const get_system_logs = gql`
query SystemLogs(
    $where: SystemLogWhereInput
    $orderBy: [SystemLogOrderByWithRelationInput!]
    $take: Int
  ) {
    systemLogs(where: $where, orderBy: $orderBy, take: $take) {
      created_at
      code
      id
      metadata
      log_id
      log_type
      user {
        name
      }
    }
  }
`;

export const getSysLgVar = (code: string,take=5) => ({
    where: { code: { equals: code } },
    orderBy: [{ created_at: "desc" }],
    take
});

export const create_one_sys_lg = gql`
mutation CreateOneSystemLog($data: SystemLogCreateInput!) {
    createOneSystemLog(data: $data) {
      id
    }
  }  
`;

export const createOneSysLogVar = (data: any) => ({ data })