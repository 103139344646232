import { gql } from "@apollo/client";

export const get_top_treatment = gql`
  query Services(
    $where: ServiceWhereInput
    $orderBy: [ServiceOrderByWithRelationInput!]
    $take: Int
  ) {
    services(where: $where, orderBy: $orderBy, take: $take) {
      name
      image
      created_at
      id
      clinic_id
      _count {
        bookings
        checkins
      }
    }
  }
`;
export const getTopTreatmentVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[] | string;
  searchText?: string | null;
  take: number;
}) => {
  const { status = [], searchText } = data;
  let variables: any = {},
    where: any = {},
    OR: any[] = [];
  if (searchText && searchText !== "") {
    OR.push({
      name: {
        contains: searchText,
      },
    });
  }
  where.clinic_id = { equals: data.clinicId };
  if (status.length > 0) where.status = { in: status };
  if (OR.length > 0) where.OR = OR;
  variables.where = where;
  variables.orderBy = [{ created_at: "desc" }];
  variables.take = 300;
  return variables;
};

export const get_top_treatment_checkin_count = gql`
  query GroupByCheckIn(
    $by: [CheckInScalarFieldEnum!]!
    $where: CheckInWhereInput
  ) {
    groupByCheckIn(by: $by, where: $where) {
      _count {
        _all
      }
      service_id
      clinic_id
    }
  }
`;
export const getTopTreatmentCheckInCountVar = (data: {
  clinicId: string;
  serviceIds: string[];
  startDate: Date;
  endDate: Date;
  status?: string | string[];
}) => {
  let variables: any = {};
  variables.by = ["service_id", "clinic_id", "status"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    service_id: { in: data.serviceIds },
    status: { in: data.status },
  };
  return variables;
};

export const excel_top_treatment = gql`
  query Services(
    $where: ServiceWhereInput
    $orderBy: [ServiceOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    services(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      name
      created_at
      id
      clinic_id
      _count {
        bookings
        checkins
      }
    }
  }
`;
export const excelTopTreatmentVar = (data: {
  clinicId: string;
  startDate: Date;
  endDate: Date;
  status?: string[] | string;
  take: number;
  skip: number;
}) => ({
  where: {
    clinic_id: {
      equals: data.clinicId,
    },
    status: {
      in: data.status,
    },
  },
  orderBy: [
    {
      checkins: {
        _count: "desc",
      },
    },
  ],
  take: 150,
  skip: data.skip,
});

export const excel_top_treatment_checkin_count = gql`
  query GroupByCheckIn(
    $by: [CheckInScalarFieldEnum!]!
    $where: CheckInWhereInput
  ) {
    groupByCheckIn(by: $by, where: $where) {
      _count {
        _all
      }
      service_id
      clinic_id
    }
  }
`;
export const excelTopTreatmentCheckInCountVar = (data: {
  clinicId: string;
  serviceIds: string[];
  startDate: Date;
  endDate: Date;
  status?: string | string[];
}) => {
  let variables: any = {};
  variables.by = ["service_id", "clinic_id", "status"];
  variables.where = {
    clinic_id: { equals: data.clinicId },
    in_time: { gte: data.startDate, lte: data.endDate },
    service_id: { in: data.serviceIds },
    status: { in: data.status },
  };
  return variables;
};
