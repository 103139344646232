import { Card } from 'antd'
import React from 'react'

export default function NewSalePage() {
    return (
        <Card title="New Sale">

        </Card>
    )
}
