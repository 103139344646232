import { useMutation } from "@apollo/client";
import { Button, Checkbox, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { ApolloResult, Service } from "../../store";
import { update_one_service } from "../../graphql/service";
import { set } from "lodash";
import { message } from "antd";

interface FormProps {
  description: string | undefined;
  serviceId: string;
}

const ServiceDetailForm: React.FC<FormProps> = ({ description, serviceId }) => {
  const [form] = Form.useForm();
  const [componentDisabled, setComponentDisabled] = useState<boolean>(true);
  const [editButtonText, setEditButtonText] = useState<"Edit" | "Cancel">(
    "Edit"
  );
  const [initDescription, setInitDescription] = useState<string | undefined>(
    description
  );

  const [update, { loading, data, error }] =
    useMutation<ApolloResult<"updateOneService", Service>>(update_one_service);

  useEffect(() => {
    if (loading) return;
    if (error != null) {
      errorMessage();
    } else if (data) {
      successMessage();
      setInitDescription(form.getFieldValue("description"));
      setEditButtonText("Edit");
      setComponentDisabled(true);
    }
  }, [data]);

  const onFinish = async (values: FormProps) => {
    if (values.description == null) return;
    const variables = {
      where: { id: serviceId },
      data: {
        description: {
          set: values.description,
        },
      },
    };
    await update({ variables });
  };

  const editOnClick = () => {
    if (componentDisabled) {
      setEditButtonText("Cancel");
    } else {
      setEditButtonText("Edit");
      onReset();
    }
    setComponentDisabled(!componentDisabled);
  };

  const onReset = () => {
    form.resetFields();
  };

  const successMessage = () => {
    message.success("Service saving successful.");
  };

  const errorMessage = () => {
    message.error("Service saving fail!");
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{ flex: "110px" }}
        labelAlign="left"
        labelWrap
        wrapperCol={{ flex: 1 }}
        colon={false}
        style={{
          width: "60%",
        }}
        form={form}
        initialValues={{ description: initDescription }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Description" name="description">
          <Input.TextArea
            disabled={componentDisabled}
            showCount
            autoSize={{
              minRows: 3,
              maxRows: 8,
            }}
            maxLength={1024}
          />
        </Form.Item>

        <Form.Item style={{ display: "inline-block", marginRight: "1em" }}>
          <Button
            disabled={componentDisabled || loading}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>

        <Button
          htmlType="button"
          style={{ display: "inline-block" }}
          disabled={loading}
          onClick={editOnClick}
        >
          {editButtonText}
        </Button>
      </Form>
    </>
  );
};

export default ServiceDetailForm;
