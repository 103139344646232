import { ShareAltOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import MultiSelect from './mulit_select2';

interface P {
    onChange: (saleChannels: string) => void;
    defaultValue?: string;
    title: string;
    loading?: boolean
}

const SaleChannelModal = ({ defaultValue, onChange, title, loading = false }: P) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [values, setValues] = useState<string[]>([]);
    useEffect(() => {
        if (defaultValue) {
            setValues(defaultValue.split(","))
        }
    }, [defaultValue])

    const openModal = () => {
        setIsModalVisible(true);
    };
    const handleSave = () => {
        setIsModalVisible(false);
        if (onChange) {
            onChange(values.join(','))
        }
    };
    return (
        <>
            <Button loading={loading} shape="square" onClick={openModal} icon={<ShareAltOutlined />}></Button>
            <Modal
                title={title}
                visible={isModalVisible}
                onOk={handleSave}
                okText={"Save"}
                onCancel={() => setIsModalVisible(false)}
                bodyStyle={{ height: "250px" }}
                destroyOnClose={true}
            >
                <MultiSelect
                    options={[
                        { label: "APLUS", value: "APLUS" },
                        { label: "KBZ", value: "KBZ" },
                        { label: "UAB", value: "UAB" },
                        { label: "AYA", value: "AYA" },
                        { label: "PITI", value: "PITI" },
                    ]}
                    onChange={(vals) => {
                        setValues(vals)
                    }}
                    selected={values}
                    placeholder="Select sale channels"
                />

            </Modal>
        </>
    );
};

export default SaleChannelModal as any;
