import { gql } from "@apollo/client";

export const find_one_system_data = gql`
query FindUniqueSystemData($where: SystemDataWhereUniqueInput!) {
    findUniqueSystemData(where: $where) {
      code
      id
      metadata
      description
    }
  }  
`;

export const getOneSystemDataVar = (
  code: string
) => ({ where: { code } })


export const update_one_system_data = gql`
mutation UpdateOneSystemData($data: SystemDataUpdateInput!, $where: SystemDataWhereUniqueInput!) {
  updateOneSystemData(data: $data, where: $where) {
    id
  }
}
`;

export const getUpdateOneSystemData = (id: string, data: any, connectors?: any) => {
  let variables: any = {}, update: any = {};
  variables.where = { id }
  if (typeof data === 'object') {
    update = Object.assign({}, ...Object.keys(data).map((k, i) => ({ [k]: { "set": data[k] } })))
  }
  variables.data = update;
  if (connectors && typeof connectors === 'object') {
    variables.data = { ...variables.data, ...connectors }
  }
  return variables;
}

export const upsert_one_system_data = gql`
mutation UpsertOneSystemData(
  $where: SystemDataWhereUniqueInput!
  $create: SystemDataCreateInput!
  $update: SystemDataUpdateInput!
) {
  upsertOneSystemData(where: $where, create: $create, update: $update) {
    id
  }
}
`;

export const getUpsertOneSystemData = (code: string, data?: any) => {
  let variables: any = {}, update: any = {}, create: any = {};
  variables.where = { code }
  if (typeof data === 'object') {
    create = { ...data, code }
    update = Object.assign({}, ...Object.keys(data).map((k, i) => ({ [k]: { "set": data[k] } })))
  }
  variables.create = create;
  variables.update = update;
  return variables;
}
