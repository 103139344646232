const invoices=[
    {
        clinicCode:"GTPITI",
        template:'template1',
        color:"ff00ff"
    },
    {
        clinicCode:"GTLEMON",
        template:'template1',
        color:"F3E2AC"
    },
    {
        clinicCode:"GTPURE",
        template:'template1',
        color:"36A77C"
    },
    {
        clinicCode:"GTDRMIN",
        template:'template3',
        color:"D7B267"
    },
  
]

export const getInvoiceTemplateAndColor=(clinicCode:string)=>{
    let template= invoices.find(clinc=>{
       return clinc.clinicCode===clinicCode
    })
    if(!template){
        template=  {
            clinicCode:clinicCode,
            template:'template1',
            color:"00ff00"
        }
    }
    return template
}