import Cookies from "js-cookie";
type NotiRes = {
    status: "success" | "invalid",
    message?: string,
    total?: number,
};
type TokenRes = {
    status: "success" | "invalid",
    access_token?: string,
    expires_in?: string | number,
}
export interface PSNoitification {
    topic: string;
    title: string;
    body: any;
    phone?: string;
}

export const getPassApiToken = async (uri: string, refreshToken: string) => {
    let accessToken: string | null = null;
    const resp = await fetch(uri,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "refresh_token": refreshToken })
        }
    );
    if (resp.status >= 200 && resp.status < 500) {
        const r = await resp.json() as TokenRes;
        if (r.access_token) {
            accessToken = r.access_token;
        }
        if (r.expires_in && r.access_token) {
            const sec = Number(r.expires_in) - 60;
            var in1Hr = new Date(new Date().getTime() + sec * 1000)
            Cookies.set('p_token', r.access_token, { expires: in1Hr, secure: true });
        }
    } else {
        const text = await resp.text();
        // tslint:disable-next-line:no-console
        console.error(new Error(`status: ${resp.status}, body: ${text}`));
    }
    return accessToken;
}
export const sendPassNotice = async (notifications: PSNoitification[], refreshToken: string, refreshTokenUrl: string): Promise<NotiRes> => {
    const token = await getPassApiToken(refreshTokenUrl, refreshToken);
    const uri = `https://apiv1-44lxzoj7ya-uc.a.run.app/v0/notification`;
    // console.log("token",token,"\n uri",uri);
    
    const resp = await fetch(uri,
        {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ messages: notifications })
        }
    );
    if (resp.status >= 200 && resp.status < 500) {
        const r = await resp.json() as NotiRes;
        
        return r;
    }
    const text = await resp.text();

    // tslint:disable-next-line:no-console
    console.error(new Error(`status: ${resp.status}, body: ${text}`));
    return {
        status: 'invalid'
    };
};