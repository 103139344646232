import {
  EditOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  SaveOutlined
} from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Switch,
  Table,
  Typography
} from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import Search from "antd/lib/input/Search";
import React, { useEffect, useState } from "react";
import Error from "../../components/apollo_error";
import ImagUpload from "../../components/image_upload";
import LoadingSpinner from "../../components/loading_indicator";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  Clinic,
  Offer,
  OfferCategory,
  ServiceFormType
} from "../../store";

import { Select, message } from "antd";
import { createOneServiceFormTypeVar, create_one_service_form_type, getServiceFormTypeVar, get_service_form_types, get_service_from_type_slims, updateOneServiceFormTypeVar, update_one_service_form_type } from "../../graphql/service_form";
import { omitUndefined } from "../../helpers/utils";
import TextareaUpload from "../../components/textarea_upload";
import { Avatar } from "antd";
const { TextArea } = Input;
const { Paragraph } = Typography;

interface P {
  currentClinic?: Clinic;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "switch" | "textarea" | "select" | "img";
  record: Offer;
  options?: OfferCategory[];
  required: boolean;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  required,
  options,
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  let inputNode: any = <Input />;
  if (inputType === "number") {
    inputNode = <InputNumber />;
  }
  if (inputType === "switch") {
    inputNode = <Switch defaultChecked={record.status === "ACTIVE"} />;
  }
  if (inputType === "select") {
    inputNode = (
      <Select
        defaultValue={(record as any)[dataIndex]}
        style={{ width: 120 }}
        options={(options || []).map((op) => ({
          value: op.id,
          label: op.name,
        }))}
      />
    );
  }
  if (inputType === "textarea") {
    inputNode = (
      <TextareaUpload
        defaultValue={(record as any)[dataIndex]}
        title={` ${record.name}(${title})`}
      />
    );
  }
  if (inputType === "img") {
    inputNode = <ImagUpload defaultValue={(record as any)[dataIndex]} />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
      {/* Modal for editing */}
    </td>
  );
};

const ServiceConsentForm = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [formTypes, setFormTypes] = useState<ServiceFormType[]>([]);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record: ServiceFormType) => record.id === editingKey;
  const isNew = (r: ServiceFormType) => r.id.startsWith("new_");
  const variables = getServiceFormTypeVar({ clinicId, types: ["CONSENT"] });
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"serviceFormTypes", ServiceFormType[]>
  >(get_service_from_type_slims, { variables });
  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneServiceFormType", Offer>>(update_one_service_form_type);
  const [create, { loading: createLoading }] =
    useMutation<ApolloResult<"createOneServiceFormType", Offer>>(create_one_service_form_type);

  useEffect(() => {
    if (data?.serviceFormTypes) {
      setFormTypes(data.serviceFormTypes);
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const edit = (r: ServiceFormType) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const add = () => {
    const newData: ServiceFormType = {
      id: `new_${new Date().getTime()}`,
      name: "New Consent Form",
      status: "INACTIVE",
      terms: [],
      form_type: "CONSENT",
      consent_sign:"right"
    };
    const newList = [...formTypes];
    newList.unshift(newData);
    setFormTypes(newList);
    setEditingKey(newData.id);
  };
  const save = async (r: ServiceFormType) => {
    if (r.id.startsWith("new_")) {
      await onCreate(r);
    } else {
      await onUpdate(r)
    }
    setEditingKey("");
    form.resetFields();
  };
  const onCreate = async (r: ServiceFormType) => {
    let formData = (await form.validateFields()) as any;
    let fData = { ...formData };
    delete fData["id"];
    let payload = omitUndefined(fData);
    payload = {
      ...payload,
      status: (typeof payload.status === "boolean") ? (payload.status === true ? "ACTIVE" : "INACTIVE") : payload.status,
      form_type: "CONSENT",
    }
    const variables = createOneServiceFormTypeVar({
      clinicId,
      payload
    })
    console.log('create data', variables)
    const result = await create({ variables });
    if (result.data?.createOneServiceFormType) {
      message.success("create success");
      await refetch();
    } else {
      message.error(`create failed`);
    }
  };
  const onUpdate = async (r: ServiceFormType) => {
    let formData = (await form.validateFields()) as any;
    const fData = { ...formData };
    let payload = omitUndefined(fData);
    payload = {
      ...payload,
      status: (typeof payload.status === "boolean") ? (payload.status === true ? "ACTIVE" : "INACTIVE") : payload.status
    }
    const variables = updateOneServiceFormTypeVar(r.id, payload)
    const result = await update({ variables });
    if (result.data?.updateOneServiceFormType) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
  }
  const onCancel = async (r: ServiceFormType) => {
    if (r.id.startsWith("new_")) {
      const newList = [...formTypes];
      newList.shift();
      setFormTypes(newList);
    }
    setEditingKey("");
    form.resetFields();
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      required: true,
      filteredValue: searchText ? [searchText] : null,
      sorter: (a: ServiceFormType, b: ServiceFormType) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      onFilter: (value: string, record: ServiceFormType) =>
        record.name.toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Image",
      dataIndex: "consent_image",
      key: "consent_image",
      inputType: "img",
      required: true,
     // sorter: (a: ServiceFormType, b: ServiceFormType) => a.status.localeCompare(b.status),
      editable: true,
      render: (v: number, r: ServiceFormType) => {
        return <Avatar src={r.consent_image} size={50} />;
    },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      inputType: "switch",
      required: true,
      sorter: (a: ServiceFormType, b: ServiceFormType) => a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "legal_desc",
      key: "legal_desc",
      editable: true,
      inputType: "textarea",
      required: true,
      render: (v: number, r: ServiceFormType) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}>
            {r.legal_desc}
          </Paragraph>
        );
      },
    },
    {
      title: "Sign Aligment",
      dataIndex: "consent_sign_align",
      key: "consent_sign_align",
      inputType: "select",
      required: true,
      sorter: (a: ServiceFormType, b: ServiceFormType) => a.status.localeCompare(b.status),
      editable: true,
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      fixed: "right",
      render: (v: number, record: ServiceFormType) => {
        const editable = isEditing(record);
        const n = isNew(record);
        return (
          <ButtonGroup>
            {editable ? (
              createLoading || saveLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${record.name}?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}>
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: ServiceFormType) => ({
        record,
        options: [
          {id:"right",label:"Right"},
          {id:"center",label:"Center"},
          {id:"left",label:"Left"}
        ],
        inputType: col.inputType || "text",
        required: col.required || false,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Service Consent Forms</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={loading}
            showHeader={formTypes.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={formTypes.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {context}
    </React.Fragment>
  );
};

export default withClinic(ServiceConsentForm);
