import React from 'react';
import { Col, Badge, Row, Typography, Card } from 'antd'
import { formatMoney } from '../helpers/utils';
const { Title } = Typography;
interface P {
    title: string;
    value: string | number;
    icon: any;
    unit?: string;
    persent?: string | null;
    bnb?: string;
    loading?: boolean;
}
export default function PanelCard(props: P) {
    return (
        <Col
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-10"
        >
            <Card
                type="inner"
                size="small"
                loading={props.loading || false}
                bordered={true}
                style={{ backgroundColor: "white", borderRadius: "25px" }}
            >
                <div className="number ">
                    <Row align="middle" gutter={[24, 0]}>
                        <Col xs={18}>
                            <div className="flex justify-center items-center gap-3">
                                <span className="text-base">
                                    <Badge placement="start" count={props.persent} color='green' offset={[130, 0]}>
                                        {props.title}
                                    </Badge>
                                </span>
                                <Badge color={'blue'} offset={[10, -3]} size="small" count={props.unit || ''}></Badge>
                            </div>
                            <Title level={3}>
                                {formatMoney(Number(props.value))}
                            </Title>
                        </Col>
                        <Col xs={6} className="">
                            {/* <div className="icon-box text-3xl">{c.icon}</div> */}
                            {props.icon}
                        </Col>
                    </Row>
                </div>
            </Card>
        </Col>
    )
}
