import React, { useEffect, useState } from "react";
import { ApolloResult, Clinic, PSAccount, PSAccountType, PSTransaction } from "../../store";
import withClinic from "../../hooks/with_clinic";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  getAccountTypeVar,
  getAccountsVariables,
  getTotalCountAccountVar,
  getTrxVar,
  get_account_type,
  get_accounts,
  get_total_account_count,
  get_trxs,
} from "../../graphql/pass";
import { Card, Row, Col, Typography, Table, Empty, Input } from "antd";
import Cookies from "js-cookie";
import { getPassApiToken } from "../../api/pass_api";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import { formatMoney } from "../../helpers/utils";
import { HistoryOutlined, HolderOutlined, MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { PaginationConfig } from "antd/lib/pagination";
import { format } from "date-fns";
const { Search } = Input;
interface P {
  currentClinic?: Clinic;
}

function PassAccounts(props: P) {
  const passConfig = () => {
    let config: {
      refresh_token_url?: string;
      refresh_token?: string;
      id?: string;
    } | null = null;
    if (props.currentClinic && props.currentClinic.pass) {
      const obj = JSON.parse(props.currentClinic.pass) as {
        refresh_token_url?: string;
        refresh_token?: string;
        id?: string;
      };
      if (obj.refresh_token && obj.refresh_token_url) {
        config = obj;
      }
    }
    return config;
  };
  const [searchText, setSearchText] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(20);
  const [loading2, setLoading2] = useState(false);
  const [trxAccount, setTrxAccount] = useState<string | null>(null);
  const [pagination, setPagination] = useState<PaginationConfig | null>(null)
  const accountTypeCode = passConfig()?.id || "";
  const currentAccResult = useQuery<
    ApolloResult<"aggregateAccount", { _count: { id: number } }>
  >(get_total_account_count, {
    variables: getTotalCountAccountVar({ code: accountTypeCode, searchText }),
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    context: { className: "pass" },
  });

  const [getAccounts, { data, loading, error, refetch }] = useLazyQuery<
    ApolloResult<"accounts", PSAccount[]>
  >(get_accounts, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    context: {
      className: "pass",
    },
  });

  const [getTrxs, { loading: trxLoading, data: trxData }] = useLazyQuery<ApolloResult<"transactions", PSTransaction[]>>(get_trxs, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    context: {
      className: "pass",
    },
  });

  useEffect(() => {
    if (trxAccount) {
      getTrxs({
        variables: getTrxVar({
          accountId: trxAccount,
          take: 10,
          skip: 0
        })
      })
    }
  }, [trxAccount])

  useEffect(() => {
    if (error) {
      const pass = passConfig();
      if (
        !Cookies.get("p_token") &&
        pass?.refresh_token &&
        pass?.refresh_token_url
      ) {
        setLoading2(true);
        getPassApiToken(pass.refresh_token_url, pass.refresh_token).then(
          (r) => {
            refetch().then(() => {
              setLoading2(false);
            });
          }
        );
      }
    }
  }, [error]);

  useEffect(() => {
    const variables = getAccountsVariables({
      accountTypeCode,
      searchText,
      take: pageSize,
    });
    getAccounts({ variables });
  }, [searchText, props.currentClinic])

  if (loading || loading2) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!searchText && !data) return <Empty description="no accounts" />;
  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (v: number, r: PSAccount) => {
        return r.customer.name;
      },
    },
    {
      title: "PhoneNo",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (v: number, r: PSAccount) => {
        return r.customer.phone_number;
      },
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      render: (v: number, r: PSAccount) => {
        return formatMoney(Number(r.balance));
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (v: number, r: PSAccount) => {
        return r.lock ? "locked" : "active";
      },
    },
    Table.EXPAND_COLUMN,
    {
      title: "Transaction",
      dataIndex: "transaction",
      key: "transaction",
      width: "3%",
      render: (v: number, r: PSAccount) => {
        return <Typography style={{ textAlign: "left" }}>{r._count.transactions}</Typography>;
      },
    },
  ];
  const total = currentAccResult.data?.aggregateAccount._count.id || 0;

  const expandedRowRender = (r: PSAccount) => {
    const columns2: any[] = [
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        editable: false,
        render: (v: PSTransaction, t: PSTransaction) => {
          return format(new Date(t.created_at), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "TransactionNo",
        dataIndex: "transaction_number",
        key: "transaction_number",
        align: "center",
      },
      {
        title: "Balance",
        dataIndex: "balance",
        key: "balance",
        render: (v: number, t: PSTransaction) => {
          return formatMoney(Number(t.balance));
        },
      },
      {
        title: "Status",
        dataIndex: "transaction_status",
        key: "transaction_status",
      },
      {
        title: "Transfer",
        dataIndex: "transfer",
        key: "transfer",
        render: (v: number, t: PSTransaction) => {
          const text = t.transaction_detail.sender.id === r.id ?
            `Transfer to ${t.transaction_detail.recipient.customer.name}` :
            `Transfer from ${t.transaction_detail.sender.customer.name}`;
          return <Typography style={{ textAlign: "center" }}>{text}</Typography>;
        },
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
      },
    ];

    return <Table
      size="small"
      loading={trxLoading}
      columns={columns2}
      dataSource={(trxData?.transactions || []).filter(t => t.account_id === r.id)}
      onChange={(p: PaginationConfig) => {
        const take = p.pageSize || 10;
        const skip = ((p.current || 1) - 1) * take;
        getTrxs({
          variables: getTrxVar({
            accountId: trxAccount!,
            skip,
            take,
          })
        })
      }}
      pagination={{
        total: r._count.transactions,
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30']
      }}
    />
  }
  const onExpandedRowsChange = async (Ids: string[]) => {
    if (Ids.length > 0) {
      setTrxAccount(Ids[0]);
    } else {
      setTrxAccount(null);
    }
  };
  return (
    <React.Fragment>
      <Row>
        <Col flex={4}>
          <Row>
            <Col span={8}>
              <Typography level={2}>Wallets</Typography>
            </Col>
            <Col span={8} offset={8}>
              <Search
                defaultValue={searchText}
                placeholder={`search ${total} account(s)...`}
                allowClear
                size="default"
                onSearch={(val: any) => setSearchText(val.toLowerCase())}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col span={24}>
          <Table
            loading={loading}
            showHeader={data?.accounts || [].length > 0}
            dataSource={(data?.accounts || []).map((c, i) => ({
              ...c,
              key: c.id,
            }))}
            columns={columns}
            rowClassName="editable-row"
            expandable={{
              expandedRowRender,
              onExpandedRowsChange,
              expandIcon: ({ expanded, onExpand, record }: any) =>
                expanded ? (
                  <MinusCircleTwoTone onClick={e => onExpand(record, e)} />
                ) : (
                  <HistoryOutlined onClick={e => trxAccount ? {} : onExpand(record, e)} style={{ color: trxAccount ? "whitesmoke" : "black" }} />
                )
            }}
            onChange={(p: PaginationConfig) => {
              setPagination(p)
              const take = p.pageSize || pageSize;
              const skip = ((p.current || 1) - 1) * take;
              getAccounts({
                variables: getAccountsVariables({
                  accountTypeCode,
                  searchText,
                  take,
                  skip
                })
              })
            }}
            pagination={{
              total,
              current: pagination?.current,
              pageSize: pagination?.pageSize,
              defaultPageSize: pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '50']
            }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default withClinic(PassAccounts);
