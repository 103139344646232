import React, { useState } from 'react';
import { getAuth, signInWithCustomToken } from "@firebase/auth";
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { GoogleCircleFilled } from "@ant-design/icons";
import { Button } from 'antd';
import { useMutation } from '@apollo/client';
import { gauth_query, getGAuthVar } from "../../graphql/gauth";
import { ApolloResult } from '../../store';
const clientId = process.env.REACT_APP_GOOGLE_CREDENTIALS_CLIENT_ID || '';

type S = { error: boolean, loading: boolean }
export default function LoginForm() {
    const [auth, { loading, error, data }] = useMutation<ApolloResult<"gauth", string>>(gauth_query)
    const [state, setState] = useState({ error: false, loading: false } as S);
    const handleGLoginSuccess = async (response: CredentialResponse) => {
        if (!response.credential) return;
        setState({
            loading: true,
            error: false,
        })

        const r = await auth({ variables: getGAuthVar(response.credential) });
        if (r.data?.gauth) {
            try {
                await signInWithCustomToken(getAuth(), r.data.gauth);
            } catch (error) {
                setState({
                    error: true,
                    loading: false,
                })
            }
        } else {
            setState({
                loading: false,
                error: true
            })
        }
    }
    const handleGLoginError = () => {
        setState({
            error: true,
            loading: false,
        })
    }
    return (
        <div style={{ display: "flex", flexDirection: 'column' }}>
            <GoogleOAuthProvider clientId={clientId}>
                {state.loading ? <Button
                    loading={true}
                    type="primary"
                    icon={<GoogleCircleFilled />}
                    style={{ backgroundColor: 'black' }}
                >
                    Signing...
                </Button>
                    : <GoogleLogin
                        //hosted_domain="datafocus.cloud"
                        text="signin_with"
                        size="large"
                        onSuccess={handleGLoginSuccess}
                        onError={handleGLoginError}

                    />}
            </GoogleOAuthProvider>
            <div style={{ color: "red", fontWeight: 'bold', textAlign: 'center' }}>
                {state.error === true && <span>Invalid Mail</span>}
            </div>
        </div>
    );
}
