import React, { useEffect, useState } from "react";
import withClinic from "../hooks/with_clinic";
import {
  ApolloResult,
  Clinic,
  GTLeave,
  GTNotification,
  Order,
  Practitioner,
} from "../store";
import {
  Row,
  Col,
  Typography,
  Empty,
  Button,
  Popconfirm,
  InputNumber,
  DatePicker,
  Input,
  Switch,
  Avatar,
} from "antd";
import Search from "antd/lib/input/Search";
import { Form } from "antd";
import { Table, message } from "antd";
import { format } from "date-fns";
import ButtonGroup from "antd/lib/button/button-group";
import { useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../components/loading_indicator";
import Error from "../components/apollo_error";
import ImagUpload from "../components/image_upload";
import MultiSelect from "../components/multi_select";
import {
  LoadingOutlined,
  EditOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Select } from "antd";
import {
  create_one_leave,
  getLeaveVar,
  getUpdateOneLeaveVar,
  get_leaves,
  update_one_leave,
} from "../graphql/leave";
import {
  getPractitionersVar,
  get_practitioners,
} from "../graphql/practitioner";
const { TextArea } = Input;
const { Paragraph } = Typography;

interface P {
  currentClinic?: Clinic;
}
const leaveStatus = ["PENDING", "APPROVE", "REJECTED"];
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  required: boolean;
  editOnOld: boolean;
  title: any;
  inputType:
    | "number"
    | "text"
    | "switch"
    | "textarea"
    | "select"
    | "img"
    | "date";
  record: GTNotification;
  options?: any[];
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  options,
  editing,
  dataIndex,
  required,
  title,
  inputType,
  editOnOld,
  record,
  index,
  children,
  ...restProps
}) => {
  const isNew = record?.id?.startsWith("new_");
  let inputNode: any = <Input disabled />;
  if (inputType === "number") {
    if (isNew || (!isNew && editOnOld)) {
      inputNode = <InputNumber />;
    }
  }
  if (inputType === "switch") {
    if (isNew || (!isNew && editOnOld)) {
      inputNode = <Switch defaultChecked={record.status === "ACTIVE"} />;
    }
  }
  if (inputType === "select") {
    inputNode = (
      <Select
        defaultValue={(record as any)[dataIndex]}
        style={{ width: 120 }}
        options={options || []}
        disabled={!isNew && !editOnOld}
      />
    );
  }
  if (inputType === "textarea") {
    if (isNew || (!isNew && editOnOld)) {
      inputNode = (
        <TextArea
          key={`${dataIndex}`}
          rows={5}
          defaultValue={(record as any)[dataIndex]}
        />
      );
    }
  }
  if (inputType === "img") {
    if (isNew || (!isNew && editOnOld)) {
      inputNode = <ImagUpload defaultValue={(record as any)[dataIndex]} />;
    }
  }
  if (inputType === "date") {
    if (isNew || (!isNew && editOnOld)) {
      inputNode = (
        <DatePicker defaultValue={null} showTime key={`date_${dataIndex}`} />
      );
    }
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const LeavePage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [leaves, setLeaves] = useState<GTLeave[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: GTLeave) => record.id === editingKey;
  const isNew = (r: GTLeave) => r.id.startsWith("new_");
  const variables = getLeaveVar({
    clinicId,
    searchText: searchText || null,
    status: filter,
  });
  const { loading, data, error, refetch } = useQuery<
    ApolloResult<"practitionerLeaves", GTLeave[]>
  >(get_leaves, { variables });
  const var2 = getPractitionersVar({
    clinicId,
    startDate: new Date(),
    endDate: new Date(),
    searchText: null,
    take: 150,
    status: ["ACTIVE"],
  });
  const { loading: pacLoad, data: pacData } = useQuery<
    ApolloResult<"practitioners", Practitioner[]>
  >(get_practitioners, { variables: var2 });
  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOnePractitionerLeave", GTLeave>>(
      update_one_leave
    );
  const [create, { loading: createLoading }] =
    useMutation<ApolloResult<"createOnePractitionerLeave", GTLeave>>(
      create_one_leave
    );
  useEffect(() => {
    if (data?.practitionerLeaves) {
      setLeaves(data.practitionerLeaves);
    }
  }, [data]);
  if (loading || pacLoad) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const edit = (r: GTLeave) => {
    form.setFieldsValue({ ...r });
    setEditingKey(r.id!);
  };
  const add = () => {
    const newData: GTLeave = {
      id: `new_${new Date().getTime()}`,
      from_time: new Date(),
      to_time: new Date(),
      status: "PENDING",
      practitioner_id: "unknown",
      created_at: new Date(),
    };
    const newList = [...leaves];
    newList.unshift(newData);
    setLeaves(newList);
    setEditingKey(newData.id);
  };
  const save = async (r: GTLeave) => {
    if (r.id.startsWith("new_")) {
      await onCreate(r);
    } else {
      let formData = (await form.validateFields()) as GTLeave;
      const fData = { status: formData.status, remark: formData.remark };
      const result = await update({
        variables: getUpdateOneLeaveVar(r.id, fData),
      });
      if (result.data?.updateOnePractitionerLeave) {
        message.success("update success");
        await refetch();
      } else {
        message.error("update failed");
      }
    }
    setEditingKey("");
    form.resetFields();
  };
  const onCreate = async (r: GTLeave) => {
    let formData = (await form.validateFields()) as any;
    let fData = { ...formData };
    const practitionerId = formData.practitioner_id;
    const from_time = new Date(
      formData.from_time.local().format("YYYY-MM-DD HH:mm")
    );
    const to_time = new Date(
      formData.to_time.local().format("YYYY-MM-DD HH:mm")
    );
    fData.from_time = from_time;
    fData.to_time = to_time;
    delete fData["id"];
    delete fData["practitioner_id"];
    fData = { ...fData, clinic: { connect: { id: clinicId } } };
    fData = { ...fData, practitioner: { connect: { id: practitionerId } } };
    const result = await create({
      variables: {
        data: fData,
      },
    });
    if (result.data?.createOnePractitionerLeave) {
      message.success("create success");
      await refetch();
    } else {
      message.error(`create failed`);
    }
  };
  const onCancel = async (r: GTLeave) => {
    if (r.id.startsWith("new_")) {
      const newList = [...leaves];
      newList.shift();
      setLeaves(newList);
    }
    form.resetFields();
    setEditingKey("");
  };
  const columns: any[] = [
    {
      title: "Therapist",
      dataIndex: "practitioner_id",
      key: "practitioner_id",
      editable: true,
      required: true,
      editOnOld: false,
      render: (v: number, r: GTLeave) => {
        return r.practitioner?.name || r.practitioner_id;
      },
    },
    {
      title: "From",
      dataIndex: "from_time",
      key: "from_time",
      editable: true,
      required: true,
      editOnOld: false,
      sorter: (a: GTLeave, b: GTLeave) =>
        new Date(a.from_time).getTime() - new Date(b.from_time).getTime(),
      render: (v: number, r: GTLeave) => {
        return format(new Date(r.from_time), "dd MMM,yyyy h:mm a");
      },
    },
    {
      title: "To",
      dataIndex: "to_time",
      key: "to_time",
      editable: true,
      required: true,
      editOnOld: false,
      sorter: (a: GTLeave, b: GTLeave) =>
        new Date(a.to_time).getTime() - new Date(b.to_time).getTime(),
      render: (v: number, r: GTLeave) => {
        return format(new Date(r.to_time), "dd MMM,yyyy h:mm a");
      },
    },
    {
      title: "Staus",
      dataIndex: "status",
      key: "status",
      sorter: (a: GTLeave, b: GTLeave) => a.status.localeCompare(b.status),
      editable: true,
      required: true,
      editOnOld: true,
    },
    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      editable: true,
      required: false,
      editOnOld: true,
      render: (v: number, r: GTLeave) => {
        return (
          <Paragraph
            style={{ maxWidth: 230 }}
            ellipsis={{ rows: 3, expandable: false }}
          >
            {r.remark}
          </Paragraph>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "center",
      width: "10%",
      dataIndex: "action",
      editable: false,
      required: false,
      editOnOld: false,
      fixed: "right",
      render: (v: number, record: GTLeave) => {
        const editable = isEditing(record);
        const n = isNew(record);
        return (
          <ButtonGroup>
            {editable ? (
              saveLoading || createLoading ? (
                <Button type="primary" icon={<LoadingOutlined />}>
                  saving...
                </Button>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={`Sure to save ${
                    record.practitioner?.name || "New Leave"
                  }?`}
                  onConfirm={() => save(record)}
                  onCancel={() => onCancel(record)}
                >
                  <Button type="primary" icon={<SaveOutlined />}>
                    save
                  </Button>
                </Popconfirm>
              )
            ) : (
              <Button
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => edit(record)}
              />
            )}
          </ButtonGroup>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    let inputType = "textarea";
    if (col.dataIndex === "practitioner_id" || col.dataIndex === "status") {
      inputType = "select";
    }
    if (col.dataIndex === "from_time" || col.dataIndex === "to_time") {
      inputType = "date";
    }
    const options =
      col.dataIndex === "practitioner_id"
        ? (pacData?.practitioners || []).map((pac) => ({
            label: pac.name,
            value: pac.id,
          }))
        : leaveStatus.map((s) => ({ label: s, value: s }));
    return {
      ...col,
      onCell: (record: GTLeave) => ({
        record,
        options,
        required: col.required,
        inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editOnOld: col.editOnOld,
        editing: isEditing(record),
      }),
    };
  });

  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Leaves</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder="search ...."
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <Button type="primary" icon={<PlusCircleOutlined />} onClick={add}>
          Add
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={saveLoading || createLoading || loading}
            showHeader={leaves.length > 0}
            components={{ body: { cell: EditableCell } }}
            dataSource={leaves.map((b) => ({ key: b.id, ...b }))}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4 }}>
      <Col span={24}>
        <MultiSelect
          selected={filter}
          placeholder="Select leave status"
          options={leaveStatus}
          onChange={setFilter}
        />
      </Col>
    </Row>
  );
  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(LeavePage);
