import { gql } from "@apollo/client";
import { Bank } from "../store";

export const get_banks = gql`
query Banks($orderBy: [BankOrderByWithRelationInput!]) {
    banks(orderBy: $orderBy) {
      id
      logo
      name
      created_at
      code
    }
  }
  
`;

export const getBanksVar = () => ({
    "orderBy": [
        {
            "updated_at": "desc"
        }
    ]
});

export const set_one_bank = gql`
mutation UpsertOneBank(
    $where: BankWhereUniqueInput!
    $create: BankCreateInput!
    $update: BankUpdateInput!
  ) {
    upsertOneBank(where: $where, create: $create, update: $update) {
      id
    }
  }  
`;

export const getSetOneBankVar = (data: Bank) => ({
    "where": {
        "code": data.code
    },
    "create": {
        "code": data.code,
        "logo": data.logo,
        "name": data.name
    },
    "update": {
        "logo": {
            "set": data.logo
        },
        "name": {
            "set": data.name
        }
    }
})