import React, { useEffect } from "react";
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { UserInfo, actions, User } from "../../store";
import { connect } from "react-redux";
import LoginForm from "./login_form";
import backgroundImage from "./cover.png";
import bgImage2 from "./cover.jpg";
import logo from "../../assets/logo.png";
import paths from "../../routes/paths";
import { Card } from "antd";
interface P {
  loggedIn: (info: User) => void;
}
function Login(props: P) {
  let Navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const authListener = onAuthStateChanged(getAuth(), async (authUser) => {
      if (authUser) {
        let path = paths.home;
        let searchParams: string | null = null;
        const token = await authUser.getIdTokenResult();
        if (token.claims) {
          props.loggedIn(token.claims as User);
        }
        if (location.state?.from) {
          path = location.state.from;
        }
        if (location.state?.search) {
          searchParams = location.state.search;
        }
        // if (location.search && typeof location.search === "string") {
        //   //custom route
        //   const nextroutes = location.search.split("=");
        //   if (nextroutes.length === 2) {
        //     path = nextroutes[1];
        //   }
        // }
        if (searchParams) {
          Navigate(
            { pathname: path, search: `?${searchParams}` },
            { replace: true }
          );
        } else {
          Navigate(path, { replace: true });
        }
      }
    });
    return authListener;
  }, [props, location]);


  return (
    <div style={styles.container}>
      <Card bordered={true} style={styles.card}>
        <img src={logo} alt="" width={"50px"} style={styles.img} />
        <p style={styles.title as any}>Great Admin</p>
        <div style={styles.login}>
          <LoginForm />
        </div>
      </Card>
    </div>
  );
}
const mapStateToDispatch = (dispatch: any) => {
  return {
    loggedIn: (info: User) => dispatch(actions.authAction.loggedIn(info)),
  };
};
export default connect(null, mapStateToDispatch)(Login) as any;

const styles = {
  container: {
    display: "flex",
    justifyContent: "right",
    alignItems: "flex-end",
    padding: 50,
    height: "100vh",
    backgroundColor: "black",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  card: {
    width: "auto",
    boxShow: "10px 5px 5px white",
  },
  title: {
    paddingBottom: "5px",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "bold",
  },
  img: {
    margin: "auto",
    display: "block",
  },

  login: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
