import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Row,
  Col,
  Typography,
  Empty,
  Card,
  Popconfirm,
  Table,
  List,
  Avatar,
  Button,
  message,
  Rate,
} from "antd";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import { format } from "date-fns";
import withClinic from "../../hooks/with_clinic";
import {
  delete_one_service,
  getDeleteOneSrvVar,
  get_one_service,
  update_one_service,
} from "../../graphql/service";
import {
  Service,
  ApolloResult,
  Clinic,
  Practitioner,
  GTRating,
  SystemLog,
  User,
  ServiceRoom,
  ServiceFormType,
} from "../../store";
import {
  getPractitionersVar,
  get_practitioners,
} from "../../graphql/practitioner";
import { formatMoney } from "../../helpers/utils";
import MultiSelect2 from "../../components/mulit_select2";
import { orderBy } from "lodash";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import paths from "../../routes/paths";
import { create_one_sys_lg } from "../../graphql/system_log";
import { getServiceRoomsVar, service_rooms } from "../../graphql/service_room";
import {
  getServiceFormTypeVar,
  get_service_from_type_slims,
} from "../../graphql/service_form";
import { Select } from "antd";
import ServiceDetailForm from "./service_detail_form";
const { Meta } = Card;
interface P {
  currentClinic?: Clinic;
  user?: User;
}
const baseTabs = [
  {
    key: "info",
    tab: "Service Info",
  },
  {
    key: "therapist",
    tab: "Service Therapists",
  },
  {
    key: "rate",
    tab: "Service Rating",
  },
  {
    key: "room",
    tab: "Service Rooms",
  },
  {
    key: "form",
    tab: "Record Form",
  },
  {
    key: "description",
    tab: "Description",
  },
];
function ServiceDetail(props: P) {
  const { id: serviceId = "" } = useParams() as { id?: string };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [activeTabKey, setActiveTabKey] = useState<string>("info");
  const [tabs, setTabs] = useState([...baseTabs]);
  const [currentTherapistIds, setCurrentTherapistIds] = useState<string[]>([]);
  const [currentRoomIds, setCurrentRoomIds] = useState<string[]>([]);
  const [therapistChanged, setTherapistChanged] = useState<boolean>(false);
  const [roomChanged, setRoomChanged] = useState<boolean>(false);
  const { data, loading, error, refetch } = useQuery<
    ApolloResult<"service", Service>
  >(get_one_service, { variables: { where: { id: serviceId } } });
  const var2 = getPractitionersVar({
    clinicId,
    startDate: new Date(),
    endDate: new Date(),
    searchText: null,
    take: 150,
    status: ["ACTIVE"],
  });
  const { loading: pacLoad, data: pacData } = useQuery<
    ApolloResult<"practitioners", Practitioner[]>
  >(get_practitioners, { variables: var2 });
  const variables = getServiceRoomsVar(clinicId);
  const { data: srvRooms } = useQuery<
    ApolloResult<"serviceRooms", ServiceRoom[]>
  >(service_rooms, { variables });
  const [getRecordFroms, { loading: rfLoading, data: rfData }] = useLazyQuery<
    ApolloResult<"serviceFormTypes", ServiceFormType[]>
  >(get_service_from_type_slims, {
    variables: getServiceFormTypeVar({
      clinicId,
      types: ["RECORD"],
      status: ["ACTIVE"],
    }),
  });
  const [update, { loading: saveLoading }] =
    useMutation<ApolloResult<"updateOneService", Service>>(update_one_service);
  const [deleteOne, { loading: delLoading }] =
    useMutation<ApolloResult<"deleteOneService", Service>>(delete_one_service);
  const [creatLog, { loading: lgSaveLoading }] =
    useMutation<ApolloResult<"createOneSystemLog", SystemLog>>(
      create_one_sys_lg
    );
  useEffect(() => {
    if (searchParams.get("mode")) {
      const mode = searchParams.get("mode");
      if (mode === "delete") {
        setTabs((prev) =>
          [...prev].concat([{ key: "action", tab: "Actions" }])
        );
      }
    }
  }, [searchParams.get("mode")]);
  useEffect(() => {
    if (data?.service) {
      setCurrentTherapistIds(data.service.practitioners.map((p) => p.id));
      setCurrentRoomIds(data.service.rooms.map((r) => r.id));
    }
  }, [data]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data || !data.service) return <Empty description={"No service"} />;
  const handleTherapistChange = (ids: string[]) => {
    setCurrentTherapistIds(ids);
    setTherapistChanged(true);
  };
  const handleRoomChange = (ids: string[]) => {
    setCurrentRoomIds(ids);
    setRoomChanged(true);
  };
  const handleServiceDelete = async () => {
    if (!data || !data.service) return;
    const variables = getDeleteOneSrvVar(data.service.id, {
      practionIds: data.service.practitioners.map((prc) => prc.id),
      serviceTypeIds: data.service.service_types.map((st) => st.id),
    });
    const result = await deleteOne({ variables });
    if (result.data?.deleteOneService) {
      const log_id = `${data.service.id}_${new Date().getTime()}`;
      const code = `${data.service.id}`;
      const metadata = JSON.stringify({
        type: "service",
        action: "delete",
        context: data.service,
      });
      const user = { connect: { id: props.user?.userId } };
      const var2 = { data: { code, log_id, metadata, user } };
      await creatLog({ variables: var2 });
      navigate(paths.services);
    }
  };
  const handleTherapistSave = async () => {
    let variables: any = {};
    variables.where = { id: serviceId };
    variables.data = {
      practitioners: {
        disconnect: data.service.practitioners.map((p) => ({ id: p.id })),
        connect: currentTherapistIds.map((id) => ({ id })),
      },
    };
    const res = await update({ variables });
    if (res.data?.updateOneService) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
    setTherapistChanged(false);
  };
  const handleRoomSave = async () => {
    let variables: any = {};
    variables.where = { id: serviceId };
    variables.data = {
      rooms: {
        disconnect: data.service.rooms.map((p) => ({ id: p.id })),
        connect: currentRoomIds.map((id) => ({ id })),
      },
    };
    const res = await update({ variables });
    if (res.data?.updateOneService) {
      message.success("update success");
      await refetch();
    } else {
      message.error("update failed");
    }
    setRoomChanged(false);
  };
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
    if (key === "form") {
      if (!rfData?.serviceFormTypes) {
        getRecordFroms();
      }
    }
  };
  const ServiceInfo = () => {
    return (
      <Card
        size="small"
        type="inner"
        hoverable
        style={{ width: 240 }}
        cover={<img alt="service" src={data.service.image} />}
      >
        <Meta
          title={data.service.name}
          description={`Price: ${formatMoney(Number(data.service.price))} MMK`}
        />
      </Card>
    );
  };
  const ServiceRate = () => {
    return (
      <Card size="small" type="inner">
        <List
          itemLayout="horizontal"
          dataSource={orderBy(data.service.ratings, "created_at", "desc")}
          renderItem={(item: GTRating) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={item.member.image} />}
                title={
                  <>
                    <Link to={paths.getMemberDetailRoute(item.member.id)}>
                      {item.member.name}
                    </Link>
                    <span>
                      {" "}
                      <Rate allowHalf defaultValue={item.rate} />
                    </span>
                  </>
                }
                description={item.note}
              />
            </List.Item>
          )}
        />
      </Card>
    );
  };

  const ServiceTherapist = () => {
    return (
      <Card
        size="small"
        type="inner"
        title={therapistChanged && "Therapists"}
        extra={
          therapistChanged && (
            <Button
              type="primary"
              icon={<SaveOutlined />}
              loading={saveLoading}
              onClick={handleTherapistSave}
            >
              save
            </Button>
          )
        }
      >
        <MultiSelect2
          options={(pacData?.practitioners || []).map((pc) => ({
            value: pc.id,
            label: pc.name,
          }))}
          selected={currentTherapistIds}
          placeholder="select therapists"
          onChange={handleTherapistChange}
        />
      </Card>
    );
  };
  const ServiceRoomC = () => {
    return (
      <Card
        size="small"
        type="inner"
        title={roomChanged && "Service Rooms"}
        extra={
          <>
            {roomChanged && (
              <Button
                type="primary"
                icon={<SaveOutlined />}
                loading={saveLoading}
                onClick={handleRoomSave}
              >
                save
              </Button>
            )}
            <Link to={paths.serviceRooms} style={{ marginLeft: 15 }}>
              See More Rooms...
            </Link>
          </>
        }
      >
        <MultiSelect2
          options={(srvRooms?.serviceRooms || []).map((pc) => ({
            value: pc.id,
            label: pc.name,
          }))}
          selected={currentRoomIds}
          placeholder="select service rooms"
          onChange={handleRoomChange}
        />
      </Card>
    );
  };
  const ServiceRF = () => {
    return (
      <Card
        loading={rfLoading || saveLoading}
        size="small"
        type="inner"
        extra={
          <>
            <Link to={paths.service_record_froms} style={{ marginLeft: 15 }}>
              See More ...
            </Link>
          </>
        }
      >
        <Select
          disabled={saveLoading}
          defaultValue={data.service.form_type_id}
          style={{ width: 250 }}
          onChange={async (formTypeId: string) => {
            let variables: any = {};
            variables.where = { id: serviceId };
            variables.data = {
              form_type: { connect: { id: formTypeId } },
            };
            const res = await update({ variables });
            if (res.data?.updateOneService) {
              message.success("update success");
              await refetch();
            } else {
              message.error("update failed");
            }
          }}
          options={rfData?.serviceFormTypes.map((rf) => ({
            value: rf.id,
            label: rf.name,
          }))}
        />
      </Card>
    );
  };
  const Actons = () => {
    return (
      <Card
        size="small"
        type="inner"
        style={{
          display: "flex",
          placeContent: "center",
          alignItems: "center",
        }}
      >
        <Popconfirm
          title={`Are you sure to delete service (${data.service.name})  at clinic (${props.currentClinic?.name})`}
          okText="Yes"
          cancelText="No"
          onConfirm={handleServiceDelete}
        >
          <Button
            icon={<DeleteOutlined />}
            // onClick={handleServiceDelete}
            loading={delLoading}
            danger
          >
            Delete
          </Button>
        </Popconfirm>
      </Card>
    );
  };

  const tabContext: Record<string, React.ReactNode> = {
    info: <ServiceInfo />,
    rate: <ServiceRate />,
    therapist: <ServiceTherapist />,
    action: <Actons />,
    room: <ServiceRoomC />,
    form: <ServiceRF />,
    description: (
      <ServiceDetailForm
        serviceId={serviceId}
        description={data.service?.description}
      />
    ),
  } as any;

  return (
    <React.Fragment>
      <Card
        title={data.service?.name + " Service"}
        loading={pacLoad}
        tabList={tabs}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
      >
        {tabContext[activeTabKey]}
      </Card>
    </React.Fragment>
  );
}
export default withClinic(ServiceDetail);
const styles = {
  card: {
    height: "100%",
  },
};
