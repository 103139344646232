
import { Row, Col, Typography } from "antd";
import "chartjs-adapter-moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from "chart.js";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const options = (title: string) => ({
  response: true,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: true,
      text: title,
      font: {
        size: 24
      }
    },
  },
  scales: {
    x: {
      type: "time",
      time: {
        unit: "day"
      }
    },
    y: {
      display: false,
    }
  }
});

const values = [
  {
    x: new Date("2020-01-01"),
    y: 100.2
  },
  {
    x: new Date("2020-01-02"),
    y: 102.2
  },
  {
    x: new Date("2020-01-03"),
    y: 105.3
  },
  {
    x: new Date("2020-01-11"),
    y: 104.4
  }
];

const getDataSource = (data: any[]) => ({
  datasets: [
    {
      fill: true,
      label: "sales",
      data,
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    }
  ]
});
interface P {
  data: Array<{ x: Date, y: number }>
  title: string
}
function LineChart(props: P) {
  const { title } = props;
  return (
    <>
      <Line options={options(title) as any} data={getDataSource(props.data)} />
    </>
  );
}

export default LineChart;
