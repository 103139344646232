import { Action } from "redux";
import { getAuth } from "@firebase/auth";
import { AuthState, User, UserInfo } from "../state";
import { LoginActionTypes as actionTypes } from "../actions/action_types";
import { decryptData, encryptData } from "../../helpers/crypto";
const { LOGGED_IN, LOGOUT_REQUEST, LOGGED_OUT } = actionTypes;

export const actions = {
  logginIn: () => ({
    type: actionTypes.LOGGING_IN,
    payload: {},
  }),
  loggedIn: (userInfo: User) => ({
    type: LOGGED_IN,
    payload: { userInfo },
  }),
  logOutRequest: () => ({
    type: LOGOUT_REQUEST,
    payload: {},
  }),
  loggedOut: () => ({
    type: LOGGED_OUT,
    payload: {},
  }),
};

type LoggedAction = Action & {
  payload: { userInfo: UserInfo } | { error: string };
};
export const reducers = (
  state: AuthState = { status: "none" },
  action: LoggedAction
) => {
  switch (action.type) {
    case actionTypes.LOGGING_IN: {
      return {
        status: "loading",
      };
    }
    case LOGGED_IN: {
      const { userInfo } = action.payload as { userInfo: UserInfo };
      if (userInfo) {
        encryptData("userInfo", { status: "loggedIn", userInfo });
        // encryptData("clinicInfo", "");
      }
      return {
        status: "loggedIn",
        userInfo,
      };
    }
    case LOGGED_OUT:
      encryptData("userInfo", { status: "none" });
      getAuth().signOut();
      // localStorage.removeItem("userInfo");
      // localStorage.removeItem("clinic");
      localStorage.clear();
      return { status: "none" };
    default:
      break;
  }

  return state;
};
