import React, { useEffect, useState } from "react";
import { Button, Result, message, Upload, Typography } from "antd";
import {
  ImportOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import * as XLSX from "xlsx";
import type { UploadProps } from "antd";
import { ExportExcel } from "../../helpers/excel";
import { useMutation } from "@apollo/client";
import { ApolloResult, Clinic } from "../../store";
import {
  create_many_member,
  many_member_join_clinic,
  getCreateManyMemberVar,
  getManyMemberJoinClinicVar,
  delete_many_member,
} from "../../graphql/member";
import withClinic from "../../hooks/with_clinic";
import { checkPhoneE164 } from "../../helpers/utils";
import { chunk } from "lodash";
import {
  create_many_clinic_member,
  delete_many_clinic_member,
  getDeleteManyClinicMemberVar,
} from "../../graphql/clinic_member";
import { getCreateManyClinicMemberVar } from "../../graphql/clinic_member";
const { Paragraph, Text } = Typography;
interface P {
  currentClinic?: Clinic;
}
interface ImportData {
  name: string;
  phoneNumber: string;
  memberId?: string;
  dateOfBirth?: string; // yy/mm/dd
  gender?: string; //Male/Female
}
const MemberImport = (p: P) => {
  const clinicId = p.currentClinic?.id || null;
  const [data, setData] = useState<ImportData[]>([]);
  const [loading, setLoading] = useState(false);
  const [createMembers, { loading: mbrLoading }] =
    useMutation<ApolloResult<"createManyMember", { count: number }>>(
      create_many_member
    );
  const [deleteMembers, { loading: delLoading }] =
    useMutation<ApolloResult<"deleteManyMember", { count: number }>>(
      delete_many_member
    );
  const [membersJoinClinic, { loading: mbrcLoading }] = useMutation<
    ApolloResult<"updateOneClinic", { id: string }>
  >(many_member_join_clinic);
  const [createClinicMembers, { loading: cmloading }] = useMutation<
    ApolloResult<"createManyClinicMember", { count: number }>
  >(create_many_clinic_member);
  const [deleteClinicMembers, { loading: dmloading }] = useMutation<
    ApolloResult<"deleteManyClinicMember", { count: number }>
  >(delete_many_clinic_member);
  const props: any = {
    beforeUpload: async (file: File) => {
      const isExcel =
        file.type === "application/vnd.ms-excel" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        message.error(`${file.name} is not support excel file`);
      }

      var reader = new FileReader();
      reader.onload = function (e: any) {
        var data = e.target.result;
        var excelData = XLSX.read(data, {
          type: "binary",
        });
        let mbrs: ImportData[] = [];
        for (const sheet of excelData.SheetNames) {
          const sData = XLSX.utils.sheet_to_json(excelData.Sheets[sheet]);
          mbrs = sData as ImportData[];
        }
        setLoading(false);
        // if (products.length > 100) {
        //     message.error(`Maximum(100) import limit exceeded`);
        // } else {
        //     setData(products);
        // }
        setData(mbrs);
      };
      reader.onerror = function (ex) {
        setLoading(false);
        // console.log(ex);
      };
      reader.readAsBinaryString(file);
      return false;
    },
    onChange: (info: any) => {
      setLoading(true);
      // console.log("fileinfo", info.fileList);
    },
  };
  const sampleExport = () => {
    const fileName = "member_import";
    let excelData: ImportData[] = [];
    excelData.push({
      name: "M one",
      phoneNumber: "91234567",
      memberId: "M0001",
      dateOfBirth: "2015-12-23",
      gender: "Male",
    });
    excelData.push({
      name: "M Two",
      phoneNumber: "959222222",
      memberId: "M0002",
      dateOfBirth: "1995-10-03",
      gender: "Female",
    });
    ExportExcel(excelData, fileName);
  };
  const filterMembers = () => {
    let newList: Array<{
      name: string;
      phonenumber: string;
      password: string;
      member_id?: string | null;
      metadata?: string;
    }> = [];
    for (const mi of data) {
      let metadata: any = {
        bodyweight: "",
        dateOfBirth: "",
        gender: "",
        address: "",
        nationality: "",
      };
      const { name, phoneNumber, memberId, dateOfBirth, gender } = mi;
      const phonenumber = checkPhoneE164(`${phoneNumber}`);
      if (dateOfBirth) {
        metadata.dateOfBirth = `${dateOfBirth}`;
      }
      if (gender) {
        metadata.gender = gender;
      }
      if (phonenumber) {
        newList.push({
          name,
          phonenumber,
          password: "",
          member_id: memberId ? `${memberId}` : null,
          metadata: JSON.stringify(metadata),
        });
      }
    }
    return newList;
  };
  const handleImport = async () => {
    if (!clinicId) {
      message.error(`Invalid clinic`);
      return;
    }
    const list = filterMembers();
    const split_list = chunk(list, 100);
    let successCount = 0,
      failedCount = 0;
    for (const members of split_list) {
      // const res = await deleteMembers({ variables: getDeleteManyMemberVar(members.map(mbr => mbr.phonenumber)) });
      // if (res.data?.deleteManyMember) {
      //     console.log('response', res)
      // }
      const create = await createMembers({
        variables: getCreateManyMemberVar(
          members.map((m) => ({ ...m, member_id: "" })),
          true
        ),
      });
      if (create.data?.createManyMember) {
        successCount += Number(create.data?.createManyMember.count || 0);
        const join = await membersJoinClinic({
          variables: getManyMemberJoinClinicVar(
            members.map((mbr) => ({ phonenumber: mbr.phonenumber })),
            clinicId
          ),
        });
        if (join.data?.updateOneClinic.id) {
          message.success(
            `successfully added ${members.length} members to ${p.currentClinic?.name}`
          );
          // clean clinic members
          const dvar = getDeleteManyClinicMemberVar(
            clinicId,
            list.map((l) => l.phonenumber)
          );
          await deleteClinicMembers({ variables: dvar });
          // join clinic members
          const var3 = getCreateManyClinicMemberVar(
            clinicId,
            list.map((l) => ({
              name: l.name,
              member_id: l.member_id,
              phonenumber: l.phonenumber,
              metadata: l.metadata,
            }))
          );
          const cmJoin = await createClinicMembers({ variables: var3 });
          if (cmJoin.data?.createManyClinicMember.count) {
            message.success(
              `successfully added ${cmJoin.data?.createManyClinicMember.count} clinc members to ${p.currentClinic?.name}`
            );
          }
        }
      } else {
        failedCount += members.length;
      }
    }
    if (successCount > 0) {
      message.success(`successfully imported ${successCount} members`);
    }
    if (failedCount > 0) {
      message.error(`Failed import ${failedCount} members`);
    }
    setData([]);
  };
  return (
    <Result
      status="info"
      icon={<ImportOutlined />}
      title="Member excel import!"
      subTitle="Download a sample sample xls file and compare it to your import file to ensure you have the file perfect for the import."
      extra={[
        <Button type="primary" key="console" onClick={sampleExport}>
          Download sample
        </Button>,
        <Upload
          accept=".xlsx, .xls"
          showUploadList={false}
          maxCount={1}
          {...props}
        >
          <Button icon={loading ? <LoadingOutlined /> : <UploadOutlined />}>
            Upload Excel File
          </Button>
        </Upload>,
      ]}
    >
      {data.length > 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you uploaded has {data.length}{" "}
              {data.length > 1 ? "rows" : "row"}:
            </Text>
          </Paragraph>
          <Button
            onClick={handleImport}
            type="primary"
            loading={mbrLoading || mbrcLoading || delLoading}
            disabled={data.length <= 0}
          >
            Import to clinic ({p.currentClinic?.name})
          </Button>
        </div>
      )}
    </Result>
  );
};
export default withClinic(MemberImport);
