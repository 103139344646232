import React, { useEffect, useState } from 'react';
import { BankOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Button, Row, Form, Space, Input } from 'antd';
import { Bank, BankAccount } from '../store';
import type { FormInstance } from 'antd/es/form';
import { Select } from 'antd';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
interface P {
    title?: string;
    accounts: BankAccount[];
    banks: Bank[];
    saving: boolean;
    onSave: (val: BankAccount[]) => void
}
interface FormData {
    bank_accounts: BankAccount[];
}
const BankAccountList = (props: P) => {
    const formRef = React.useRef<FormInstance<FormData>>(null);
    const [show, setShow] = useState(false);
    const handleOk = (val: FormData) => {
        props.onSave(val.bank_accounts);
        setShow(false)
    }
    useEffect(() => {
        if (show == true) {
            const fData: FormData = { bank_accounts: props.accounts }
            formRef.current?.setFieldsValue({ ...fData });
        }
    }, [show])
    return (
        <React.Fragment>
            <Button
                loading={props.saving || false}
                icon={<BankOutlined />}
                onClick={() => {
                    setShow(true);
                }}
            />
            <Modal
                title={`${props.title || ""}`}
                open={show}
                onCancel={() => setShow(false)}
                footer={[
                    <Button loading={props.saving} type="primary" form="banklistForm" key="submit" htmlType="submit">
                        Save
                    </Button>
                ]}
                width={800}
            >
                <Form
                    //  {...layout}
                    id="banklistForm"
                    ref={formRef}
                    name="control-ref"
                    onFinish={handleOk}
                    style={{ maxWidth: 800 }}
                >
                    <Form.Item>
                        <Form.List name="bank_accounts"  >
                            {(fields: any, { add, remove }: any) => (
                                <>
                                    {fields.map(({ key, name, ...restField }: any) => (
                                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                            <Form.Item
                                                {...restField}
                                                label="id"
                                                name={[name, 'id']}
                                                style={{ display: 'none' }}
                                            //  rules={[{ required: true, message: 'Missing first name' }]}
                                            >
                                                <Input placeholder="id" disabled />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                label="Type"
                                                name={[name, 'account_type_id']}
                                                rules={[{ required: true, message: 'Account type is required' }]}
                                            >
                                                <Select
                                                    options={props.banks.map(b => ({ value: b.id, label: b.name }))}
                                                    style={{ width: 150 }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                label="Name"
                                                name={[name, 'account_name']}
                                                rules={[{ required: true, message: 'Account name is required' }]}
                                            >
                                                <Input placeholder="account name" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                label="Number"
                                                name={[name, 'account_number']}
                                                rules={[{ required: true, message: 'Account number is required' }]}
                                            >
                                                <Input placeholder="account number" />
                                            </Form.Item>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Space>
                                    ))}
                                    <Form.Item>
                                        <Button type="dashed" onClick={() => add({ id: "new" })} block icon={<PlusCircleOutlined />}>
                                            Add
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Modal >
        </React.Fragment >
    );
};

export default BankAccountList;