import { gql } from "@apollo/client";

export const service_type_categories = gql`
query ServiceTypeCategories(
    $where: ServiceTypeCategoryWhereInput
    $orderBy: [ServiceTypeCategoryOrderByWithRelationInput!]
  ) {
    serviceTypeCategories(where: $where, orderBy: $orderBy) {
      id
      is_private
      name
      image
      status
      created_at
      description
      order
      sale_channel
    }
  }
`;

export const getServiceTypeCategoriesVar = (clinicId: string) => ({
  where: { clinic_id: { equals: clinicId } },
  orderBy: [{ updated_at: "desc" }]
});

export const update_one_service_type = gql`
mutation UpdateOneServiceTypeCategory(
  $data: ServiceTypeCategoryUpdateInput!
  $where: ServiceTypeCategoryWhereUniqueInput!
) {
  updateOneServiceTypeCategory(data: $data, where: $where) {
    id
  }
}

`;

export const getUpdateOneServiceTypeCategoryVar = (id: string, data: any, connectors?: any) => {
  let variables: any = {}, update: any = {};
  variables.where = { id }
  if (typeof data === 'object') {
    update = Object.assign({}, ...Object.keys(data).map((k, i) => ({ [k]: { "set": data[k] } })))
  }
  variables.data = update;
  if (connectors && typeof connectors === 'object') {
    variables.data = { ...variables.data, ...connectors }
  }
  return variables;
}


export const create_one_service_type = gql`
mutation CreateOneServiceTypeCategory($data: ServiceTypeCategoryCreateInput!) {
  createOneServiceTypeCategory(data: $data) {
    id
  }
}
`;