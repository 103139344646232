import { useMutation, useQuery } from "@apollo/client";
import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  ApolloResult,
  ClinicMember,
  ClinicMemberUpdate,
  Practitioner,
} from "../../store";
import {
  getPractitionersVar,
  get_practitioners,
} from "../../graphql/practitioner";
import {
  getOneClinicMember,
  getOneClinicMemberVar,
  updateOneClinicMember,
  updateOneClinicMemberVar,
} from "../../graphql/clinic_member";
import { message } from "antd";

const { Option } = Select;

interface InputProps {
  therapist?: string;
}

interface FormInputProps {
  value?: InputProps;
  onChange?: (value: InputProps) => void;
  practitionerList: Practitioner[];
}

const Input: React.FC<FormInputProps> = ({
  value = {},
  onChange,
  practitionerList,
}) => {
  const [therapist, setTherapist] = useState<string>("");

  const triggerChange = (changedValue: { therapist?: string }) => {
    onChange?.({ therapist: therapist, ...value, ...changedValue });
  };

  const onTherapistChange = (newTherapist: string) => {
    setTherapist(newTherapist);
    triggerChange({ therapist: newTherapist });
  };

  return (
    <span>
      <Form.Item
        name="therapist"
        label="Therapist"
        style={{ margin: "8px 0", width: "100%" }}
      >
        <Select
          value={value.therapist || therapist}
          style={{ width: "12em", margin: "0 20px 0 0" }}
          onChange={onTherapistChange}
        >
          {practitionerList.map((practitioner) => (
            <Option key={practitioner.id} value={practitioner.id}>
              {practitioner.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </span>
  );
};

interface FormProps {
  clinicId: string;
  clinicMemberId: string;
}

const ClinicMemberTherapistForm: React.FC<FormProps> = ({
  clinicId,
  clinicMemberId,
}) => {
  const practitionerList = useQuery<
    ApolloResult<"practitioners", Practitioner[]>
  >(get_practitioners, {
    variables: getPractitionersVar({
      clinicId,
      startDate: new Date(),
      endDate: new Date(),
      take: 100,
    }),
    fetchPolicy: "cache-and-network",
  });

  const clinicMember = useQuery<ApolloResult<"clinicMember", ClinicMember>>(
    getOneClinicMember,
    {
      variables: getOneClinicMemberVar(clinicMemberId),
      fetchPolicy: "cache-and-network",
    }
  );

  const [updateClinicMember, { loading, data, error }] = useMutation<
    ApolloResult<"updateOneClinicMember", ClinicMemberUpdate>
  >(updateOneClinicMember);

  useEffect(() => {
    if (loading) return;
    if (error != null) {
      errorMessage();
    } else if (data) {
      successMessage();
    }
  }, [data]);

  const successMessage = () => {
    message.success("Therapist saving successful.");
  };

  const errorMessage = () => {
    message.error("Therapist saving fail!");
  };

  const onFinish = async ({ therapist }: InputProps) => {
    if (therapist == null) return;
    const variables = updateOneClinicMemberVar(clinicMemberId, therapist);
    await updateClinicMember({ variables });
  };

  if (!clinicId) return null;
  if (practitionerList.data == null || clinicMember.data == null) return null;

  return (
    <Form
      name="customized_form_controls"
      layout="inline"
      onFinish={onFinish}
      initialValues={{
        therapist: clinicMember.data.clinicMember.practitioner?.id ?? "",
      }}
    >
      <Input practitionerList={practitionerList.data.practitioners} />
      <Form.Item>
        <Button
          style={{ width: "100%", margin: "8px 0" }}
          type="primary"
          htmlType="submit"
          disabled={loading}
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ClinicMemberTherapistForm;
