import React, { useMemo } from "react";
import { Card, Typography, Avatar, Col, Row } from "antd";
// import { Member } from '../store';
import defaultPhoto from "../assets/user.png";
import { Member } from "../store";
interface P {
  data: Member;
}

export default function MemberInfo(props: P) {
  const { data } = props;
  const member = data.clinic_members.length > 0 ? data.clinic_members[0] : data;

  const credit_balance = useMemo(() => {
    return data.orders.reduce((total, order) => {
      if (order.payment_status !== "PARTIAL_PAID") return total;
      return total + Number(order.credit_balance);
    }, 0);
  }, [data]);

  return (
    <Card
      style={styles.card}
      title={<Typography.Title level={3}>{member.name || ""}</Typography.Title>}
      bordered={false}
      size="small"
    >
      <Row gutter={24}>
        <Col offset={8}>
          <Avatar src={member.image || defaultPhoto} size={120} />
        </Col>
      </Row>
      <Row gutter={24} style={styles.item}>
        <Col span={10}>
          <Typography.Title style={styles.typography} strong>
            Phone
          </Typography.Title>
        </Col>
        <Col span={14}>{member.phonenumber}</Col>
        <Col span={10}>
          <Typography.Title style={styles.typography} strong>
            Member ID
          </Typography.Title>
        </Col>
        <Col span={14}>{member.member_id}</Col>
        <Col span={10}>
          <Typography.Title style={styles.typography} strong>
            Credit Balance
          </Typography.Title>
        </Col>
        <Col span={14}>{credit_balance}</Col>
      </Row>
    </Card>
  );
}

const styles = {
  card: {
    height: "100%",
  },
  cardTitle: {
    textAlign: "center",
  },
  typography: {
    fontSize: 14,
    padding: 2,
  },
  item: {
    marginTop: 5,
  },
  cardMeta: {
    justifyContent: "center",
    display: "flex",
  },
};
