import React, { useEffect, useState } from "react";
import {
  ApolloResult,
  Clinic,
  Member,
  Booking,
  CheckIn,
  MemberServiceBag,
  PSConfig,
  PSAccount,
  PSTransaction,
  User,
} from "../../store";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Row, Col, Typography, Empty, Card, Table } from "antd";
import { useLazyQuery, useQuery } from "@apollo/client";
import { getMemberDetailVar, get_member_detail } from "../../graphql/member";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import MemberInfo from "../../components/member_info";
import MemberBarChart from "../../charts/member_sale_linechart";
import { format } from "date-fns";
import withClinic from "../../hooks/with_clinic";
import { Image } from "antd";
import { getAccountByTypeVar, get_account_by_type } from "../../graphql/pass";
import withPass from "../../hooks/with_pass";
import { formatMoney } from "../../helpers/utils";
import { orderBy } from "lodash";
import { Input, message } from "antd";
import { Space } from "antd";
import { Button } from "antd";
import ButtonGroup from "antd/lib/button/button-group";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Popconfirm } from "antd";
import deleteMember from "../../graphql/delete_member";
import MemberBagEdit from "../../components/member_bag_edit";
import paths from "../../routes/paths";
import ClinicMemberTherapistForm from "./clinic_member_therapist_form";

interface P {
  currentClinic?: Clinic;
  passConfig?: PSConfig;
  user?: User;
}
function MemberDetail(props: P) {
  const navigate = useNavigate();
  const { id: memberId = "" } = useParams() as { id?: string };
  const [filteredData, setFilteredData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [searchParams] = useSearchParams();
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isSupport, setSupport] = useState(false);
  const [deleting, setDelete] = useState(false);
  const [currentBag, setCurrentBag] = useState<MemberServiceBag | null>(null);

  const psConfig = props.passConfig;
  const clinicId = props.currentClinic?.id || "";
  const userId = props.user?.userId || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const { data, loading, error, refetch } = useQuery<
    ApolloResult<"member", Member>
  >(get_member_detail, {
    variables: getMemberDetailVar({ clinicId, memberId }),
  });

  const [getPSAccount, { loading: psLoading, data: psData }] = useLazyQuery<
    ApolloResult<"getNewAccount", PSAccount>
  >(get_account_by_type, {
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
    context: { className: "pass" },
  });
  useEffect(() => {
    if (searchParams.get("mode")) {
      const mode = searchParams.get("mode");
      if (mode === "support") {
        setSupport(true);
      }
    }
  }, [searchParams.get("mode")]);
  useEffect(() => {
    if (data?.member.phonenumber && psConfig?.id) {
      const variables = getAccountByTypeVar(
        data.member.phonenumber,
        psConfig.id
      );
      getPSAccount({ variables });
    }
  }, [data, psConfig]);
  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  // console.log(
  //   data.member.checkin.filter((item) => item.service.name === "Whole Face"),
  //   "data"
  // );
  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);

    const filteredData = data.member.checkin.filter((item) =>
      item.service.name
        .toString()
        .toLowerCase()
        .includes(selectedKeys[0].toLowerCase())
    );
    setFilteredData(filteredData);
  };
  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
    setFilteredData(data.member.checkin);
  };
  const handleDeleteMember = async () => {
    setDelete(true);
    const result = await deleteMember(
      { clinicId, memberId, phoneNumber: data.member.phonenumber },
      userId
    );
    if (result.success) {
      message.success("Successfully Deleted!");
      setTimeout(() => {
        navigate(paths.members);
      }, 1000);
    } else {
      message.error(`Failed with ${result.errors.join(",")}`);
    }
    setDelete(false);
  };

  const firstRow = () => {
    return (
      <Row gutter={24}>
        <Col span={8}>
          <Row>
            <MemberInfo data={data?.member} />
          </Row>
          <Row>
            <ClinicMemberTherapistForm
              clinicId={clinicId}
              clinicMemberId={data.member.clinic_members[0]?.id}
            />
          </Row>
        </Col>
        <Col span={16}>
          <MemberBarChart data={data?.member.orders || []} />
        </Col>
      </Row>
    );
  };
  const secondRow = () => {
    let columnA: any[] = [
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: MemberServiceBag, b: MemberServiceBag) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        render: (v: number, r: MemberServiceBag) => {
          return r.service.name;
        },
      },
      {
        title: "Usage",
        dataIndex: "usage",
        key: "usage",
        editable: false,
        sorter: (a: MemberServiceBag, b: MemberServiceBag) =>
          a.remaining_count - b.remaining_count,
        render: (v: number, r: MemberServiceBag) => {
          return `${r.remaining_count}/${r.original_count}`;
        },
      },
      {
        title: "Package",
        dataIndex: "package",
        key: "package",
        editable: false,
        sorter: (a: MemberServiceBag, b: MemberServiceBag) =>
          (a.service_package?.name || "").localeCompare(
            b.service_package?.name || ""
          ),
        render: (v: number, r: MemberServiceBag) => {
          return r.service_package?.name || "";
        },
      },
    ];
    if (isSupport) {
      columnA.push({
        title: "Action",
        key: "action",
        align: "center",
        width: "10%",
        dataIndex: "action",
        editable: false,
        fixed: "right",
        render: (v: number, r: MemberServiceBag) => {
          const isEditing = currentBag?.id === r.id;
          return (
            <ButtonGroup>
              <span onClick={() => setCurrentBag(r)}>
                {" "}
                <EditOutlined />
              </span>
              {isEditing && (
                <MemberBagEdit
                  userId={props.user?.userId || ""}
                  bag={r}
                  title={`${data.member.name} | ${r.service.name} (${r.remaining_count}/${r.original_count})  `}
                  onClose={() => {
                    setCurrentBag(null);
                  }}
                  onFinish={async () => {
                    await refetch();
                  }}
                />
              )}
            </ButtonGroup>
          );
        },
      });
    }
    let columnB: any[] = [
      {
        title: "Date",
        dataIndex: "from_time",
        key: "from_time",
        editable: false,
        sorter: (a: Booking, b: Booking) =>
          new Date(a.from_time).getTime() - new Date(b.from_time).getTime(),
        render: (v: number, r: Booking) => {
          return format(new Date(r.from_time), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: Booking, b: Booking) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        render: (v: number, r: Booking) => {
          return r.service.name;
        },
      },
      {
        title: "Staus",
        dataIndex: "status",
        key: "status",
        sorter: (a: Booking, b: Booking) => a.status.localeCompare(b.status),
        editable: false,
      },
    ];
    let colummC: any[] = [
      {
        title: "CheckIn Time",
        dataIndex: "in_time",
        key: "in_time",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          new Date(a.in_time).getTime() - new Date(b.in_time).getTime(),
        render: (v: number, r: CheckIn) => {
          return format(new Date(r.in_time), "dd MMM,yyyy h:mm a");
        },
        defaultSortOrder: "desc",
      },
      {
        title: "CheckOut Time",
        dataIndex: "out_time",
        key: "out_time",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          new Date(a.out_time).getTime() - new Date(b.out_time).getTime(),
        render: (v: number, r: CheckIn) => {
          return (
            r.out_time && format(new Date(r.out_time), "dd MMM,yyyy h:mm a")
          );
        },
        defaultSortOrder: "desc",
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          a.service.name
            .toLowerCase()
            .localeCompare(b.service.name.toLowerCase()),
        filterIcon: (filtered: any) => <SearchOutlined />,
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }: any) => (
          <div style={{ padding: 8 }}>
            <Input
              placeholder={`Search name`}
              value={selectedKeys[0]}
              onChange={(e: any) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              // onPressEnter={() => handleSearch(selectedKeys, confirm, "name")}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, "name")}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                disabled={!selectedKeys[0]}
              >
                Search
              </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Reset
              </Button>
            </Space>
          </div>
        ),
        render: (v: number, r: CheckIn) => {
          return r?.service?.name;
        },
      },

      {
        title: "Therapist",
        dataIndex: "therapist",
        key: "therapist",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          a?.practitioner?.name
            .toLowerCase()
            .localeCompare(b?.practitioner?.name.toLowerCase()),
        render: (v: number, r: CheckIn) => {
          return r?.practitioner?.name;
        },
      },
      {
        title: "Helper",
        dataIndex: "Helper",
        key: "service_helper",
        editable: false,
        sorter: (a: CheckIn, b: CheckIn) =>
          a?.booking?.service_helper?.name
            .toLowerCase()
            .localeCompare(b?.booking?.service_helper?.name.toLowerCase()),
        render: (v: number, r: CheckIn) => {
          return r?.booking?.service_helper?.name;
        },
      },
      {
        title: "note",
        dataIndex: "merchant_note",
        key: "merchant_note",
        editable: false,
      },
      {
        title: "Staus",
        dataIndex: "status",
        key: "status",
        sorter: (a: CheckIn, b: CheckIn) => a.status.localeCompare(b.status),
        editable: false,
      },
    ];
    // console.log('member bags', data.member.member_service_bags)
    return (
      <Row style={{ marginTop: 5 }}>
        <Col span={12}>
          <Card
            loading={false}
            size="small"
            type="inner"
            title={`Purchased Services`}
            style={styles.card}
          >
            <Table
              loading={loading}
              showHeader={data.member.member_service_bags.length > 0}
              size="small"
              columns={columnA}
              dataSource={(data.member.member_service_bags || []).filter(
                (b) => b.remaining_count > 0
              )}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            type="inner"
            title={`Bookings`}
            size="small"
            style={styles.card}
          >
            <Table
              loading={loading}
              showHeader={(data?.member.bookings || []).length > 0}
              size="small"
              columns={columnB}
              dataSource={(data?.member.bookings || []).filter(
                (b) => b.status === "BOOKED" || b.status === "REQUEST"
              )}
              pagination={{ pageSize: 5 }}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card
            title={`CheckIns/CheckOuts`}
            size="small"
            type="inner"
            style={styles.card}
          >
            <Table
              loading={loading}
              showHeader={(data?.member?.checkin || []).length > 0}
              size="small"
              columns={colummC}
              dataSource={searchedColumn ? filteredData : data?.member.checkin}
              pagination={{ pageSize: 5 }}
              onFilter={(value: any, record: any) =>
                record[searchedColumn]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              }
            />
          </Card>
        </Col>
      </Row>
    );
  };
  const thirdRow = () => {
    let column: any[] = [
      {
        title: "Before",
        dataIndex: "before",
        key: "before",
        editable: false,
        width: 150,
        align: "center",
        render: (v: number, r: CheckIn) => {
          return (
            <span>
              <Image src={r.images[0].image} />
              Before
            </span>
          );
        },
      },
      {
        title: "After",
        dataIndex: "after",
        key: "after",
        width: 150,
        editable: false,
        render: (v: number, r: CheckIn) => {
          return (
            <span>
              {" "}
              <Image src={r.images[1].image} />
              After
            </span>
          );
        },
      },
      {
        title: "Service",
        dataIndex: "service",
        key: "service",
        editable: false,
        align: "left",
        sorter: (a: CheckIn, b: CheckIn) =>
          a.service.name.localeCompare(b.service.name),
        render: (v: number, r: CheckIn) => {
          return (
            <span>
              <Typography.Title level={5}>{r.service.name}</Typography.Title>{" "}
              {format(new Date(r.created_at), "dd MMM,yyyy")}{" "}
            </span>
          );
        },
      },
    ];
    const divideArr = (arr: any[]) => {
      const middle = Math.ceil(arr.length / 2);
      const firstHalf = arr.slice(0, middle);
      const secondHalf = arr.slice(middle);
      return { firstHalf, secondHalf };
    };
    const filterCheckIn = (ci: CheckIn) => {
      return ci.images.length >= 2;
    };
    const { firstHalf = [], secondHalf = [] } = divideArr(
      data.member.checkin.filter((c) => filterCheckIn(c))
    );
    if (firstHalf.length <= 0) return null;
    return (
      <Card
        loading={false}
        type="inner"
        size="small"
        title={`Treatment History`}
      >
        <Row style={{ marginTop: 4 }}>
          <Col span={12}>
            <Table
              loading={loading}
              showHeader={false}
              size="small"
              columns={column}
              dataSource={firstHalf}
              pagination={false}
            />
          </Col>
          <Col span={12}>
            <Table
              loading={loading}
              showHeader={false}
              size="small"
              columns={column}
              dataSource={secondHalf}
              pagination={false}
            />
          </Col>
        </Row>
      </Card>
    );
  };
  const wRow = () => {
    if (!psData || !psData.getNewAccount) return null;
    const wcolumns = [
      {
        title: "Date",
        dataIndex: "created_at",
        key: "created_at",
        editable: false,
        sorter: (a: PSTransaction, b: PSTransaction) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
        render: (v: number, r: PSTransaction) => {
          return format(new Date(r.created_at), "dd MMM,yyyy h:mm a");
        },
      },
      {
        title: "Status",
        dataIndex: "transaction_status",
        key: "transaction_status",
        editable: false,
        sorter: (a: PSTransaction, b: PSTransaction) =>
          a.transaction_status.localeCompare(b.transaction_status),
      },
      {
        title: "Amount",
        dataIndex: "balance",
        key: "balance",
        editable: false,
        sorter: (a: PSTransaction, b: PSTransaction) =>
          Number(a.balance) - Number(b.balance),
        render: (v: number, r: PSTransaction) => {
          return formatMoney(Number(r.balance));
        },
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        editable: false,
      },
    ];
    return (
      <Card
        size="small"
        type="inner"
        title={
          <>
            <Typography.Title level={3}>
              Wallet Info = {formatMoney(Number(psData.getNewAccount.balance))}
            </Typography.Title>
            <Typography.Title level={3}>{} </Typography.Title>
          </>
        }
        // extra={
        //     <>
        //         <Typography.Title level={3}>{formatMoney(Number(psData.getNewAccount.balance))} </Typography.Title>
        //     </>
        // }
      >
        <Table
          loading={psLoading}
          showHeader={(psData.getNewAccount.transactions || []).length > 0}
          size="small"
          columns={wcolumns}
          dataSource={orderBy(
            psData.getNewAccount.transactions || [],
            "created_at",
            "desc"
          )}
          pagination={{ pageSize: 5 }}
        />
      </Card>
    );
  };
  const supportRow = () => {
    return (
      <Card
        size="small"
        type="inner"
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
        }}
      >
        <Popconfirm
          title={`Are you sure to delete  (${data.member.name})  at clinic (${props.currentClinic?.name})`}
          okText="Yes"
          cancelText="No"
          onConfirm={handleDeleteMember}
        >
          <Button icon={<DeleteOutlined />} loading={deleting} danger>
            Delete
          </Button>
        </Popconfirm>
      </Card>
    );
  };
  return (
    <React.Fragment>
      {isSupport && supportRow()}
      {firstRow()}
      {secondRow()}
      {wRow()}
      {thirdRow()}
    </React.Fragment>
  );
}
export default withClinic(withPass(MemberDetail));
const styles = {
  card: {
    height: "100%",
  },
};
