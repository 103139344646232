import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Therapist Appointment",
    },
  },
  scales: {
    y: {
      display: false
    }
  }
};
const getDataSource = (labels: any[], data: any[]) => ({
  labels,
  datasets: [
    {

      label: "appointment",
      data,
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    }
  ]
})
interface P {
  title: string;
  labels: any[],
  data: any[];
}
const BarChart = (props: P) => {
  return <Bar options={options} data={getDataSource(props.labels, props.data)} />;
};

export default BarChart;
