import React, { useCallback, useEffect, useState } from "react";
import withClinic from "../../hooks/with_clinic";
import {
  ApolloResult,
  CheckIn,
  Clinic,
  Practitioner,
  Service,
} from "../../store";
import { Row, Col, Typography, Empty } from "antd";
import Search from "antd/lib/input/Search";
import DateRange from "../../components/date_range";
import { Form } from "antd";
import { Table } from "antd";
import { addDays, endOfMonth, format, startOfMonth, subDays } from "date-fns";
import { useLazyQuery, useQuery } from "@apollo/client";
import LoadingSpinner from "../../components/loading_indicator";
import Error from "../../components/apollo_error";
import {
  getPractitionerCheckInCountVar,
  // getservicesVar,
  get_practitioner_checkin_count,
  get_practitioners,
} from "../../graphql/practitioner";
import { ExportExcel } from "../../helpers/excel";
import { FileExcelFilled } from "@ant-design/icons";
import { Button } from "antd";
import {
  excelTopTreatmentCheckInCountVar,
  excelTopTreatmentVar,
  excel_top_treatment,
  excel_top_treatment_checkin_count,
  getTopTreatmentCheckInCountVar,
  getTopTreatmentVar,
  get_top_treatment,
  get_top_treatment_checkin_count,
} from "../../graphql/reports/top_treatment";
import client from "../../graphql/apollo_client";
import { getCheckInsVar, get_checkins } from "../../graphql/checkin";
import {
  getTopMemberCheckInCountVar,
  get_top_member_checkin_count,
} from "../../graphql/export";
interface P {
  currentClinic?: Clinic;
}

const TopMemberReport = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  // const clinicId = "clhobuntc0002s6019ajmqkcj";
  const [searchText, setSearchText] = useState<string | null>(null);
  const [exporting, setExporting] = useState(false);

  const [ranges, setRanges] = useState({
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  });
  const [form] = Form.useForm();
  const variables = getCheckInsVar({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    take: 150,
    status: "CHECKOUT",
  });
  const { loading, data, error } = useQuery<
    ApolloResult<"checkIns", CheckIn[]>
  >(get_checkins, { variables });
  // console.log(data?.checkIns, "get_checkins");

  const [getCheckInCount, { data: checkInData, loading: cload }] = useLazyQuery<
    ApolloResult<
      "groupByCheckIn",
      Array<{ _count: { _all: number }; member_id: string }>
    >
  >(get_top_member_checkin_count);
  // console.log(checkInData, "CheckINData");

  const checking = cload;

  const checkInCount = Object.assign(
    {},
    ...(checkInData?.groupByCheckIn || []).map((a) => ({
      [a.member_id]: a._count._all,
    }))
  );
  // console.log(checkInCount, "checkIn COunt pr");
  const load = useCallback(
    (v: { memberIds: string[]; startDate: Date; endDate: Date }) => {
      getCheckInCount({
        variables: getTopMemberCheckInCountVar({ ...v, clinicId }),
      });
    },
    [clinicId, getCheckInCount]
  );
  useEffect(() => {
    if (data?.checkIns && data.checkIns.length > 0) {
      const memberIds = data?.checkIns.map((p) => p.member_id);
      const { startDate, endDate } = ranges;
      load({ memberIds, startDate, endDate });
    }
  }, [data, ranges, load]);

  const handleExport = async () => {
    const fileName =
      "treatment_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];

    let list: Service[] = [];
    let finalData: any[] = [];
    let serviceCheckInCount;
    let rolling = true;
    const take = 100;
    let skip = 0;
    setExporting(true);
    while (rolling) {
      const variables = excelTopTreatmentVar({
        clinicId,
        take,
        skip,
        // status: "CHECKOUT",
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      });
      const result = await client.query<ApolloResult<"services", Service[]>>({
        query: excel_top_treatment,
        variables,
      });
      const variables2 = excelTopTreatmentCheckInCountVar({
        clinicId,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
        serviceIds: result.data.services.map((v) => v.id),
      });
      const checkInCount = await client.query<
        ApolloResult<
          "groupByCheckIn",
          Array<{ _count: { _all: number }; service_id: string }>
        >
      >({
        query: excel_top_treatment_checkin_count,
        variables: variables2,
      });

      serviceCheckInCount = Object.assign(
        {},
        ...(checkInCount?.data.groupByCheckIn || []).map((a) => ({
          [a.service_id]: a._count._all,
        }))
      );

      // console.log(finalData);

      const _services = result.data.services || [];
      list = list.concat(_services);

      skip += _services.length;
      rolling = _services.length === take;
    }
    let totalCheckOutCount = 0;
    list.sort((a, b) => {
      const countA = checkInCount[a.id] || 0;
      const countB = checkInCount[b.id] || 0;
      return countB - countA;
    });

    for (const t of list) {
      const Service = t.name;
      const CheckOutCount = serviceCheckInCount[t.id] || 0;

      excelData.push({
        Service,
        CheckOutCount,
      });
      totalCheckOutCount += CheckOutCount;
    }
    excelData.push({
      Service: "total",
      CheckOutCount: totalCheckOutCount,
    });
    ExportExcel(excelData, fileName);
    setExporting(false);
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
    if (data?.checkIns && data?.checkIns?.length > 0) {
      const memberIds = data.checkIns.map((p) => p.member_id);
      load({ memberIds, startDate, endDate });
    }
  };
  const columns: any[] = [
    {
      title: "Customer",
      dataIndex: "member",
      key: "member",
      editable: false,
      sorter: (a: CheckIn, b: CheckIn) =>
        a.member.name.toLowerCase().localeCompare(b.member.name.toLowerCase()),
      render: (text: number, record: CheckIn) => <p>{record.member.name}</p>,
    },
    {
      title: "CheckIn",
      dataIndex: "check_in",
      key: "check_in",
      sorter: (a: CheckIn, b: CheckIn) =>
        (checkInCount[b.id] || 0) - (checkInCount[a.id] || 0),
      sortOrder: "ascend",
      render: (text: number, record: CheckIn) =>
        checkInCount[record.member.id] || 0,
    },
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>
            <Typography level={2}>Top Member Report</Typography>
          </Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${
                props.currentClinic?._count.members || 0
              } members...`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
      <Col span={2}>
        <Button
          loading={exporting}
          icon={<FileExcelFilled />}
          onClick={handleExport}>
          Export
        </Button>
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            loading={checking}
            showHeader={data?.checkIns?.length > 0}
            //components={{ body: { cell: EditableCell, } }}
            // expandable={{ expandedRowRender, onExpandedRowsChange }}
            dataSource={data.checkIns.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            pagination={false}
          />
        </Form>
      </Col>
    </Row>
  );
  // const filters = (
  //   <Row style={{ marginTop: 4 }}>
  //     <Col span={24}>
  //       <MultiSelect
  //         selected={filter}
  //         placeholder="Select therapist status"
  //         options={["ACTIVE", "INACTIVE"]}
  //         onChange={setFilter}
  //       />
  //     </Col>
  //   </Row>
  // );

  return (
    <React.Fragment>
      {control}
      {/* {filters} */}
      {context}
    </React.Fragment>
  );
};

export default withClinic(TopMemberReport);
