import React, { useState } from 'react'
import withClinic from '../hooks/with_clinic';
import { ApolloResult, Booking, CheckIn, Clinic, Order, Product, Service, } from '../store';
import { Row, Col, Typography, Empty } from 'antd';
import Search from 'antd/lib/input/Search';
import DateRange from '../components/date_range';
import { Form } from 'antd';
import { Table } from 'antd';
import { addDays, format, subDays } from 'date-fns';
import ButtonGroup from 'antd/lib/button/button-group';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../components/loading_indicator';
import Error from '../components/apollo_error';
import MultiSelect from '../components/multi_select';
import { formatMoney } from '../helpers/utils';
import { Avatar } from 'antd';
import { getServicesVar, get_services } from '../graphql/service';
import { getProductsVar, get_products } from '../graphql/product';
interface P {
    currentClinic?: Clinic
}

const ProductPage = (props: P) => {
    const clinicId = props.currentClinic?.id || "";
    // const clinicId = "clhobuntc0002s6019ajmqkcj";
    const [searchText, setSearchText] = useState<string | null>(null);
    const [filter, setFilter] = useState<string[]>([]);
    const [ranges, setRanges] = useState({ startDate: subDays(new Date(), 30), endDate: addDays(new Date(), 15) })
    const [form] = Form.useForm();
    const variables = getProductsVar({ clinicId, startDate: ranges.startDate, endDate: ranges.endDate, searchText, take: 150, status: filter });
    const { loading, data, error } = useQuery<ApolloResult<"products", Product[]>>(get_products, { variables })
    if (loading) return <LoadingSpinner />
    if (error) return <Error error={error} />
    if (!data) return <Empty />
    const onDateChange = (startDate: Date, endDate: Date) => {
        setRanges({ startDate, endDate });
    }
    const columns: any[] = [

        {
            title: 'Photo',
            dataIndex: "image",
            key: 'image',
            editable: false,
            render: (v: number, r: Product) => {
                return <Avatar src={r.images.length > 0 ? r.images[0].image : null} size={50} />;
            },
        },
        {
            title: 'Name',
            dataIndex: "name",
            key: "name",
            editable: false,
            sorter: (a: Product, b: Product) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            defaultSortOrder:"ascend"

        },
        {
            title: 'Staus',
            dataIndex: "status",
            key: 'status',
            sorter: (a: Product, b: Product) => a.status.localeCompare(b.status),
            editable: false,
        },
        {
            title: 'Measurement',
            dataIndex: "measurement_amount",
            key: 'measurement_amount',
            sorter: (a: Product, b: Product) => Number(a.measurement_amount) - Number(b.measurement_amount),
            editable: true,
            render: (v: number, r: Product) => {
                return `${formatMoney(Number(r.measurement_amount))} ${r.measurement.name}`;
            },
        },
        {
            title: 'Brand',
            dataIndex: "brand",
            key: 'brand',
            sorter: (a: Product, b: Product) => a.brand.name.localeCompare(b.brand.name),
            editable: true,
            render: (v: number, r: Product) => {
                return `${r.brand.name}`;
            },
        },
        {
            title: 'Sort Order',
            dataIndex: "sort_order",
            key: "sort_order",
            editable: false,
            sorter: (a: Service, b: Service) => a.sort_order - b.sort_order,
        },
        {
            title: 'CreatedAt',
            dataIndex: "created_at",
            key: 'created_at',
            editable: false,
            sorter: (a: Order, b: Order) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (v: Order, r: Order) => {
                return format(new Date(r.created_at), 'dd MMM,yyyy');
            },
        },
    ];
    const control = (
        <Row style={{ marginTop: -18 }}>
            <Col flex={4}>
                <Row >
                    <Col span={8}>
                        <Typography level={2}>Products</Typography>

                    </Col>
                    <Col span={8} offset={8}>
                        <Search
                            defaultValue={searchText || undefined}
                            placeholder="search ...."
                            allowClear
                            size="middle"
                            onSearch={(val: any) => setSearchText(val)}
                        />

                    </Col>
                </Row>
            </Col>
            <Col flex={0}>
                <DateRange startDate={ranges.startDate} endDate={ranges.endDate} onSelect={onDateChange} />
            </Col>
        </Row>
    );
    const context = (
        <Row style={{ marginTop: 8 }}>
            <Col span={24}>
                <Form form={form} component={false}>
                    <Table
                        showHeader={data.products.length > 0}
                        //components={{ body: { cell: EditableCell, } }}
                        // expandable={{ expandedRowRender, onExpandedRowsChange }}
                        dataSource={data.products.map(b => ({ key: b.id, ...b }))}
                        columns={columns}
                        rowClassName="editable-row"
                        pagination={false}
                    />
                </Form>
            </Col>
        </Row>
    );
    const filters = (
        <Row style={{ marginTop: 4 }}>
            <Col span={24}>
                <MultiSelect
                    selected={filter}
                    placeholder='Select product status'
                    options={["ACTIVE", "INACTIVE"]}
                    onChange={setFilter} />
            </Col>
        </Row>
    )

    return (
        <React.Fragment>
            {control}
            {filters}
            {context}
        </React.Fragment>
    )
}

export default withClinic(ProductPage);
