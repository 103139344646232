import { gql } from "@apollo/client";

export const get_clinics = gql`
query Clinics($where: ClinicWhereInput) {
    clinics(where: $where) {
      id
      logo
      name
      company_id
      code
      pass
      company {
        name 
      }
      _count {
        members
        bookings
        practitioners
      }
    }
  }
`;

export const getClinicVariables = (ids: string[] = []) => ({ where: { id: { in: ids } } })
export const get_clinic = gql`
query GetClinic($where: ClinicWhereUniqueInput!) {
  getClinic(where: $where) {
    code
    hostname
    id
    logo
    name
    printer_logo
    address
    description
    phonenumber
    opening_in
    closing_in
    pass
    images {
      id
      name
      image
    }
    bank_accounts {
      id
      account_number
      account_name
      account_type_id
      account_type {
        code
        id
        name
      }
    }
  }
}

`;
export const update_clinic = gql`
mutation UpdateOneClinic(
  $data: ClinicUpdateInput!
  $where: ClinicWhereUniqueInput!
) {
  updateOneClinic(data: $data, where: $where) {
    id
  }
}

`;
export const getClinicVar = (clinicId: string) => ({
  where: { id: clinicId }
})
export const getClinicUpdateVar = (id: string, data: any, connectors?: any) => {
  let variables: any = {}, update: any = {};
  variables.where = { id }
  if (typeof data === 'object') {
    update = Object.assign({}, ...Object.keys(data).map((k, i) => ({ [k]: { "set": data[k] } })))
  }
  variables.data = update;
  if (connectors && typeof connectors === 'object') {
    variables.data = { ...variables.data, ...connectors }
  }
  return variables;
}