import { ApolloClient, InMemoryCache, from } from "@apollo/client";
//import auth_link from './links/auth_link';
import http_link from './links/http_link';

const link = from([http_link]);
const cache = new InMemoryCache({
    addTypename: false,
});

const client = new ApolloClient({
    link,
    cache,
});


export default client;

