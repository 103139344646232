import React, { useEffect, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Modal, Input, Form } from 'antd';
import { Button, Row, Space, Select } from 'antd';
import { Clinic, SystemLog } from '../store';
import type { FormInstance } from 'antd/es/form';

// {"key":"clh8is1g30067s601c86o15n2-25f28a8fb44468989ba985399088b88",
// "url":"https://us-central1-piti-pass.cloudfunctions.net/api/v0/quick_transfer",
// "transfer_url":"https://us-central1-piti-pass.cloudfunctions.net/api/v0/transfer",
// "refresh_token_url": "https://us-central1-piti-pass.cloudfunctions.net/api/v0/refresh_token",
// "refresh_token":"APZUo0STw7fWzY90TlpbeVE6RhM8YSwVsXdtNYuKn8DebUjdmW76XPe1oXHFGuelOBRbYkRzKkjf7hEzr9M9i2MaEsFihE4QCuCWgUesNqJhetTD9RkNoC4hcJXeRxRjCmC_vU9thPWHFd3VVOBsbdcsuQXVxBh9cy-KpCd6n4pYPo_n2MPDDfm9myrwtR-CE3hpjjvASbQo",
// "id":"piti"}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
interface P {
    currentClinic?: Clinic;
    saving: boolean;
    title: string;
    onSave: (val: GTPassConfig) => void;
}
interface FormData {
    env: string;
    id: string;
    key: string;
    refresh_token: string;
}
interface GTPassConfig {
    id: string;
    env?: string;
    key: string;
    url: string;
    transfer_url: string;
    refresh_token_url: string;
    refresh_token: string;
}
const urls = {
    staging: {
        url: "https://apiv1-44lxzoj7ya-uc.a.run.app/v0/quick_transfer",
        transfer_url: "https://apiv1-44lxzoj7ya-uc.a.run.app/v0/transfer",
        refresh_token_url: "https://apiv1-44lxzoj7ya-uc.a.run.app/v0/refresh_token",
    },
    production: {
        url: "https://apiv1-44lxzoj7ya-uc.a.run.app/v1/quick_transfer",
        transfer_url: "https://apiv1-44lxzoj7ya-uc.a.run.app/v1/transfer",
        refresh_token_url: "https://apiv1-44lxzoj7ya-uc.a.run.app/v1/refresh_token",
    }
}


const PassConfig = (props: P) => {
    const formRef = React.useRef<FormInstance<FormData>>(null);
    const [show, setShow] = useState(false);
    useEffect(() => { formRef.current?.resetFields(); }, [])
    useEffect(() => {
        if (show === true) {
            if (props.currentClinic?.pass) {
                const ps = JSON.parse(props.currentClinic.pass) as GTPassConfig;
                const fData: FormData = {
                    env: ps.env || "",
                    id: ps.id || "",
                    key: ps.key,
                    refresh_token: ps.refresh_token,
                }
                formRef.current?.setFieldsValue({ ...fData });
            }
        }
    }, [show])
    const handleOk = (val: FormData) => {
        let config: GTPassConfig = { ...val, ...urls[val.env as "staging" | "production"] }
        props.onSave(config);
        setShow(false)
    }
    return (
        <React.Fragment>
            <Button
                loading={props.saving || false}
                icon={<SettingOutlined />}
                onClick={() => setShow(true)}
            />
            <Modal
                title={`${props.title || ""}`}
                open={show}
                onCancel={() => setShow(false)}
                footer={[
                    <Button loading={props.saving} type="primary" form="passForm" key="submit" htmlType="submit">
                        Save
                    </Button>
                ]}
            >
                <Form
                    {...layout}
                    id="passForm"
                    ref={formRef}
                    name="control-ref"
                    onFinish={handleOk}
                    style={{ maxWidth: 800 }}
                >

                    <Form.Item
                        label="Environment"
                        name="env"
                        rules={[{ required: true, message: 'Environment is required' }]}
                    >
                        <Select
                            options={[
                                { value: "staging", label: "Staging" },
                                { value: "production", label: "Production" }
                            ]}
                            style={{ width: 150 }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Pass ID"
                        name="id"
                        rules={[{ required: true, message: 'Pass ID is required' }]}
                    >
                        <Input />
                    </Form.Item >

                    <Form.Item
                        label="Signature Key"
                        name="key"
                        rules={[{ required: true, message: 'Signature key is required' }]}

                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Refresh Token"
                        name="refresh_token"
                        rules={[{ required: true, message: 'Refresh token is required' }]}

                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal >
        </React.Fragment >
    );
};

export default PassConfig;